import React, { Component } from 'react';
import './App.css';
import CommunityItem from './CommunityItem';
import img_elLogo from './images/UpdateInfoScreen_elLogo_1476464072.png';
import img_elFacebookcircle from './images/CommunityScreen_elFacebookcircle.png';
import img_elFb from './images/UpdateInfoScreen_elFb_660566527.png';
import img_elInstagramcircle from './images/CommunityScreen_elInstagramcircle.png';
import img_elIg from './images/UpdateInfoScreen_elIg_98352411.png';
import img_elTwittercircle from './images/CommunityScreen_elTwittercircle.png';
import img_elTwitter from './images/UpdateInfoScreen_elTwitter_1312850244.png';
import btn_icon_1841739048 from './images/btn_icon_1841739048.png';
import img_elLogoznesthorizwhite from './images/CommunityScreen_elLogoznesthorizwhite.png';
import img_elBeta from './images/CommunityScreen_elBeta.png';
import Drawer1 from './Drawer1';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';

// Bootstrap framework component imports

import { Container,Row,Col,NavLink } from 'react-bootstrap';
import Header from './includes/header';
import { FaSearch } from 'react-icons/fa';
export default class CommunityScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, ds_SlotUserPhoto, ds_SlotUserID, dataSheetRow

  constructor(props) {
    super(props);

    this.state = {
      textnumberofitems: (<div>Number of items</div>),
      textnumberofitems_plainText: "Number of items",
      pickerNumberOfRows: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_numberOfCommunities'] : ''),
      drawer1_isOpen: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  pickerValueChanged_elPickerNumberOfRows = (event) => {
    this.setState({pickerNumberOfRows: event.target.value});

    this.props.appActions.updateDataSlot("ds_numberOfCommunities", event.target.value);
  }

  onClick_elIconButton = async (ev) => {
    this.toggle_drawer1();

  }


  onClick_elLogoznesthorizwhite = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { ...this.props, transitionId: 'fadeIn' });

  }


  toggle_drawer1 = () => {
    this.setState({drawer1_isOpen: !this.state.drawer1_isOpen});
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    // eslint-disable-next-line
    const dataSheet_communities = this.props.dataSheets['communities'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };

    // Embedded HTML content for element 'embed'
    // const htmlContent_embed = "<iframe width=“500\" height=\"450\" frameborder=\"0\" style=\"border:0\"\nsrc=\"http://localhost:3002/leaflet-map.html\" allowfullscreen></iframe> ";

    const style_elEmbed = {
      pointerEvents: 'auto',
     };

    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('communities').items);
    this._elList_items = [];

    var mapMarkers = "";

    for (var i=0; i < items_list.length; i++) {
       mapMarkers += "&coords=";
       mapMarkers += items_list[i].communityLat;
       mapMarkers += ",";
       mapMarkers += items_list[i].communityLong;
    }

    var htmlContent_embed = "<iframe  class=\"map\" frameborder=\"0\" style=\"border:0\"\nsrc=\"https://znest.com/leaflet-map.html?" + mapMarkers + "&zoom=12\" allowfullscreen></iframe> ";
    
    // const style_elList = {
    //   height: 'auto',  // This element is in scroll flow
    //  };

    // const style_elBkgdOutlineOverall = {
    //   display: 'block',
    //   color: '#fff',
    //   textAlign: 'center',
    //   backgroundColor: '#39527f',
    //  };
    // const style_elTextNumberOfItems = {
    //   color: 'rgba(0, 0, 0, 0.8500)',
    //   textAlign: 'right',
    //   display: 'none',
    //  };

    // let cssClass_progressIndicator = 'circularProgressIndicator';
    // if (this.props.appActions.isLoading())
    //   cssClass_progressIndicator += ' circularProgressIndicator-active';


    // let selection_pickerNumberOfRows = this.state.pickerNumberOfRows;
    // // Source datasheet and selected data column for picker element 'pickerNumberOfRows'
    // const dataSource_pickerNumberOfRows = this.props.appActions.getDataSheet('pickerNumberOfRows');
    // const valueColumnName_pickerNumberOfRows = 'numberOfRows';

    // const style_elPickerNumberOfRows = {
    //   pointerEvents: 'auto',
    //   display: 'none',
    //  };
    // const style_elLogo = {
    //   height: 'auto',
    //   display: 'none',
    //  };

    // const style_elButtonAboutUs = {
    //   display: 'block',
    //   textTransform: 'uppercase',
    //   fontSize: 10.4,
    //   color: '#feffff',
    //   textAlign: 'center',
    //   backgroundColor: 'transparent',
    //  };

    // const style_elButtonLearnMore = {
    //   display: 'block',
    //   textTransform: 'uppercase',
    //   fontSize: 10.4,
    //   color: '#feffff',
    //   textAlign: 'center',
    //   backgroundColor: 'transparent',
    //  };

    // const style_elFacebookcircle = {
    //   backgroundImage: 'url('+img_elFacebookcircle+')',
    //   backgroundSize: '100% 100%',
    //  };
    // const style_elFb = {
    //   height: 'auto',
    //   display: 'none',
    //  };

    // const style_elInstagramcircle = {
    //   backgroundImage: 'url('+img_elInstagramcircle+')',
    //   backgroundSize: '100% 100%',
    //  };
    // const style_elIg = {
    //   height: 'auto',
    //   display: 'none',
    //  };

    // const style_elTwittercircle = {
    //   backgroundImage: 'url('+img_elTwittercircle+')',
    //   backgroundSize: '100% 100%',
    //  };
    // const style_elTwitter = {
    //   height: 'auto',
    //   display: 'none',
    //  };

    // const style_elIconButton = {
    //   display: 'block',
    //   textTransform: 'uppercase',
    //   backgroundImage: 'url('+btn_icon_1841739048+')',
    //   backgroundRepeat: 'no-repeat',
    //   backgroundSize: '76.387%',
    //   backgroundPosition: '50% 0%',
    //   color: '(null)',
    //   textAlign: 'left',
    //   backgroundColor: 'transparent',
    //   cursor: 'pointer',
    //   pointerEvents: 'auto',
    //  };

    // const style_elLogoznesthorizwhite = {
    //   backgroundImage: 'url('+img_elLogoznesthorizwhite+')',
    //   backgroundSize: '100% 100%',
    //   cursor: 'pointer',
    //   pointerEvents: 'auto',
    //  };

    // const style_elBeta = {
    //   backgroundImage: 'url('+img_elBeta+')',
    //   backgroundSize: '100% 100%',
    //  };
    // const style_drawer1 = {
    //   pointerEvents: (this.state.drawer1_isOpen) ? 'auto' : 'none'
    // }

    return (
      <div className="AppScreen CommunityScreen" style={baseStyle}>
      <Header/>

        {/* <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div> */}

        <div className="layoutFlow" style={layoutFlowStyle}>
        <div className="col-md-10 mx-auto">

        <Container className="pt-5">
         {/* Search Section */}
         <Row>
         <Col lg={3}></Col>
         <Col lg={6}>
        <div className="flowRow flowRow_HomeScreen_elZipAutoComplete_758871 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_ZipCodeExploreCommunity" type="text" placeholder="Search by City,Zip, Community name" onChange={this.textInputChanged_elZipCodeExplore} value={this.state.zipCodeExplore}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_elSearchButton"  onClick={this.onClick_elSearchButton} >
            <FaSearch />
            </button>
          </div>
         </div> 
         </Col>
         <Col lg={3}></Col>
       </Row>
         {/* Map Section */}
        <Row className="pt-5">
        <h4 className="text-left title-bold pb-3"><b>6 facilities found around Los Angles, CA 900068</b></h4>

          <Col lg={6} md={6} xs={12}>
           <div className="elImageIL pb-5">
           <div className="embeddedContent elEmbed">
            <div style={style_elEmbed}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
            </div>
          </div>
             </div>
          </Col>
          <Col lg={6} md={12} xs={12} className="text-center pl-3">
          <div className="elImageAL pb-5">
          <div className="hasNestedComps elList pb-5">
            <ul class="list-details">
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <CommunityItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'communities'} dataSheetRow={row} {...{ 'communityType': row['communityType'], 'communityCoverImage': row['communityCoverImage'], 'communityAddress': row['communityAddress'], 'communityName': row['communityName'], 'communityPhone': row['communityPhone'], 'communityCityStateZip': row['communityCityStateZip'], 'index': row['index'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
            </div>
          </div>
          </Col>
          </Row>
          </Container>

          {/* <div className="embeddedContent elEmbed">
            <div style={style_elEmbed}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
            </div>
          </div>

          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <CommunityItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'communities'} dataSheetRow={row} {...{ 'communityType': row['communityType'], 'communityCoverImage': row['communityCoverImage'], 'communityAddress': row['communityAddress'], 'communityName': row['communityName'], 'communityPhone': row['communityPhone'], 'communityCityStateZip': row['communityCityStateZip'], 'index': row['index'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div> */}
        </div>

        {/* <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elBkgdOutlineOverall" style={style_elBkgdOutlineOverall} />
            <div className="baseFont elTextNumberOfItems" style={style_elTextNumberOfItems}>
              <div>{this.state.textnumberofitems}</div>
            </div>
            <div className="elProgressIndicator">
              <svg className={cssClass_progressIndicator}>
                <circle className="circularProgress-animatedPath" style={{stroke: 'rgba(0, 0, 0, 0.8500)'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
            <Select className="baseFont elPickerNumberOfRows" style={style_elPickerNumberOfRows}  onChange={this.pickerValueChanged_elPickerNumberOfRows} value={selection_pickerNumberOfRows} >
              {dataSource_pickerNumberOfRows.items.every(item => {
                return item[valueColumnName_pickerNumberOfRows] !== selection_pickerNumberOfRows;
              }) ? <Option value=''/> : null}
              {dataSource_pickerNumberOfRows.items.map(item => {
                const colValue = item[valueColumnName_pickerNumberOfRows];
                const labelColValue = item[valueColumnName_pickerNumberOfRows];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
            <img className="elLogo" style={style_elLogo} src={img_elLogo} alt=""  />
            <button className="font-SFUITextRegular  elButtonAboutUs" style={style_elButtonAboutUs}>
              About Us
            </button>
            <button className="font-SFUITextRegular  elButtonLearnMore" style={style_elButtonLearnMore}>
              learn more
            </button>
            <div className="elFacebookcircle" style={style_elFacebookcircle} />
            <img className="elFb" style={style_elFb} src={img_elFb} alt=""  />
            <div className="elInstagramcircle" style={style_elInstagramcircle} />
            <img className="elIg" style={style_elIg} src={img_elIg} alt=""  />
            <div className="elTwittercircle" style={style_elTwittercircle} />
            <img className="elTwitter" style={style_elTwitter} src={img_elTwitter} alt=""  />
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
            <div className="elLogoznesthorizwhite" style={style_elLogoznesthorizwhite} onClick={this.onClick_elLogoznesthorizwhite}  />
            <div className="elBeta" style={style_elBeta} />
          </div>
        </div>
        <div className="drawer drawer1" style={style_drawer1} onClick={this.toggle_drawer1}>
          <div className={"drawerContent" + (this.state.drawer1_isOpen ? " drawerOpen": " drawerClosedLeft")} onClick={(e) => {e.stopPropagation();}}>
            <Drawer1 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_drawer1} />
          </div>
        </div>
      </div> */}
      </div>
      </div>
    )
  }

}
