import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Drawer1 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#464646',
     };
    
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_elButtonCopy = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    return (
      <div className="Drawer1">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" >
              About Us
            </Button>
          </div>
          
          <div className="elButtonCopy">
            <Button className="actionFont" style={style_elButtonCopy}  color="accent" >
              Learn More
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
