import React, { Component } from 'react';
import './App.css';
// import UploadPhotos from './UploadPhotos';
import btn_icon_1104747056 from './images/btn_icon_1104747056.png';
import btn_icon_1581555355 from './images/btn_icon_1581555355.png';
import img_elTwittercircle from './images/UpdateInfoScreen_elTwittercircle.png';
import img_elTwitter from './images/UpdateInfoScreen_elTwitter_1312850244.png';
import img_elInstagramcircle from './images/UpdateInfoScreen_elInstagramcircle.png';
import img_elIg from './images/UpdateInfoScreen_elIg_98352411.png';
import img_elFacebookcircle from './images/UpdateInfoScreen_elFacebookcircle.png';
import img_elFb from './images/UpdateInfoScreen_elFb_660566527.png';
import img_elLogo from './images/UpdateInfoScreen_elLogo_1476464072.png';
import img_elLogoznesthorizwhite from './images/UpdateInfoScreen_elLogoznesthorizwhite.png';
import img_elBeta from './images/UpdateInfoScreen_elBeta.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class UpdateInfoScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, communityName, ilBeds, alBeds, mcBeds, snBeds, communityZip

  constructor(props) {
    super(props);

    this.state = {
      descheadingtext: (<div>Update Community Details</div>),
      descheadingtext_plainText: "Update Community Details",
      desctext: (<div>Any suggested changes must first be verified by a ZNest moderator.</div>),
      desctext_plainText: "Any suggested changes must first be verified by a ZNest moderator.",
      nametext: (<div>Community Name</div>),
      nametext_plainText: "Community Name",
      communityname: (<div> </div>),
      communityname_plainText: " ",
      addresstext: (<div>Address</div>),
      addresstext_plainText: "Address",
      communityaddress: (<div> </div>),
      communityaddress_plainText: " ",
      commAddressInput: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityAddress'] : '') || '',
      citytext: (<div>City</div>),
      citytext_plainText: "City",
      communitycity: (<div> </div>),
      communitycity_plainText: " ",
      commCityInput: this.transformPropValue_commCityInput((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCity'] : '')) || '',
      statetext: (<div>State</div>),
      statetext_plainText: "State",
      communitystate: (<div> </div>),
      communitystate_plainText: " ",
      commStateInput: this.transformPropValue_commStateInput((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityState'] : '')) || '',
      ziptext: (<div>ZIP</div>),
      ziptext_plainText: "ZIP",
      communityzip: (<div> </div>),
      communityzip_plainText: " ",
      commZipInput: this.transformPropValue_commZipInput((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityAddress'] : '')) || '',
      phonetext: (<div>Phone</div>),
      phonetext_plainText: "Phone",
      communityphone: (<div> </div>),
      communityphone_plainText: " ",
      commPhoneInput: this.transformPropValue_commPhoneInput((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityPhone'] : '')) || '',
      websitetext: (<div>Website</div>),
      websitetext_plainText: "Website",
      communityweb: (<div> </div>),
      communityweb_plainText: " ",
      commWebInput: this.transformPropValue_commWebInput((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityWWW'] : '')) || '',
      textbasicinfo: (<div>Basic Information</div>),
      textbasicinfo_plainText: "Basic Information",
      textlicensetypes: (<div>Type of Licenses<br /><br /><br />Independent Living<br /><br />Assisted Living<br /><br />Memory Care<br /><br />Skilled Nursing</div>),
      textlicensetypes_plainText: "Type of Licenses\n\n\nIndependent Living\n\nAssisted Living\n\nMemory Care\n\nSkilled Nursing",
      textlicensedbeds: (<div>Licensed Beds</div>),
      textlicensedbeds_plainText: "Licensed Beds",
      indelivingbedcount: (<div> </div>),
      indelivingbedcount_plainText: " ",
      assistedlivingbedcount: (<div> </div>),
      assistedlivingbedcount_plainText: " ",
      memorycarebedcount: (<div> </div>),
      memorycarebedcount_plainText: " ",
      skillednursingbedcount: (<div> </div>),
      skillednursingbedcount_plainText: " ",
      otherbedcount: (<div>0</div>),
      otherbedcount_plainText: "0",
      texttotalbed: (<div>Total</div>),
      texttotalbed_plainText: "Total",
      totalbedcount: (<div>9</div>),
      totalbedcount_plainText: "9",
      elValidator_visible: false,
      validator: (<div>*Please fill in the required information</div>),
      validator_plainText: "*Please fill in the required information",
      elButtonSubmitChanges_visible: true,
      elButtonBack_visible: false,
      field: this.transformPropValue_field((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_enteredZipCode'] : '')) || '',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButtonAddPhotos = async (ev) => {
    let newVal = "CommunityDetails2";
    this.props.appActions.updateDataSlot('(null)', newVal);

    // Go back in screen navigation history
    this.props.appActions.goBack();

  }


  textInputChanged_elCommAddressInput = (event) => {
    this.setState({commAddressInput: event.target.value});
  }

  textInputChanged_elCommCityInput = (event) => {
    this.setState({commCityInput: event.target.value});
  }
  transformPropValue_commCityInput = (input) => {
    let dsArr = this.props.appActions.getDataSheet('selected_community');
    var itemsLength = dsArr.items.length;

    if(itemsLength > 0) {
      var city = dsArr.items[0].communityCity;
    }

    input = city;

    return input;
  }


  textInputChanged_elCommStateInput = (event) => {
    this.setState({commStateInput: event.target.value});
  }
  transformPropValue_commStateInput = (input) => {
    let dsArr = this.props.appActions.getDataSheet('selected_community');
    var itemsLength = dsArr.items.length;

    if(itemsLength > 0) {
      var state = dsArr.items[0].communityState;
    }

    input = state;

    return input;
  }


  textInputChanged_elCommZipInput = (event) => {
    this.setState({commZipInput: event.target.value});
  }
  transformPropValue_commZipInput = (input) => {
    let dsArr = this.props.appActions.getDataSheet('selected_community');
    var itemsLength = dsArr.items.length;

    if(itemsLength > 0) {
      var zip = dsArr.items[0].communityZip;
    }

    input = zip;

    return input;
  }


  textInputChanged_elCommPhoneInput = (event) => {
    this.setState({commPhoneInput: event.target.value});
  }
  transformPropValue_commPhoneInput = (input) => {
    let dsArr = this.props.appActions.getDataSheet('selected_community');
    var itemsLength = dsArr.items.length;

    if(itemsLength > 0) {
      var phone = dsArr.items[0].communityPhone;
    }

    input = phone;

    return input;
  }


  textInputChanged_elCommWebInput = (event) => {
    this.setState({commWebInput: event.target.value});
  }
  transformPropValue_commWebInput = (input) => {
    let dsArr = this.props.appActions.getDataSheet('selected_community');
    var itemsLength = dsArr.items.length;

    if(itemsLength > 0) {
      var www = dsArr.items[0].communityWWW;
    }

    input = www;
    return input;
  }


  onClick_elButtonSubmitChanges = async (ev) => {
    // Perform action 'Validate' on element 'validator'
    const items = [
      { result: true /* validate this.state.commPhoneInput */, name: 'commPhoneInput', value: this.state.commPhoneInput },
      { result: /^\d*$/.test(this.state.commZipInput) /* only numbers */, name: 'commZipInput', value: this.state.commZipInput },
    ];

    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        break;
      }
    };
    this.setState({elValidator_visible: !valid});

    if ( !valid) {
      return;
    }

    await this.sendData_elButtonSubmitChanges_to_updateInfoDB();

    this.setState({elButtonSubmitChanges_visible: !this.state.elButtonSubmitChanges_visible});

    this.setState({elButtonBack_visible: !this.state.elButtonBack_visible});

  }


  onClick_elButtonBack = async (ev) => {
    // Go back in screen navigation history
    this.props.appActions.goBack();

  }


  textInputChanged_elField = (event) => {
    this.setState({field: event.target.value});
  }
  transformPropValue_field = (input) => {
    let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

    let dsArr = this.props.appActions.getDataSheet('selected_community');

    var itemsLength = dsArr.items.length;

    if(itemsLength > 0) {
      var name = dsArr.items[0].communityName;
      var phone = dsArr.items[0].communityPhone;
      var www = dsArr.items[0].communityWWW;
      var city = dsArr.items[0].communityCity;
      var state = dsArr.items[0].communityState;
      var zip = dsArr.items[0].communityZip;
    }

    console.log("------ commName = ", name);
    console.log("------ commPhone = ", phone);
    console.log("------ commWWW = ", www);

    this.props.appActions.updateDataSlot("ds_selectedCommunityPhone", phone);
    this.props.appActions.updateDataSlot("ds_selectedCommunityWWW", www);
    this.props.appActions.updateDataSlot("ds_selectedCommunityCity", city);
    this.props.appActions.updateDataSlot("ds_selectedCommunityState", state);
    this.props.appActions.updateDataSlot("ds_selectedCommunityZip", zip);
  }


  onClick_elFab = async (ev) => {
    let newVal = this.props.communityName;
    this.props.appActions.updateDataSlot('ds_selectedCommunityName', newVal);

    // Go back in screen navigation history
    this.props.appActions.goBack();

  }


  onClick_elIconButton = async (ev) => {
    this.toggle_n();

  }


  onClick_elLogoznesthorizwhite = async (ev) => {
    // Go back in screen navigation history
    this.props.appActions.goBack();

  }


  sendData_elButtonSubmitChanges_to_updateInfoDB = () => {
    const dataSheet = this.props.appActions.getDataSheet('updateInfoDB');

    let row = this.props.dataSheetRow || {
      "communityName": this.props.communityName,
      "ilBeds": this.props.ilBeds,
      "alBeds": this.props.alBeds,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityZip": this.props.communityZip,
    };
    row = { ...row,
      updateWeb: this.state.commWebInput,
      communityCity: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCity'] : ''),
      updateState: this.state.commStateInput,
      updateCity: this.state.commCityInput,
      communityAddress: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityAddress'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      updateAddress: this.state.commAddressInput,
      communityState: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityState'] : ''),
      communityZip: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityZip'] : ''),
      communityWWW: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityWWW'] : ''),
      updateZip: this.state.commZipInput,
      communityPhone: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityPhone'] : ''),
      communityName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityName'] : ''),
      updatePhone: this.state.commPhoneInput,
    };

    const transformData = (input) => {
        input.timestamp = Date.now();

      // Artifacts
      input.ilBeds = '';
      input.alBeds = '';
      input.mcBeds = '';
      input.snBeds = '';

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('updateInfoDB', row);
    } else {
      return this.props.appActions.addToDataSheet('updateInfoDB', row);
    }
  }


  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elDescHeadingText = {
      fontSize: 17.1,
      color: '#ff2600',
      textAlign: 'left',
     };
    const style_elDescText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elNameText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elNameText_outer = {
      display: 'none',
     };

    const value_communityName = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityName'] : '');

    const style_elCommunityName = {
      fontSize: 25.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elButtonAddPhotos = {
      display: 'block',
      textTransform: 'uppercase',
      color: 'black',
      textAlign: 'center',
      backgroundColor: '#cfffdc',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonAddPhotos_outer = {
      display: 'none',
     };
    const style_elCompPhotoUpload_outer = {
      display: 'none',
     };
    const style_elAddressText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const value_communityAddress = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityAddress'] : '');

    const style_elCommunityAddress = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommunityAddress_outer = {
      display: 'none',
     };

    const value_commAddressInput = this.state.commAddressInput;

    const style_elCommAddressInput = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elCityText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommunityCity = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommunityCity_outer = {
      display: 'none',
     };

    const value_commCityInput = this.state.commCityInput;

    const style_elCommCityInput = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elStateText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommunityState = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommunityState_outer = {
      display: 'none',
     };

    const value_commStateInput = this.state.commStateInput;

    const style_elCommStateInput = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elZipText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const value_communityZip = this.props.communityZip;

    const style_elCommunityZip = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommunityZip_outer = {
      display: 'none',
     };

    const value_commZipInput = this.state.commZipInput;

    const style_elCommZipInput = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elPhoneText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_communityPhone = (input) => {

      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getPhone);

        function getPhone(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].communityPhone;
          }
         }
      }

      return input;
    }

    const value_communityPhone = transformPropValue_communityPhone((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityPhone'] : ''));

    const style_elCommunityPhone = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommunityPhone_outer = {
      display: 'none',
     };

    const value_commPhoneInput = this.state.commPhoneInput;

    const style_elCommPhoneInput = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elWebsiteText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const value_communityWeb = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityWWW'] : '');

    const style_elCommunityWeb = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommunityWeb_outer = {
      display: 'none',
     };

    const value_commWebInput = this.state.commWebInput;

    const style_elCommWebInput = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextBasicInfo = {
      fontSize: 23.8,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextLicenseTypes = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextLicensedBeds = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities');
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].ilBeds;
          }
         }
      }

      return input;
    }

    const value_indeLivingBedCount = transformPropValue_indeLivingBedCount(this.props.ilBeds);

    const style_elIndeLivingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_assistedLivingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;


      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].alBeds;
          }
         }
      }

      return input;
    }

    const value_assistedLivingBedCount = transformPropValue_assistedLivingBedCount(this.props.alBeds);

    const style_elAssistedLivingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_memoryCareBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].mcBeds;
          }
         }
      }

      return input;
    }

    const value_memoryCareBedCount = transformPropValue_memoryCareBedCount(this.props.mcBeds);

    const style_elMemoryCareBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_skilledNursingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].snBeds;
          }
         }
      }

      return input;
    }

    const value_skilledNursingBedCount = transformPropValue_skilledNursingBedCount(this.props.snBeds);

    const style_elSkilledNursingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elOtherBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elOtherBedCount_outer = {
      display: 'none',
     };

    const style_elLine1 = {
      borderTop: '1px solid black',
     };
    const style_elTextTotalBed = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_totalBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var ilBeds = dsArr.items[0].ilBeds;
        var alBeds = dsArr.items[0].alBeds;
        var snBeds = dsArr.items[0].snBeds;
      }

      input = ilBeds + alBeds + snBeds;

      return input;
    }

    const value_totalBedCount = transformPropValue_totalBedCount((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_numberOfCommunities'] : ''));

    const style_elTotalBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elValidator = {
      color: '#ff2600',
      textAlign: 'left',
     };
    const elValidator = this.state.elValidator_visible ? (
      <div className="elValidator">
        <div className="baseFont" style={style_elValidator}>
          <div>{this.state.validator}</div>
        </div>
      </div>

     ) : null;

    const style_elButtonSubmitChanges = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ff2600',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elButtonSubmitChanges = this.state.elButtonSubmitChanges_visible ? (
      <div className="elButtonSubmitChanges">
        <Button className="actionFont" style={style_elButtonSubmitChanges} onClick={this.onClick_elButtonSubmitChanges} >
          Submit Changes
        </Button>
      </div>

     ) : null;

    const style_elButtonBack = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elButtonBack = this.state.elButtonBack_visible ? (
      <div className="elButtonBack">
        <Button className="actionFont" style={style_elButtonBack} onClick={this.onClick_elButtonBack} >
          Back
        </Button>
      </div>

     ) : null;

    const value_communityCoverImage = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : '');

    const style_elCommunityCoverImage = {
      height: 'auto',
     };
    const style_elCommunityCoverImage_outer = {
      display: 'none',
     };

    const style_elLine = {
      borderTop: '1px solid black',
     };
    const style_elLine_outer = {
      display: 'none',
     };

    const value_field = this.state.field;

    const style_elField = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elField_outer = {
      display: 'none',
     };

    const style_elBkgdOutlineOverall = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
     };

    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };

    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_1581555355+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.387%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elTwittercircle = {
      backgroundImage: 'url('+img_elTwittercircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elTwitter = {
      height: 'auto',
      display: 'none',
     };

    const style_elInstagramcircle = {
      backgroundImage: 'url('+img_elInstagramcircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elIg = {
      height: 'auto',
      display: 'none',
     };

    const style_elFacebookcircle = {
      backgroundImage: 'url('+img_elFacebookcircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elFb = {
      height: 'auto',
      display: 'none',
     };

    const style_elButtonLearnMore = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };

    const style_elButtonAboutUs = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };
    const style_elLogo = {
      height: 'auto',
      display: 'none',
     };

    const style_elLogoznesthorizwhite = {
      backgroundImage: 'url('+img_elLogoznesthorizwhite+')',
      backgroundSize: '100% 100%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elBeta = {
      backgroundImage: 'url('+img_elBeta+')',
      backgroundSize: '100% 100%',
     };

    return (
      <div className="AppScreen UpdateInfoScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elDescHeadingText">
            <div className="systemFontBold" style={style_elDescHeadingText}>
              <div>{this.state.descheadingtext}</div>
            </div>
          </div>

          <div className="elDescText">
            <div className="systemFontRegular" style={style_elDescText}>
              <div>{this.state.desctext}</div>
            </div>
          </div>

          <div className="elNameText" style={style_elNameText_outer}>
            <div className="systemFontBold" style={style_elNameText}>
              <div>{this.state.nametext}</div>
            </div>
          </div>

          <div className="elCommunityName">
            <div className="font-avenirNextRegular" style={style_elCommunityName}>
              <div>{value_communityName}</div>
            </div>
          </div>

          <div className="elButtonAddPhotos" style={style_elButtonAddPhotos_outer}>
            <button className="actionFont" style={style_elButtonAddPhotos} onClick={this.onClick_elButtonAddPhotos} >
              Add Photos
            </button>
          </div>

          <div className="hasNestedComps elCompPhotoUpload" style={style_elCompPhotoUpload_outer}>
            <div>
            </div>
          </div>

          <div className="elAddressText">
            <div className="systemFontBold" style={style_elAddressText}>
              <div>{this.state.addresstext}</div>
            </div>
          </div>

          <div className="elCommunityAddress" style={style_elCommunityAddress_outer}>
            <div className="baseFont" style={style_elCommunityAddress}>
              <div>{value_communityAddress}</div>
            </div>
          </div>

          <div className="elCommAddressInput">
            <input className="baseFont" style={style_elCommAddressInput} type="text" placeholder="" onChange={this.textInputChanged_elCommAddressInput} value={value_commAddressInput !== undefined ? value_commAddressInput : ''}  />
          </div>

          <div className="elCityText">
            <div className="systemFontBold" style={style_elCityText}>
              <div>{this.state.citytext}</div>
            </div>
          </div>

          <div className="elCommunityCity" style={style_elCommunityCity_outer}>
            <div className="baseFont" style={style_elCommunityCity}>
              <div>{this.state.communitycity}</div>
            </div>
          </div>

          <div className="elCommCityInput">
            <input className="baseFont" style={style_elCommCityInput} type="text" placeholder="" onChange={this.textInputChanged_elCommCityInput} value={value_commCityInput !== undefined ? value_commCityInput : ''}  />
          </div>

          <div className="elStateText">
            <div className="systemFontBold" style={style_elStateText}>
              <div>{this.state.statetext}</div>
            </div>
          </div>

          <div className="elCommunityState" style={style_elCommunityState_outer}>
            <div className="baseFont" style={style_elCommunityState}>
              <div>{this.state.communitystate}</div>
            </div>
          </div>

          <div className="elCommStateInput">
            <input className="baseFont" style={style_elCommStateInput} type="text" placeholder="" onChange={this.textInputChanged_elCommStateInput} value={value_commStateInput !== undefined ? value_commStateInput : ''}  />
          </div>

          <div className="elZipText">
            <div className="systemFontBold" style={style_elZipText}>
              <div>{this.state.ziptext}</div>
            </div>
          </div>

          <div className="elCommunityZip" style={style_elCommunityZip_outer}>
            <div className="baseFont" style={style_elCommunityZip}>
              <div>{value_communityZip}</div>
            </div>
          </div>

          <div className="elCommZipInput">
            <input className="baseFont" style={style_elCommZipInput} type="number" placeholder="" onChange={this.textInputChanged_elCommZipInput} value={value_commZipInput !== undefined ? value_commZipInput : ''}  />
          </div>

          <div className="elPhoneText">
            <div className="systemFontBold" style={style_elPhoneText}>
              <div>{this.state.phonetext}</div>
            </div>
          </div>

          <div className="elCommunityPhone" style={style_elCommunityPhone_outer}>
            <div className="baseFont" style={style_elCommunityPhone}>
              <div>{value_communityPhone}</div>
            </div>
          </div>

          <div className="elCommPhoneInput">
            <input className="baseFont" style={style_elCommPhoneInput} type="tel" placeholder="" onChange={this.textInputChanged_elCommPhoneInput} value={value_commPhoneInput !== undefined ? value_commPhoneInput : ''}  />
          </div>

          <div className="elWebsiteText">
            <div className="systemFontBold" style={style_elWebsiteText}>
              <div>{this.state.websitetext}</div>
            </div>
          </div>

          <div className="elCommunityWeb" style={style_elCommunityWeb_outer}>
            <div className="baseFont" style={style_elCommunityWeb}>
              <div>{value_communityWeb}</div>
            </div>
          </div>

          <div className="elCommWebInput">
            <input className="baseFont" style={style_elCommWebInput} type="text" placeholder="" onChange={this.textInputChanged_elCommWebInput} value={value_commWebInput !== undefined ? value_commWebInput : ''}  />
          </div>

          <div className="elTextBasicInfo">
            <div className="systemFontRegular" style={style_elTextBasicInfo}>
              <div>{this.state.textbasicinfo}</div>
            </div>
          </div>

          <div className="elTextLicenseTypes">
            <div className="baseFont" style={style_elTextLicenseTypes}>
              <div><div dangerouslySetInnerHTML={{__html: this.state.textlicensetypes_plainText.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>

          <div className="elTextLicensedBeds">
            <div className="baseFont" style={style_elTextLicensedBeds}>
              <div>{this.state.textlicensedbeds}</div>
            </div>
          </div>

          <div className="elIndeLivingBedCount">
            <div className="baseFont" style={style_elIndeLivingBedCount}>
              <div>{value_indeLivingBedCount}</div>
            </div>
          </div>

          <div className="elAssistedLivingBedCount">
            <div className="baseFont" style={style_elAssistedLivingBedCount}>
              <div>{value_assistedLivingBedCount}</div>
            </div>
          </div>

          <div className="elMemoryCareBedCount">
            <div className="baseFont" style={style_elMemoryCareBedCount}>
              <div>{value_memoryCareBedCount}</div>
            </div>
          </div>

          <div className="elSkilledNursingBedCount">
            <div className="baseFont" style={style_elSkilledNursingBedCount}>
              <div>{value_skilledNursingBedCount}</div>
            </div>
          </div>

          <div className="elOtherBedCount" style={style_elOtherBedCount_outer}>
            <div className="baseFont" style={style_elOtherBedCount}>
              <div>{this.state.otherbedcount}</div>
            </div>
          </div>

          <div className="elLine1">
            <div style={style_elLine1} />
          </div>

          <div className="elTextTotalBed">
            <div className="baseFont" style={style_elTextTotalBed}>
              <div>{this.state.texttotalbed}</div>
            </div>
          </div>

          <div className="elTotalBedCount">
            <div className="baseFont" style={style_elTotalBedCount}>
              <div>{value_totalBedCount}</div>
            </div>
          </div>

          { elValidator }
          { elButtonSubmitChanges }
          { elButtonBack }
          <div className="elCommunityCoverImage" style={style_elCommunityCoverImage_outer}>
            <img style={style_elCommunityCoverImage} src={this.props.appActions.createImageUrlFromProp(value_communityCoverImage)} alt=""  />
          </div>

          <div className="elLine" style={style_elLine_outer}>
            <div style={style_elLine} />
          </div>

          <div className="flowRow flowRow_UpdateInfoScreen_elField_578562240">
          <div className="elField" style={style_elField_outer}>
            <input className="baseFont" style={style_elField} type="text" placeholder="" onChange={this.textInputChanged_elField} value={value_field !== undefined ? value_field : ''}  />
          </div>

          </div>
          <div className="elSpacer">
            <div />
          </div>
        </div>

        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elBkgdOutlineOverall" style={style_elBkgdOutlineOverall} />
            <Button className="actionFont elFab" style={style_elFab}  variant="fab" color="accent" onClick={this.onClick_elFab} >
              <img src={btn_icon_1104747056} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
            <div className="elTwittercircle" style={style_elTwittercircle} />
            <img className="elTwitter" style={style_elTwitter} src={img_elTwitter} alt=""  />
            <div className="elInstagramcircle" style={style_elInstagramcircle} />
            <img className="elIg" style={style_elIg} src={img_elIg} alt=""  />
            <div className="elFacebookcircle" style={style_elFacebookcircle} />
            <img className="elFb" style={style_elFb} src={img_elFb} alt=""  />
            <button className="font-SFUITextRegular  elButtonLearnMore" style={style_elButtonLearnMore}>
              learn more
            </button>
            <button className="font-SFUITextRegular  elButtonAboutUs" style={style_elButtonAboutUs}>
              About Us
            </button>
            <img className="elLogo" style={style_elLogo} src={img_elLogo} alt=""  />
            <div className="elLogoznesthorizwhite" style={style_elLogoznesthorizwhite} onClick={this.onClick_elLogoznesthorizwhite}  />
            <div className="elBeta" style={style_elBeta} />
          </div>
        </div>
      </div>
    )
  }

}
