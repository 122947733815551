import React, { Component } from 'react';
import './App.css';

// Bootstrap framework component imports
import { LightBox } from 'react-lightbox-pack';
import "react-lightbox-pack/dist/index.css";
import { Container,Row,Col,Table,Button,ListGroup,Tooltip,OverlayTrigger,Popover } from 'react-bootstrap';

// const images = props.appActions.dataSheets.photos;

/*const images = [
	{
		"id":  1,
		// "image":  "http://via.placeholder.com/350x250",
		"image":  "https://firebasestorage.googleapis.com/v0/b/wikiliving.appspot.com/o/64eb4536-eda7-4a9e-bdcb-92ee864edec0.jpg?alt=media&token=5faf2040-74f3-4d27-820d-d8683a51c892",
		"title":  "Lorem Ipsum",
		"description":
		"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos assumenda, velit explicabo non at consequuntur accusamus hic optio alias error nisi sunt sint veniam aperiam similique dolor fugit itaque minima!"
	},
	{
		"id":  2,
		// "image":  "http://via.placeholder.com/350x250",
		"image":  "https://firebasestorage.googleapis.com/v0/b/wikiliving.appspot.com/o/dccc9636-b42d-41d7-8bbe-675de23ed6fb.jpg?alt=media&token=936e380d-9603-4abc-94a6-b8827e2e48d7",
		"title":  "Lorem Ipsum",
		"description":
		"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos assumenda, velit explicabo non at consequuntur accusamus hic optio alias error nisi sunt sint veniam aperiam similique dolor fugit itaque minima!"
	},
	{
		"id":  3,
		// "image":  "http://via.placeholder.com/350x250",
		"image":  "https://firebasestorage.googleapis.com/v0/b/wikiliving.appspot.com/o/5ecdd996-ab6c-458d-9c8a-479fba252fab.jpg?alt=media&token=fa3f794a-6ba6-435e-9764-f3cae5338fe0",
		"title":  "Lorem Ipsum",
		"description":
		"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos assumenda, velit explicabo non at consequuntur accusamus hic optio alias error nisi sunt sint veniam aperiam similique dolor fugit itaque minima!"
	},
	{
		"id":  4,
		// "image":  "http://via.placeholder.com/350x250",
		"image":  "https://firebasestorage.googleapis.com/v0/b/wikiliving.appspot.com/o/c1cfc6a6-7857-4a9e-aaf9-d2b7ce2dcc64.jpg?alt=media&token=1e852b05-77c8-496a-ae33-9d2091394820",
		"title":  "Lorem Ipsum",
		"description":
		"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos assumenda, velit explicabo non at consequuntur accusamus hic optio alias error nisi sunt sint veniam aperiam similique dolor fugit itaque minima!"
	}
]*/

// const dataSheet_photos = this.props.appActions.getDataSheet('photos');

const ScrollingPhotos = (props) => {

	const images = props.appActions.dataSheets.photos.items;
	console.log('images',images)
	// const images = props.appActions.dataSheets.photos;
	const doubleValue = images.map((item,index)=>{
		return {'id':item.key,'image':item.imageFilePath,'document_key':item.dcument_key};
	});
	// State
	const [toggle, setToggle] =  React.useState(false);
	const [sIndex, setSIndex] =  React.useState(0);

  // console.log(props.appActions.createImageUrlFromProp(props.imageFilePath))
	// console.log("props = ", props);
	// console.log("props = ", props.appActions.dataSheets.photos);
	console.log("props = ", props.appActions.dataSheets.photos.items);
	// console.log("props = ", props.appActions.dataSheets.photos.items[0]);	// This has imageFileName, etc
	// console.log("props = ", props.appActions.dataSheets.photos.items[1]);
	// console.log("props = ", props.appActions.dataSheets.photos.items[0].props.imageFilePath);
	// console.log("props = ", this.props.appActions.dataSheet["photos"]);

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};

	return (
		<Row>
			{doubleValue.map((item, index) => (
			<>
      <Col lg={3} md={12} xs={12} className="pt-5" >
				<img
					key={item.id}
					src={item.image}
					alt={item.document_key}
					style={{ height:  "200px", width:  "400px" }}
					onClick={() => {
					lightBoxHandler(true, index);
					}}
          className='img-fluid'
				/>
      </Col>
			</>
			))}

			{/* Component */}
			<LightBox
				state={toggle}
        event={lightBoxHandler}
        data={doubleValue}
        imageWidth="380px"
        imageHeight="200px"
        thumbnailHeight={50}
        thumbnailWidth={50}
        setImageIndex={setSIndex}
        imageIndex={sIndex}
			/>
      </Row>
	);
}

export default ScrollingPhotos;
// export default class ScrollingPhotos extends Component {

//   // Properties used by this component:
//   // photoURL, imageFilePath

//   // constructor(props) {
//   //   super(props);

//   //   this.state = {
//   //   };
//   // }

//   constructor(props) {
//     super(props);

//     this.state = {
//       photoIndex: 0,
//       isOpen: false,
//     };
//   }



//   render() {
//     // eslint-disable-next-line
//     // const dataSheet_photos = this.props.appActions.getDataSheet('photos');
//     // const style_elImage = {
//     //   backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.imageFilePath)+')',
//     //   backgroundRepeat: 'no-repeat',
//     //   backgroundPosition: '50% 50%',
//     //   backgroundSize: 'cover',
//     //  };
//     const { photoIndex, isOpen } = this.state;

//     return (
//       <div>
//         <button type="button" onClick={() => this.setState({ isOpen: true })}>
//           Open Lightbox
//         </button>

//         {isOpen && (
//           <Lightbox
//             mainSrc={images[photoIndex]}
//             nextSrc={images[(photoIndex + 1) % images.length]}
//             prevSrc={images[(photoIndex + images.length - 1) % images.length]}
//             onCloseRequest={() => this.setState({ isOpen: false })}
//             onMovePrevRequest={() =>
//               this.setState({
//                 photoIndex: (photoIndex + images.length - 1) % images.length,
//               })
//             }
//             onMoveNextRequest={() =>
//               this.setState({
//                 photoIndex: (photoIndex + 1) % images.length,
//               })
//             }
//           />
//         )}
//       </div>
//     );
//   }
// }
