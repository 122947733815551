import React, { Component } from 'react';
import './App.css';
import Rating from 'react-rating';
// Bootstrap framework component imports
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom'


import { Container,Row,Col,NavLink } from 'react-bootstrap';
import Header from './includes/header';
import { FaSearch } from 'react-icons/fa';
export default class CommunityItem extends Component {

  // Properties used by this component:
  // communityType, communityCoverImage, communityAddress, communityName, dataSheetRow, communityPhone, communityCityStateZip, index

  constructor(props) {
    super(props);

    this.state = {
      communityindex: (<div> </div>),
      communityindex_plainText: " ",
      communityname: (<div>(Community name)</div>),
      communityname_plainText: "(Community name)",
      communitytype: (<div>(Type)</div>),
      communitytype_plainText: "(Type)",
      communityaddress: (<div>(Address)</div>),
      communityaddress_plainText: "(Address)",
      communitycitystatezip: (<div> </div>),
      communitycitystatezip_plainText: " ",
      communityphone: (<div>(Phone)</div>),
      communityphone_plainText: "(Phone)",
      textblock: (<div> </div>),
      textblock_plainText: " ",
      textblock2: (<div> </div>),
      textblock2_plainText: " ",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButtonReviews = async (ev) => {
    let newVal = this.props.dataSheetRow.key;
    this.props.appActions.updateDataSlot('ds_selectedCommunityId', newVal);

    newVal = this.props.communityAddress;
    this.props.appActions.updateDataSlot('ds_selectedCommunityAddress', newVal);

    newVal = this.props.communityName;
    this.props.appActions.updateDataSlot('ds_selectedCommunityName', newVal);

    newVal = this.props.communityCoverImage;
    this.props.appActions.updateDataSlot('ds_selectedCommunityCoverImage', newVal);

    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['communities'], this.props.appActions.serviceOptions_communities, false);

    // Go to screen 'CommunityDetails'
    // this.props.appActions.goToScreen('communityDetails', { ...this.props, transitionId: 'fadeIn' });
    this.props.appActions.goToScreen('communityDetail', { ...this.props, transitionId: 'fadeIn' });

  }


  render() {

    const style_elBackgroundShape = {
      background: 'rgba(255, 255, 255, 1.000)',
     };

    let transformPropValue_communityIndex = (input) => {

      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let listIndex = Number(this.props.appActions.dataSlots['ds_communityListIndex']);

      var itemsLength = dsArr.items.length;
      var indexTemp;

      if(itemsLength > 0) {
        if (listIndex == itemsLength){
        	 this.props.appActions.dataSlots['ds_communityListIndex'] = 0;
           listIndex = 0;
           //console.log("********.  Went above itemsLength");
        }

        indexTemp = listIndex + 1;

        this.props.appActions.dataSlots['ds_communityListIndex'] = indexTemp;

        //console.log("new index = ", indexTemp);

        input = indexTemp + ".";
      }

      return input;
    }

    const value_communityIndex = transformPropValue_communityIndex(this.props.index);

    const style_elCommunityIndex = {
      fontSize: 15.2,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const value_communityName = this.props.communityName;

    const style_elCommunityName = {
      fontSize: 15.2,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const value_communityType = this.props.communityType;

    const style_elCommunityType = {
      fontSize: 11.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const value_communityAddress = this.props.communityAddress;

    const style_elCommunityAddress = {
      fontSize: 11.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_communityCityStateZip = (input) => {
      input = this.props.dataSheetRow.communityCity + ', ' + this.props.dataSheetRow.communityState + ' ' + this.props.dataSheetRow.communityZip;

      return input;
    }

    const value_communityCityStateZip = transformPropValue_communityCityStateZip(this.props.communityCityStateZip);

    const style_elCommunityCityStateZip = {
      fontSize: 11.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const value_communityPhone = this.props.communityPhone;

    const style_elCommunityPhone = {
      fontSize: 11.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elButtonReviews = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elRectangleSpacer = {
      background: 'rgba(252, 253, 253, 1.000)',
     };
    const style_elRectangleSpacer_outer = {
      display: 'none',
     };
    const style_elTextBlock = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextBlock2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    return (
      <div className="CommunityItem pb-5">
        <div className="layoutFlow">

        <div className="detail d-flex">

          <div className="elImage">
            <img className="style_elImage" src={this.props.appActions.createImageUrlFromProp(this.props.communityCoverImage)} alt=""  />
          </div>

          <div className="elCommunityIndex pl-3">
            <div className="elCommunityName">
            <div className="font-avenirNextBold" style={style_elCommunityName}>
              <div class="title">{value_communityIndex} {value_communityName}</div>
            </div>
          {/*  <div className="d-flex" style={style_elCommunityType}>
              <div className="rating">

              <ReactStars
               count={5}
               size={24}
               activeColor="#ffd700"
               readonly
               />
              </div>
              <div className="rating pt-2">
              <NavLink to="#" className="btn-outline-primary mt-auto btn-details">122 Reviews</NavLink>
              </div>
            </div> */}
          </div>
          <div className="font-avenirBook" style={style_elCommunityAddress}>
              <p class="title-bold"></p>
            </div>
          <div className="font-avenirBook" style={style_elCommunityAddress}>
              <p class="title-bold">{value_communityAddress}</p>
            </div>

            <div className="font-avenirBook" style={style_elCommunityCityStateZip}>
              <p class="title-bold">{value_communityCityStateZip}</p>
            </div>
            <div className="font-avenirBook" style={style_elCommunityPhone}>
              <p class="title-bold">Tel: {value_communityPhone}</p>
            </div>
            {/* The following "actin" - ie. "link" is bypassed - by the 2 onClick below
                This is so that this.props can be passed to the next page under this.onClick_elButtonReviews */}
            <div className="baseFont elTextBlock" style={style_elTextBlock}>
            <Link to={"./communityDetail"} className="btn-outline-primary mt-auto btn-details">Details</Link>
            </div>
            <div className="elButtonReviews">
              <div className="actionFont" style={style_elButtonReviews} onClick={this.onClick_elButtonReviews}  />
          </div>
          </div>

      {/*<div className="elCommunityName">
            <div className="font-avenirNextBold" style={style_elCommunityName}>
              <div>{value_communityName}</div>
            </div>
          </div>

          <div className="elCommunityType">
            <div className="font-avenirBook" style={style_elCommunityType}>
              <div>{value_communityType}</div>
            </div>
          </div>

          <div className="elCommunityAddress">
            <div className="font-avenirBook" style={style_elCommunityAddress}>
              <div>{value_communityAddress}</div>
            </div>
          </div>

          </div>
          <div className="elCommunityCityStateZip">
            <div className="font-avenirBook" style={style_elCommunityCityStateZip}>
              <div>{value_communityCityStateZip}</div>
            </div>
          </div>

          <div className="elCommunityPhone">
            <div className="font-avenirBook" style={style_elCommunityPhone}>
              <div>{value_communityPhone}</div>
            </div>
          </div>

          <div className="elButtonReviews">
            <div className="actionFont" style={style_elButtonReviews} onClick={this.onClick_elButtonReviews}  />
          </div>

          <div className="elRectangleSpacer" style={style_elRectangleSpacer_outer}>
            <div style={style_elRectangleSpacer} />
          </div>*/}
        </div>

        {/* This is needed for the onClick - to bypass the "link" action above */}
        <div className="elButtonReviews">
          <div className="actionFont" style={style_elButtonReviews} onClick={this.onClick_elButtonReviews}  />
        </div>

        {/*<div className="foreground">
          <div className="baseFont elTextBlock" style={style_elTextBlock}>
            <div>{this.state.textblock}</div>
          </div>
          <div className="baseFont elTextBlock2" style={style_elTextBlock2}>
            <div>{this.state.textblock2}</div>
          </div>
        </div>*/}



        </div>

      </div>
    )
  }

}
