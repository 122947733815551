import React, { useState,useRef } from "react";
// Bootstrap framework component imports
import '../App.css';
import { Navbar,Nav,Button,Container,OverlayTrigger,Popover } from 'react-bootstrap';
import img_elLogoznesthorizwhite from '../images/logo-white.svg';
import img_elFacebookcircle from '../images/facebook-circle.svg';
import img_elIg from '../images/instagram-circle.svg';
import img_elTwitter from '../images/twitter-circle.svg';
import { Modal,Form } from 'react-bootstrap';
import img_fb from '../images/fb.png';
import img_google from '../images/google-signin.png';
import ReactTooltip from 'react-tooltip';


function Header()  {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  return (
    <>
         {/* Navbar  Start*/}
 <Navbar collapseOnSelect expand="lg" bg="header" variant="dark" >
  <Container>
      <Navbar.Brand href="#home">
        <div className="d-flex">
        <img
        src={img_elLogoznesthorizwhite}
        width="100"
        className="d-inline-block align-top"
        alt="logo"
      />
      
      <h5 className="haeder-beta pt+5" data-tip="This site is currently only available<br> in Hawaii. Subscribe to our email and<br> you will be notified when we roll <br>out to other states." 
      data-html={true} data-background-color="white" data-text-color="black">BETA*</h5>
      <ReactTooltip effect="solid" place="bottom"/>
      </div>
    </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
    <Nav>
      <Nav.Link href="#features">About Us</Nav.Link>
      <Nav.Link href="#pricing">Learn More</Nav.Link>
      <Nav.Link href="#pricing">Log In</Nav.Link>
      <Nav.Link href="#features"><Button variant="outline-light" className="btn-signup" onClick={handleShow}>Sign Up</Button>
</Nav.Link>

      <Nav.Link href="#pricing"><img className="elFb"  src={img_elFacebookcircle} alt="fb"  width="30"/></Nav.Link>
      <Nav.Link href="#pricing"> <img className="elIg"  src={img_elIg} alt="instagram"  width="30"/></Nav.Link>
      <Nav.Link href="#pricing"> <img className="elTwitter" src={img_elTwitter} alt="twitter"  width="30"/></Nav.Link>
      {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown> */}
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>

<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h3 className="text-center">Sign In to write a create a listing, rate a facility, and write a review.</h3>
          <img className="img-fluid" src={img_fb} alt="fb-login"  />
          <img className="img-fluid" src={img_google} alt="google-login"  />
          <hr></hr>
        <Form>
         <Form.Group className="mb-3" controlId="formBasicEmail">
           <Form.Label>Email address</Form.Label>
           <Form.Control type="email" placeholder="Enter email" />
         <Form.Text className="text-muted">
          We'll never share your email with anyone else.
         </Form.Text>
        </Form.Group>

       <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <Form.Control type="password" placeholder="Password" />
      </Form.Group>
     
      <div className="d-grid gap-2">
      <p className="mb-3 float-end"> Forget Password</p>
       <Button variant="primary" size="lg">Sign In</Button>
     </div>
      <hr></hr>
      <p className="text-center">Don,t Have Account SignUp</p>
      </Form>
        </Modal.Body>
       
      </Modal>
    </>
  )
}

export default Header;