import React, { Component} from "react";
import "./App.css";
import bennett from "./assets/bennett.png";
import craig from "./assets/craig.png";
import tom from "./assets/tom.png";
import david from "./assets/david.png";
import jacob from "./assets/jacob.png";
import alan from "./assets/alan.png";
import ReactPlayer from 'react-player/youtube'

// Bootstrap framework component imports

import { Container,Row,Col,Nav,NavLink} from 'react-bootstrap';
import Header from './includes/header';
import { FaSearch } from 'react-icons/fa';

class About extends Component {
  
  render() {
    return (
      
      <div className="About">
        <Header/>
        {/* About section */}
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-10 mx-auto">
            <h1 className="Contributors-header display-3 text-center mt-5">About Us</h1>
              <h2 className="bottom-border mt-5"></h2>
              <h2 className="About-subheader display-5 mt-5">Be a Supporter!</h2>
              <div className="About-section container-fluid">
              <div className="col-md-8 mx-auto">

                <p className="About-description text-justify my-5">
                  Please consider making a contribution in order to help us add
                  new features that will allow families to find senior care more
                  easily. Because we are a for-profit social impact company,
                  donations from individuals are not tax-deductible. Corporate
                  contributions are typically tax deductible as an operating
                  expense, but please consult your accountant for financial
                  advice.
                </p>
                </div>
              </div>
              <div className="About-video container-fluid">
              <ReactPlayer
          className='react-player'
          url='https://youtu.be/NwdQx2P_ytk'
          width='100%'
          height='100%'
        />
              </div>
            </div>
          </div>
        </div>

        {/* Contributors section */}
        <div className="Contributors container-fluid pb-5 pt-5">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <h2 className="Contributors-header display-5 mt-5">Contributors</h2>
              <p className="About-description text-justify my-5">
                Thank you to our amazing community of supporters! Click the
                donation level to see the list of contributors.
              </p>

              <Container className="pt-5">

              <Row className="pt-5">
              <Col lg={1} md={6} xs={12} className="text-center"></Col>  
                <Col lg={2} md={6} xs={12} className="text-center">       
                <Nav.Link href="/contributor#diamond"><div className="Donation">
                  <div className="Donation-level mx-auto mb-2">
                    <div className="Donation-text">
                      <p className="Donation-title">Diamond</p>
                      <span className="Donation-amount">$5,000+</span>
                    </div>
                  </div>
                </div></Nav.Link>
              </Col>
              <Col lg={2} md={6} xs={12} className="text-center">       
                <Nav.Link href="/contributor#platinum"> <div className="Donation">
                  <div className="Donation-level mx-auto mb-2">
                    <div className="Donation-text">
                      <p className="Donation-title">Platinum</p>
                      <span className="Donation-amount">$2,000-4,999</span>
                    </div>
                  </div>
                </div></Nav.Link>
              </Col>
              <Col lg={2} md={6} xs={12} className="text-center">       
              <Nav.Link href="/contributor#gold">
                <div className="Donation">
                  <div className="Donation-level mx-auto mb-2">
                    <div className="Donation-text">
                      <p className="Donation-title">Gold</p>
                      <span className="Donation-amount">$500-1,999</span>
                    </div>
                  </div>
                </div>
                </Nav.Link>
              </Col>
              <Col lg={2} md={6} xs={12} className="text-center">       
              <Nav.Link href="/contributor#silver">
                <div className="Donation">
                  <div className="Donation-level mx-auto mb-2">
                    <div className="Donation-text">
                      <p className="Donation-title">Silver</p>
                      <span className="Donation-amount">$100-499</span>
                    </div>
                  </div>
                </div>
                </Nav.Link>
              </Col>
              <Col lg={2} md={6} xs={12} className="text-center">       
              <Nav.Link href="/contributor#bronze">
                <div className="Donation">
                  <div className="Donation-level mx-auto mb-2">
                    <div className="Donation-text">
                      <p className="Donation-title">Bronze</p>
                      <span className="Donation-amount">Up to $99</span>
                    </div>
                  </div>
                </div>
                </Nav.Link>
                </Col>
                <Col lg={1} md={6} xs={12} className="text-center"></Col>  
                </Row>
            </Container>
              
            </div>
          </div>
        </div>

        {/* Letter section */}
        <div className="Letter container-fluid bg-grey pb-5 pt-5">
          <div className="Letter-container mx-auto my-5 pt-5">
            {/* <div className="row">
              <div className="col-sm-12 col-md-6"> */}
            <h2 className="Contributors-header display-5">Letter from the CEO</h2>
            <div className="Letter-content m-5 mx-auto">
              <p>Hello Everybody, </p>
              <p>
                {" "}
                I wanted to take a moment to explain why I decided to create
                ZNest. Being in the senior housing industry for over 20 years, I
                came to realize that we as a community needed to innovate more
                so that seniors could find the right type of care.{" "}
              </p>
              <p>
                My first idea was to disrupt the senior housing industry by
                creating a private-pay day care for middle- income seniors
                called Sun Bay Senior Club. The idea was that if middle-income
                families had to work all day long, we would take care of their
                loved ones during the day for around $1,300 per month as long as
                the families could take care of the loved ones at night. Here’s
                a short{" "}
                <a
                  href="https://www.facebook.com/109619966130830/videos/1214020932111929"
                  target="_blank"
                  rel="noreferrer"
                >
                  video
                </a>{" "}
                of the concept.
              </p>
              <p>
                We launched the concept at the end of 2019… right before the
                COVID-19 pandemic. We had to shut down, and I failed.{" "}
              </p>
              <p>
                During the pandemic, I came up with the idea which led to
                creating this website. Seeing that other referral websites were
                curating content for their corporate sponsors, I became bothered
                by the notion that families were having a tough time finding
                good senior care. I realized that good care exists all around
                us. We just need to be more efficient about letting people know
                where they are.{" "}
              </p>
              <p>
                I realize the term “democratizing the data” has become somewhat
                cliché, but my goal is to do just that and to make this platform
                a constantly evolving solution to do the right thing for all
                seniors and their families.{" "}
              </p>
              <p>Sincerely,</p>
              <p>Bennett Kim</p>
              <p>CEO, ZNest</p>
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* Leadership Section */}
        <div className="Leader container-fluid mb-5 pb-5 pt-5">
          <h2 className="Contributors-header display-5 mb-4">Leadership</h2>

          <Container className="pt-5">

<Row className="pt-5">
<Col lg={1} md={6} xs={12} className="text-center"></Col>  
  <Col lg={2} md={6} xs={12} className="text-center">       
    <div className="Donation mb-5">
    <div className="Leader-img">
                  <img src={bennett} alt="Bennett Kim Headshot" />
                </div>
                <div className="Leader-name">Bennett Kim</div>
                <div className="Leader-title">Co-founder and CEO</div>
  </div>
</Col>
<Col lg={2} md={6} xs={12} className="text-center">       
<div className="Donation mb-5">
    <div className="Leader-img">
                  <img src={bennett} alt="Bennett Kim Headshot" />
                </div>
                <div className="Leader-name">Bennett Kim</div>
                <div className="Leader-title">Co-founder and CEO</div>
  </div>
</Col>
<Col lg={2} md={6} xs={12} className="text-center">       

<div className="Donation mb-5">
    <div className="Leader-img">
                  <img src={bennett} alt="Bennett Kim Headshot" />
                </div>
                <div className="Leader-name">Bennett Kim</div>
                <div className="Leader-title">Co-founder and CEO</div>
  </div>
</Col>
<Col lg={2} md={6} xs={12} className="text-center">       

<div className="Donation mb-5">
    <div className="Leader-img">
                  <img src={bennett} alt="Bennett Kim Headshot" />
                </div>
                <div className="Leader-name">Bennett Kim</div>
                <div className="Leader-title">Co-founder and CEO</div>
  </div>
</Col>
<Col lg={2} md={6} xs={12} className="text-center">       

<div className="Donation mb-5">
    <div className="Leader-img">
                  <img src={bennett} alt="Bennett Kim Headshot" />
                </div>
                <div className="Leader-name">Bennett Kim</div>
                <div className="Leader-title">Co-founder and CEO</div>
  </div>
  </Col>
  <Col lg={1} md={6} xs={12} className="text-center"></Col>  
  </Row>
</Container>
      
<div className="Contributors container-fluid">
          <div className="row">
            <div className="col-md-4 mx-auto">
              {/* <p className="Contributors-text my-4">
                Thank you to our amazing community of supporters! Click the
                donation level to see the list of contributors.
              </p> */}
            <Container className="pt-5">

              <Row className="pt-5">
                <Col lg={6} md={6} xs={12} className="text-center">       
                <div className="Donation mb-5">
    <div className="Leader-img">
                  <img src={bennett} alt="Bennett Kim Headshot" />
                </div>
                <div className="Leader-name">Bennett Kim</div>
                <div className="Leader-title">Co-founder and CEO</div>
  </div>
              </Col>
              <Col lg={6} md={6} xs={12} className="text-center">       
              <div className="Donation mb-5">
    <div className="Leader-img">
                  <img src={bennett} alt="Bennett Kim Headshot" />
                </div>
                <div className="Leader-name">Bennett Kim</div>
                <div className="Leader-title">Co-founder and CEO</div>
  </div>
              </Col>
               
                </Row>
            </Container>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default About;