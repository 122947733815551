import React, { Component } from "react";
import "./App.css";
import bennett from "./assets/bennett.png";
import craig from "./assets/craig.png";
import tom from "./assets/tom.png";
import david from "./assets/david.png";
import jacob from "./assets/jacob.png";
import alan from "./assets/alan.png";
// Bootstrap framework component imports

import { Container,Row,Col,Table,Button } from 'react-bootstrap';
import Header from './includes/header';
import { FaSearch } from 'react-icons/fa';

class HowItWorks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      textnumberofitems: (<div>Number of items</div>),
      textnumberofitems_plainText: "Number of items",
      pickerNumberOfRows: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_numberOfCommunities'] : ''),
      drawer1_isOpen: false,
    };
  }
  render() {
    return (
      <div className="About">
        <Header/>
        {/* About section */}
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-8 mx-auto">
            <Container className="pt-5">
         {/* Search Section */}
        <Row>
         <Col lg={3}></Col>
         <Col lg={6}>
         <div className="flowRow flowRow_HomeScreen_elZipAutoComplete_758871 d-flex">
         <div className="elZipCodeExplore">
            <input className="baseFont style_ZipCodeExploreHow" type="text" placeholder="Search by City,Zip, Community name" onChange={this.textInputChanged_elZipCodeExplore} value={this.state.zipCodeExplore}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_elSearchButton"  onClick={this.onClick_elSearchButton} >
            <FaSearch />
            </button>
          </div>
         </div> 
         </Col>
          <Col lg={3}></Col>
          </Row>
         </Container>
            <h1 className="Contributors-header display-3 text-center mt-5">How It Works</h1>
              <h2 className="bottom-border mt-5"></h2>
              <h2 className="About-subheader display-5 mt-5">Open Data</h2>
              <div className="About-section container-fluid">
                <p className="text-center my-5">
                Most of the other websites that claim to help you find the best senior housing and care options are referral sites that are sponsored by large corporations.
                 Sure, they are free, BUT you may only see search results based on which corporation is paying them the most. That means there may be a high-quality option
                  right next to where you live, but you won’t even see that option listed on their website. In fact, most of the other websites have approximately 20,000 
                  communities in their database and the search results are curated. We have over 50,000 communities in our database, and if you want to search for an option 
                  based on criteria like proximity or price, we will a comprehensive list which is not influenced by corporate sponsorship.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Letter section */}
        <div className="Letter container-fluid bg-grey pb-5 pt-5">
          <div className="Letter-container mx-auto my-5 pt-5">
            {/* <div className="row">
              <div className="col-sm-12 col-md-6"> */}
            <h2 className="Contributors-header display-5">Refferal Site Comparision</h2>
            <div className="Letter-content m-5 mx-auto">
             
               {/* Calculation Tables */}
          <Table className='table borderless' responsive>
          <thead>
            <tr>
            <th></th>
            <th></th>
            <th></th>
           </tr>
         </thead>
        <tbody>
        <tr>
           <td></td>
           <td><Button variant="primary" className="btn-search" >Referral Websites</Button></td>
           <td><Button variant="primary" className="btn-search" >ZNest</Button></td>
          </tr>
        <tr>
           <td>Search Results</td>
           <td> Curated based on corporate sponsors </td>
           <td>Comprehensive</td>
          </tr>
          <tr>
           <td>Spam Calls/Emails from Providers?</td>
           <td>Yes</td>
           <td>No</td>

          </tr>
          <tr>
           <td>No. of Options?</td>
           <td>≈ 25,000 Communities</td>
           <td>50,000+ Communities</td>
          </tr>
        </tbody>
        </Table>
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* Leadership Section */}
        <div className="col-md-8 mx-auto">

        <div className="Leader container-fluid mb-5 pb-5 pt-5">
          <h2 className="Contributors-header display-5 mb-4">Why Create an Account</h2>

          <p>Membership is free! ZNest will provide you with the most accurate pricing for each senior housing and care option.
               In exchange we ask you to be a part of the community so that we can help each other. When you visit an option and notice the pricing has changed, you can be a part of helping the community by submitting updates. 
               In order to submit updates, however, you will have to create an account. Members will also be eligible for various rewards. 
             </p>  
      
        </div>
      </div>
      </div>
    );
  }
}

export default HowItWorks;