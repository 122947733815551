import React, { Component } from 'react';
import './App.css';
import btn_icon_1540821800 from './images/btn_icon_1540821800.png';
import btn_icon_338335078 from './images/btn_icon_338335078.png';
import img_elTwittercircle from './images/TCScreen_elTwittercircle.png';
import img_elTwitter from './images/UpdateInfoScreen_elTwitter_1312850244.png';
import img_elInstagramcircle from './images/TCScreen_elInstagramcircle.png';
import img_elIg from './images/UpdateInfoScreen_elIg_98352411.png';
import img_elFacebookcircle from './images/TCScreen_elFacebookcircle.png';
import img_elFb from './images/UpdateInfoScreen_elFb_660566527.png';
import img_elLogo from './images/UpdateInfoScreen_elLogo_1476464072.png';
import img_elLogoznesthorizwhite from './images/TCScreen_elLogoznesthorizwhite.png';
import img_elBeta from './images/TCScreen_elBeta.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class TCScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, communityName, ilBeds, alBeds, mcBeds, snBeds, communityZip

  constructor(props) {
    super(props);

    this.state = {
      tcheadingtext: (<div>Terms and Conditions</div>),
      tcheadingtext_plainText: "Terms and Conditions",
      tctext: (<div>These Terms and Conditions (which, together with the Business Terms below, are the “Terms”) are effective immediately.</div>),
      tctext_plainText: "These Terms and Conditions (which, together with the Business Terms below, are the “Terms”) are effective immediately.",
      tctext2: (<div>PLEASE NOTE: THESE TERMS INCLUDE DISPUTE RESOLUTION PROVISIONS (SEE SECTION 13) THAT, WITH LIMITED EXCEPTIONS, REQUIRE THAT (1) CLAIMS YOU BRING AGAINST ZNEST BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND (2) YOU WAIVE YOUR RIGHT TO BRING OR PARTICIPATE IN ANY CLASS, GROUP, OR REPRESENTATIVE ACTION OR PROCEEDING.</div>),
      tctext2_plainText: "PLEASE NOTE: THESE TERMS INCLUDE DISPUTE RESOLUTION PROVISIONS (SEE SECTION 13) THAT, WITH LIMITED EXCEPTIONS, REQUIRE THAT (1) CLAIMS YOU BRING AGAINST ZNEST BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND (2) YOU WAIVE YOUR RIGHT TO BRING OR PARTICIPATE IN ANY CLASS, GROUP, OR REPRESENTATIVE ACTION OR PROCEEDING.",
      tctext3: (<div>These Terms govern your access to and use of our products and services, including those offered through our websites, events, communications (e.g., emails, phone calls, and texts) and mobile applications (collectively, the “Service”). By accessing or using the Service, you are agreeing to these Terms, which form a legally binding contract with ZNest, LLC, a Delaware Limited Liability Company with its headquarters in Los Angeles, California. “ZNest” means ZNest, LLC, as applicable. Do not access or use the Service if you are unwilling or unable to be bound by the Terms.</div>),
      tctext3_plainText: "These Terms govern your access to and use of our products and services, including those offered through our websites, events, communications (e.g., emails, phone calls, and texts) and mobile applications (collectively, the “Service”). By accessing or using the Service, you are agreeing to these Terms, which form a legally binding contract with ZNest, LLC, a Delaware Limited Liability Company with its headquarters in Los Angeles, California. “ZNest” means ZNest, LLC, as applicable. Do not access or use the Service if you are unwilling or unable to be bound by the Terms.",
      tcsmallheadingtext: (<div>1.   DEFINITIONS</div>),
      tcsmallheadingtext_plainText: "1.   DEFINITIONS",
      tcbulletnumbering: (<div>A.</div>),
      tcbulletnumbering_plainText: "A.",
      tcbullettext: (<div>Parties. “You” and “your” refer to you, as a user of the Service. A “user” is someone who accesses or in any way uses the Service.  “We,” “us,” and “our” refer to ZNest.</div>),
      tcbullettext_plainText: "Parties. “You” and “your” refer to you, as a user of the Service. A “user” is someone who accesses or in any way uses the Service.  “We,” “us,” and “our” refer to ZNest.",
      tcbulletnumberingcopy: (<div>B.</div>),
      tcbulletnumberingcopy_plainText: "B.",
      tcbullettextcopy: (<div>Content. “Content” means text, images, photos, audio, video, and all other forms of data or communication. “Your Content” means Content that you submit or transmit to, through, or in connection with the Service, such as ratings, reviews, photos, videos, compliments, invitations, check-ins, votes, friending and following activity, direct messages, and information that you contribute to your user profile or suggest for a business page. “User Content” means Content that users submit or transmit to, through, or in connection with the Service. “ZNest Content” means Content that we create and make available in connection with the Service. “Third Party Content” means Content that originates from parties other than ZNest or its users, which is made available in connection with the Service. “Service Content” means all of the Content that is made available in connection with the Service, including Your Content, User Content, ZNest Content, and Third Party Content.</div>),
      tcbullettextcopy_plainText: "Content. “Content” means text, images, photos, audio, video, and all other forms of data or communication. “Your Content” means Content that you submit or transmit to, through, or in connection with the Service, such as ratings, reviews, photos, videos, compliments, invitations, check-ins, votes, friending and following activity, direct messages, and information that you contribute to your user profile or suggest for a business page. “User Content” means Content that users submit or transmit to, through, or in connection with the Service. “ZNest Content” means Content that we create and make available in connection with the Service. “Third Party Content” means Content that originates from parties other than ZNest or its users, which is made available in connection with the Service. “Service Content” means all of the Content that is made available in connection with the Service, including Your Content, User Content, ZNest Content, and Third Party Content.",
      tcbulletnumberingcopy2: (<div>C.</div>),
      tcbulletnumberingcopy2_plainText: "C.",
      tcbullettextcopy2: (<div>Sites and Accounts. “Consumer Site” means ZNest’s consumer website (www.znest.com and related domains) and mobile applications. “Consumer Account” means the account you create to access or use the Consumer Site. “Business Account” means the account you create to access or use the ZNest for Business Owners website (biz.znest.com and related domains) and mobile applications. “Account” means any Consumer Account or Business Account.</div>),
      tcbullettextcopy2_plainText: "Sites and Accounts. “Consumer Site” means ZNest’s consumer website (www.znestcare.com and related domains) and mobile applications. “Consumer Account” means the account you create to access or use the Consumer Site. “Business Account” means the account you create to access or use the ZNest for Business Owners website (biz.znest.com and related domains) and mobile applications. “Account” means any Consumer Account or Business Account.",
      tcsmallheadingtextcopy: (<div>2.   CHANGES TO THE TERMS</div>),
      tcsmallheadingtextcopy_plainText: "2.   CHANGES TO THE TERMS",
      tcbullettextcopy3: (<div>We may modify the Terms from time to time. The most current version of the Terms will be located here. You understand and agree that your access to or use of the Service is governed by the Terms effective at the time of your access to or use of the Service. If we make material changes to these Terms, we will notify you by email, by posting notice on the Service, and/or by other method prior to the effective date of the changes. We will also indicate at the top of this page the date that such changes were last made. You should revisit these Terms on a regular basis as revised versions will be binding on you. You understand and agree that your continued access to or use of the Service after the effective date of changes to the Terms represents your acceptance of such changes.</div>),
      tcbullettextcopy3_plainText: "We may modify the Terms from time to time. The most current version of the Terms will be located here. You understand and agree that your access to or use of the Service is governed by the Terms effective at the time of your access to or use of the Service. If we make material changes to these Terms, we will notify you by email, by posting notice on the Service, and/or by other method prior to the effective date of the changes. We will also indicate at the top of this page the date that such changes were last made. You should revisit these Terms on a regular basis as revised versions will be binding on you. You understand and agree that your continued access to or use of the Service after the effective date of changes to the Terms represents your acceptance of such changes.",
      tcsmallheadingtextcopy2: (<div>3.   TRANSLATION</div>),
      tcsmallheadingtextcopy2_plainText: "3.   TRANSLATION",
      tcbullettextcopy4: (<div>We may translate these Terms into other languages for your convenience. Nevertheless, the English version governs your relationship with ZNest, and any inconsistencies among the different versions will be resolved in favor of the English version available here.</div>),
      tcbullettextcopy4_plainText: "We may translate these Terms into other languages for your convenience. Nevertheless, the English version governs your relationship with ZNest, and any inconsistencies among the different versions will be resolved in favor of the English version available here.",
      tcsmallheadingtextcopy3: (<div>4.   USING THE SERVICE</div>),
      tcsmallheadingtextcopy3_plainText: "4.   USING THE SERVICE",
      tcbulletnumberingcopy3: (<div>A.</div>),
      tcbulletnumberingcopy3_plainText: "A.",
      tcbullettextcopy5: (<div>Eligibility. To access or use the Service, you must have the requisite power and authority to enter into these Terms. You may not access or use the Service if you are a competitor of ZNest or if we have previously banned you from the Service or closed your Account.</div>),
      tcbullettextcopy5_plainText: "Eligibility. To access or use the Service, you must have the requisite power and authority to enter into these Terms. You may not access or use the Service if you are a competitor of ZNest or if we have previously banned you from the Service or closed your Account.",
      tcbulletnumberingcopy4: (<div>B.</div>),
      tcbulletnumberingcopy4_plainText: "B.",
      tcbullettextcopy6: (<div>Permission to Use the Service. We grant you permission to use the Service subject to these Terms. Your use of the Service is at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or is otherwise inappropriate.</div>),
      tcbullettextcopy6_plainText: "Permission to Use the Service. We grant you permission to use the Service subject to these Terms. Your use of the Service is at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or is otherwise inappropriate.",
      tcbulletnumberingcopy5: (<div>C.</div>),
      tcbulletnumberingcopy5_plainText: "C.",
      tcbullettextcopy7: (<div>Service Availability. The Service may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.</div>),
      tcbullettextcopy7_plainText: "Service Availability. The Service may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.",
      tcbulletnumberingcopy6: (<div>D.</div>),
      tcbulletnumberingcopy6_plainText: "D.",
      tcbullettextcopy8: (<div>Accounts. You must create an Account and provide certain information about yourself in order to use some of the features that are offered through the Service. You are responsible for maintaining the confidentiality of your Account password. You are also responsible for all activities that occur in connection with your Account. You agree to notify us immediately of any unauthorized use of your Account. We reserve the right to close your Account at any time for any or no reason. Your Consumer Account is for your personal, non-commercial use only, and you may not create or use a Consumer Account for anyone other than yourself. We ask that you provide complete and accurate information about yourself when creating an Account in order to bolster your credibility as a contributor to the Service. You may not impersonate someone else, provide an email address other than your own, create multiple Accounts, or transfer your Consumer Account to another person without ZNest’s prior approval.</div>),
      tcbullettextcopy8_plainText: "Accounts. You must create an Account and provide certain information about yourself in order to use some of the features that are offered through the Service. You are responsible for maintaining the confidentiality of your Account password. You are also responsible for all activities that occur in connection with your Account. You agree to notify us immediately of any unauthorized use of your Account. We reserve the right to close your Account at any time for any or no reason. Your Consumer Account is for your personal, non-commercial use only, and you may not create or use a Consumer Account for anyone other than yourself. We ask that you provide complete and accurate information about yourself when creating an Account in order to bolster your credibility as a contributor to the Service. You may not impersonate someone else, provide an email address other than your own, create multiple Accounts, or transfer your Consumer Account to another person without ZNest’s prior approval.",
      tcbulletnumberingcopy7: (<div>E.</div>),
      tcbulletnumberingcopy7_plainText: "E.",
      tcbullettextcopy9: (<div>Communications from ZNest and Others. By accessing or using the Service, you consent to receive communications from other users and ZNest through the Service, or through any other means such as emails, push notifications, text messages (including SMS and MMS), and phone calls. These communications may promote ZNest or businesses listed on ZNest, and may be initiated by ZNest, businesses listed on ZNest, or other users. You further understand that communications may be sent using an automatic telephone dialing system, and that you may be charged by your phone carrier for certain communications such as SMS messages or phone calls. You agree to notify us immediately if the phone number(s) you have provided to us have been changed or disconnected. Please note that any communications, including phone calls, with ZNest or made through the Service may be monitored and recorded for quality purposes.</div>),
      tcbullettextcopy9_plainText: "Communications from ZNest and Others. By accessing or using the Service, you consent to receive communications from other users and ZNest through the Service, or through any other means such as emails, push notifications, text messages (including SMS and MMS), and phone calls. These communications may promote ZNest or businesses listed on ZNest, and may be initiated by ZNest, businesses listed on ZNest, or other users. You further understand that communications may be sent using an automatic telephone dialing system, and that you may be charged by your phone carrier for certain communications such as SMS messages or phone calls. You agree to notify us immediately if the phone number(s) you have provided to us have been changed or disconnected. Please note that any communications, including phone calls, with ZNest or made through the Service may be monitored and recorded for quality purposes.",
      tcsmallheadingtextcopy4: (<div>5.   CONTENT</div>),
      tcsmallheadingtextcopy4_plainText: "5.   CONTENT",
      tcbulletnumberingcopy8: (<div>A.</div>),
      tcbulletnumberingcopy8_plainText: "A.",
      tcbullettextcopy10: (<div>Responsibility for Your Content. You alone are responsible for Your Content, and once posted to ZNest, it cannot always be withdrawn. You assume all risks associated with Your Content, including anyone’s reliance on its quality, accuracy, or reliability, and any risks associated with personal information you disclose. You represent that you own or have the necessary permissions to use and authorize the use of Your Content as described herein. You may not imply that Your Content is in any way sponsored or endorsed by ZNest.<br />You may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, service mark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the violation of any law or regulation; or violates these Terms.</div>),
      tcbullettextcopy10_plainText: "Responsibility for Your Content. You alone are responsible for Your Content, and once posted to ZNest, it cannot always be withdrawn. You assume all risks associated with Your Content, including anyone’s reliance on its quality, accuracy, or reliability, and any risks associated with personal information you disclose. You represent that you own or have the necessary permissions to use and authorize the use of Your Content as described herein. You may not imply that Your Content is in any way sponsored or endorsed by ZNest.\nYou may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, service mark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the violation of any law or regulation; or violates these Terms.",
      tcbulletnumberingcopy9: (<div>B.</div>),
      tcbulletnumberingcopy9_plainText: "B.",
      tcbullettextcopy11: (<div>Our Right to Use Your Content. We may use Your Content in a number of different ways, including by publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms (“Other Media”). As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose. Please note that you also irrevocably grant the users of the Service and any Other Media the right to access Your Content in connection with their use of the Service and any Other Media. Finally, you irrevocably waive, and cause to be waived, against ZNest and its users any claims and assertions of moral rights or attribution with respect to Your Content. By “use” we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.</div>),
      tcbullettextcopy11_plainText: "Our Right to Use Your Content. We may use Your Content in a number of different ways, including by publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms (“Other Media”). As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose. Please note that you also irrevocably grant the users of the Service and any Other Media the right to access Your Content in connection with their use of the Service and any Other Media. Finally, you irrevocably waive, and cause to be waived, against ZNest and its users any claims and assertions of moral rights or attribution with respect to Your Content. By “use” we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.",
      tcbulletnumberingcopy10: (<div>C.</div>),
      tcbulletnumberingcopy10_plainText: "C.",
      tcbullettextcopy12: (<div>Ownership. As between you and ZNest, you own Your Content. We own the ZNest Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation (including, but not limited to, our selection, coordination, aggregation, and arrangement of User Content and other Service Content), computer code, products, software, aggregate star ratings, and all other elements and components of the Service excluding Your Content, User Content and Third Party Content. We also own the copyrights, trademarks, service marks, trade names, trade secrets, and other intellectual and proprietary rights throughout the world associated with the ZNest Content and the Service, which are protected by copyright, trade dress, patent, trademark, and trade secret laws and all other applicable intellectual and proprietary rights and laws. As such, you may not sell, license, copy, publish, modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way use or exploit any of the ZNest Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Service and the ZNest Content are retained by us.</div>),
      tcbullettextcopy12_plainText: "Ownership. As between you and ZNest, you own Your Content. We own the ZNest Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation (including, but not limited to, our selection, coordination, aggregation, and arrangement of User Content and other Service Content), computer code, products, software, aggregate star ratings, and all other elements and components of the Service excluding Your Content, User Content and Third Party Content. We also own the copyrights, trademarks, service marks, trade names, trade secrets, and other intellectual and proprietary rights throughout the world associated with the ZNest Content and the Service, which are protected by copyright, trade dress, patent, trademark, and trade secret laws and all other applicable intellectual and proprietary rights and laws. As such, you may not sell, license, copy, publish, modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way use or exploit any of the ZNest Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Service and the ZNest Content are retained by us.",
      tcbulletnumberingcopy11: (<div>D.</div>),
      tcbulletnumberingcopy11_plainText: "D.",
      tcbullettextcopy13: (<div>Advertising. ZNest and its licensees may publicly display advertisements, paid content, and other information nearby or in association with Your Content. You are not entitled to any compensation for such advertisements. The manner, mode and extent of such advertising are subject to change without specific notice to you.</div>),
      tcbullettextcopy13_plainText: "Advertising. ZNest and its licensees may publicly display advertisements, paid content, and other information nearby or in association with Your Content. You are not entitled to any compensation for such advertisements. The manner, mode and extent of such advertising are subject to change without specific notice to you.",
      tcbulletnumberingcopy12: (<div>E.</div>),
      tcbulletnumberingcopy12_plainText: "E.",
      tcbullettextcopy15: (<div>Other. User Content (including any that may have been created by users employed or contracted by ZNest) does not necessarily reflect the opinion of ZNest. Except as required by law, we have no obligation to retain or provide you with copies of Your Content, and we do not guarantee any confidentiality with respect to Your Content. We reserve the right to remove, screen, edit, or reinstate User Content at our sole discretion for any reason or no reason, and without notice to you. For example, we may remove a review if we believe it violates our Content Guidelines. Except in accordance with ZNest’s Verified License program, ZNest does not attempt to verify any licenses a local business or its representatives may have, and consumers should inquire about any such licenses with the business directly. Businesses whose licenses have been verified by ZNest will have a “Verified License” badge displayed on their ZNest business page.</div>),
      tcbullettextcopy15_plainText: "Other. User Content (including any that may have been created by users employed or contracted by ZNest) does not necessarily reflect the opinion of ZNest. Except as required by law, we have no obligation to retain or provide you with copies of Your Content, and we do not guarantee any confidentiality with respect to Your Content. We reserve the right to remove, screen, edit, or reinstate User Content at our sole discretion for any reason or no reason, and without notice to you. For example, we may remove a review if we believe it violates our Content Guidelines. Except in accordance with ZNest’s Verified License program, ZNest does not attempt to verify any licenses a local business or its representatives may have, and consumers should inquire about any such licenses with the business directly. Businesses whose licenses have been verified by ZNest will have a “Verified License” badge displayed on their ZNest business page.",
      tcsmallheadingtextcopy5: (<div>6.   BOOKING AND TRANSACTING</div>),
      tcsmallheadingtextcopy5_plainText: "6.   BOOKING AND TRANSACTING",
      tcbullettextcopy14: (<div>You can access features through the Service that allow you to book or transact online with local businesses, such as scheduling appointments. These features may be provided by ZNest’s third-party partners, including through iframes or similar formats, and their use may be governed by different or additional terms presented to you as part of the booking or transaction process. Please note that such third-party partners and/or the transacting local businesses themselves are responsible for fulfilling such bookings and transactions.</div>),
      tcbullettextcopy14_plainText: "You can access features through the Service that allow you to book or transact online with local businesses, such as scheduling appointments. These features may be provided by ZNest’s third-party partners, including through iframes or similar formats, and their use may be governed by different or additional terms presented to you as part of the booking or transaction process. Please note that such third-party partners and/or the transacting local businesses themselves are responsible for fulfilling such bookings and transactions.",
      tcsmallheadingtextcopy6: (<div>7.   REPRESENTATIONS AND WARRANTIES</div>),
      tcsmallheadingtextcopy6_plainText: "7.   REPRESENTATIONS AND WARRANTIES",
      tcbullettextcopy19: (<div>We are under no obligation to enforce the Terms on your behalf against another user. While we encourage you to let us know if you believe another user has violated the Terms, we reserve the right to investigate and take appropriate action at our sole discretion.</div>),
      tcbullettextcopy19_plainText: "We are under no obligation to enforce the Terms on your behalf against another user. While we encourage you to let us know if you believe another user has violated the Terms, we reserve the right to investigate and take appropriate action at our sole discretion.",
      tcbulletnumberingcopy13: (<div>A.</div>),
      tcbulletnumberingcopy13_plainText: "A.",
      tcbullettextcopy16: (<div>You represent and warrant that:</div>),
      tcbullettextcopy16_plainText: "You represent and warrant that:",
      tcbulletnumberingcopy14: (<div>i.</div>),
      tcbulletnumberingcopy14_plainText: "i.",
      tcbullettextcopy17: (<div>You have read and understood our Content Guidelines;</div>),
      tcbullettextcopy17_plainText: "You have read and understood our Content Guidelines;",
      tcbulletnumberingcopy16: (<div>ii.</div>),
      tcbulletnumberingcopy16_plainText: "ii.",
      tcbullettextcopy20: (<div>You have read and understood our Privacy Policy. If you use the Service outside of the United States of America, you consent to having your personal data transferred to and processed in the United States of America; and</div>),
      tcbullettextcopy20_plainText: "You have read and understood our Privacy Policy. If you use the Service outside of the United States of America, you consent to having your personal data transferred to and processed in the United States of America; and",
      tcbulletnumberingcopy17: (<div>iii.</div>),
      tcbulletnumberingcopy17_plainText: "iii.",
      tcbullettextcopy21: (<div>Prior to attending any event listed on the Service, you have read and agree to our Event Terms and Conditions.</div>),
      tcbullettextcopy21_plainText: "Prior to attending any event listed on the Service, you have read and agree to our Event Terms and Conditions.",
      tcbulletnumberingcopy15: (<div>B.</div>),
      tcbulletnumberingcopy15_plainText: "B.",
      tcbullettextcopy18: (<div>You also represent and warrant that you will not, and will not assist, encourage, or enable others to use the Service to:</div>),
      tcbullettextcopy18_plainText: "You also represent and warrant that you will not, and will not assist, encourage, or enable others to use the Service to:",
      tcbulletnumberingcopy18: (<div>i.</div>),
      tcbulletnumberingcopy18_plainText: "i.",
      tcbullettextcopy22: (<div>Violate our Terms, including the Content Guidelines and Event Terms and Conditions;</div>),
      tcbullettextcopy22_plainText: "Violate our Terms, including the Content Guidelines and Event Terms and Conditions;",
      tcbulletnumberingcopy19: (<div>ii.</div>),
      tcbulletnumberingcopy19_plainText: "ii.",
      tcbullettextcopy23: (<div>Post any fake or defamatory review, trade reviews with others, or compensate someone or be compensated to post, refrain from posting, or remove a review;</div>),
      tcbullettextcopy23_plainText: "Post any fake or defamatory review, trade reviews with others, or compensate someone or be compensated to post, refrain from posting, or remove a review;",
      tcbulletnumberingcopy20: (<div>iii.</div>),
      tcbulletnumberingcopy20_plainText: "iii.",
      tcbullettextcopy24: (<div>Violate any third party’s rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;</div>),
      tcbullettextcopy24_plainText: "Violate any third party’s rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;",
      tcbulletnumberingcopy21: (<div>iv.</div>),
      tcbulletnumberingcopy21_plainText: "iv.",
      tcbullettextcopy25: (<div>Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;</div>),
      tcbullettextcopy25_plainText: "Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;",
      tcbulletnumberingcopy22: (<div>v.</div>),
      tcbulletnumberingcopy22_plainText: "v.",
      tcbullettextcopy26: (<div>Promote a business or other commercial venture or event, or otherwise use the Service for commercial purposes, except in connection with a Business Account in accordance with the Business Terms;</div>),
      tcbullettextcopy26_plainText: "Promote a business or other commercial venture or event, or otherwise use the Service for commercial purposes, except in connection with a Business Account in accordance with the Business Terms;",
      tcbulletnumberingcopy23: (<div>vi.</div>),
      tcbulletnumberingcopy23_plainText: "vi.",
      tcbullettextcopy27: (<div>Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage in keyword spamming, or otherwise attempt to manipulate the Service’s search results, review Recommendation Software (as defined in the Business Terms below), or any third party website;</div>),
      tcbullettextcopy27_plainText: "Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage in keyword spamming, or otherwise attempt to manipulate the Service’s search results, review Recommendation Software (as defined in the Business Terms below), or any third party website;",
      tcbulletnumberingcopy24: (<div>vii.</div>),
      tcbulletnumberingcopy24_plainText: "vii.",
      tcbullettextcopy28: (<div>Solicit personal information from minors, or submit or transmit pornography;</div>),
      tcbullettextcopy28_plainText: "Solicit personal information from minors, or submit or transmit pornography;",
      tcbulletnumberingcopy25: (<div>viii.</div>),
      tcbulletnumberingcopy25_plainText: "viii.",
      tcbullettextcopy29: (<div>Violate any applicable law;</div>),
      tcbullettextcopy29_plainText: "Violate any applicable law;",
      tcbulletnumberingcopy26: (<div>ix.</div>),
      tcbulletnumberingcopy26_plainText: "ix.",
      tcbullettextcopy30: (<div>Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the Service or Service Content (other than Your Content), except as expressly authorized by ZNest;</div>),
      tcbullettextcopy30_plainText: "Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the Service or Service Content (other than Your Content), except as expressly authorized by ZNest;",
      tcbulletnumberingcopy27: (<div>x.</div>),
      tcbulletnumberingcopy27_plainText: "x.",
      tcbullettextcopy31: (<div>Use any robot, spider, Service search/retrieval application, or other automated device, process or means to access, retrieve, copy, scrape, or index any portion of the Service or any Service Content, except as expressly permitted by ZNest;</div>),
      tcbullettextcopy31_plainText: "Use any robot, spider, Service search/retrieval application, or other automated device, process or means to access, retrieve, copy, scrape, or index any portion of the Service or any Service Content, except as expressly permitted by ZNest;",
      tcbulletnumberingcopy28: (<div>xi.</div>),
      tcbulletnumberingcopy28_plainText: "xi.",
      tcbullettextcopy32: (<div>Reverse engineer any portion of the Service, unless applicable law prohibits this restriction, in which case you agree to provide us with 30 days’ prior written notice here;</div>),
      tcbullettextcopy32_plainText: "Reverse engineer any portion of the Service, unless applicable law prohibits this restriction, in which case you agree to provide us with 30 days’ prior written notice here;",
      tcbulletnumberingcopy29: (<div>xii.</div>),
      tcbulletnumberingcopy29_plainText: "xii.",
      tcbullettextcopy33: (<div>Remove or modify any copyright, trademark, or other proprietary rights notice that appears on any portion of the Service or on any materials printed or copied from the Service;</div>),
      tcbullettextcopy33_plainText: "Remove or modify any copyright, trademark, or other proprietary rights notice that appears on any portion of the Service or on any materials printed or copied from the Service;",
      tcbulletnumberingcopy30: (<div>xiii.</div>),
      tcbulletnumberingcopy30_plainText: "xiii.",
      tcbullettextcopy35: (<div>Record, process, or mine information about users;</div>),
      tcbullettextcopy35_plainText: "Record, process, or mine information about users;",
      tcbulletnumberingcopy31: (<div>xiv.</div>),
      tcbulletnumberingcopy31_plainText: "xiv.",
      tcbullettextcopy36: (<div>Access, retrieve or index any portion of the Service for purposes of constructing or populating a searchable database of business reviews;</div>),
      tcbullettextcopy36_plainText: "Access, retrieve or index any portion of the Service for purposes of constructing or populating a searchable database of business reviews;",
      tcbulletnumberingcopy32: (<div>xv.</div>),
      tcbulletnumberingcopy32_plainText: "xv.",
      tcbullettextcopy37: (<div>Reformat or frame any portion of the Service;</div>),
      tcbullettextcopy37_plainText: "Reformat or frame any portion of the Service;",
      tcbulletnumberingcopy33: (<div>xvi.</div>),
      tcbulletnumberingcopy33_plainText: "xvi.",
      tcbullettextcopy38: (<div>Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on ZNest’s technology infrastructure or otherwise make excessive traffic demands of the Service;</div>),
      tcbullettextcopy38_plainText: "Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on ZNest’s technology infrastructure or otherwise make excessive traffic demands of the Service;",
      tcbulletnumberingcopy34: (<div>xvii.</div>),
      tcbulletnumberingcopy34_plainText: "xvii.",
      tcbullettextcopy39: (<div>Attempt to gain unauthorized access to the Service, Accounts, computer systems or networks connected to the Service through hacking, password mining or any other means;</div>),
      tcbullettextcopy39_plainText: "Attempt to gain unauthorized access to the Service, Accounts, computer systems or networks connected to the Service through hacking, password mining or any other means;",
      tcbulletnumberingcopy35: (<div>xviii.</div>),
      tcbulletnumberingcopy35_plainText: "xviii.",
      tcbullettextcopy40: (<div>Use the Service or any Service Content to transmit any computer viruses, worms, defects, Trojan horses, malicious code, spyware, malware or other items of a destructive or harmful nature;</div>),
      tcbullettextcopy40_plainText: "Use the Service or any Service Content to transmit any computer viruses, worms, defects, Trojan horses, malicious code, spyware, malware or other items of a destructive or harmful nature;",
      tcbulletnumberingcopy36: (<div>xix.</div>),
      tcbulletnumberingcopy36_plainText: "xix.",
      tcbullettextcopy41: (<div>Use any device, software or routine that interferes with the proper working of the Service, or otherwise attempt to interfere with the proper working of the Service;</div>),
      tcbullettextcopy41_plainText: "Use any device, software or routine that interferes with the proper working of the Service, or otherwise attempt to interfere with the proper working of the Service;",
      tcbulletnumberingcopy37: (<div>xx.</div>),
      tcbulletnumberingcopy37_plainText: "xx.",
      tcbullettextcopy42: (<div>Use the Service to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the Service or Service Content; or</div>),
      tcbullettextcopy42_plainText: "Use the Service to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the Service or Service Content; or",
      tcbulletnumberingcopy38: (<div>xxi.</div>),
      tcbulletnumberingcopy38_plainText: "xxi.",
      tcbullettextcopy43: (<div>Remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Service, features that prevent or restrict the use or copying of Service Content, or features that enforce limitations on the use of the Service.</div>),
      tcbullettextcopy43_plainText: "Remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Service, features that prevent or restrict the use or copying of Service Content, or features that enforce limitations on the use of the Service.",
      tcsmallheadingtextcopy7: (<div>8.   ADDITIONAL POLICIES AND TERMS</div>),
      tcsmallheadingtextcopy7_plainText: "8.   ADDITIONAL POLICIES AND TERMS",
      tcbulletnumberingcopy39: (<div>A.</div>),
      tcbulletnumberingcopy39_plainText: "A.",
      tcbullettextcopy34: (<div>Copyright and Trademark Disputes. You agree to follow our Infringement Policy in notifying us about copyright and trademark disputes concerning User Content. You agree we may forward any notification sent pursuant to our Infringement Policy to the user who submitted the User Content at issue.</div>),
      tcbullettextcopy34_plainText: "Copyright and Trademark Disputes. You agree to follow our Infringement Policy in notifying us about copyright and trademark disputes concerning User Content. You agree we may forward any notification sent pursuant to our Infringement Policy to the user who submitted the User Content at issue.",
      tcbulletnumberingcopy40: (<div>B.</div>),
      tcbulletnumberingcopy40_plainText: "B.",
      tcbullettextcopy44: (<div>Additional Terms. Your use of the Service is subject to any and all additional terms, policies, rules, or guidelines that we may post on or link to from the Service (the “Additional Terms”). All such Additional Terms are hereby incorporated by reference into, and made a part of, these Terms. If you have a Business Account, the Business Terms provided below apply to you.</div>),
      tcbullettextcopy44_plainText: "Additional Terms. Your use of the Service is subject to any and all additional terms, policies, rules, or guidelines that we may post on or link to from the Service (the “Additional Terms”). All such Additional Terms are hereby incorporated by reference into, and made a part of, these Terms. If you have a Business Account, the Business Terms provided below apply to you.",
      tcsmallheadingtextcopy8: (<div>9.   SUGGESTIONS AND IMPROVEMENTS</div>),
      tcsmallheadingtextcopy8_plainText: "9.   SUGGESTIONS AND IMPROVEMENTS",
      tcbullettextcopy45: (<div>By sending us any ideas, suggestions, documents or proposals (“Feedback”), you agree that (i) your Feedback does not contain any third party confidential or proprietary information, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, (iv) we have no obligation to review, consider, or implement the Feedback, or to return to you all or part of the Feedback, and (v) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide, assignable, sublicensable, transferable license to use, modify, prepare derivative works of, publish, distribute and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against ZNest and its users any claims and assertions of any moral rights contained in such Feedback.</div>),
      tcbullettextcopy45_plainText: "By sending us any ideas, suggestions, documents or proposals (“Feedback”), you agree that (i) your Feedback does not contain any third party confidential or proprietary information, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, (iv) we have no obligation to review, consider, or implement the Feedback, or to return to you all or part of the Feedback, and (v) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide, assignable, sublicensable, transferable license to use, modify, prepare derivative works of, publish, distribute and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against ZNest and its users any claims and assertions of any moral rights contained in such Feedback.",
      tcsmallheadingtextcopy9: (<div>10. THIRD PARTY CONTENT AND SERVICES</div>),
      tcsmallheadingtextcopy9_plainText: "10. THIRD PARTY CONTENT AND SERVICES",
      tcbulletnumberingcopy41: (<div>A.</div>),
      tcbulletnumberingcopy41_plainText: "A.",
      tcbullettextcopy46: (<div>The Service may host Third Party Content, or include links to other websites or applications (each, a “Third Party Service”). We do not control or endorse any Third Party Content or Third Party Service. You agree that we are not responsible for the availability, accuracy, or content of any such Third Party Content or Third Party Service. Your use of and reliance on any Third Party Content or Third Party Service is at your own risk.  Some of the services made available through the Service and Third Party Services may be subject to additional third party terms of service, privacy policies, licensing terms and disclosures, and other terms, conditions, and policies, including without limitation the ones posted here. It is your responsibility to familiarize yourself with any such applicable third party terms.</div>),
      tcbullettextcopy46_plainText: "The Service may host Third Party Content, or include links to other websites or applications (each, a “Third Party Service”). We do not control or endorse any Third Party Content or Third Party Service. You agree that we are not responsible for the availability, accuracy, or content of any such Third Party Content or Third Party Service. Your use of and reliance on any Third Party Content or Third Party Service is at your own risk.  Some of the services made available through the Service and Third Party Services may be subject to additional third party terms of service, privacy policies, licensing terms and disclosures, and other terms, conditions, and policies, including without limitation the ones posted here. It is your responsibility to familiarize yourself with any such applicable third party terms.",
      tcsmallheadingtextcopy10: (<div>11. INDEMNITY</div>),
      tcsmallheadingtextcopy10_plainText: "11. INDEMNITY",
      tcbullettextcopy47: (<div>You agree to indemnify, defend, and hold harmless ZNest, its parents, subsidiaries, affiliates, any related companies, suppliers, licensors and partners, and the officers, directors, employees, agents, contractors and representatives of each of them (collectively, the “ZNest Entities”) from and against any and all third party claims, actions, demands, losses, damages, costs, liabilities and expenses (including but not limited to attorneys’ fees and court costs)  arising out of or relating to: (i) your access to or use of the Service, including Your Content, (ii) your violation of the Terms, (iii) your breach of your representations and warranties provided under these Terms, (iv) any products or services purchased or obtained by you in connection with the Service, (v) your products or services, or the marketing or provision thereof to end users, or (vi) the infringement by you, or any third party using your Account, of any intellectual property or other right of any person or entity. ZNest reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any such matter without the prior written consent of ZNest. ZNest will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</div>),
      tcbullettextcopy47_plainText: "You agree to indemnify, defend, and hold harmless ZNest, its parents, subsidiaries, affiliates, any related companies, suppliers, licensors and partners, and the officers, directors, employees, agents, contractors and representatives of each of them (collectively, the “ZNest Entities”) from and against any and all third party claims, actions, demands, losses, damages, costs, liabilities and expenses (including but not limited to attorneys’ fees and court costs)  arising out of or relating to: (i) your access to or use of the Service, including Your Content, (ii) your violation of the Terms, (iii) your breach of your representations and warranties provided under these Terms, (iv) any products or services purchased or obtained by you in connection with the Service, (v) your products or services, or the marketing or provision thereof to end users, or (vi) the infringement by you, or any third party using your Account, of any intellectual property or other right of any person or entity. ZNest reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any such matter without the prior written consent of ZNest. ZNest will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.",
      tcsmallheadingtextcopy11: (<div>12. DISCLAIMERS AND LIMITATIONS OF LIABILITY</div>),
      tcsmallheadingtextcopy11_plainText: "12. DISCLAIMERS AND LIMITATIONS OF LIABILITY",
      tcbullettextcopy48: (<div>PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE ZNEST ENTITIES TO YOU. EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. BY ACCESSING OR USING THE SERVICE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.</div>),
      tcbullettextcopy48_plainText: "PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE ZNEST ENTITIES TO YOU. EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. BY ACCESSING OR USING THE SERVICE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.",
      tcbulletnumberingcopy42: (<div>A.</div>),
      tcbulletnumberingcopy42_plainText: "A.",
      tcbullettextcopy49: (<div>THE SERVICE AND SERVICE CONTENT ARE MADE AVAILABLE TO YOU ON AN “AS IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE ZNEST ENTITIES MAY NOT MONITOR, CONTROL, OR VET USER CONTENT OR THIRD PARTY CONTENT. AS SUCH, YOUR USE OF THE SERVICE IS AT YOUR OWN DISCRETION AND RISK. THE ZNEST ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, COMPLETENESS, ACCURACY, OR RELIABILITY OF THE SERVICE, ITS SAFETY OR SECURITY, INCLUDING WITHOUT LIMITATION THE SECURITY OF YOUR DATA, OR THE SERVICE CONTENT. ACCORDINGLY, THE ZNEST ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE SERVICE’S INOPERABILITY, DEPLETION OF BATTERY POWER OR OTHER IMPAIRMENT OF DEVICES USED TO ACCESS THE SERVICE, SERVICE UNAVAILABILITY, SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF THE BUSINESS LISTINGS, RATINGS, REVIEWS (INCLUDING THEIR CONTENT OR OMISSION OF CONTENT, ORDER, AND DISPLAY), METRICS OR OTHER CONTENT FOUND ON, USED ON, OR MADE AVAILABLE THROUGH THE SERVICE.</div>),
      tcbullettextcopy49_plainText: "THE SERVICE AND SERVICE CONTENT ARE MADE AVAILABLE TO YOU ON AN “AS IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE ZNEST ENTITIES MAY NOT MONITOR, CONTROL, OR VET USER CONTENT OR THIRD PARTY CONTENT. AS SUCH, YOUR USE OF THE SERVICE IS AT YOUR OWN DISCRETION AND RISK. THE ZNEST ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, COMPLETENESS, ACCURACY, OR RELIABILITY OF THE SERVICE, ITS SAFETY OR SECURITY, INCLUDING WITHOUT LIMITATION THE SECURITY OF YOUR DATA, OR THE SERVICE CONTENT. ACCORDINGLY, THE ZNEST ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE SERVICE’S INOPERABILITY, DEPLETION OF BATTERY POWER OR OTHER IMPAIRMENT OF DEVICES USED TO ACCESS THE SERVICE, SERVICE UNAVAILABILITY, SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF THE BUSINESS LISTINGS, RATINGS, REVIEWS (INCLUDING THEIR CONTENT OR OMISSION OF CONTENT, ORDER, AND DISPLAY), METRICS OR OTHER CONTENT FOUND ON, USED ON, OR MADE AVAILABLE THROUGH THE SERVICE.",
      tcbulletnumberingcopy43: (<div>B.</div>),
      tcbulletnumberingcopy43_plainText: "B.",
      tcbullettextcopy50: (<div>THE ZNEST ENTITIES MAKE NO CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE BUSINESSES OR ADVERTISERS LISTED ON THE SERVICE OR THAT OFFER GOODS OR SERVICES THROUGH THE SERVICE, OR THE SERVICE’S USERS. ACCORDINGLY, THE ZNEST ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE FROM ANY SUCH THIRD PARTY’S ACTIONS OR OMISSIONS, INCLUDING, FOR EXAMPLE, IF ANOTHER USER OR BUSINESS MISUSES YOUR CONTENT, IDENTITY OR PERSONAL INFORMATION, OR IF YOU HAVE A NEGATIVE EXPERIENCE WITH ONE OF THE BUSINESSES OR ADVERTISERS LISTED OR FEATURED ON THE SERVICE. YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES THROUGH THE SERVICE IS AT YOUR OWN DISCRETION AND RISK.</div>),
      tcbullettextcopy50_plainText: "THE ZNEST ENTITIES MAKE NO CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE BUSINESSES OR ADVERTISERS LISTED ON THE SERVICE OR THAT OFFER GOODS OR SERVICES THROUGH THE SERVICE, OR THE SERVICE’S USERS. ACCORDINGLY, THE ZNEST ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE FROM ANY SUCH THIRD PARTY’S ACTIONS OR OMISSIONS, INCLUDING, FOR EXAMPLE, IF ANOTHER USER OR BUSINESS MISUSES YOUR CONTENT, IDENTITY OR PERSONAL INFORMATION, OR IF YOU HAVE A NEGATIVE EXPERIENCE WITH ONE OF THE BUSINESSES OR ADVERTISERS LISTED OR FEATURED ON THE SERVICE. YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES THROUGH THE SERVICE IS AT YOUR OWN DISCRETION AND RISK.",
      tcbulletnumberingcopy44: (<div>C.</div>),
      tcbulletnumberingcopy44_plainText: "C.",
      tcbullettextcopy51: (<div>YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SERVICE</div>),
      tcbullettextcopy51_plainText: "YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SERVICE",
      tcbulletnumberingcopy45: (<div>D.</div>),
      tcbulletnumberingcopy45_plainText: "D.",
      tcbullettextcopy52: (<div>THE ZNEST ENTITIES’ MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SERVICE OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT PAID, IF ANY, BY YOU TO THE ZNEST ENTITIES IN CONNECTION WITH THE SERVICE IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100.</div>),
      tcbullettextcopy52_plainText: "THE ZNEST ENTITIES’ MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SERVICE OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT PAID, IF ANY, BY YOU TO THE ZNEST ENTITIES IN CONNECTION WITH THE SERVICE IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100.",
      tcbulletnumberingcopy46: (<div>E.</div>),
      tcbulletnumberingcopy46_plainText: "E.",
      tcbullettextcopy53: (<div>THE ZNEST ENTITIES’ LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ZNEST ENTITIES WILL NOT BE LIABLE FOR ANY (i) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS OR REVENUE, (iii) BUSINESS INTERRUPTION, (iv) REPUTATIONAL HARM, (v) LOSS OF INFORMATION OR DATA; OR (vi) LIABILITY WITH RESPECT TO A CONSUMER ALERT POSTED ON ANY ZNEST BUSINESS PAGES FOR YOUR BUSINESS. THE WAIVERS AND LIMITATIONS SPECIFIED IN THIS SECTION 12 WILL SURVIVE AND APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE.</div>),
      tcbullettextcopy53_plainText: "THE ZNEST ENTITIES’ LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ZNEST ENTITIES WILL NOT BE LIABLE FOR ANY (i) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS OR REVENUE, (iii) BUSINESS INTERRUPTION, (iv) REPUTATIONAL HARM, (v) LOSS OF INFORMATION OR DATA; OR (vi) LIABILITY WITH RESPECT TO A CONSUMER ALERT POSTED ON ANY ZNEST BUSINESS PAGES FOR YOUR BUSINESS. THE WAIVERS AND LIMITATIONS SPECIFIED IN THIS SECTION 12 WILL SURVIVE AND APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE.",
      tcsmallheadingtextcopy12: (<div>13.   	ARBITRATION, DISPUTES, AND CHOICE OF LAW</div>),
      tcsmallheadingtextcopy12_plainText: "13.   \tARBITRATION, DISPUTES, AND CHOICE OF LAW",
      tcbulletnumberingcopy47: (<div>A.</div>),
      tcbulletnumberingcopy47_plainText: "A.",
      tcbullettextcopy54: (<div>If you are a resident of the United States or Canada:</div>),
      tcbullettextcopy54_plainText: "If you are a resident of the United States or Canada:",
      tcbulletnumberingcopy48: (<div>i.</div>),
      tcbulletnumberingcopy48_plainText: "i.",
      tcbullettextcopy55: (<div>EXCEPT FOR EXCLUDED CLAIMS: ANY CLAIM, CAUSE OF ACTION, REQUEST FOR RELIEF OR DISPUTE THAT MIGHT ARISE BETWEEN YOU AND ZNEST (“CLAIMS”) MUST BE RESOLVED BY ARBITRATION ON AN INDIVIDUAL BASIS; YOU AND WE AGREE THAT EACH MAY BRING OR PARTICIPATE IN CLAIMS AGAINST THE OTHER ONLY IN OUR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND ZNEST AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. YOU AND ZNEST EXPRESSLY WAIVE THE RIGHT TO TRIAL BY A JURY.</div>),
      tcbullettextcopy55_plainText: "EXCEPT FOR EXCLUDED CLAIMS: ANY CLAIM, CAUSE OF ACTION, REQUEST FOR RELIEF OR DISPUTE THAT MIGHT ARISE BETWEEN YOU AND ZNEST (“CLAIMS”) MUST BE RESOLVED BY ARBITRATION ON AN INDIVIDUAL BASIS; YOU AND WE AGREE THAT EACH MAY BRING OR PARTICIPATE IN CLAIMS AGAINST THE OTHER ONLY IN OUR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND ZNEST AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. YOU AND ZNEST EXPRESSLY WAIVE THE RIGHT TO TRIAL BY A JURY.",
      tcbulletnumberingcopy49: (<div>ii.</div>),
      tcbulletnumberingcopy49_plainText: "ii.",
      tcbullettextcopy56: (<div>“Excluded Claims” means: (a) Claims brought by you or ZNest that could be brought in small claims court, if permitted by the rules of that court, or (b) Claims related to intellectual property (like copyrights and trademarks), violations of ZNest’s API Terms of Use (which, for clarity, are governed by those terms), or a breach of Section 7 above (Representations and Warranties). Excluded Claims may be brought in court. Any issues relating to the scope and enforceability of the arbitration provision will be resolved by the arbitrator. If any Claim cannot be arbitrated in accordance with this provision, then only that Claim may be brought in court and all other Claims remain subject to arbitration. Notwithstanding this paragraph, Business Claims, as defined in Section 3 of the Additional Terms for Business Accounts, are governed by that section.</div>),
      tcbullettextcopy56_plainText: "“Excluded Claims” means: (a) Claims brought by you or ZNest that could be brought in small claims court, if permitted by the rules of that court, or (b) Claims related to intellectual property (like copyrights and trademarks), violations of ZNest’s API Terms of Use (which, for clarity, are governed by those terms), or a breach of Section 7 above (Representations and Warranties). Excluded Claims may be brought in court. Any issues relating to the scope and enforceability of the arbitration provision will be resolved by the arbitrator. If any Claim cannot be arbitrated in accordance with this provision, then only that Claim may be brought in court and all other Claims remain subject to arbitration. Notwithstanding this paragraph, Business Claims, as defined in Section 3 of the Additional Terms for Business Accounts, are governed by that section.",
      tcbulletnumberingcopy50: (<div>iii.</div>),
      tcbulletnumberingcopy50_plainText: "iii.",
      tcbullettextcopy57: (<div>Excluded Claims and Claims that cannot be arbitrated must be brought in court. California law will govern these Terms (to the extent not preempted or inconsistent with federal law), as well as any such Excluded Claim or Claim that cannot be arbitrated, without regard to conflict of law provisions. You or ZNest may seek relief in any small claims court of competent jurisdiction. All other Excluded Claims and Claims that cannot be arbitrated are subject to the exclusive jurisdiction in, and the exclusive venue of, the state and federal courts located within Los Angeles County, California and you consent to the personal jurisdiction of these courts for the purpose of litigating any such Claim.</div>),
      tcbullettextcopy57_plainText: "Excluded Claims and Claims that cannot be arbitrated must be brought in court. California law will govern these Terms (to the extent not preempted or inconsistent with federal law), as well as any such Excluded Claim or Claim that cannot be arbitrated, without regard to conflict of law provisions. You or ZNest may seek relief in any small claims court of competent jurisdiction. All other Excluded Claims and Claims that cannot be arbitrated are subject to the exclusive jurisdiction in, and the exclusive venue of, the state and federal courts located within Los Angeles County, California and you consent to the personal jurisdiction of these courts for the purpose of litigating any such Claim.",
      tcbulletnumberingcopy51: (<div>iv.</div>),
      tcbulletnumberingcopy51_plainText: "iv.",
      tcbullettextcopy58: (<div>Arbitration shall be administered by the American Arbitration Association (“AAA”) in accordance with its Consumer Arbitration Rules then in effect. For more information, visit www.adr.org. Arbitration may be conducted in person, through the submission of documents, by phone or online. The arbitrator may award damages to you individually as a court could, including declaratory or injunctive relief, but only to the extent required to satisfy your individual claim.</div>),
      tcbullettextcopy58_plainText: "Arbitration shall be administered by the American Arbitration Association (“AAA”) in accordance with its Consumer Arbitration Rules then in effect. For more information, visit www.adr.org. Arbitration may be conducted in person, through the submission of documents, by phone or online. The arbitrator may award damages to you individually as a court could, including declaratory or injunctive relief, but only to the extent required to satisfy your individual claim.",
      tcbulletnumberingcopy52: (<div>v.</div>),
      tcbulletnumberingcopy52_plainText: "v.",
      tcbullettextcopy59: (<div>Unless the arbitrator finds the arbitration was frivolous or brought for an improper purpose, ZNest will pay all filing, AAA, and arbitrator’s fees and expenses. We waive any right to seek an award of attorneys’ fees and expenses in connection with any non-frivolous arbitration between you and us.</div>),
      tcbullettextcopy59_plainText: "Unless the arbitrator finds the arbitration was frivolous or brought for an improper purpose, ZNest will pay all filing, AAA, and arbitrator’s fees and expenses. We waive any right to seek an award of attorneys’ fees and expenses in connection with any non-frivolous arbitration between you and us.",
      tcsmallheadingtextcopy13: (<div>14.   	TERMINATION</div>),
      tcsmallheadingtextcopy13_plainText: "14.   \tTERMINATION",
      tcbulletnumberingcopy53: (<div>A.</div>),
      tcbulletnumberingcopy53_plainText: "A.",
      tcbullettextcopy60: (<div>You may terminate the Terms at any time by closing your Account, discontinuing any access to or use of the Service, and providing ZNest with a notice of termination here.</div>),
      tcbullettextcopy60_plainText: "You may terminate the Terms at any time by closing your Account, discontinuing any access to or use of the Service, and providing ZNest with a notice of termination here.",
      tcbulletnumberingcopy54: (<div>B.</div>),
      tcbulletnumberingcopy54_plainText: "B.",
      tcbullettextcopy61: (<div>We may close your Account, suspend your ability to use certain portions of the Service, terminate any license or permission granted to you hereunder, and/or ban you altogether from the Service for any or no reason, and without notice or liability of any kind. Any such action could prevent you from accessing your Account, the Service, Your Content, Service Content, or any other related information.</div>),
      tcbullettextcopy61_plainText: "We may close your Account, suspend your ability to use certain portions of the Service, terminate any license or permission granted to you hereunder, and/or ban you altogether from the Service for any or no reason, and without notice or liability of any kind. Any such action could prevent you from accessing your Account, the Service, Your Content, Service Content, or any other related information.",
      tcbulletnumberingcopy55: (<div>C.</div>),
      tcbulletnumberingcopy55_plainText: "C.",
      tcbullettextcopy62: (<div>In the event of any termination of these Terms, whether by you or us, Sections 1, 3,  5, 7–15 of the Terms of Service will continue in full force and effect.</div>),
      tcbullettextcopy62_plainText: "In the event of any termination of these Terms, whether by you or us, Sections 1, 3,  5, 7–15 of the Terms of Service will continue in full force and effect.",
      tcsmallheadingtextcopy14: (<div>15.   	GENERAL TERMS</div>),
      tcsmallheadingtextcopy14_plainText: "15.   \tGENERAL TERMS",
      tcbulletnumberingcopy56: (<div>A.</div>),
      tcbulletnumberingcopy56_plainText: "A.",
      tcbullettextcopy63: (<div>We reserve the right to modify, update, or discontinue the Service at our sole discretion, at any time, for any or no reason, and without notice or liability.</div>),
      tcbullettextcopy63_plainText: "We reserve the right to modify, update, or discontinue the Service at our sole discretion, at any time, for any or no reason, and without notice or liability.",
      tcbulletnumberingcopy57: (<div>B.</div>),
      tcbulletnumberingcopy57_plainText: "B.",
      tcbullettextcopy64: (<div>Except as otherwise stated in Section 10 above, nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party.</div>),
      tcbullettextcopy64_plainText: "Except as otherwise stated in Section 10 above, nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party.",
      tcbulletnumberingcopy58: (<div>C.</div>),
      tcbulletnumberingcopy58_plainText: "C.",
      tcbullettextcopy65: (<div>The Terms contain the entire agreement between you and us regarding the use of the Service, and supersede any prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.</div>),
      tcbullettextcopy65_plainText: "The Terms contain the entire agreement between you and us regarding the use of the Service, and supersede any prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.",
      tcbulletnumberingcopy59: (<div>D.</div>),
      tcbulletnumberingcopy59_plainText: "D.",
      tcbullettextcopy66: (<div>Any failure on ZNest’s part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. The Terms may not be waived, except pursuant to a writing executed by ZNest.</div>),
      tcbullettextcopy66_plainText: "Any failure on ZNest’s part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. The Terms may not be waived, except pursuant to a writing executed by ZNest.",
      tcbulletnumberingcopy60: (<div>E.</div>),
      tcbulletnumberingcopy60_plainText: "E.",
      tcbullettextcopy67: (<div>If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of competent jurisdiction, then only that provision shall be modified to reflect the parties’ intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.</div>),
      tcbullettextcopy67_plainText: "If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of competent jurisdiction, then only that provision shall be modified to reflect the parties’ intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.",
      tcbulletnumberingcopy61: (<div>F.</div>),
      tcbulletnumberingcopy61_plainText: "F.",
      tcbullettextcopy68: (<div>The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with ZNest’s prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.</div>),
      tcbullettextcopy68_plainText: "The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with ZNest’s prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.",
      tcbulletnumberingcopy62: (<div>G.</div>),
      tcbulletnumberingcopy62_plainText: "G.",
      tcbullettextcopy69: (<div>You agree that no joint venture, partnership, employment, agency, special or fiduciary relationship exists between you and ZNest as a result of these Terms or your use of the Service.</div>),
      tcbullettextcopy69_plainText: "You agree that no joint venture, partnership, employment, agency, special or fiduciary relationship exists between you and ZNest as a result of these Terms or your use of the Service.",
      tcbulletnumberingcopy63: (<div>H.</div>),
      tcbulletnumberingcopy63_plainText: "H.",
      tcbullettextcopy70: (<div>The section titles in the Terms are for convenience only and have no legal or contractual effect.</div>),
      tcbullettextcopy70_plainText: "The section titles in the Terms are for convenience only and have no legal or contractual effect.",
      tctextcopy: (<div>ADDITIONAL TERMS FOR BUSINESS ACCOUNTS</div>),
      tctextcopy_plainText: "ADDITIONAL TERMS FOR BUSINESS ACCOUNTS",
      tctext2copy: (<div>The following terms (“Business Terms”), in addition to the Terms of Service above, govern your access to and use of your Business Account. In the event of any conflict between these Business Terms and the Terms of Service, the Business Terms apply. If you have purchased products or services from ZNest on behalf of your business (e.g., advertising or business tools), the terms of that purchase apply in the event of any conflict with these Business Terms. Capitalized words used but not defined in these Business Terms have the meanings described in the Terms of Service. By creating, accessing, or using your Business Account, you are agreeing to these Business Terms and concluding a legally binding contract with ZNest. You are not authorized to create, access, or use a Business Account if you do not agree to these Business Terms.</div>),
      tctext2copy_plainText: "The following terms (“Business Terms”), in addition to the Terms of Service above, govern your access to and use of your Business Account. In the event of any conflict between these Business Terms and the Terms of Service, the Business Terms apply. If you have purchased products or services from ZNest on behalf of your business (e.g., advertising or business tools), the terms of that purchase apply in the event of any conflict with these Business Terms. Capitalized words used but not defined in these Business Terms have the meanings described in the Terms of Service. By creating, accessing, or using your Business Account, you are agreeing to these Business Terms and concluding a legally binding contract with ZNest. You are not authorized to create, access, or use a Business Account if you do not agree to these Business Terms.",
      tctext3copy: (<div>PLEASE READ THESE BUSINESS TERMS CAREFULLY AS THEY REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.</div>),
      tctext3copy_plainText: "PLEASE READ THESE BUSINESS TERMS CAREFULLY AS THEY REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.",
      tctext2copy2: (<div>In the event of any termination of these Business Terms, whether by you or us, these Business Terms in their entirety will continue in full force and effect.</div>),
      tctext2copy2_plainText: "In the event of any termination of these Business Terms, whether by you or us, these Business Terms in their entirety will continue in full force and effect.",
      tcsmallheadingtextcopy15: (<div>1.   	REQUIREMENTS, REPRESENTATIONS AND WARRANTIES</div>),
      tcsmallheadingtextcopy15_plainText: "1.   \tREQUIREMENTS, REPRESENTATIONS AND WARRANTIES",
      tcbulletnumberingcopy64: (<div>A.</div>),
      tcbulletnumberingcopy64_plainText: "A.",
      tcbullettextcopy71: (<div>In order to access or use the Services, you agree that:</div>),
      tcbullettextcopy71_plainText: "In order to access or use the Services, you agree that:",
      tcbulletnumberingcopy65: (<div>i.</div>),
      tcbulletnumberingcopy65_plainText: "i.",
      tcbullettextcopy72: (<div>you have the authority to act on behalf of the business or businesses associated with or claimed through your Business Account and bind any such business (including any corresponding business entity) to the Business Terms (such business or businesses, your “Business”);</div>),
      tcbullettextcopy72_plainText: "you have the authority to act on behalf of the business or businesses associated with or claimed through your Business Account and bind any such business (including any corresponding business entity) to the Business Terms (such business or businesses, your “Business”);",
      tcbulletnumberingcopy66: (<div>ii.</div>),
      tcbulletnumberingcopy66_plainText: "ii.",
      tcbullettextcopy73: (<div>your access to or use of the Business Site will only be in your capacity as an authorized representative of your Business;</div>),
      tcbullettextcopy73_plainText: "your access to or use of the Business Site will only be in your capacity as an authorized representative of your Business;",
      tcbulletnumberingcopy67: (<div>iii.</div>),
      tcbulletnumberingcopy67_plainText: "iii.",
      tcbullettextcopy74: (<div>you will not use the Consumer Site for business activities, including but not limited to flagging reviews or messaging people who have reviewed your Business;</div>),
      tcbullettextcopy74_plainText: "you will not use the Consumer Site for business activities, including but not limited to flagging reviews or messaging people who have reviewed your Business;",
      tcbulletnumberingcopy68: (<div>iv.</div>),
      tcbulletnumberingcopy68_plainText: "iv.",
      tcbullettextcopy75: (<div>your Business complies with applicable laws and does not offer, advertise, sell, or lease illegal products and/or services;</div>),
      tcbullettextcopy75_plainText: "your Business complies with applicable laws and does not offer, advertise, sell, or lease illegal products and/or services;",
      tcbulletnumberingcopy69: (<div>v.</div>),
      tcbulletnumberingcopy69_plainText: "v.",
      tcbullettextcopy76: (<div>you grant ZNest a non-transferable, non-exclusive, royalty-free limited license to display your public website on the Services, or allow for its display through iframes or other framing technology;</div>),
      tcbullettextcopy76_plainText: "you grant ZNest a non-transferable, non-exclusive, royalty-free limited license to display your public website on the Services, or allow for its display through iframes or other framing technology;",
      tcbulletnumberingcopy70: (<div>vi.</div>),
      tcbulletnumberingcopy70_plainText: "vi.",
      tcbullettextcopy77: (<div>you agree that we may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we have on record for your business, and that our communications (including phone calls) with you may be monitored and recorded for quality purposes;</div>),
      tcbullettextcopy77_plainText: "you agree that we may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we have on record for your business, and that our communications (including phone calls) with you may be monitored and recorded for quality purposes;",
      tcbulletnumberingcopy71: (<div>vii.</div>),
      tcbulletnumberingcopy71_plainText: "vii.",
      tcbullettextcopy78: (<div>you understand that we may display ratings and/or information based on health and safety inspections (“Ratings”) for your Business, and may place a Consumer Alert regarding those Ratings, on the business page for your Business; and</div>),
      tcbullettextcopy78_plainText: "you understand that we may display ratings and/or information based on health and safety inspections (“Ratings”) for your Business, and may place a Consumer Alert regarding those Ratings, on the business page for your Business; and",
      tcbulletnumberingcopy72: (<div>viii.</div>),
      tcbulletnumberingcopy72_plainText: "viii.",
      tcbullettextcopy79: (<div>you understand and acknowledge that non-disparagement clauses in certain consumer contracts, such as clauses that seek to restrict or prohibit reviews (including provisions that penalize consumers for posting reviews) about your Business, are prohibited under California law (Cal. Civil Code § 1670.8) and under the federal Consumer Review Fairness Act (15 U.S. Code § 45b) and you agree that you will not include such clauses in your consumer contracts, or otherwise attempt to enforce non-disparagement or ‘gag’ clauses against consumers under any circumstances. You understand that we may publicly notify consumers, including by placing a Consumer Alert on the business page for your Business, if we have a good faith belief that such clauses are used by your Business.</div>),
      tcbullettextcopy79_plainText: "you understand and acknowledge that non-disparagement clauses in certain consumer contracts, such as clauses that seek to restrict or prohibit reviews (including provisions that penalize consumers for posting reviews) about your Business, are prohibited under California law (Cal. Civil Code § 1670.8) and under the federal Consumer Review Fairness Act (15 U.S. Code § 45b) and you agree that you will not include such clauses in your consumer contracts, or otherwise attempt to enforce non-disparagement or ‘gag’ clauses against consumers under any circumstances. You understand that we may publicly notify consumers, including by placing a Consumer Alert on the business page for your Business, if we have a good faith belief that such clauses are used by your Business.",
      tcbulletnumberingcopy73: (<div>B.</div>),
      tcbulletnumberingcopy73_plainText: "B.",
      tcbullettextcopy80: (<div>You represent and warrant that you will not, and will not authorize or induce any other party, to:</div>),
      tcbullettextcopy80_plainText: "You represent and warrant that you will not, and will not authorize or induce any other party, to:",
      tcbulletnumberingcopy74: (<div>i.</div>),
      tcbulletnumberingcopy74_plainText: "i.",
      tcbullettextcopy81: (<div>offer incentives of any kind, such as discounts, freebies, refunds, gift cards, contest entries, offers, or deals in exchange for the posting of reviews of your Business, or to prevent or remove reviews, and you understand and acknowledge that ZNest, through its Consumer Alerts, may publicly notify consumers about such incentives and other attempts to obtain, prevent, or remove reviews;</div>),
      tcbullettextcopy81_plainText: "offer incentives of any kind, such as discounts, freebies, refunds, gift cards, contest entries, offers, or deals in exchange for the posting of reviews of your Business, or to prevent or remove reviews, and you understand and acknowledge that ZNest, through its Consumer Alerts, may publicly notify consumers about such incentives and other attempts to obtain, prevent, or remove reviews;",
      tcbulletnumberingcopy75: (<div>ii.</div>),
      tcbulletnumberingcopy75_plainText: "ii.",
      tcbullettextcopy82: (<div>solicit or ask for reviews from your customers;</div>),
      tcbullettextcopy82_plainText: "solicit or ask for reviews from your customers;",
      tcbulletnumberingcopy76: (<div>iii.</div>),
      tcbulletnumberingcopy76_plainText: "iii.",
      tcbullettextcopy83: (<div>write reviews or vote on Content (e.g., voting user reviews as useful, funny, or cool) for your Business or your Business’s competitors;</div>),
      tcbullettextcopy83_plainText: "write reviews or vote on Content (e.g., voting user reviews as useful, funny, or cool) for your Business or your Business’s competitors;",
      tcbulletnumberingcopy77: (<div>iv.</div>),
      tcbulletnumberingcopy77_plainText: "iv.",
      tcbullettextcopy84: (<div>pay or induce anyone to post, refrain from posting, or remove reviews, or otherwise attempt to circumvent fraud detection systems;</div>),
      tcbullettextcopy84_plainText: "pay or induce anyone to post, refrain from posting, or remove reviews, or otherwise attempt to circumvent fraud detection systems;",
      tcbulletnumberingcopy78: (<div>v.</div>),
      tcbulletnumberingcopy78_plainText: "v.",
      tcbullettextcopy85: (<div>attempt to generate automated, fraudulent, or otherwise invalid ad impressions, inquiries, conversions, ad clicks, or other actions;</div>),
      tcbullettextcopy85_plainText: "attempt to generate automated, fraudulent, or otherwise invalid ad impressions, inquiries, conversions, ad clicks, or other actions;",
      tcbulletnumberingcopy79: (<div>vi.</div>),
      tcbulletnumberingcopy79_plainText: "vi.",
      tcbullettextcopy86: (<div>use any automated means or form of scraping or data extraction to access, query or otherwise collect ZNest data, content and/or reviews from the Consumer Site or the Business Site, except as expressly permitted by ZNest;</div>),
      tcbullettextcopy86_plainText: "use any automated means or form of scraping or data extraction to access, query or otherwise collect ZNest data, content and/or reviews from the Consumer Site or the Business Site, except as expressly permitted by ZNest;",
      tcbulletnumberingcopy80: (<div>vii.</div>),
      tcbulletnumberingcopy80_plainText: "vii.",
      tcbullettextcopy87: (<div>use any ZNest trademark or service mark in any manner without ZNest’s prior written consent; or</div>),
      tcbullettextcopy87_plainText: "use any ZNest trademark or service mark in any manner without ZNest’s prior written consent; or",
      tcbulletnumberingcopy81: (<div>viii.</div>),
      tcbulletnumberingcopy81_plainText: "viii.",
      tcbullettextcopy88: (<div>misrepresent your identity or affiliation to anyone in connection with ZNest.</div>),
      tcbullettextcopy88_plainText: "misrepresent your identity or affiliation to anyone in connection with ZNest.",
      tcbulletnumberingcopy82: (<div>C.</div>),
      tcbulletnumberingcopy82_plainText: "C.",
      tcbullettextcopy89: (<div>You understand and acknowledge that ZNest allows consumers to post Content about your Business, including photos, ratings, and reviews. </div>),
      tcbullettextcopy89_plainText: "You understand and acknowledge that ZNest allows consumers to post Content about your Business, including photos, ratings, and reviews. ",
      tcbullettextcopy90: (<div>The following Sections 2 and 3 apply if you are a resident of the United States or Canada only:</div>),
      tcbullettextcopy90_plainText: "The following Sections 2 and 3 apply if you are a resident of the United States or Canada only:",
      tcsmallheadingtextcopy16: (<div>2.   	DISCLAIMERS AND LIMITATIONS OF LIABILITY</div>),
      tcsmallheadingtextcopy16_plainText: "2.   \tDISCLAIMERS AND LIMITATIONS OF LIABILITY",
      tcsmallheadingtextcopy17: (<div>PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE ZNEST ENTITIES TO YOU. FOR CLARITY, THE BELOW APPLIES IN ADDITION TO THE DISCLAIMERS AND LIMITATIONS OF LIABILITY DETAILED IN SECTION 12 OF THE TERMS.</div>),
      tcsmallheadingtextcopy17_plainText: "PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE ZNEST ENTITIES TO YOU. FOR CLARITY, THE BELOW APPLIES IN ADDITION TO THE DISCLAIMERS AND LIMITATIONS OF LIABILITY DETAILED IN SECTION 12 OF THE TERMS.",
      tcsmallheadingtextcopy18: (<div>The Federal Communications Decency Act (47 U.S. Code § 230) limits the liability of interactive computer services, like ZNest, for their role in publishing third-party Content, including consumer reviews. Additionally, anti-SLAPP laws, such as Cal. Civ. Proc. Code  § 425.16 in California, may require you to pay ZNest’s attorneys’ fees if you attempt to impose such liability on ZNest through legal proceedings</div>),
      tcsmallheadingtextcopy18_plainText: "The Federal Communications Decency Act (47 U.S. Code § 230) limits the liability of interactive computer services, like ZNest, for their role in publishing third-party Content, including consumer reviews. Additionally, anti-SLAPP laws, such as Cal. Civ. Proc. Code  § 425.16 in California, may require you to pay ZNest’s attorneys’ fees if you attempt to impose such liability on ZNest through legal proceedings",
      tcsmallheadingtextcopy19: (<div>3.  ARBITRATION, DISPUTES, AND CHOICE OF LAW</div>),
      tcsmallheadingtextcopy19_plainText: "3.  ARBITRATION, DISPUTES, AND CHOICE OF LAW",
      tcsmallheadingtextcopy20: (<div>FOR CLARITY, THIS SECTION GOVERNS ANY BUSINESS CLAIM BROUGHT BY YOU OR ZNEST. ANY CLAIM NOT SUBJECT TO THIS SECTION IS INSTEAD GOVERNED BY SECTION 13 OF THE TERMS.</div>),
      tcsmallheadingtextcopy20_plainText: "FOR CLARITY, THIS SECTION GOVERNS ANY BUSINESS CLAIM BROUGHT BY YOU OR ZNEST. ANY CLAIM NOT SUBJECT TO THIS SECTION IS INSTEAD GOVERNED BY SECTION 13 OF THE TERMS.",
      tcsmallheadingtextcopy21: (<div>Except for Excluded Business Claims, any controversy or claim arising out of or relating to: (a) these Terms, or the breach thereof; or (b) your access to or use of your Business Account Business Site; (each such controversy or claim, a “Business Claim”), shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The arbitrator will issue a reasoned award in writing, including all findings of fact and law upon which the award was made. The arbitrator will not have the power to commit errors of law, and the award may be vacated or corrected through judicial review by a court of competent jurisdiction under the California Arbitration Act for any such error. “Excluded Business Claims” means Claims related to intellectual property (like copyrights and trademarks) or violations of Section 7 of the Terms of Service (Representations and Warranties).<br />Business Claims shall be heard by a single arbitrator. Arbitrations will be held in San Francisco, California, but the parties may choose for themselves whether to appear in person, by phone, or through the submission of documents. The arbitration shall be governed by the laws of the State of California. The prevailing party shall be entitled to an award of reasonable attorneys’ fees.</div>),
      tcsmallheadingtextcopy21_plainText: "Except for Excluded Business Claims, any controversy or claim arising out of or relating to: (a) these Terms, or the breach thereof; or (b) your access to or use of your Business Account Business Site; (each such controversy or claim, a “Business Claim”), shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The arbitrator will issue a reasoned award in writing, including all findings of fact and law upon which the award was made. The arbitrator will not have the power to commit errors of law, and the award may be vacated or corrected through judicial review by a court of competent jurisdiction under the California Arbitration Act for any such error. “Excluded Business Claims” means Claims related to intellectual property (like copyrights and trademarks) or violations of Section 7 of the Terms of Service (Representations and Warranties).\nBusiness Claims shall be heard by a single arbitrator. Arbitrations will be held in San Francisco, California, but the parties may choose for themselves whether to appear in person, by phone, or through the submission of documents. The arbitration shall be governed by the laws of the State of California. The prevailing party shall be entitled to an award of reasonable attorneys’ fees.",
      tcsmallheadingtextcopy22: (<div>NOTWITHSTANDING THE FOREGOING, FOR ANY BUSINESS CLAIM THAT IS NOT SUBJECT TO ARBITRATION, INCLUDING WITHOUT LIMITATION EXCLUDED BUSINESS CLAIMS, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE STATE AND FEDERAL COURTS LOCATED WITHIN SAN FRANCISCO COUNTY, CALIFORNIA, WHICH IS THE PLACE OF PERFORMANCE OF THESE BUSINESS TERMS.</div>),
      tcsmallheadingtextcopy22_plainText: "NOTWITHSTANDING THE FOREGOING, FOR ANY BUSINESS CLAIM THAT IS NOT SUBJECT TO ARBITRATION, INCLUDING WITHOUT LIMITATION EXCLUDED BUSINESS CLAIMS, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE STATE AND FEDERAL COURTS LOCATED WITHIN SAN FRANCISCO COUNTY, CALIFORNIA, WHICH IS THE PLACE OF PERFORMANCE OF THESE BUSINESS TERMS.",
      tcsmallheadingtextcopy23: (<div>YOU AND ZNEST AGREE THAT EACH MAY BRING OR PARTICIPATE IN BUSINESS CLAIMS AGAINST THE OTHER ONLY IN THEIR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND ZNEST AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE BUSINESS CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. IF A BUSINESS CLAIM IMPLICATES THIS SECTION, AND THIS SECTION IS FOUND TO BE INVALID, UNENFORCEABLE OR ILLEGAL BY A COURT, SUCH BUSINESS CLAIM MUST BE ADJUDICATED BY A COURT AND NOT BY AN ARBITRATOR.</div>),
      tcsmallheadingtextcopy23_plainText: "YOU AND ZNEST AGREE THAT EACH MAY BRING OR PARTICIPATE IN BUSINESS CLAIMS AGAINST THE OTHER ONLY IN THEIR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND ZNEST AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE BUSINESS CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. IF A BUSINESS CLAIM IMPLICATES THIS SECTION, AND THIS SECTION IS FOUND TO BE INVALID, UNENFORCEABLE OR ILLEGAL BY A COURT, SUCH BUSINESS CLAIM MUST BE ADJUDICATED BY A COURT AND NOT BY AN ARBITRATOR.",
      text: (<div>New text. Double-click to edit dfdsafdsaf dsafsa sdfa fasddfsa ds</div>),
      text_plainText: "New text. Double-click to edit dfdsafdsaf dsafsa sdfa fasddfsa ds",
      nametext: (<div>Community Name</div>),
      nametext_plainText: "Community Name",
      field: this.transformPropValue_field((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_enteredZipCode'] : '')) || '',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elField = (event) => {
    this.setState({field: event.target.value});
  }
  transformPropValue_field = (input) => {

  }


  onClick_elFab = async (ev) => {
    let newVal = this.props.communityName;
    this.props.appActions.updateDataSlot('(null)', newVal);

    // Go back in screen navigation history
    this.props.appActions.goBack();

  }


  onClick_elIconButton = async (ev) => {
    this.toggle_n();

  }


  onClick_elLogoznesthorizwhite = async (ev) => {
    // Go back in screen navigation history
    this.props.appActions.goBack();

  }


  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elTCHeadingText = {
      fontSize: 28.5,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCText2 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCText3 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumbering = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy2 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy2 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy3 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy2 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy4 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy3 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy3 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy5 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy4 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy6 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy5 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy7 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy6 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy8 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy7 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy9 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy4 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy8 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy10 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy9 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy11 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy10 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy12 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy11 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy13 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy12 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy15 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy5 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy14 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy6 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy19 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy13 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy16 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy14 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy17 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy16 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy20 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy17 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy21 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy15 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy18 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy18 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy22 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy19 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy23 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy20 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy24 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy21 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy25 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy22 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy26 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy23 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy27 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy24 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy28 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy25 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy29 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy26 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy30 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy27 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy31 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy28 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy32 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy29 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy33 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy30 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy35 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy31 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy36 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy32 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy37 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy33 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy38 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy34 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy39 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy35 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy40 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy36 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy41 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy37 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy42 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy38 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy43 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy7 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy39 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy34 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy40 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy44 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy8 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy45 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy9 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy41 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy46 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy10 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy47 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy11 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy48 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy42 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy49 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy43 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy50 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy44 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy51 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy45 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy52 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy46 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy53 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy12 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy47 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy54 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy48 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy55 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy49 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy56 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy50 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy57 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy51 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy58 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy52 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy59 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy13 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy53 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy60 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy54 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy61 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy55 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy62 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy14 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy56 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy63 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy57 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy64 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy58 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy65 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy59 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy66 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy60 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy67 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy61 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy68 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy62 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy69 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy63 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy70 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elLine = {
      borderTop: '1px solid black',
     };
    const style_elTCTextCopy = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCText2Copy = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCText3Copy = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCText2Copy2 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy15 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy64 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy71 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy65 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy72 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy66 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy73 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy67 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy74 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy68 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy75 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy69 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy76 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy70 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy77 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy71 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy78 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy72 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy79 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy73 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy80 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy74 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy81 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy75 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy82 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy76 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy83 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy77 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy84 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy78 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy85 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy79 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy86 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy80 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy87 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy81 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy88 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletNumberingCopy82 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy89 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCBulletTextCopy90 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy16 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy17 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy18 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy19 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy20 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy21 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy22 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTCSmallHeadingTextCopy23 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText_outer = {
      display: 'none',
     };
    const style_elNameText = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elNameText_outer = {
      display: 'none',
     };

    const value_field = this.state.field;

    const style_elField = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elField_outer = {
      display: 'none',
     };

    const style_elBkgdOutlineOverall = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
     };

    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };

    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_338335078+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.387%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elTwittercircle = {
      backgroundImage: 'url('+img_elTwittercircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elTwitter = {
      height: 'auto',
      display: 'none',
     };

    const style_elInstagramcircle = {
      backgroundImage: 'url('+img_elInstagramcircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elIg = {
      height: 'auto',
      display: 'none',
     };

    const style_elFacebookcircle = {
      backgroundImage: 'url('+img_elFacebookcircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elFb = {
      height: 'auto',
      display: 'none',
     };

    const style_elButtonLearnMore = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };

    const style_elButtonAboutUs = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };
    const style_elLogo = {
      height: 'auto',
      display: 'none',
     };

    const style_elLogoznesthorizwhite = {
      backgroundImage: 'url('+img_elLogoznesthorizwhite+')',
      backgroundSize: '100% 100%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elBeta = {
      backgroundImage: 'url('+img_elBeta+')',
      backgroundSize: '100% 100%',
     };

    return (
      <div className="AppScreen TCScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elTCHeadingText">
            <div className="systemFontBold" style={style_elTCHeadingText}>
              <div>{this.state.tcheadingtext}</div>
            </div>
          </div>

          <div className="elTCText">
            <div className="systemFontRegular" style={style_elTCText}>
              <div>{this.state.tctext}</div>
            </div>
          </div>

          <div className="elTCText2">
            <div className="systemFontBold" style={style_elTCText2}>
              <div>{this.state.tctext2}</div>
            </div>
          </div>

          <div className="elTCText3">
            <div className="systemFontRegular" style={style_elTCText3}>
              <div>{this.state.tctext3}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingText">
            <div className="systemFontBold" style={style_elTCSmallHeadingText}>
              <div>{this.state.tcsmallheadingtext}</div>
            </div>
          </div>

          <div className="elTCBulletNumbering">
            <div className="systemFontBold" style={style_elTCBulletNumbering}>
              <div>{this.state.tcbulletnumbering}</div>
            </div>
          </div>

          <div className="elTCBulletText">
            <div className="systemFontRegular" style={style_elTCBulletText}>
              <div>{this.state.tcbullettext}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy}>
              <div>{this.state.tcbulletnumberingcopy}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy}>
              <div>{this.state.tcbullettextcopy}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy2">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy2}>
              <div>{this.state.tcbulletnumberingcopy2}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy2">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy2}>
              <div>{this.state.tcbullettextcopy2}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy}>
              <div>{this.state.tcsmallheadingtextcopy}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy3">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy3}>
              <div>{this.state.tcbullettextcopy3}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy2">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy2}>
              <div>{this.state.tcsmallheadingtextcopy2}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy4">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy4}>
              <div>{this.state.tcbullettextcopy4}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy3">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy3}>
              <div>{this.state.tcsmallheadingtextcopy3}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy3">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy3}>
              <div>{this.state.tcbulletnumberingcopy3}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy5">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy5}>
              <div>{this.state.tcbullettextcopy5}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy4">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy4}>
              <div>{this.state.tcbulletnumberingcopy4}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy6">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy6}>
              <div>{this.state.tcbullettextcopy6}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy5">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy5}>
              <div>{this.state.tcbulletnumberingcopy5}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy7">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy7}>
              <div>{this.state.tcbullettextcopy7}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy6">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy6}>
              <div>{this.state.tcbulletnumberingcopy6}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy8">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy8}>
              <div>{this.state.tcbullettextcopy8}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy7">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy7}>
              <div>{this.state.tcbulletnumberingcopy7}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy9">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy9}>
              <div>{this.state.tcbullettextcopy9}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy4">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy4}>
              <div>{this.state.tcsmallheadingtextcopy4}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy8">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy8}>
              <div>{this.state.tcbulletnumberingcopy8}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy10">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy10}>
              <div><div dangerouslySetInnerHTML={{__html: this.state.tcbullettextcopy10_plainText.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy9">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy9}>
              <div>{this.state.tcbulletnumberingcopy9}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy11">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy11}>
              <div>{this.state.tcbullettextcopy11}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy10">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy10}>
              <div>{this.state.tcbulletnumberingcopy10}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy12">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy12}>
              <div>{this.state.tcbullettextcopy12}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy11">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy11}>
              <div>{this.state.tcbulletnumberingcopy11}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy13">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy13}>
              <div>{this.state.tcbullettextcopy13}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy12">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy12}>
              <div>{this.state.tcbulletnumberingcopy12}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy15">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy15}>
              <div>{this.state.tcbullettextcopy15}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy5">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy5}>
              <div>{this.state.tcsmallheadingtextcopy5}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy14">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy14}>
              <div>{this.state.tcbullettextcopy14}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy6">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy6}>
              <div>{this.state.tcsmallheadingtextcopy6}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy19">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy19}>
              <div>{this.state.tcbullettextcopy19}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy13">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy13}>
              <div>{this.state.tcbulletnumberingcopy13}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy16">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy16}>
              <div>{this.state.tcbullettextcopy16}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy14">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy14}>
              <div>{this.state.tcbulletnumberingcopy14}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy17">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy17}>
              <div>{this.state.tcbullettextcopy17}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy16">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy16}>
              <div>{this.state.tcbulletnumberingcopy16}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy20">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy20}>
              <div>{this.state.tcbullettextcopy20}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy17">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy17}>
              <div>{this.state.tcbulletnumberingcopy17}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy21">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy21}>
              <div>{this.state.tcbullettextcopy21}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy15">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy15}>
              <div>{this.state.tcbulletnumberingcopy15}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy18">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy18}>
              <div>{this.state.tcbullettextcopy18}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy18">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy18}>
              <div>{this.state.tcbulletnumberingcopy18}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy22">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy22}>
              <div>{this.state.tcbullettextcopy22}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy19">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy19}>
              <div>{this.state.tcbulletnumberingcopy19}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy23">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy23}>
              <div>{this.state.tcbullettextcopy23}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy20">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy20}>
              <div>{this.state.tcbulletnumberingcopy20}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy24">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy24}>
              <div>{this.state.tcbullettextcopy24}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy21">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy21}>
              <div>{this.state.tcbulletnumberingcopy21}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy25">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy25}>
              <div>{this.state.tcbullettextcopy25}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy22">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy22}>
              <div>{this.state.tcbulletnumberingcopy22}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy26">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy26}>
              <div>{this.state.tcbullettextcopy26}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy23">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy23}>
              <div>{this.state.tcbulletnumberingcopy23}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy27">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy27}>
              <div>{this.state.tcbullettextcopy27}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy24">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy24}>
              <div>{this.state.tcbulletnumberingcopy24}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy28">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy28}>
              <div>{this.state.tcbullettextcopy28}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy25">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy25}>
              <div>{this.state.tcbulletnumberingcopy25}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy29">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy29}>
              <div>{this.state.tcbullettextcopy29}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy26">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy26}>
              <div>{this.state.tcbulletnumberingcopy26}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy30">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy30}>
              <div>{this.state.tcbullettextcopy30}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy27">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy27}>
              <div>{this.state.tcbulletnumberingcopy27}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy31">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy31}>
              <div>{this.state.tcbullettextcopy31}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy28">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy28}>
              <div>{this.state.tcbulletnumberingcopy28}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy32">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy32}>
              <div>{this.state.tcbullettextcopy32}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy29">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy29}>
              <div>{this.state.tcbulletnumberingcopy29}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy33">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy33}>
              <div>{this.state.tcbullettextcopy33}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy30">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy30}>
              <div>{this.state.tcbulletnumberingcopy30}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy35">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy35}>
              <div>{this.state.tcbullettextcopy35}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy31">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy31}>
              <div>{this.state.tcbulletnumberingcopy31}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy36">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy36}>
              <div>{this.state.tcbullettextcopy36}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy32">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy32}>
              <div>{this.state.tcbulletnumberingcopy32}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy37">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy37}>
              <div>{this.state.tcbullettextcopy37}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy33">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy33}>
              <div>{this.state.tcbulletnumberingcopy33}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy38">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy38}>
              <div>{this.state.tcbullettextcopy38}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy34">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy34}>
              <div>{this.state.tcbulletnumberingcopy34}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy39">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy39}>
              <div>{this.state.tcbullettextcopy39}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy35">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy35}>
              <div>{this.state.tcbulletnumberingcopy35}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy40">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy40}>
              <div>{this.state.tcbullettextcopy40}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy36">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy36}>
              <div>{this.state.tcbulletnumberingcopy36}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy41">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy41}>
              <div>{this.state.tcbullettextcopy41}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy37">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy37}>
              <div>{this.state.tcbulletnumberingcopy37}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy42">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy42}>
              <div>{this.state.tcbullettextcopy42}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy38">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy38}>
              <div>{this.state.tcbulletnumberingcopy38}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy43">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy43}>
              <div>{this.state.tcbullettextcopy43}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy7">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy7}>
              <div>{this.state.tcsmallheadingtextcopy7}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy39">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy39}>
              <div>{this.state.tcbulletnumberingcopy39}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy34">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy34}>
              <div>{this.state.tcbullettextcopy34}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy40">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy40}>
              <div>{this.state.tcbulletnumberingcopy40}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy44">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy44}>
              <div>{this.state.tcbullettextcopy44}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy8">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy8}>
              <div>{this.state.tcsmallheadingtextcopy8}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy45">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy45}>
              <div>{this.state.tcbullettextcopy45}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy9">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy9}>
              <div>{this.state.tcsmallheadingtextcopy9}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy41">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy41}>
              <div>{this.state.tcbulletnumberingcopy41}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy46">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy46}>
              <div>{this.state.tcbullettextcopy46}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy10">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy10}>
              <div>{this.state.tcsmallheadingtextcopy10}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy47">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy47}>
              <div>{this.state.tcbullettextcopy47}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy11">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy11}>
              <div>{this.state.tcsmallheadingtextcopy11}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy48">
            <div className="systemFontBold" style={style_elTCBulletTextCopy48}>
              <div>{this.state.tcbullettextcopy48}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy42">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy42}>
              <div>{this.state.tcbulletnumberingcopy42}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy49">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy49}>
              <div>{this.state.tcbullettextcopy49}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy43">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy43}>
              <div>{this.state.tcbulletnumberingcopy43}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy50">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy50}>
              <div>{this.state.tcbullettextcopy50}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy44">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy44}>
              <div>{this.state.tcbulletnumberingcopy44}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy51">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy51}>
              <div>{this.state.tcbullettextcopy51}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy45">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy45}>
              <div>{this.state.tcbulletnumberingcopy45}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy52">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy52}>
              <div>{this.state.tcbullettextcopy52}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy46">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy46}>
              <div>{this.state.tcbulletnumberingcopy46}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy53">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy53}>
              <div>{this.state.tcbullettextcopy53}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy12">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy12}>
              <div>{this.state.tcsmallheadingtextcopy12}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy47">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy47}>
              <div>{this.state.tcbulletnumberingcopy47}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy54">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy54}>
              <div>{this.state.tcbullettextcopy54}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy48">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy48}>
              <div>{this.state.tcbulletnumberingcopy48}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy55">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy55}>
              <div>{this.state.tcbullettextcopy55}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy49">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy49}>
              <div>{this.state.tcbulletnumberingcopy49}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy56">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy56}>
              <div>{this.state.tcbullettextcopy56}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy50">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy50}>
              <div>{this.state.tcbulletnumberingcopy50}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy57">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy57}>
              <div>{this.state.tcbullettextcopy57}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy51">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy51}>
              <div>{this.state.tcbulletnumberingcopy51}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy58">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy58}>
              <div>{this.state.tcbullettextcopy58}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy52">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy52}>
              <div>{this.state.tcbulletnumberingcopy52}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy59">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy59}>
              <div>{this.state.tcbullettextcopy59}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy13">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy13}>
              <div>{this.state.tcsmallheadingtextcopy13}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy53">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy53}>
              <div>{this.state.tcbulletnumberingcopy53}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy60">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy60}>
              <div>{this.state.tcbullettextcopy60}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy54">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy54}>
              <div>{this.state.tcbulletnumberingcopy54}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy61">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy61}>
              <div>{this.state.tcbullettextcopy61}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy55">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy55}>
              <div>{this.state.tcbulletnumberingcopy55}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy62">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy62}>
              <div>{this.state.tcbullettextcopy62}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy14">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy14}>
              <div>{this.state.tcsmallheadingtextcopy14}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy56">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy56}>
              <div>{this.state.tcbulletnumberingcopy56}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy63">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy63}>
              <div>{this.state.tcbullettextcopy63}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy57">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy57}>
              <div>{this.state.tcbulletnumberingcopy57}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy64">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy64}>
              <div>{this.state.tcbullettextcopy64}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy58">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy58}>
              <div>{this.state.tcbulletnumberingcopy58}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy65">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy65}>
              <div>{this.state.tcbullettextcopy65}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy59">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy59}>
              <div>{this.state.tcbulletnumberingcopy59}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy66">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy66}>
              <div>{this.state.tcbullettextcopy66}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy60">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy60}>
              <div>{this.state.tcbulletnumberingcopy60}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy67">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy67}>
              <div>{this.state.tcbullettextcopy67}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy61">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy61}>
              <div>{this.state.tcbulletnumberingcopy61}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy68">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy68}>
              <div>{this.state.tcbullettextcopy68}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy62">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy62}>
              <div>{this.state.tcbulletnumberingcopy62}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy69">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy69}>
              <div>{this.state.tcbullettextcopy69}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy63">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy63}>
              <div>{this.state.tcbulletnumberingcopy63}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy70">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy70}>
              <div>{this.state.tcbullettextcopy70}</div>
            </div>
          </div>

          <div className="elLine">
            <div style={style_elLine} />
          </div>

          <div className="elTCTextCopy">
            <div className="systemFontBold" style={style_elTCTextCopy}>
              <div>{this.state.tctextcopy}</div>
            </div>
          </div>

          <div className="elTCText2Copy">
            <div className="systemFontRegular" style={style_elTCText2Copy}>
              <div>{this.state.tctext2copy}</div>
            </div>
          </div>

          <div className="elTCText3Copy">
            <div className="systemFontBold" style={style_elTCText3Copy}>
              <div>{this.state.tctext3copy}</div>
            </div>
          </div>

          <div className="elTCText2Copy2">
            <div className="systemFontRegular" style={style_elTCText2Copy2}>
              <div>{this.state.tctext2copy2}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy15">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy15}>
              <div>{this.state.tcsmallheadingtextcopy15}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy64">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy64}>
              <div>{this.state.tcbulletnumberingcopy64}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy71">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy71}>
              <div>{this.state.tcbullettextcopy71}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy65">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy65}>
              <div>{this.state.tcbulletnumberingcopy65}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy72">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy72}>
              <div>{this.state.tcbullettextcopy72}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy66">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy66}>
              <div>{this.state.tcbulletnumberingcopy66}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy73">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy73}>
              <div>{this.state.tcbullettextcopy73}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy67">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy67}>
              <div>{this.state.tcbulletnumberingcopy67}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy74">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy74}>
              <div>{this.state.tcbullettextcopy74}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy68">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy68}>
              <div>{this.state.tcbulletnumberingcopy68}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy75">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy75}>
              <div>{this.state.tcbullettextcopy75}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy69">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy69}>
              <div>{this.state.tcbulletnumberingcopy69}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy76">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy76}>
              <div>{this.state.tcbullettextcopy76}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy70">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy70}>
              <div>{this.state.tcbulletnumberingcopy70}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy77">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy77}>
              <div>{this.state.tcbullettextcopy77}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy71">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy71}>
              <div>{this.state.tcbulletnumberingcopy71}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy78">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy78}>
              <div>{this.state.tcbullettextcopy78}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy72">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy72}>
              <div>{this.state.tcbulletnumberingcopy72}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy79">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy79}>
              <div>{this.state.tcbullettextcopy79}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy73">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy73}>
              <div>{this.state.tcbulletnumberingcopy73}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy80">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy80}>
              <div>{this.state.tcbullettextcopy80}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy74">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy74}>
              <div>{this.state.tcbulletnumberingcopy74}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy81">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy81}>
              <div>{this.state.tcbullettextcopy81}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy75">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy75}>
              <div>{this.state.tcbulletnumberingcopy75}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy82">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy82}>
              <div>{this.state.tcbullettextcopy82}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy76">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy76}>
              <div>{this.state.tcbulletnumberingcopy76}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy83">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy83}>
              <div>{this.state.tcbullettextcopy83}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy77">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy77}>
              <div>{this.state.tcbulletnumberingcopy77}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy84">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy84}>
              <div>{this.state.tcbullettextcopy84}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy78">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy78}>
              <div>{this.state.tcbulletnumberingcopy78}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy85">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy85}>
              <div>{this.state.tcbullettextcopy85}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy79">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy79}>
              <div>{this.state.tcbulletnumberingcopy79}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy86">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy86}>
              <div>{this.state.tcbullettextcopy86}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy80">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy80}>
              <div>{this.state.tcbulletnumberingcopy80}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy87">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy87}>
              <div>{this.state.tcbullettextcopy87}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy81">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy81}>
              <div>{this.state.tcbulletnumberingcopy81}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy88">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy88}>
              <div>{this.state.tcbullettextcopy88}</div>
            </div>
          </div>

          <div className="elTCBulletNumberingCopy82">
            <div className="systemFontBold" style={style_elTCBulletNumberingCopy82}>
              <div>{this.state.tcbulletnumberingcopy82}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy89">
            <div className="systemFontRegular" style={style_elTCBulletTextCopy89}>
              <div>{this.state.tcbullettextcopy89}</div>
            </div>
          </div>

          <div className="elTCBulletTextCopy90">
            <div className="systemFontBold" style={style_elTCBulletTextCopy90}>
              <div>{this.state.tcbullettextcopy90}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy16">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy16}>
              <div>{this.state.tcsmallheadingtextcopy16}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy17">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy17}>
              <div>{this.state.tcsmallheadingtextcopy17}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy18">
            <div className="systemFontRegular" style={style_elTCSmallHeadingTextCopy18}>
              <div>{this.state.tcsmallheadingtextcopy18}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy19">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy19}>
              <div>{this.state.tcsmallheadingtextcopy19}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy20">
            <div className="systemFontBold" style={style_elTCSmallHeadingTextCopy20}>
              <div>{this.state.tcsmallheadingtextcopy20}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy21">
            <div className="systemFontRegular" style={style_elTCSmallHeadingTextCopy21}>
              <div><div dangerouslySetInnerHTML={{__html: this.state.tcsmallheadingtextcopy21_plainText.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy22">
            <div className="systemFontRegular" style={style_elTCSmallHeadingTextCopy22}>
              <div>{this.state.tcsmallheadingtextcopy22}</div>
            </div>
          </div>

          <div className="elTCSmallHeadingTextCopy23">
            <div className="systemFontRegular" style={style_elTCSmallHeadingTextCopy23}>
              <div>{this.state.tcsmallheadingtextcopy23}</div>
            </div>
          </div>

          <div className="elSpacer">
            <div />
          </div>

          <div className="elText" style={style_elText_outer}>
            <div className="baseFont" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
          </div>

          <div className="elNameText" style={style_elNameText_outer}>
            <div className="systemFontBold" style={style_elNameText}>
              <div>{this.state.nametext}</div>
            </div>
          </div>

          <div className="flowRow flowRow_TCScreen_elField_1010406279">
          <div className="elField" style={style_elField_outer}>
            <input className="baseFont" style={style_elField} type="text" placeholder="" onChange={this.textInputChanged_elField} value={value_field !== undefined ? value_field : ''}  />
          </div>

          </div>
        </div>

        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elBkgdOutlineOverall" style={style_elBkgdOutlineOverall} />
            <Button className="actionFont elFab" style={style_elFab}  variant="fab" color="accent" onClick={this.onClick_elFab} >
              <img src={btn_icon_1540821800} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
            <div className="elTwittercircle" style={style_elTwittercircle} />
            <img className="elTwitter" style={style_elTwitter} src={img_elTwitter} alt=""  />
            <div className="elInstagramcircle" style={style_elInstagramcircle} />
            <img className="elIg" style={style_elIg} src={img_elIg} alt=""  />
            <div className="elFacebookcircle" style={style_elFacebookcircle} />
            <img className="elFb" style={style_elFb} src={img_elFb} alt=""  />
            <button className="font-SFUITextRegular  elButtonLearnMore" style={style_elButtonLearnMore}>
              learn more
            </button>
            <button className="font-SFUITextRegular  elButtonAboutUs" style={style_elButtonAboutUs}>
              About Us
            </button>
            <img className="elLogo" style={style_elLogo} src={img_elLogo} alt=""  />
            <div className="elLogoznesthorizwhite" style={style_elLogoznesthorizwhite} onClick={this.onClick_elLogoznesthorizwhite}  />
            <div className="elBeta" style={style_elBeta} />
          </div>
        </div>
      </div>
    )
  }

}
