import React, { Component } from 'react';
import './App.css';
import FirebaseLogin from './FirebaseLogin';
import img_elLogoznesthorizwhite from './images/LoginScreen_elLogoznesthorizwhite.png';
import img_elBeta from './images/LoginScreen_elBeta.png';
import img_elTwittercircle from './images/LoginScreen_elTwittercircle.png';
import img_elInstagramcircle from './images/LoginScreen_elInstagramcircle.png';
import img_elFacebookcircle from './images/LoginScreen_elFacebookcircle.png';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class LoginScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>Login or Sign Up to Continue</div>),
      text_plainText: "Login or Sign Up to Continue",
      textcopy2: (<div>You will receive a verification email if sign up / login via email</div>),
      textcopy2_plainText: "You will receive a verification email if sign up / login via email",
      textcopy3: (<div>Once you click on the verification email, refresh the web browser if this page does not proceed automatically</div>),
      textcopy3_plainText: "Once you click on the verification email, refresh the web browser if this page does not proceed automatically",
      text2: (<div>By continuing, you agree to ZNest’s</div>),
      text2_plainText: "By continuing, you agree to ZNest’s",
      textcopy: (<div>Terms and Conditions</div>),
      textcopy_plainText: "Terms and Conditions",
    };
  }

  componentDidMount() {
    // Check if Firebase login has been completed.
    let unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          if (firebase.auth().currentUser) {
            if (this._elFirebaseLogin)
              this._elFirebaseLogin.saveCurrentUserDataInApp();
            
            sessionStorage.setItem('loginStatus_login', 'active');
            this.props.appActions.goToScreen('communityDetails2');
            
          }
        }
        unregisterAuthObserver();
      }
    );
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elTextCopy = async (ev) => {
    // Go to screen 'TC'
    this.props.appActions.goToScreen('tC', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elLogoznesthorizwhite = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elText = {
      fontSize: 20.9,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elTextCopy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elTextCopy3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elTextCopy = {
      color: '#0071fe',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFirebaseLogin = {
      pointerEvents: 'auto',
     };
    
    const style_elBkgdOutlineOverall = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
     };
    
    const style_elLogoznesthorizwhite = {
      backgroundImage: 'url('+img_elLogoznesthorizwhite+')',
      backgroundSize: '100% 100%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elBeta = {
      backgroundImage: 'url('+img_elBeta+')',
      backgroundSize: '100% 100%',
     };
    
    const style_elTwittercircle = {
      backgroundImage: 'url('+img_elTwittercircle+')',
      backgroundSize: '100% 100%',
     };
    
    const style_elInstagramcircle = {
      backgroundImage: 'url('+img_elInstagramcircle+')',
      backgroundSize: '100% 100%',
     };
    
    const style_elFacebookcircle = {
      backgroundImage: 'url('+img_elFacebookcircle+')',
      backgroundSize: '100% 100%',
     };
    
    const style_elButtonAboutUs = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };
    
    const style_elButtonLearnMore = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="AppScreen LoginScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elText">
            <div className="systemFontRegular" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="baseFont" style={style_elTextCopy2}>
              <div>{this.state.textcopy2}</div>
            </div>
          </div>
          
          <div className="elTextCopy3">
            <div className="baseFont" style={style_elTextCopy3}>
              <div>{this.state.textcopy3}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.state.text2}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy} onClick={this.onClick_elTextCopy} >
              <div>{this.state.textcopy}</div>
            </div>
          </div>
          
          <div className="elFirebaseLogin">
            <div style={style_elFirebaseLogin}>
              <FirebaseLogin ref={(el)=> this._elFirebaseLogin = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elBkgdOutlineOverall" style={style_elBkgdOutlineOverall} />
            <div className="elLogoznesthorizwhite" style={style_elLogoznesthorizwhite} onClick={this.onClick_elLogoznesthorizwhite}  />
            <div className="elBeta" style={style_elBeta} />
            <div className="elTwittercircle" style={style_elTwittercircle} />
            <div className="elInstagramcircle" style={style_elInstagramcircle} />
            <div className="elFacebookcircle" style={style_elFacebookcircle} />
            <button className="font-SFUITextRegular  elButtonAboutUs" style={style_elButtonAboutUs}>
              About Us
            </button>
            <button className="font-SFUITextRegular  elButtonLearnMore" style={style_elButtonLearnMore}>
              Learn more
            </button>
          </div>
        </div>
      </div>
    )
  }
  
}
