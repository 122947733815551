import React, { Component } from "react";
import "./App.css";
import bennett from "./assets/bennett.png";
import craig from "./assets/craig.png";
import tom from "./assets/tom.png";
import david from "./assets/david.png";
import jacob from "./assets/jacob.png";
import alan from "./assets/alan.png";

// Bootstrap framework component imports

import { Container,Row,Col,Table,Nav } from 'react-bootstrap';
import Header from './includes/header';
import { FaSearch } from 'react-icons/fa';

class ContributorScreen extends Component {
  render() {
    return (
      <div className="Contributors">
        <Header/>
        {/* Contributors section */}
        <div className="Contributors container-fluid">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <h1 className="Contributors-header display-3 text-center mt-5">Contributors</h1>
              <h2 className="bottom-border mt-5"></h2>
              {/* <p className="Contributors-text my-4">
                Thank you to our amazing community of supporters! Click the
                donation level to see the list of contributors.
              </p> */}
            <Container className="pt-5">

              <Row className="pt-5">
              <Col lg={1} md={6} xs={12} className="text-center"></Col>  
                <Col lg={2} md={6} xs={12} className="text-center d-none d-sm-block">       
                <Nav.Link href="#diamond"><div className="Donation">
                  <div className="Donation-level">
                    <div className="Donation-text">
                      <p className="Donation-title">Diamond</p>
                      <span className="Donation-amount">$5,000+</span>
                    </div>
                  </div>
                </div></Nav.Link>
              </Col>
              <Col lg={2} md={6} xs={12} className="text-center d-none d-sm-block">       
              <Nav.Link href="#platinum"><div className="Donation">
                  <div className="Donation-level">
                    <div className="Donation-text">
                      <p className="Donation-title">Platinum</p>
                      <span className="Donation-amount">$2,000-4,999</span>
                    </div>
                  </div>
                </div></Nav.Link>
              </Col>
              <Col lg={2} md={6} xs={12} className="text-center d-none d-sm-block">       

              <Nav.Link href="#gold"><div className="Donation">
                  <div className="Donation-level">
                    <div className="Donation-text">
                      <p className="Donation-title">Gold</p>
                      <span className="Donation-amount">$500-1,999</span>
                    </div>
                  </div>
                </div></Nav.Link>
              </Col>
              <Col lg={2} md={6} xs={12} className="text-center d-none d-sm-block">       

              <Nav.Link href="#silver"> <div className="Donation">
                  <div className="Donation-level">
                    <div className="Donation-text">
                      <p className="Donation-title">Silver</p>
                      <span className="Donation-amount">$100-499</span>
                    </div>
                  </div>
                </div></Nav.Link>
              </Col>
              <Col lg={2} md={6} xs={12} className="text-center d-none d-sm-block">       
              <Nav.Link href="#bronze">
                <div className="Donation">
                  <div className="Donation-level justify-content-md-center">
                    <div className="Donation-text">
                      <p className="Donation-title">Bronze</p>
                      <span className="Donation-amount">Up to $99</span>
                    </div>
                  </div>
                </div>
                </Nav.Link>
                </Col>
                <Col lg={1} md={6} xs={12} className="text-center"></Col>  
                </Row>
            </Container>
               
              </div>
            </div>
          </div>

        {/* Letter section */}
        <div className="col-md-10 mx-auto">
          <Container>
            {/* <div className="row">
              <div className="col-sm-12 col-md-6"> */}
          <h1 className="Contributors-header display-5 text-left mt-5" id="diamond">Diamond Contributors</h1>
            <Table>
  <thead>
    <tr>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
  </tbody>
</Table>
             
<h1 className="Contributors-header display-5 text-left mt-5" id="platinum">Platinum Contributors</h1>
            <Table>
  <thead>
    <tr>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
  </tbody>
</Table>
<h1 className="Contributors-header display-5 text-left mt-5" id="gold">Gold Contributors</h1>
            <Table>
  <thead>
    <tr>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
  </tbody>
</Table>
<h1 className="Contributors-header display-5 text-left mt-5" id="silver">Silver Contributors</h1>
            <Table>
  <thead>
    <tr>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
  </tbody>
</Table>
<h1 className="Contributors-header display-5 text-left mt-5" id="bronze">Bronze Contributors</h1>
            <Table>
  <thead>
    <tr>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
    <tr>
    <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
      <td>Contributor Name</td>
    </tr>
  </tbody>
</Table>
        <Col className="space"></Col>
         </Container>
        </div>
      </div>
    );
  }
}

export default ContributorScreen
;
