// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_city extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "item";
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
  }


  urlFromOptions(options) {
    const baseUrl = "https://api.zip-codes.com/ZipCodesAPI.svc/1.0/GetZipCodeDetails/";

    let path = options.servicePath || '';
    if (path.length > 0 && path.substr(0, 1) !== '/' && baseUrl.substr(baseUrl.length - 1, 1) !== '/')
      path = '/'+path;

    let query = options.query || '';
    if (query.length > 0) {
      const dataSlots = options.dataSlots || {};
      query = "?" + this.expandSlotTemplateString(query, dataSlots);
    }

    return baseUrl + path + query;
  }

  _fetchComplete = (err, options) => {
    if (err) {
      //console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);

    const url = this.urlFromOptions(options);

    let headers = {};
    headers['Content-Type'] = 'application/json';

    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };

    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          //console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });
  }

  removeItem(item, options) {
    super.removeItem(item, options);

    const url = this.urlFromOptions(options);

    let headers = {};
    headers['Content-Type'] = 'application/json';

    const fetchOpts = {
      method: 'DELETE',
    	headers: headers,
    	body: JSON.stringify(item),
    };

    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          //console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });
  }

  replaceItemByRowIndex(idx, item, options) {
    super.replaceItemByRowIndex(idx, item, options);

    const url = this.urlFromOptions(options);

    let headers = {};
    headers['Content-Type'] = 'application/json';

    const fetchOpts = {
      method: 'PUT',
    	headers: headers,
    	body: JSON.stringify(item),
    };

    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          //console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });
  }


}
