import React, { Component } from 'react';
import './App.css';
import img_elImage1 from './images/HomeScreen_elImage1_1298597997.png';
import img_elImage2 from './images/HomeScreen_elImage2_1191308422.png';
import img_elImage3 from './images/HomeScreen_elImage3_969349285.png';
import img_elImage4 from './images/HomeScreen_elImage4_1278846250.png';
import img_elImage5 from './images/HomeScreen_elImage5_1260450749.png';
import FirebaseLogin from './FirebaseLogin';
import img_elImageIL from './images/CommunityDetails2Screen_elImageIL_828629221.png';
import img_elImageAL from './images/CommunityDetails2Screen_elImageAL_1199380299.png';
import img_elImageMC from './images/CommunityDetails2Screen_elImageMC_1946152380.png';
import img_elImageSN from './images/CommunityDetails2Screen_elImageSN_102632794.png';
import img_elImageOpenData from './images/HomeScreen_elImageOpenData_157495235.jpg';
import UserInfo from './UserInfo';
import img_elFb from './images/UpdateInfoScreen_elFb_660566527.png';
import img_elFacebookcircle from './images/HomeScreen_elFacebookcircle.png';
import img_elIg from './images/UpdateInfoScreen_elIg_98352411.png';
import img_elInstagramcircle from './images/HomeScreen_elInstagramcircle.png';
import img_elTwitter from './images/UpdateInfoScreen_elTwitter_1312850244.png';
import img_elTwittercircle from './images/HomeScreen_elTwittercircle.png';
import btn_icon_1033694547 from './images/btn_icon_1033694547.png';
import img_elIcontooltip02 from './images/HomeScreen_elIcontooltip02.png';
import img_elBeta from './images/HomeScreen_elBeta.png';
import img_elLogoznesthorizwhite from './images/HomeScreen_elLogoznesthorizwhite.png';

import Drawer1 from './Drawer1';

// UI framework component imports
// import Button from 'muicss/lib/react/button';

// Bootstrap framework component imports

import { Container,Row,Col,Button,NavLink } from 'react-bootstrap';
import Header from './includes/header';
import { FaSearch } from 'react-icons/fa';

export default class HomeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, cityAliasName, ds_SlotUserPhoto

  constructor(props) {
    super(props);

    this.state = {
      textmain: (<h1 className="display-2">Find the perfect senior living community for you.</h1>),
      textmain_plainText: "Find the perfect senior living community for you.",
      textsub: "ZNest is a community of supportive people who share pricing information.",
      textsub_plainText: "ZNest is a community of supportive people who share pricing information.",
      zipCodeExplore: '',
      zipautocomplete: (<div>AutoComplete</div>),
      zipautocomplete_plainText: "AutoComplete",
      textsubsub: (<h4>Search over 50,000 communities in our database.</h4>),
      textsubsub_plainText: "Search over 50,000 communities in our database.",
      textalreadyloggedin: (<div>You are already logged in</div>),
      textalreadyloggedin_plainText: "You are already logged in",
      elTextNoZipMatch_visible: false,
      textnozipmatch: (<div>No community listed with that zip code.Add your email address to the wait list.</div>),
      textnozipmatch_plainText: "No community listed with that zip code.Add your email address to the wait list.",
      elEmailThankYou_visible: false,
      emailthankyou: (<div>Thank you.<br /><br />You will be notified when communities are added to this zip code.</div>),
      emailthankyou_plainText: "Thank you.\n\nYou will be notified when communities are added to this zip code.",
      elEmailWaitList_visible: false,
      emailWaitList: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserEmail'] : '') || '',
      elEmailSubmitButton_visible: false,
      elValidator_visible: false,
      validator: (<div>*Please enter valid email format </div>),
      validator_plainText: "*Please enter valid email format ",
      texttypesofcare: "Search by types of care",
      texttypesofcare_plainText: "Types of care",
      textil: (<div>Independent Living</div>),
      textil_plainText: "Independent Living",
      textal: (<div>Assisted Living</div>),
      textal_plainText: "Assisted Living",
      textmc: (<div>Memory Care</div>),
      textmc_plainText: "Memory Care",
      textsn: (<div>Skilled Nursing</div>),
      textsn_plainText: "Skilled Nursing",
      textiltext: (<div>For active seniors, includes basic services like meals, activities, laundry, and housekeeping.</div>),
      textiltext_plainText: "For active seniors, includes basic services like meals, activities, laundry, and housekeeping.",
      textaltext: (<div>For seniors who need help with activities of daily living like dressing, bathing, and grooming.</div>),
      textaltext_plainText: "For seniors who need help with activities of daily living like dressing, bathing, and grooming.",
      textmctext: (<div>For seniors with memory care needs like Alzheimer’s or other forms of dementia.</div>),
      textmctext_plainText: "For seniors with memory care needs like Alzheimer’s or other forms of dementia.",
      textsntext: (<div>For seniors who need supervision from trained medical staff.</div>),
      textsntext_plainText: "For seniors who need supervision from trained medical staff.",
      textopendata: (<h1 className="display-3">Open data</h1>),
      textopendata_plainText: "Open data",
      textopendatatext: (<h5>We have over 50,000 communities in our database, and if you want to search for an option based on criteria like proximity or price, we have a comprehensive list which is not influenced by corporate sponsorship.</h5>),
      textopendatatext_plainText: "We have over 50,000 communities in our database, and if you want to search for an option based on criteria like proximity or price, we have a comprehensive list which is not influenced by corporate sponsorship.",
      textwhyacct: (<h1 className="display-3">Why create an account</h1>),
      textwhyacct_plainText: "Why create an account",
      textwhyaccttext: (<div>Membership is free! ZNest will provide you with the most accurate pricing for each senior housing and care option. In exchange we ask you to be a part of the community so that we can help each other. When you visit an option and notice the pricing has changed, you can be a part of helping the community by submitting updates. In order to submit updates, however, you will have to create an account. Members will also be eligible for various rewards.</div>),
      textwhyaccttext_plainText: "Membership is free! ZNest will provide you with the most accurate pricing for each senior housing and care option. In exchange we ask you to be a part of the community so that we can help each other. When you visit an option and notice the pricing has changed, you can be a part of helping the community by submitting updates. In order to submit updates, however, you will have to create an account. Members will also be eligible for various rewards.",
      elButtonSignUp_visible: true,
      elButtonLogIn_visible: true,
      elAntiTooltipHotspot_visible: false,
      elBetaCard_visible: false,
      elBetaText_visible: false,
      betatext: (<div>This site is currently only available in Hawaii. Subscribe to our email and you will be notified when we roll out to other states.</div>),
      betatext_plainText: "This site is currently only available in Hawaii. Subscribe to our email and you will be notified when we roll out to other states.",
      drawer1_isOpen: false,
      betaversion:"*Beta version is for Hawaii communities only (try 96822). National launch coming soon! Add your e-mail to be notified."

    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elZipCodeExplore = (event) => {
    this.setState({zipCodeExplore: event.target.value});

    this.props.appActions.updateDataSlot("ds_enteredZipCode", event.target.value);
  }

  onClick_elZipAutoComplete = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['communities'], this.props.appActions.serviceOptions_communities, false);

    // Go to screen 'Community'
    this.props.appActions.goToScreen('community', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elSearchButton = async (ev) => {
    let newVal = this.state.zipCodeExplore;
    this.props.appActions.updateDataSlot('ds_enteredZipCode', newVal);

    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['communities'], this.props.appActions.serviceOptions_communities, false);

    newVal = "0";
    this.props.appActions.updateDataSlot('ds_communityListIndex', newVal);

    this.setState({elEmailThankYou_visible: false});

    if( this.props.appActions.getDataSheet('communities').items.length != 0)
      // Go to screen 'Community'
      this.props.appActions.goToScreen('community', { ...this.props, transitionId: 'fadeIn' });
    else
      console.log("No community listed with the zip code.Add your email address to the wait list");
      this.setState({betaversion: 'No community listed with the zip code.Add your email address to the wait list'});
      this.setState({elTextNoZipMatch_visible: true});
      this.setState({elEmailWaitList_visible: true});
      this.setState({elEmailSubmitButton_visible: true});

  }


  textInputChanged_elEmailWaitList = (event) => {
    this.setState({emailWaitList: event.target.value});
  }

  onClick_elEmailSubmitButton = async (ev) => {
    // Perform action 'Validate' on element 'validator'
    const items = [
      { result: this.state.emailWaitList != null && this.state.emailWaitList.length > 0 && /^(\S+@\S+\.\S+)?$/.test(this.state.emailWaitList) /* email */, name: 'emailWaitList', value: this.state.emailWaitList },
    ];

    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        break;
      }
    };
    this.setState({elValidator_visible: !valid});

    if ( !valid) {
      return;
    }

    await this.sendData_elEmailSubmitButton_to_waitList();

    this.setState({elEmailThankYou_visible: true});

    this.setState({elEmailWaitList_visible: false});

    this.setState({elEmailSubmitButton_visible: false});

    this.setState({elTextNoZipMatch_visible: false});

  }


  onClick_elButtonSignUp = async (ev) => {
    this.setState({elButtonLogIn_visible: false});

    this.setState({elButtonSignUp_visible: false});

    // Go to screen 'LoginHome'
    this.props.appActions.goToScreen('loginHome', { transitionId: 'fadeIn' });

  }


  onClick_elButtonLogIn = async (ev) => {
    this.setState({elButtonLogIn_visible: false});

    this.setState({elButtonSignUp_visible: false});

    // Go to screen 'LoginHome'
    this.props.appActions.goToScreen('loginHome', { transitionId: 'fadeIn' });

  }


  onClick_elIconButton = async (ev) => {
    this.toggle_drawer1();

  }


  onClick_elAntiTooltipHotspot = async (ev) => {
    this.setState({elBetaText_visible: false});

    this.setState({elBetaCard_visible: false});

    this.setState({elAntiTooltipHotspot_visible: false});

  }


  onClick_elIcontooltip02 = async (ev) => {
    this.setState({elBetaText_visible: !this.state.elBetaText_visible});

    this.setState({elBetaCard_visible: !this.state.elBetaCard_visible});

    this.setState({elAntiTooltipHotspot_visible: true});

  }


  onClick_elLogoznesthorizwhite = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { ...this.props, transitionId: 'fadeIn' });

  }


  toggle_drawer1 = () => {
    this.setState({drawer1_isOpen: !this.state.drawer1_isOpen});
  }

  sendData_elEmailSubmitButton_to_waitList = () => {
    const dataSheet = this.props.appActions.getDataSheet('waitList');

    let row = this.props.dataSheetRow || {
      "CityAliasName": this.props.CityAliasName,
    };
    row = { ...row,
      waitListEmail: this.state.emailWaitList,
      waitListZip: this.state.zipCodeExplore,
    };

    const transformData = (input) => {
        input.timestamp = Date.now();

      input.CityAliasName = ""; // artifact

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('waitList', row);
    } else {
      return this.props.appActions.addToDataSheet('waitList', row);
    }
  }


  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const value_zipAutoComplete = this.props.CityAliasName;

    const style_elZipAutoComplete = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

   
    const style_elTextSubSub = {
      fontSize: 9.5,
      color: '#919191',
      textAlign: 'left',
     };
    const style_elTextAlreadyLoggedIn = {
      fontSize: 19.0,
      color: '#39527f',
      textAlign: 'center',
     };
    const style_elTextAlreadyLoggedIn_outer = {
      display: 'none',
     };
    const style_elFirebaseLogin = {
      pointerEvents: 'auto',
     };
    const style_elFirebaseLogin_outer = {
      display: 'none',
     };
    const style_elTextNoZipMatch = {
      fontSize: 13.3,
      color: '#ff2600',
      textAlign: 'left',
     };
    const elTextNoZipMatch = this.state.elTextNoZipMatch_visible ? (
      <div className="elTextNoZipMatch">
        <div className="systemFontRegular" style={style_elTextNoZipMatch}>
          <div>{this.state.textnozipmatch}</div>
        </div>
      </div>

     ) : null;
    const style_elEmailThankYou = {
      fontSize: 13.3,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const elEmailThankYou = this.state.elEmailThankYou_visible ? (
      <div className="elEmailThankYou">
        <div className="systemFontBold" style={style_elEmailThankYou}>
          <div><div dangerouslySetInnerHTML={{__html: this.state.emailthankyou_plainText.replace(/\n/g, '<br>')}}></div></div>
        </div>
      </div>

     ) : null;

    const value_emailWaitList = this.state.emailWaitList;

    const style_elEmailWaitList = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#919191',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elEmailWaitList = this.state.elEmailWaitList_visible ? (
      <div className="flowRow flowRow_HomeScreen_elZipAutoComplete_758871 d-flex mt-3">
          {/* <div className="elZipAutoComplete">
            <div className="baseFont" style={style_elZipAutoComplete} onClick={this.onClick_elZipAutoComplete} >
              <div>{value_zipAutoComplete}</div>
            </div>
          </div> */}

   
          <div className="elZipCodeExplore">
            <input className="baseFont style_elEmailCodeExplore" type="email" placeholder="Enter Your Email Address" onChange={this.textInputChanged_elEmailWaitList} value={value_emailWaitList !== undefined ? value_emailWaitList : ''}/>
          </div>
          <div className="elSearchButton pb-3">
            <button className="style_elSearchButton"  onClick={this.onClick_elEmailSubmitButton} >
           Submit
            </button>
          </div>
    </div>
     ) : null;

    const style_elEmailSubmitButton = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 14.1,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: '#315283',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elEmailSubmitButton = this.state.elEmailSubmitButton_visible ? (
      <div className="elEmailSubmitButton">
        <button className="systemFontRegular" style={style_elEmailSubmitButton} onClick={this.onClick_elEmailSubmitButton} >
          Submit
        </button>
      </div>

     ) : null;
    const style_elValidator = {
      color: '#ff2600',
      textAlign: 'left',
     };
    const elValidator = this.state.elValidator_visible ? (
      <div className="elValidator">
        <div className="baseFont" style={style_elValidator}>
          <div>{this.state.validator}</div>
        </div>
      </div>

     ) : null;
 
    const style_elImageIL = {
      height: '50px',
     };
    const style_elImageAL = {
      height: '50px',
     };
    const style_elImageMC = {
      height: '50px',
     };
    const style_elImageSN = {
      height: '50px',
     };

    const style_elCardFooter_outer = {
      backgroundColor: 'white',
     };

    const style_elBkgdOutlineOverall = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
     };
    let transformStateValue_elUserInfo = (input) => {
      if (input!=""){
      return 1;
      }
      else {
      return 0;
      }

    }

    const style_elButtonSignUp = {
      display: 'block',
      textTransform: 'capitalize',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elButtonLogIn = {
      display: 'block',
      textTransform: 'capitalize',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elButtonLogIn = this.state.elButtonLogIn_visible ? (
      <button className="font-SFUITextRegular  elButtonLogIn" style={style_elButtonLogIn} onClick={this.onClick_elButtonLogIn} >
        Log in
      </button>
     ) : null;

    const style_elButtonLearnMore = {
      display: 'block',
      textTransform: 'capitalize',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };

    const style_elButtonAboutUs = {
      display: 'block',
      textTransform: 'capitalize',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };

    const style_elAntiTooltipHotspot = {
      display: 'block',
      textTransform: 'capitalize',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAntiTooltipHotspot = this.state.elAntiTooltipHotspot_visible ? (
      <div className="actionFont elAntiTooltipHotspot" style={style_elAntiTooltipHotspot} onClick={this.onClick_elAntiTooltipHotspot}  />
     ) : null;

    const style_elBetaText = {
      fontSize: 12.3,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

  
    return (
      <div className="AppScreen HomeScreen" style={baseStyle}>
        {/* Navbar  Start*/}
        <Header/>
        <div className="layoutFlow" style={layoutFlowStyle}>

       {/* Start Main Below Header Section */}
       <div className="Component banner">
       <Container>
         <Row className="pt-15">
         <Col lg={6} md={12} xs={12}>
           <h1 className="beta pt-5">BETA*</h1>
          <div className="TextMain">
              {this.state.textmain}
          </div>

          <div className="TextSub pb-5">
              <h2>{this.state.textsub}</h2>
          </div>

    
          {/* 96822 Zip Code */}
          <div className="flowRow flowRow_HomeScreen_elZipAutoComplete_758871 d-flex">
          <div className="elZipAutoComplete">
            <div className="baseFont" style={style_elZipAutoComplete} onClick={this.onClick_elZipAutoComplete} >
              <div>{value_zipAutoComplete}</div>
            </div>
          </div>

   
          <div className="elZipCodeExplore">
            <input className="baseFont style_elZipCodeExplore" type="text" placeholder="Search by ZipCode" onChange={this.textInputChanged_elZipCodeExplore} value={this.state.zipCodeExplore}  />
          </div>
          <div className="elSearchButton pb-3">
            <button className="style_elSearchButton"  onClick={this.onClick_elSearchButton} >
            <FaSearch />
            </button>
          </div>
          </div>

          {/* Not End email exist */}
          <div className="elTextSubSub mt-3">
            <div className="beta-alert text-center">
            {this.state.betaversion}
            {/* Not email exist */}
          { elEmailThankYou }
          { elEmailWaitList }
          {/* { elEmailSubmitButton } */}
          { elValidator }
            </div>
          </div>
          <div className="col-md-10 mx-auto mt-3">
          <Col lg={12} md={12} xs={12} className="systemFontRegular text-center">
            <p>{this.state.textsubsub}</p>
          </Col>
          </div>
          </Col>
          </Row>
          </Container>
      </div>
         
       {/* End Main Below Header Section */}

       {/* Start Search by Care Section */}
       <Container className="pt-5">
           <div class="title text-center display-3">{this.state.texttypesofcare}</div>
           <Row className="pt-5">
          <Col lg={3} md={6} xs={12} className="text-center">
           <div className="elImageIL pb-5">
            <img style={style_elImageIL} src={img_elImageIL} alt=""  />
            <h2 className="pt-3 title">{this.state.textil}</h2>
            <div className="pb-3 subtitle">{this.state.textiltext}</div>
            <Button variant="primary" className="btn-search">Search</Button>         
             </div>
          </Col>
          <Col lg={3} md={6} xs={12} className="text-center">
          <div className="elImageAL pb-5">
            <img style={style_elImageAL} src={img_elImageAL} alt=""  />
            <h2 className="pt-3 title">{this.state.textal}</h2>
            <div className="pb-3 subtitle">{this.state.textaltext}</div>
            <Button variant="primary" className="btn-search">Search</Button>         
          </div>
          </Col>
          <Col lg={3} md={6} xs={12} className="text-center">
          <div className="elImageMC pb-5">
            <img style={style_elImageMC} src={img_elImageMC} alt=""  />
            <h2 className="pt-3 title">{this.state.textmc}</h2>
            <div className="pb-3 subtitle">{this.state.textmctext}</div>
            <Button variant="primary" className="btn-search">Search</Button>         
          </div>
          </Col>
          <Col lg={3} md={6} xs={12} className="text-center">
          <div className="elImageSN pb-5">
            <img style={style_elImageSN} src={img_elImageSN} alt=""  />
            <h2 className="pt-3 title">{this.state.textsn}</h2>
            <div className="pb-3 subtitle">{this.state.textsntext}</div>
            <Button variant="primary" className="btn-search">Search</Button>         
          </div>
        </Col>
        </Row>
        </Container>
         
       {/* End Search by Care Section */}
       
        {/* Start Middle Banner by Care Section */}
        <Row className="text-center bg-grey mt-5">
        <Col lg={6} md={12} xs={12}>
       <div className="elImageOpenData">
            <img className='img-fluid' src={img_elImageOpenData} alt=""  />
        </div>
        </Col>
        <Col lg={6} md={12} xs={12} className="center-block pb-5">
        <div className="col-md-10 mx-auto">

          <div className="elTextOpenData pt-10">
            <div className="title text-center display-3 mt-5">
             {this.state.textopendata_plainText}
            </div>
          </div>
          <div className="elTextOpenDataText">
            <div className="systemFontRegular pb-5 pt-3" >
              <p>{this.state.textopendatatext_plainText}</p>
            </div>
          </div>
          <Button variant="primary" className="btn-search">LEARN MORE</Button>  
          </div>
        </Col>
        </Row>
        <Container>
        <Col xs={12} className="text-center pt-5">
        <div className="elTextWhyAcct">
            <div className="title text-center display-3 title" >
              {this.state.textwhyacct_plainText}
            </div>
          </div>
          <div className="elTextWhyAcctText pb-5">
            <div className="systemFontRegular" >
              <div className="pb-5 pt-3">{this.state.textwhyaccttext}</div>
              <Button variant="primary" className="btn-search">SIGN UP NOW</Button>         

            </div>
          </div> 

          <div className="elTextAlreadyLoggedIn" style={style_elTextAlreadyLoggedIn_outer}>
            <div className="systemFontBold" style={style_elTextAlreadyLoggedIn}>
              <div>{this.state.textalreadyloggedin}</div>
            </div>
          </div>
        </Col>
        <Col xs={12} className="text-center">
        <div className="pb-5 footer" style={style_elCardFooter_outer}>
            <NavLink to="/portfolio/website-designs#" className="btn-outline-primary mt-auto">ZNext 2021</NavLink>
             <NavLink to="/portfolio/website-designs#" className="btn-outline-primary mt-auto">Privacy Policy</NavLink>
             <NavLink to="/portfolio/website-designs#" className="btn-outline-primary mt-auto">Terms of Use</NavLink>
          </div>
        </Col>
        </Container>
     {/* Start Middle bANNER by Care Section */}

          <div className="elFirebaseLogin" style={style_elFirebaseLogin_outer}>
            <div style={style_elFirebaseLogin}>
              <FirebaseLogin ref={(el)=> this._elFirebaseLogin = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>

          {/* { elTextNoZipMatch } */}
          {/* { elEmailThankYou }
          { elEmailWaitList }
          { elEmailSubmitButton }
          { elValidator } */}
                
        </div>
      </div>
    )
  }

}
