import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class LoginpasswordScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);

    this.state = {
      passwordField: '',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elPasswordField = (event) => {
    this.setState({passwordField: event.target.value});
  }

  onClick_elButtonPassword = async (ev) => {
    // 'Unlock gate' action.
    this.sendLogin();

  }


  sendLogin = () => {
    // This implements the 'simple password' unlock
    // For prototyping only
    // To implement a real login, we would use a web service plugin instead
    const pass = this.state.passwordField || '';
    if (pass === "pv2021") {
       this.finishLogin();
    } else {
      const err = 'Incorrect password.';

    }
  }

  finishLogin = () => {
      sessionStorage.setItem('loginStatus_loginpassword', 'active');
      this.props.appActions.goToScreen('home');
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };

    const style_elPasswordField = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };

    const style_elButtonPassword = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    return (
      <div className="AppScreen LoginpasswordScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elPasswordField">
            <input className="baseFont" style={style_elPasswordField} type="password" placeholder="" onChange={this.textInputChanged_elPasswordField} value={this.state.passwordField}  />
          </div>

          <div className="elButtonPassword">
            <Button className="actionFont" style={style_elButtonPassword}  color="accent" onClick={this.onClick_elButtonPassword} >
              LOGIN
            </Button>
          </div>
        </div>
      </div>
    )
  }

}
