import React, { Component } from 'react';
import './App.css';

export default class UserInfo extends Component {

  // Properties used by this component:
  // ds_SlotUserPhoto, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elTextBlock: (<div> </div>),
      state0_elTextBlock_plainText: " ",
      state1_elTextBlock: (<div>LOGGED IN</div>),
      state1_elTextBlock_plainText: "LOGGED IN",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elTextBlock = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { transitionId: 'fadeIn' });
  
  }
  
  
  renderState0() {
    const style_state0_elTextBlock = {
      color: '#feffff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="UserInfo">
        <div className="foreground">
          <div className="baseFont state0_elTextBlock" style={style_state0_elTextBlock} onClick={this.onClick_state0_elTextBlock} >
            <div>{this.state.state0_elTextBlock}</div>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  renderState1() {
    const style_state1_elImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.ds_SlotUserPhoto)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_state1_elTextBlock = {
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'left',
     };
    
    return (
      <div className="UserInfo">
        <div className="foreground">
          <div className="state1_elImage" style={style_state1_elImage} />
          <div className="font-SFUITextRegular  state1_elTextBlock" style={style_state1_elTextBlock}>
            <div>{this.state.state1_elTextBlock}</div>
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
