// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    item = {};
    this.items.push(item);
    item['key'] = "start_text_422511";
    item['en'] = "";

    item = {};
    this.items.push(item);
    item['key'] = "start_textcopy_368713";
    item['en'] = "Address";

    item = {};
    this.items.push(item);
    item['key'] = "start_textcopy2_822180";
    item['en'] = "Opening hours";

    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text_871640";
    item['en'] = "";

    item = {};
    this.items.push(item);
    item['key'] = "listitem1_textcopy_870306";
    item['en'] = "Address";

    item = {};
    this.items.push(item);
    item['key'] = "listitem1_textcopy2_380504";
    item['en'] = "Opening hours";

    item = {};
    this.items.push(item);
    item['key'] = "reviewslist_text_287147";
    item['en'] = "Review subject";

    item = {};
    this.items.push(item);
    item['key'] = "reviewslist_textcopy_976036";
    item['en'] = "Reviewer name";

    item = {};
    this.items.push(item);
    item['key'] = "listitem2_text_45212";
    item['en'] = "(Review Subject)";

    item = {};
    this.items.push(item);
    item['key'] = "listitem2_textcopy_983285";
    item['en'] = "(Reviewer name)";

    item = {};
    this.items.push(item);
    item['key'] = "listitem1_button_480925";
    item['en'] = "Reviews";

    item = {};
    this.items.push(item);
    item['key'] = "listitem2_textblock_98908";
    item['en'] = "(Review text)";

    item = {};
    this.items.push(item);
    item['key'] = "addreview_field_786827";
    item['en'] = "Your email";

    item = {};
    this.items.push(item);
    item['key'] = "addreview_button_596452";
    item['en'] = "Post review";

    item = {};
    this.items.push(item);
    item['key'] = "addreview_text_597163";
    item['en'] = "Id should be here";

    item = {};
    this.items.push(item);
    item['key'] = "listitem1_textcopy3_784364";
    item['en'] = "ID";

    item = {};
    this.items.push(item);
    item['key'] = "addreview_reviewtextcopy_367397";
    item['en'] = "Your name";

    item = {};
    this.items.push(item);
    item['key'] = "addreview_reviewtextcopy2_313895";
    item['en'] = "Title...";

    item = {};
    this.items.push(item);
    item['key'] = "addreview_reviewtitlecopy_929312";
    item['en'] = "Your review";

    item = {};
    this.items.push(item);
    item['key'] = "addreview_reviewernamecopy_485581";
    item['en'] = "Your review";

    item = {};
    this.items.push(item);
    item['key'] = "addreview_reviewtitlecopy_48940";
    item['en'] = "Your review";

    item = {};
    this.items.push(item);
    item['key'] = "errorinlogin_text_855306";
    item['en'] = "Error in login";

    item = {};
    this.items.push(item);
    item['key'] = "listitemreview_textblockcopy_677321";
    item['en'] = "(Date)";

    item = {};
    this.items.push(item);
    item['key'] = "login_text_518207";
    item['en'] = "Login or Sign Up to Continue";

    item = {};
    this.items.push(item);
    item['key'] = "comp1_text_731033";
    item['en'] = "Limit the number of rows";

    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy_1026884";
    item['en'] = "(Reviewer name)";

    item = {};
    this.items.push(item);
    item['key'] = "writeareview_text_365780";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "writeareview_textarea_652563";
    item['en'] = "Review...";

    item = {};
    this.items.push(item);
    item['key'] = "writeareview_text2_254570";
    item['en'] = "New review for ";

    item = {};
    this.items.push(item);
    item['key'] = "screen17_text_171243";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "listitemreview_button_823965";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text_758871";
    item['en'] = "AutoComplete";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text2_130360";
    item['en'] = "or continue without login";

    item = {};
    this.items.push(item);
    item['key'] = "userinfo_textblock_987086";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "userinfo_textblock_49514";
    item['en'] = "LOGGED IN";

    item = {};
    this.items.push(item);
    item['key'] = "writeareview_button_160588";
    item['en'] = "Cancel";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_field2_413551";
    item['en'] = "Zip Code";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_button_354760";
    item['en'] = "Search";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_text_96574";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_text_308819";
    item['en'] = "Basic Information";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_communityname";
    item['en'] = "KLM Propety";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_text_239632";
    item['en'] = "Type of Licenses\n\n\nIndependent Living\n\nAssisted Living\n\nMemory Care\n\nSkilled Nursing\n\nOther";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_text_405078";
    item['en'] = "186";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_text_955695";
    item['en'] = "Licensed Beds";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingbedcountcopy_837248";
    item['en'] = "76";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingbedcountcopy_588291";
    item['en'] = "54";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingbedcountcopy_380578";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_skillednursingbedcountcopy_691887";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_otherbedcountcopy_1042527";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_text_949213";
    item['en'] = "Total";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_otherbedcountcopy2_939502";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textbasicinfocopy_610057";
    item['en'] = "Pricing";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_texttotalbedcopy_299274";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_totalbedcountcopy_763295";
    item['en'] = "$3000";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityphone_462367";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textbasicinfo_240478";
    item['en'] = "Basic Information";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textlicensetypes_173185";
    item['en'] = "Type of Licenses\n\n\nIndependent Living\n\nAssisted Living\n\nMemory Care\n\nSkilled Nursing\n\nOther";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textlicensedbeds_681178";
    item['en'] = "Licensed Beds";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingbedcount_649518";
    item['en'] = "186";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingbedcount_742139";
    item['en'] = "76";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarebedcount_553517";
    item['en'] = "54";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingbedcount_882739";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_otherbedcount_969154";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_texttotalbed_63629";
    item['en'] = "Total";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_totalbedcount_391564";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textpricing_277028";
    item['en'] = "Pricing";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textcommunityfee_421657";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityfee_934909";
    item['en'] = "$3000";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailsedit_button_572821";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_communityphone_511625";
    item['en'] = "310-432-7471";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_textbasicinfo_767761";
    item['en'] = "Basic Information";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_textlicensetypes_449543";
    item['en'] = "Type of Licenses\n\n\nIndependent Living\n\nAssisted Living\n\nMemory Care\n\nSkilled Nursing\n\nOther";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_textlicensedbeds_330310";
    item['en'] = "Licensed Beds";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_indelivingbedcount_861055";
    item['en'] = "186";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_assistedlivingbedcount_978128";
    item['en'] = "76";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_memorycarebedcount_953624";
    item['en'] = "54";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_skillednursingbedcount_293771";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_otherbedcount_992175";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_texttotalbed_285004";
    item['en'] = "Total";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_totalbedcount_509082";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_textpricing_1017909";
    item['en'] = "Pricing";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_textcommunityfee_153256";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_communityfee_59019";
    item['en'] = "$3000";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetailseditable_button_468458";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_communityfeesubmit_373034";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textcommunityfeecopy_394000";
    item['en'] = "Independent Living";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingcopy_369374";
    item['en'] = "Independent Living";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textcommunityfeecopy_398965";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingcopy_320165";
    item['en'] = "Click on the price to report an updated price";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textclickinstructioncopy_300118";
    item['en'] = "Description:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textcommunityfeecopy_955747";
    item['en'] = "User Name";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_communityfeeusercopy_143913";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textpricingheading1copy_942212";
    item['en'] = "Median Price:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textpricingheading2copy_50080";
    item['en'] = "Price reported by:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textclickinstruction_329809";
    item['en'] = "Click on the price to report an updated price";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textpricingheading1_383996";
    item['en'] = "Description:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textpricingheading2_815102";
    item['en'] = "Median Price:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textpricingheading3_534796";
    item['en'] = "Price reported by:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityfeeuser_329255";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityfeedate_812848";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindeliving_958845";
    item['en'] = "Independent Living";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textcommunityfeecopy_320373";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_communityfeecopy_957778";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_communityfeeusercopy_273093";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_communityfeedatecopy_516652";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindelivingstudio_779869";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofee_44047";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeeuser_374871";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeedate_778107";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityfeesubmitcopy_483792";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingstudiocopy_105898";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeecopy_309532";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeeusercopy_496238";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeedatecopy_791264";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingstudiocopy2_480712";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeecopy2_975704";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeeusercopy2_107376";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeedatecopy2_923054";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingstudiocopy3_253596";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeecopy3_738398";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeeusercopy3_614137";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeedatecopy3_179290";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingstudiocopy4_543109";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeecopy4_141107";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeeusercopy4_607132";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeedatecopy4_1031957";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindeliving1bdrm_368938";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmfee_998465";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmuser_412642";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmdate_329977";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindeliving2bdrm_868161";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2bdrmfee_263807";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2bdrmuser_367162";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2bdrmdate_733206";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindeliving2ndoccufee_264988";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2ndoccufee_631759";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2ndoccufeeuser_736944";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2ndoccufeedate_235899";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindelivingcarefee_387028";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcarefee_935479";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcarefeeuser_1033288";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcarefeedate_595760";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeesubmitcopy_969429";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmfeecopy_692684";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeesubmitcopy2_218715";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeesubmitcopy3_727233";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeesubmitcopy4_462008";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeesubmitcopy5_689456";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text2_573308";
    item['en'] = "WikiLiving is a platform that provides the most accurate information about care or housing for our loved ones, ranging from healthy, active seniors to those that need daily care. WikiLiving is a free service to all users and providers of care. Other websites showcase only options that pay for sponsorship. Users can update pricing as soon as it changes, so that we, as a community of caring people, can help each other.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textweasacommcopy_31639";
    item['en'] = "We, as a community, must help each other find options for the elderly!";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text2_255724";
    item['en'] = "Types of Care";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textcopy_1022098";
    item['en'] = "Independent Living";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textcopy2_667373";
    item['en'] = "Memory Care";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textindelivingcopy_244726";
    item['en'] = "Assisted Living";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textassistlivingcopy_9589";
    item['en'] = "Skilled Nursing";

    item = {};
    this.items.push(item);
    item['key'] = "communityitem_textcopy_50384";
    item['en'] = "(Phone)";

    item = {};
    this.items.push(item);
    item['key'] = "communityitem_communityaddresscopy_730971";
    item['en'] = "(City State Zip)";

    item = {};
    this.items.push(item);
    item['key'] = "communityitem_communitynamecopy_164889";
    item['en'] = "(Community name)";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textpricingcopy_1031712";
    item['en'] = "Photos";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_button_508462";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "comp1_button_69391";
    item['en'] = "Upload";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_button_762835";
    item['en'] = "Add Photos";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingstudiocopy_983354";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeecopy_680417";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiousercopy_537256";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiodatecopy_537903";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindeliving1bdrmcopy_702709";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmfeecopy_936441";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmusercopy_627878";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmdatecopy_133843";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmfeesubmitcopy_979299";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingcopy_562326";
    item['en'] = "Assisted Living";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingcommfeecopy_892613";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingcommfeecopy_33992";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingcommfeeusercopy_439036";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingcommfeedatecopy_874214";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindelivingstudiocopy_721837";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiofeecopy_906674";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiousercopy_695360";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indelivingstudiodatecopy_590209";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindeliving1bdrmcopy_922739";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indeliving1bdrmfeecopy_690916";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indeliving1bdrmusercopy_537006";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indeliving1bdrmdatecopy_527050";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textindeliving2bdrmcopy_400687";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indeliving2bdrmfeecopy_392268";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indeliving2bdrmusercopy_24507";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indeliving2bdrmdatecopy_557955";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedliving2bdrmcopy_217508";
    item['en'] = "2-Bedroom Shared";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmfeecopy_325042";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmusercopy_1026189";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmdatecopy_996931";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedliving2bdrmcopy2_1020123";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmfeecopy2_838170";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmusercopy2_573834";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmdatecopy2_420382";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedlivingcommfeecopy_907125";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcommfeecopy_65281";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcommfeeusercopy_208860";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcommfeedatecopy_533118";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedlivingcopy_977670";
    item['en'] = "Memory Care";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedlivingcommfeecopy_858842";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcommfeecopy_512119";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcommfeeusercopy_272199";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcommfeedatecopy_99910";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedlivingstudiocopy_606123";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingstudiofeecopy_1011000";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingstudiousercopy_1042553";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingstudiodatecopy_107559";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedliving1bdrmcopy_22887";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving1bdrmfeecopy_312056";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving1bdrmusercopy_587445";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving1bdrmdatecopy_119960";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedliving2bdrmcopy_352239";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmfeecopy_422387";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmusercopy_171689";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmdatecopy_837966";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedliving2bdrmsharedcopy_874287";
    item['en'] = "2-Bedroom Shared";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmsharedfeecopy_756506";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmsharedusercopy_113623";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2bdrmshareddatecopy_454842";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedliving2ndocccopy_614340";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2ndoccfeecopy_551430";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2ndoccusercopy_473595";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedliving2ndoccdatecopy_765754";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textassistedlivingcarefeecopy_842729";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcarefeecopy_611838";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcarefeeusercopy_169182";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_assistedlivingcarefeedatecopy_358802";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving_1407395145";
    item['en'] = "Assisted Living";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingcommfee_1229558251";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcommfee_1244152914";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcommfeeuser_941100501";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcommfeedate_300806172";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityfeesubmitcopy_642650059";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindelivingcopy_1911552943";
    item['en'] = "Assisted Living";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindelivingcommfeecopy_683389435";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeecopy_2112582816";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeeusercopy_623780680";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeedatecopy_831271234";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeesubmitcopy_664884646";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindelivingstudiocopy_1795841545";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeeusercopy_2129042302";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofeedatecopy_1969897800";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeecopy2_1689423990";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeesubmitcopy2_1089387394";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeesubmitcopy2_1965921678";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingstudiocopy_731708102";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiofeecopy_1814949896";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiofeeusercopy_1450884580";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiofeedatecopy_1056370093";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiofeesubmitcopy_875883579";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving1bdrmcopy_320337709";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeecopy_654952705";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeeusercopy_246692083";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeedatecopy_125525538";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmcopy_1161340091";
    item['en'] = "2-Bedroom Shared";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeecopy_1971454872";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeeusercopy_1813460527";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeedatecopy_1952175296";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmsharedcopy_1468633366";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeecopy_1466441808";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeeusercopy_1331787741";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeedatecopy_659519275";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2ndocccopy_2054043356";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeecopy_347883993";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeeusercopy_1435036074";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeedatecopy_1338201054";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeesubmitcopy_1324264086";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmcopy_254499224";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeesubmitcopy2_1130968104";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmcopy2_2042235963";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeesubmitcopy3_1973645177";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmcopy3_1792693595";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeesubmitcopy4_351276375";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmcopy4_384119718";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingcopy_581683549";
    item['en'] = "Memory Care";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindelivingcommfeecopy_754515851";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeecopy_394976249";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeeusercopy_277337828";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeedatecopy_659086303";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingstudiocopy_2074731977";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiofeecopy_1865711641";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiofeeusercopy_707704209";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiofeedatecopy_141679421";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving1bdrmcopy_809855507";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeecopy_338963924";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeeusercopy_378051319";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfeedatecopy_1457915465";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmcopy_368454893";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeecopy_743057118";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeeusercopy_465448216";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeedatecopy_897690283";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmsharedcopy_2010008256";
    item['en'] = "2-Bedroom Shared";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeecopy_1282565617";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeeusercopy_511330000";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeedatecopy_1485187762";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2ndocccopy_1946116167";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeecopy_824797718";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeeusercopy_1886429999";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeedatecopy_1616358982";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingcarefeecopy_489765975";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefeecopy_1536922177";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefeeusercopy_960572277";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefeedatecopy_524109576";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmsharedcopy2_590280347";
    item['en'] = "2-Bedroom Shared";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeecopy2_1071274713";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeeusercopy2_1852666643";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfeedatecopy2_661988354";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2ndocccopy2_1597535035";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeecopy2_1886496135";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeeusercopy2_287346627";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfeedatecopy2_2034326600";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingcarefeecopy2_92307839";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefeecopy2_309549157";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefeeusercopy2_390977676";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefeedatecopy2_21300603";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycarecopy_666764614";
    item['en'] = "Memory Care";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeesubmitcopy_1164873350";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeesubmitcopy2_819982102";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeesubmitcopy3_1041032915";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeesubmitcopy4_708157142";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeesubmitcopy5_1841074929";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeesubmitcopy6_480851304";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfeesubmitcopy7_1810290776";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textmemorycarecopy_1776922273";
    item['en'] = "Skilled Nursing";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textmemorycare1bdrmcopy_1446771654";
    item['en'] = "Private";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_memorycare1bdrmfeecopy_25414791";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_memorycare1bdrmusercopy_225263956";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_memorycare1bdrmdatecopy_253430477";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textmemorycare2bdrmcopy_1336370099";
    item['en'] = "Semi-Private";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_memorycare2bdrmfeecopy_1146281762";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_memorycare2bdrmusercopy_399391966";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_memorycare2bdrmdatecopy_1541457649";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycarecopy_543205423";
    item['en'] = "Skilled Nursing";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycare1bdrmcopy_1036713540";
    item['en'] = "Private";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare1bdrmfeecopy_345308005";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare1bdrmfeeusercopy_246646409";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare1bdrmfeedatecopy_1795856826";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycare2bdrmcopy_721581048";
    item['en'] = "Semi-Private";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmfeecopy_312059804";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmfeeusercopy_2080153952";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmfeedatecopy_1915041689";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarecarefeesubmitcopy_1564885808";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingsemisubmitcopy_11009332";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "loginpassword_button_340426745";
    item['en'] = "LOGIN";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text2_424065696";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_button_492257583";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text_613866899";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text_782697092";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text_726082311";
    item['en'] = "For active seniors with basic services like meals, activities, laundry, and housekeeping.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_subtextindelivingcopy_1128052578";
    item['en'] = "For seniors with memory care needs like Alzhimer’s and other forms of dementia.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_subtextindelivingcopy2_1842850053";
    item['en'] = "For seniors who need help with activities of daily living like dressing, bathing, and grooming";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_subtextassistlivingcopy_483545106";
    item['en'] = "For seniors who need supervision from trained medical staff.";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_communityphonecopy_2068436492";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_textbasicinfocopy_66016928";
    item['en'] = "Basic Information";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_communitycitycopy_631071228";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_buttonaddphotos_1047831651";
    item['en'] = "Add Photos";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textphotos_1661265056";
    item['en'] = "Photos";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communitycity_1983879762";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_zipcodeexplore_189015856";
    item['en'] = "Zip Code";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_zipautocomplete_482917279";
    item['en'] = "AutoComplete";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_searchbutton_384310801";
    item['en'] = "Search";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_textweasacomm_86767174";
    item['en'] = "We, as a community, must help each other find options for the elderly!";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_textbelowweasacomm_1200804187";
    item['en'] = "WikiLiving is a platform that provides the most accurate information about care or housing for our loved ones, ranging from healthy, active seniors to those that need daily care. WikiLiving is a free service to all users and providers of care. Other websites showcase only options that pay for sponsorship. Users can update pricing as soon as it changes, so that we, as a community of caring people, can help each other.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_texttypesofcare_1550095046";
    item['en'] = "Types of Care";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_textindeliving_76836932";
    item['en'] = "Independent Living";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_textassistliving_780460540";
    item['en'] = "Assisted Living";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_subtextindeliving_1707375819";
    item['en'] = "For active seniors with basic services like meals, activities, laundry, and housekeeping.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_subtextassistliving_836763079";
    item['en'] = "For seniors who need help with activities of daily living like dressing, bathing, and grooming";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_textmemorycare_1070268606";
    item['en'] = "Memory Care";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_textskillnursing_807315208";
    item['en'] = "Skilled Nursing";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_subtextmemorycare_2119925499";
    item['en'] = "For seniors with memory care needs like Alzhimer’s and other forms of dementia.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_subtextskillnursing_1243499220";
    item['en'] = "For seniors who need supervision from trained medical staff.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain2_textcontinuewologin_124462936";
    item['en'] = "or continue without login";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text_1072798324";
    item['en'] = "New text. Double-click to edit";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text_750714781";
    item['en'] = "Find the perfect senior living community for you.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text2_1433959248";
    item['en'] = "Sun Bay Care is a community of supportive people who share pricing information.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_text_1608170760";
    item['en'] = "Search over 50,000 communities in our database.";

    item = {};
    this.items.push(item);
    item['key'] = "home_textmain_1743410361";
    item['en'] = "Find the perfect senior living community for you.";

    item = {};
    this.items.push(item);
    item['key'] = "home_textsub_1673801882";
    item['en'] = "Sun Bay Care is a community of supportive people who share pricing information.";

    item = {};
    this.items.push(item);
    item['key'] = "home_zipcodeexplore_1056620606";
    item['en'] = "Zip Code";

    item = {};
    this.items.push(item);
    item['key'] = "home_zipautocomplete_1125746762";
    item['en'] = "AutoComplete";

    item = {};
    this.items.push(item);
    item['key'] = "home_searchbutton_2132682171";
    item['en'] = "Search";

    item = {};
    this.items.push(item);
    item['key'] = "home_textsubsub_789933045";
    item['en'] = "Search over 50,000 communities in our database.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_button_1145801075";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_buttonaboutuscopy_2067188872";
    item['en'] = "learn more";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_buttonaboutuscopy2_1903482910";
    item['en'] = "Log in";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_buttonaboutuscopy3_1490380195";
    item['en'] = "sign up";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textmaincopy_208075189";
    item['en'] = "Independent Living";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textsubsubcopy_1869070819";
    item['en'] = "For active seniors, includes basic services like meals, activities, laundry, and housekeeping.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textilcopy_1010114136";
    item['en'] = "Types of care";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textilcopy_1176415559";
    item['en'] = "Assisted Living";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textiltextcopy_458043538";
    item['en'] = "For seniors who need help with activities of daily living like dressing, bathing, and grooming.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textilcopy_922754064";
    item['en'] = "Memory Care";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textilcopy2_1964478703";
    item['en'] = "Skilled Nursing";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textaltextcopy_940026611";
    item['en'] = "For seniors with memory care needs like Alzheimer’s or other forms of dementia.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textaltextcopy2_1762513305";
    item['en'] = "For seniors who need supervision from trained medical staff.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_texttypesofcarecopy_787823925";
    item['en'] = "Open data";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textsubcopy_1422280322";
    item['en'] = "We have over 50,000 communities in our database, and if you want to search for an option based on criteria like proximity or price, we have a comprehensive list which is not influenced by corporate sponsorship.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textopendatacopy_1506414053";
    item['en'] = "Why create an account";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textopendatatextcopy_1746221044";
    item['en'] = "Membership is free! Sun Bay Care will provide you with the most accurate pricing for each senior housing and care option. In exchange we ask you to be a part of the community so that we can help each other. When you visit an option and notice the pricing has changed, you can be a part of helping the community by submitting updates. In order to submit updates, however, you will have to create an account. Members will also be eligible for various rewards.";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy9_532784517";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy8_1371359889";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy7_704740209";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy6_1778687871";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy5_1653822989";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy4_1643597666";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy3_1646156599";
    item['en'] = "New button";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy2_914430659";
    item['en'] = "Login";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy_1745731627";
    item['en'] = "Learn More";

    item = {};
    this.items.push(item);
    item['key'] = "drawer1_button_12316050";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "community_buttonaboutus_288729241";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "community_buttonlearnmore_2045165769";
    item['en'] = "learn more";

    item = {};
    this.items.push(item);
    item['key'] = "community_buttonlogin_1187824606";
    item['en'] = "Log in";

    item = {};
    this.items.push(item);
    item['key'] = "community_buttonsignup_1491615615";
    item['en'] = "sign up";

    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy_1966489669";
    item['en'] = "Learn More";

    item = {};
    this.items.push(item);
    item['key'] = "drawer2_button_187593706";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_buttonlearnmore_1721445605";
    item['en'] = "learn more";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_buttonaboutus_328769322";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_buttonaboutus_1031499866";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_buttonlearnmore_891732386";
    item['en'] = "learn more";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_indeliving1bdrmfeecopy_1373363033";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_editbutton_290220116";
    item['en'] = "Edit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_buttonaboutus_1197972045";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_buttonlearnmore_749209923";
    item['en'] = "learn more";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityname_77946213";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_buttonaddphotos_2002230461";
    item['en'] = "Add Photos";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityaddress_1426992343";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communitycity_979683890";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_communityphone_368804578";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textbasicinfo_894283951";
    item['en'] = "Basic Information";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_editbutton_187052793";
    item['en'] = "Edit";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textlicensetypes_63725548";
    item['en'] = "Type of Licenses\n\n\nIndependent Living\n\nAssisted Living\n\nMemory Care\n\nSkilled Nursing\n\nOther";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textlicensedbeds_1231657674";
    item['en'] = "Licensed Beds";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingbedcount_365308549";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingbedcount_1389156417";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarebedcount_1420743633";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingbedcount_1476478232";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_otherbedcount_1153607192";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_texttotalbed_518288830";
    item['en'] = "Total";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_totalbedcount_600110571";
    item['en'] = "9";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textpricing_721839624";
    item['en'] = "Pricing";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textclickinstruction_1146816785";
    item['en'] = "Click on the price to report an updated price";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textpricingheading1_1123689615";
    item['en'] = "Description:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textpricingheading2_1108343455";
    item['en'] = "Median Price:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textpricingheading3_2055168242";
    item['en'] = "Price reported by:";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindeliving_1045677302";
    item['en'] = "Independent Living";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindelivingcommfee_1846371389";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfee_208096965";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeeuser_1181247578";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingcommfeedate_1284244688";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindelivingstudio_544976822";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiofee_2084818957";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiouser_149024218";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indelivingstudiodate_343301079";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindeliving1bdrm_1604746234";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmfee_1793126619";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmuser_821367843";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving1bdrmdate_2135677591";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textindeliving2bdrm_1853566900";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2bdrmfee_867659109";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2bdrmuser_713546036";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_indeliving2bdrmdate_92877046";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving_1529469385";
    item['en'] = "Assisted Living";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingcommfee_1119834870";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcommfee_656477959";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcommfeeuser_2131197587";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcommfeedate_2025834723";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingstudio_1499926009";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiofee_2051645686";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiouser_563228522";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingstudiodate_87217306";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving1bdrm_1454311714";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmfee_608436598";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmuser_1543363149";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving1bdrmdate_1215158943";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrm_765126793";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmfee_1102322861";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmuser_1460674437";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmdate_1897442745";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2bdrmshared_643995888";
    item['en'] = "2-Bedroom Shared";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmsharedfee_42609554";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmshareduser_467137134";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2bdrmshareddate_1401533251";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedliving2ndocc_1739552423";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccfee_1535003880";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccuser_1985827435";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedliving2ndoccdate_515367061";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textassistedlivingcarefee_1994611031";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefee_1140704637";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefeeuser_548848550";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_assistedlivingcarefeedate_428370329";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycare_1764232398";
    item['en'] = "Memory Care";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycarecommfee_638788971";
    item['en'] = "Community Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarecommfee_1367835245";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarecommfeeuser_1758008553";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarecommfeedate_2002838055";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycarestudio_479754069";
    item['en'] = "Studio";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarestudiofee_611087961";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarestudiouser_1840603910";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarestudiodate_602979200";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycare1bdrm_1148869825";
    item['en'] = "1-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare1bdrmfee_1008547989";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare1bdrmuser_439161078";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare1bdrmdate_1297535274";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycare2bdrm_138417881";
    item['en'] = "2-Bedroom";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmfee_616183740";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmuser_890573085";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmdate_396887370";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycare2bdrmshared_630964749";
    item['en'] = "2-Bedroom Shared";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmsharedfee_982830244";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmshareduser_1858945555";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2bdrmshareddate_1102651329";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycare2ndocc_628552778";
    item['en'] = "2nd Occupant Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2ndoccfee_592231742";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2ndoccuser_680758395";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycare2ndoccdate_2097861156";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textmemorycarecarefee_605491905";
    item['en'] = "Care Fee";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarecarefee_2097152123";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarecarefeeuser_101241108";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_memorycarecarefeedate_253740786";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textskillednursing_366995168";
    item['en'] = "Skilled Nursing";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textskillednursingprivate_1789931262";
    item['en'] = "Private";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingprivatefee_1489600873";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingprivateuser_844549876";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingprivatedate_747430417";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textskillednursingsemi_915342416";
    item['en'] = "Semi-Private";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingsemifee_277452600";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingsemiuser_1470091506";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_skillednursingsemidate_27543351";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textreviewsheader_2049046120";
    item['en'] = "Reviews for ";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_textphotos_1715578485";
    item['en'] = "Photos";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_text_1586197312";
    item['en'] = "Address";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_textcopy_268046052";
    item['en'] = "City";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_communitycitycopy_1841920806";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_citytextcopy_2074262586";
    item['en'] = "State";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_statetextcopy_1818608774";
    item['en'] = "Phone";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_statetextcopy_1344317443";
    item['en'] = "ZIP";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_communitystatecopy_1916680751";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_ziptextcopy_1046041456";
    item['en'] = "Website";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_zipcodecopy_1301226939";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_addresstextcopy_1600652393";
    item['en'] = "Community Name";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_button_579413050";
    item['en'] = "Submit Changes";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_addresstextcopy_654224890";
    item['en'] = "Update Community Details";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_communitynamecopy_117408448";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_nametextcopy_1809967035";
    item['en'] = "Any suggested changes must first be verified by a ZNest moderator.";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_buttonaboutus_1388013412";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_buttonlearnmore_1753729026";
    item['en'] = "learn more";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_descheadingtext_21880486";
    item['en'] = "Terms and Conditions";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_desctext_1907582094";
    item['en'] = "These Terms and Conditions (which, together with the Business Terms below, are the “Terms”) are effective immediately.";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_nametext_320023698";
    item['en'] = "Community Name";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_communityname_1206080423";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_buttonaddphotos_2010139794";
    item['en'] = "Add Photos";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_addresstext_2074393277";
    item['en'] = "1.   DEFINITIONS";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_communityaddress_1302193148";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_citytext_1416144541";
    item['en'] = "City";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_communitycity_645375448";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_statetext_367491874";
    item['en'] = "State";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_communitystate_114167868";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_ziptext_1468789162";
    item['en'] = "ZIP";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_communityzip_1455383252";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_phonetext_75286924";
    item['en'] = "Phone";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_communityphone_1101589001";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_websitetext_2129492410";
    item['en'] = "Website";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_communityweb_150764233";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_textbasicinfo_1633697093";
    item['en'] = "Basic Information";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_textlicensetypes_1680919916";
    item['en'] = "Type of Licenses\n\n\nIndependent Living\n\nAssisted Living\n\nMemory Care\n\nSkilled Nursing\n\nOther";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_textlicensedbeds_209024330";
    item['en'] = "Licensed Beds";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_indelivingbedcount_1890218585";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_assistedlivingbedcount_795530494";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_memorycarebedcount_345223420";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_skillednursingbedcount_628523449";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_otherbedcount_2005404479";
    item['en'] = "0";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_texttotalbed_223305270";
    item['en'] = "Total";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_totalbedcount_1965529898";
    item['en'] = "<no value from script>";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo2_button_1781802336";
    item['en'] = "Submit Changes";

    item = {};
    this.items.push(item);
    item['key'] = "tc_text_2074942767";
    item['en'] = "New text. Double-click to edit dfdsafdsaf dsafsa sdfa fasddfsa ds";

    item = {};
    this.items.push(item);
    item['key'] = "tc_desctextcopy_1443136036";
    item['en'] = "Any suggested changes must first be verified by a ZNest moderator.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_desctextcopy2_1520661519";
    item['en'] = "PLEASE NOTE: THESE TERMS INCLUDE DISPUTE RESOLUTION PROVISIONS (SEE SECTION 13) THAT, WITH LIMITED EXCEPTIONS, REQUIRE THAT (1) CLAIMS YOU BRING AGAINST ZNEST BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND (2) YOU WAIVE YOUR RIGHT TO BRING OR PARTICIPATE IN ANY CLASS, GROUP, OR REPRESENTATIVE ACTION OR PROCEEDING.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_desctextcopy3_1762376073";
    item['en'] = "These Terms govern your access to and use of our products and services, including those offered through our websites, events, communications (e.g., emails, phone calls, and texts) and mobile applications (collectively, the “Service”). By accessing or using the Service, you are agreeing to these Terms, which form a legally binding contract with ZNest, LLC, a Delaware Limited Liability Company with its headquarters in Los Angeles, California. “ZNest” means ZNest, LLC, as applicable. Do not access or use the Service if you are unwilling or unable to be bound by the Terms.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tctext3copy_1569622566";
    item['en'] = "Parties. “You” and “your” refer to you, as a user of the Service. A “user” is someone who accesses or in any way uses the Service.  “We,” “us,” and “our” refer to ZNest.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy_99957237";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy_1592322569";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy2_136572223";
    item['en'] = "C.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy_393129508";
    item['en'] = "Content. “Content” means text, images, photos, audio, video, and all other forms of data or communication. “Your Content” means Content that you submit or transmit to, through, or in connection with the Service, such as ratings, reviews, photos, videos, compliments, invitations, check-ins, votes, friending and following activity, direct messages, and information that you contribute to your user profile or suggest for a business page. “User Content” means Content that users submit or transmit to, through, or in connection with the Service. “ZNest Content” means Content that we create and make available in connection with the Service. “Third Party Content” means Content that originates from parties other than ZNest or its users, which is made available in connection with the Service. “Service Content” means all of the Content that is made available in connection with the Service, including Your Content, User Content, ZNest Content, and Third Party Content.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy2_1924658151";
    item['en'] = "Sites and Accounts. “Consumer Site” means ZNest’s consumer website (www.sunbaycare.com and related domains) and mobile applications. “Consumer Account” means the account you create to access or use the Consumer Site. “Business Account” means the account you create to access or use the ZNest for Business Owners website (biz.sunbaycare.com and related domains) and mobile applications. “Account” means any Consumer Account or Business Account.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy_410852951";
    item['en'] = "2.   CHANGES TO THE TERMS";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy3_1998079867";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy3_426446325";
    item['en'] = "We may modify the Terms from time to time. The most current version of the Terms will be located here. You understand and agree that your access to or use of the Service is governed by the Terms effective at the time of your access to or use of the Service. If we make material changes to these Terms, we will notify you by email, by posting notice on the Service, and/or by other method prior to the effective date of the changes. We will also indicate at the top of this page the date that such changes were last made. You should revisit these Terms on a regular basis as revised versions will be binding on you. You understand and agree that your continued access to or use of the Service after the effective date of changes to the Terms represents your acceptance of such changes.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy2_570467168";
    item['en'] = "3.   TRANSLATION";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy4_474672426";
    item['en'] = "We may translate these Terms into other languages for your convenience. Nevertheless, the English version governs your relationship with ZNest, and any inconsistencies among the different versions will be resolved in favor of the English version available here.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy3_1352791155";
    item['en'] = "4.   USING THE SERVICE";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy3_69990657";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy5_1395278200";
    item['en'] = "Eligibility. To access or use the Service, you must have the requisite power and authority to enter into these Terms. You may not access or use the Service if you are a competitor of ZNest or if we have previously banned you from the Service or closed your Account.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy4_856003490";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy6_1467782232";
    item['en'] = "Permission to Use the Service. We grant you permission to use the Service subject to these Terms. Your use of the Service is at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or is otherwise inappropriate.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy5_1408957979";
    item['en'] = "C.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy7_1647667328";
    item['en'] = "Service Availability. The Service may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy6_184501960";
    item['en'] = "D.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy8_236691616";
    item['en'] = "Accounts. You must create an Account and provide certain information about yourself in order to use some of the features that are offered through the Service. You are responsible for maintaining the confidentiality of your Account password. You are also responsible for all activities that occur in connection with your Account. You agree to notify us immediately of any unauthorized use of your Account. We reserve the right to close your Account at any time for any or no reason. Your Consumer Account is for your personal, non-commercial use only, and you may not create or use a Consumer Account for anyone other than yourself. We ask that you provide complete and accurate information about yourself when creating an Account in order to bolster your credibility as a contributor to the Service. You may not impersonate someone else, provide an email address other than your own, create multiple Accounts, or transfer your Consumer Account to another person without ZNest’s prior approval.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy7_950815192";
    item['en'] = "E.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy9_511998776";
    item['en'] = "Communications from ZNest and Others. By accessing or using the Service, you consent to receive communications from other users and ZNest through the Service, or through any other means such as emails, push notifications, text messages (including SMS and MMS), and phone calls. These communications may promote ZNest or businesses listed on ZNest, and may be initiated by ZNest, businesses listed on ZNest, or other users. You further understand that communications may be sent using an automatic telephone dialing system, and that you may be charged by your phone carrier for certain communications such as SMS messages or phone calls. You agree to notify us immediately if the phone number(s) you have provided to us have been changed or disconnected. Please note that any communications, including phone calls, with ZNest or made through the Service may be monitored and recorded for quality purposes.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy4_707422493";
    item['en'] = "5.   CONTENT";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy8_1053052176";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy10_1627305111";
    item['en'] = "Responsibility for Your Content. You alone are responsible for Your Content, and once posted to ZNest, it cannot always be withdrawn. You assume all risks associated with Your Content, including anyone’s reliance on its quality, accuracy, or reliability, and any risks associated with personal information you disclose. You represent that you own or have the necessary permissions to use and authorize the use of Your Content as described herein. You may not imply that Your Content is in any way sponsored or endorsed by ZNest.\nYou may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, service mark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the violation of any law or regulation; or violates these Terms.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy9_1623372315";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy11_1761958862";
    item['en'] = "Our Right to Use Your Content. We may use Your Content in a number of different ways, including by publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms (“Other Media”). As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose. Please note that you also irrevocably grant the users of the Service and any Other Media the right to access Your Content in connection with their use of the Service and any Other Media. Finally, you irrevocably waive, and cause to be waived, against ZNest and its users any claims and assertions of moral rights or attribution with respect to Your Content. By “use” we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy10_1197190253";
    item['en'] = "C.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy12_232259673";
    item['en'] = "Ownership. As between you and ZNest, you own Your Content. We own the ZNest Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation (including, but not limited to, our selection, coordination, aggregation, and arrangement of User Content and other Service Content), computer code, products, software, aggregate star ratings, and all other elements and components of the Service excluding Your Content, User Content and Third Party Content. We also own the copyrights, trademarks, service marks, trade names, trade secrets, and other intellectual and proprietary rights throughout the world associated with the ZNest Content and the Service, which are protected by copyright, trade dress, patent, trademark, and trade secret laws and all other applicable intellectual and proprietary rights and laws. As such, you may not sell, license, copy, publish, modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way use or exploit any of the ZNest Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Service and the ZNest Content are retained by us.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy11_852349706";
    item['en'] = "D.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy13_942697612";
    item['en'] = "Advertising. ZNest and its licensees may publicly display advertisements, paid content, and other information nearby or in association with Your Content. You are not entitled to any compensation for such advertisements. The manner, mode and extent of such advertising are subject to change without specific notice to you.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy14_346228589";
    item['en'] = "Our Right to Use Your Content. We may use Your Content in a number of different ways, including by publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms (“Other Media”). As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose. Please note that you also irrevocably grant the users of the Service and any Other Media the right to access Your Content in connection with their use of the Service and any Other Media. Finally, you irrevocably waive, and cause to be waived, against ZNest and its users any claims and assertions of moral rights or attribution with respect to Your Content. By “use” we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy12_2001079071";
    item['en'] = "E.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy15_939575681";
    item['en'] = "Other. User Content (including any that may have been created by users employed or contracted by ZNest) does not necessarily reflect the opinion of ZNest. Except as required by law, we have no obligation to retain or provide you with copies of Your Content, and we do not guarantee any confidentiality with respect to Your Content. We reserve the right to remove, screen, edit, or reinstate User Content at our sole discretion for any reason or no reason, and without notice to you. For example, we may remove a review if we believe it violates our Content Guidelines. Except in accordance with ZNest’s Verified License program, ZNest does not attempt to verify any licenses a local business or its representatives may have, and consumers should inquire about any such licenses with the business directly. Businesses whose licenses have been verified by ZNest will have a “Verified License” badge displayed on their ZNest business page.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy5_1065168127";
    item['en'] = "6.   BOOKING AND TRANSACTING";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy14_796842151";
    item['en'] = "You can access features through the Service that allow you to book or transact online with local businesses, such as scheduling appointments. These features may be provided by ZNest’s third-party partners, including through iframes or similar formats, and their use may be governed by different or additional terms presented to you as part of the booking or transaction process. Please note that such third-party partners and/or the transacting local businesses themselves are responsible for fulfilling such bookings and transactions.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy6_541022460";
    item['en'] = "7.   REPRESENTATIONS AND WARRANTIES";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy13_961529288";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy16_794475857";
    item['en'] = "You represent and warrant that:";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy14_1520624144";
    item['en'] = "i.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy17_668285729";
    item['en'] = "You have read and understood our Content Guidelines;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy15_1255630003";
    item['en'] = "C.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy18_990932422";
    item['en'] = "Ownership. As between you and ZNest, you own Your Content. We own the ZNest Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation (including, but not limited to, our selection, coordination, aggregation, and arrangement of User Content and other Service Content), computer code, products, software, aggregate star ratings, and all other elements and components of the Service excluding Your Content, User Content and Third Party Content. We also own the copyrights, trademarks, service marks, trade names, trade secrets, and other intellectual and proprietary rights throughout the world associated with the ZNest Content and the Service, which are protected by copyright, trade dress, patent, trademark, and trade secret laws and all other applicable intellectual and proprietary rights and laws. As such, you may not sell, license, copy, publish, modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way use or exploit any of the ZNest Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Service and the ZNest Content are retained by us.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy19_11814747";
    item['en'] = "We are under no obligation to enforce the Terms on your behalf against another user. While we encourage you to let us know if you believe another user has violated the Terms, we reserve the right to investigate and take appropriate action at our sole discretion.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy16_503863502";
    item['en'] = "ii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy20_1286926674";
    item['en'] = "You have read and understood our Privacy Policy. If you use the Service outside of the United States of America, you consent to having your personal data transferred to and processed in the United States of America; and";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy17_1010205596";
    item['en'] = "iii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy21_1213543356";
    item['en'] = "Prior to attending any event listed on the Service, you have read and agree to our Event Terms and Conditions.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy15_1348015458";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy18_1061598902";
    item['en'] = "You also represent and warrant that you will not, and will not assist, encourage, or enable others to use the Service to:";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy18_908797436";
    item['en'] = "i.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy22_1754779587";
    item['en'] = "Violate our Terms, including the Content Guidelines and Event Terms and Conditions;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy19_1436060089";
    item['en'] = "ii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy23_1408367926";
    item['en'] = "Post any fake or defamatory review, trade reviews with others, or compensate someone or be compensated to post, refrain from posting, or remove a review;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy20_1526021553";
    item['en'] = "iii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy24_1930093876";
    item['en'] = "Violate any third party’s rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy21_1558294135";
    item['en'] = "iv.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy25_1293759503";
    item['en'] = "Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy22_186328439";
    item['en'] = "v.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy26_885879607";
    item['en'] = "Promote a business or other commercial venture or event, or otherwise use the Service for commercial purposes, except in connection with a Business Account in accordance with the Business Terms;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy23_2009110917";
    item['en'] = "vi.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy27_986440932";
    item['en'] = "Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage in keyword spamming, or otherwise attempt to manipulate the Service’s search results, review Recommendation Software (as defined in the Business Terms below), or any third party website;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy24_1073114745";
    item['en'] = "vii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy28_1533742328";
    item['en'] = "Solicit personal information from minors, or submit or transmit pornography;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy25_867977980";
    item['en'] = "viii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy29_1911853857";
    item['en'] = "Violate any applicable law;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy26_2077732829";
    item['en'] = "ix.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy30_1196233517";
    item['en'] = "Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the Service or Service Content (other than Your Content), except as expressly authorized by ZNest;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy27_266488433";
    item['en'] = "x.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy31_839792597";
    item['en'] = "Use any robot, spider, Service search/retrieval application, or other automated device, process or means to access, retrieve, copy, scrape, or index any portion of the Service or any Service Content, except as expressly permitted by ZNest;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy28_171381492";
    item['en'] = "xi.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy32_38922121";
    item['en'] = "Reverse engineer any portion of the Service, unless applicable law prohibits this restriction, in which case you agree to provide us with 30 days’ prior written notice here;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy29_1048076847";
    item['en'] = "xii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy33_630342039";
    item['en'] = "Remove or modify any copyright, trademark, or other proprietary rights notice that appears on any portion of the Service or on any materials printed or copied from the Service;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy34_803426562";
    item['en'] = "Violate any third party’s rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy30_1796276276";
    item['en'] = "xiii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy35_1562278770";
    item['en'] = "Record, process, or mine information about users;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy31_964031859";
    item['en'] = "xiv.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy36_559259150";
    item['en'] = "Access, retrieve or index any portion of the Service for purposes of constructing or populating a searchable database of business reviews;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy32_1524956799";
    item['en'] = "xv.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy37_445144725";
    item['en'] = "Reformat or frame any portion of the Service;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy33_839867133";
    item['en'] = "xvi.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy38_1204205877";
    item['en'] = "Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on ZNest’s technology infrastructure or otherwise make excessive traffic demands of the Service;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy34_16161294";
    item['en'] = "xvii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy39_194015476";
    item['en'] = "Attempt to gain unauthorized access to the Service, Accounts, computer systems or networks connected to the Service through hacking, password mining or any other means;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy35_488778400";
    item['en'] = "xviii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy40_1059786905";
    item['en'] = "Use the Service or any Service Content to transmit any computer viruses, worms, defects, Trojan horses, malicious code, spyware, malware or other items of a destructive or harmful nature;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy36_1355654760";
    item['en'] = "xix.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy41_1081295165";
    item['en'] = "Use any device, software or routine that interferes with the proper working of the Service, or otherwise attempt to interfere with the proper working of the Service;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy37_407714608";
    item['en'] = "xx.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy42_1834743982";
    item['en'] = "Use the Service to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the Service or Service Content; or";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy38_1571844368";
    item['en'] = "xxi.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy43_799756865";
    item['en'] = "Remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Service, features that prevent or restrict the use or copying of Service Content, or features that enforce limitations on the use of the Service.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy7_480403577";
    item['en'] = "8";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy39_631985549";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy7_100801605";
    item['en'] = "8.   ADDITIONAL POLICIES AND TERMS";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy39_471634964";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy34_569805374";
    item['en'] = "Copyright and Trademark Disputes. You agree to follow our Infringement Policy in notifying us about copyright and trademark disputes concerning User Content. You agree we may forward any notification sent pursuant to our Infringement Policy to the user who submitted the User Content at issue.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy40_844168336";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy44_1136793438";
    item['en'] = "Additional Terms. Your use of the Service is subject to any and all additional terms, policies, rules, or guidelines that we may post on or link to from the Service (the “Additional Terms”). All such Additional Terms are hereby incorporated by reference into, and made a part of, these Terms. If you have a Business Account, the Business Terms provided below apply to you.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy8_1230861876";
    item['en'] = "9.   SUGGESTIONS AND IMPROVEMENTS";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy45_1204680474";
    item['en'] = "By sending us any ideas, suggestions, documents or proposals (“Feedback”), you agree that (i) your Feedback does not contain any third party confidential or proprietary information, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, (iv) we have no obligation to review, consider, or implement the Feedback, or to return to you all or part of the Feedback, and (v) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide, assignable, sublicensable, transferable license to use, modify, prepare derivative works of, publish, distribute and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against ZNest and its users any claims and assertions of any moral rights contained in such Feedback.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy9_1709600444";
    item['en'] = "10. THIRD PARTY CONTENT AND SERVICES";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy46_1749791884";
    item['en'] = "The Service may host Third Party Content, or include links to other websites or applications (each, a “Third Party Service”). We do not control or endorse any Third Party Content or Third Party Service. You agree that we are not responsible for the availability, accuracy, or content of any such Third Party Content or Third Party Service. Your use of and reliance on any Third Party Content or Third Party Service is at your own risk.  Some of the services made available through the Service and Third Party Services may be subject to additional third party terms of service, privacy policies, licensing terms and disclosures, and other terms, conditions, and policies, including without limitation the ones posted here. It is your responsibility to familiarize yourself with any such applicable third party terms.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy41_107964022";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy10_1795237575";
    item['en'] = "11. INDEMNITY";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy47_1342749792";
    item['en'] = "You agree to indemnify, defend, and hold harmless ZNest, its parents, subsidiaries, affiliates, any related companies, suppliers, licensors and partners, and the officers, directors, employees, agents, contractors and representatives of each of them (collectively, the “ZNest Entities”) from and against any and all third party claims, actions, demands, losses, damages, costs, liabilities and expenses (including but not limited to attorneys’ fees and court costs)  arising out of or relating to: (i) your access to or use of the Service, including Your Content, (ii) your violation of the Terms, (iii) your breach of your representations and warranties provided under these Terms, (iv) any products or services purchased or obtained by you in connection with the Service, (v) your products or services, or the marketing or provision thereof to end users, or (vi) the infringement by you, or any third party using your Account, of any intellectual property or other right of any person or entity. ZNest reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any such matter without the prior written consent of ZNest. ZNest will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy11_286007587";
    item['en'] = "12. DISCLAIMERS AND LIMITATIONS OF LIABILITY";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy48_191532335";
    item['en'] = "PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE ZNEST ENTITIES TO YOU. EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. BY ACCESSING OR USING THE SERVICE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy42_1683670625";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy49_1382704207";
    item['en'] = "THE SERVICE AND SERVICE CONTENT ARE MADE AVAILABLE TO YOU ON AN “AS IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE ZNEST ENTITIES MAY NOT MONITOR, CONTROL, OR VET USER CONTENT OR THIRD PARTY CONTENT. AS SUCH, YOUR USE OF THE SERVICE IS AT YOUR OWN DISCRETION AND RISK. THE ZNEST ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, COMPLETENESS, ACCURACY, OR RELIABILITY OF THE SERVICE, ITS SAFETY OR SECURITY, INCLUDING WITHOUT LIMITATION THE SECURITY OF YOUR DATA, OR THE SERVICE CONTENT. ACCORDINGLY, THE ZNEST ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE SERVICE’S INOPERABILITY, DEPLETION OF BATTERY POWER OR OTHER IMPAIRMENT OF DEVICES USED TO ACCESS THE SERVICE, SERVICE UNAVAILABILITY, SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF THE BUSINESS LISTINGS, RATINGS, REVIEWS (INCLUDING THEIR CONTENT OR OMISSION OF CONTENT, ORDER, AND DISPLAY), METRICS OR OTHER CONTENT FOUND ON, USED ON, OR MADE AVAILABLE THROUGH THE SERVICE.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy43_294703302";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy50_2013505339";
    item['en'] = "THE ZNEST ENTITIES MAKE NO CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE BUSINESSES OR ADVERTISERS LISTED ON THE SERVICE OR THAT OFFER GOODS OR SERVICES THROUGH THE SERVICE, OR THE SERVICE’S USERS. ACCORDINGLY, THE ZNEST ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE FROM ANY SUCH THIRD PARTY’S ACTIONS OR OMISSIONS, INCLUDING, FOR EXAMPLE, IF ANOTHER USER OR BUSINESS MISUSES YOUR CONTENT, IDENTITY OR PERSONAL INFORMATION, OR IF YOU HAVE A NEGATIVE EXPERIENCE WITH ONE OF THE BUSINESSES OR ADVERTISERS LISTED OR FEATURED ON THE SERVICE. YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES THROUGH THE SERVICE IS AT YOUR OWN DISCRETION AND RISK.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy44_1243660455";
    item['en'] = "C.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy51_22856972";
    item['en'] = "YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SERVICE";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy45_1882374068";
    item['en'] = "D.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy52_1863927909";
    item['en'] = "THE ZNEST ENTITIES’ MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SERVICE OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT PAID, IF ANY, BY YOU TO THE ZNEST ENTITIES IN CONNECTION WITH THE SERVICE IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy46_575611583";
    item['en'] = "E.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy53_382933765";
    item['en'] = "THE ZNEST ENTITIES’ LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ZNEST ENTITIES WILL NOT BE LIABLE FOR ANY (i) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS OR REVENUE, (iii) BUSINESS INTERRUPTION, (iv) REPUTATIONAL HARM, (v) LOSS OF INFORMATION OR DATA; OR (vi) LIABILITY WITH RESPECT TO A CONSUMER ALERT POSTED ON ANY ZNEST BUSINESS PAGES FOR YOUR BUSINESS. THE WAIVERS AND LIMITATIONS SPECIFIED IN THIS SECTION 12 WILL SURVIVE AND APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy12_1139836035";
    item['en'] = "13.   \tARBITRATION, DISPUTES, AND CHOICE OF LAW";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy47_1713364739";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy54_1817349165";
    item['en'] = "If you are a resident of the United States or Canada:";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy55_912635824";
    item['en'] = "EXCEPT FOR EXCLUDED CLAIMS: ANY CLAIM, CAUSE OF ACTION, REQUEST FOR RELIEF OR DISPUTE THAT MIGHT ARISE BETWEEN YOU AND ZNEST (“CLAIMS”) MUST BE RESOLVED BY ARBITRATION ON AN INDIVIDUAL BASIS; YOU AND WE AGREE THAT EACH MAY BRING OR PARTICIPATE IN CLAIMS AGAINST THE OTHER ONLY IN OUR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND ZNEST AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. YOU AND ZNEST EXPRESSLY WAIVE THE RIGHT TO TRIAL BY A JURY.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy56_70430599";
    item['en'] = "“Excluded Claims” means: (a) Claims brought by you or ZNest that could be brought in small claims court, if permitted by the rules of that court, or (b) Claims related to intellectual property (like copyrights and trademarks), violations of ZNest’s API Terms of Use (which, for clarity, are governed by those terms), or a breach of Section 7 above (Representations and Warranties). Excluded Claims may be brought in court. Any issues relating to the scope and enforceability of the arbitration provision will be resolved by the arbitrator. If any Claim cannot be arbitrated in accordance with this provision, then only that Claim may be brought in court and all other Claims remain subject to arbitration. Notwithstanding this paragraph, Business Claims, as defined in Section 3 of the Additional Terms for Business Accounts, are governed by that section.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy57_2040467810";
    item['en'] = "Excluded Claims and Claims that cannot be arbitrated must be brought in court. California law will govern these Terms (to the extent not preempted or inconsistent with federal law), as well as any such Excluded Claim or Claim that cannot be arbitrated, without regard to conflict of law provisions. You or ZNest may seek relief in any small claims court of competent jurisdiction. All other Excluded Claims and Claims that cannot be arbitrated are subject to the exclusive jurisdiction in, and the exclusive venue of, the state and federal courts located within Los Angeles County, California and you consent to the personal jurisdiction of these courts for the purpose of litigating any such Claim.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy48_1201836085";
    item['en'] = "i.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy49_519383166";
    item['en'] = "ii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy50_1776499091";
    item['en'] = "iii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy51_879165706";
    item['en'] = "iv.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy52_804028926";
    item['en'] = "v.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy53_201279424";
    item['en'] = "iii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy54_116067942";
    item['en'] = "iii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy58_2134772828";
    item['en'] = "Arbitration shall be administered by the American Arbitration Association (“AAA”) in accordance with its Consumer Arbitration Rules then in effect. For more information, visit www.adr.org. Arbitration may be conducted in person, through the submission of documents, by phone or online. The arbitrator may award damages to you individually as a court could, including declaratory or injunctive relief, but only to the extent required to satisfy your individual claim.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy59_898774556";
    item['en'] = "Unless the arbitrator finds the arbitration was frivolous or brought for an improper purpose, ZNest will pay all filing, AAA, and arbitrator’s fees and expenses. We waive any right to seek an award of attorneys’ fees and expenses in connection with any non-frivolous arbitration between you and us.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy13_1354080499";
    item['en'] = "14.   \tTERMINATION";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy53_2120802643";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy60_472243876";
    item['en'] = "You may terminate the Terms at any time by closing your Account, discontinuing any access to or use of the Service, and providing ZNest with a notice of termination here.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy54_1903526558";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy61_236888566";
    item['en'] = "We may close your Account, suspend your ability to use certain portions of the Service, terminate any license or permission granted to you hereunder, and/or ban you altogether from the Service for any or no reason, and without notice or liability of any kind. Any such action could prevent you from accessing your Account, the Service, Your Content, Service Content, or any other related information.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy55_1724820555";
    item['en'] = "C.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy62_1283813520";
    item['en'] = "In the event of any termination of these Terms, whether by you or us, Sections 1, 3,  5, 7–15 of the Terms of Service will continue in full force and effect.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy14_1713083211";
    item['en'] = "15.   \tGENERAL TERMS";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy56_2041425867";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy63_1610708352";
    item['en'] = "We reserve the right to modify, update, or discontinue the Service at our sole discretion, at any time, for any or no reason, and without notice or liability.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy57_1827986445";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy64_379198930";
    item['en'] = "Except as otherwise stated in Section 10 above, nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy58_378513513";
    item['en'] = "C.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy65_2117628239";
    item['en'] = "The Terms contain the entire agreement between you and us regarding the use of the Service, and supersede any prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy59_1974699222";
    item['en'] = "D.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy66_1759991249";
    item['en'] = "Any failure on ZNest’s part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. The Terms may not be waived, except pursuant to a writing executed by ZNest.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy60_1169490828";
    item['en'] = "E.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy67_1790546719";
    item['en'] = "If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of competent jurisdiction, then only that provision shall be modified to reflect the parties’ intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy61_307580437";
    item['en'] = "F.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy68_1850211421";
    item['en'] = "The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with ZNest’s prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy62_136260037";
    item['en'] = "G.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy69_480959379";
    item['en'] = "You agree that no joint venture, partnership, employment, agency, special or fiduciary relationship exists between you and ZNest as a result of these Terms or your use of the Service.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy63_2126938509";
    item['en'] = "H.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy70_74452769";
    item['en'] = "The section titles in the Terms are for convenience only and have no legal or contractual effect.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy64_1634254351";
    item['en'] = "E.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy71_953561231";
    item['en'] = "If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of competent jurisdiction, then only that provision shall be modified to reflect the parties’ intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tctextcopy_1807029599";
    item['en'] = "ADDITIONAL TERMS FOR BUSINESS ACCOUNTS";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tctext2copy_642442443";
    item['en'] = "The following terms (“Business Terms”), in addition to the Terms of Service above, govern your access to and use of your Business Account. In the event of any conflict between these Business Terms and the Terms of Service, the Business Terms apply. If you have purchased products or services from ZNest on behalf of your business (e.g., advertising or business tools), the terms of that purchase apply in the event of any conflict with these Business Terms. Capitalized words used but not defined in these Business Terms have the meanings described in the Terms of Service. By creating, accessing, or using your Business Account, you are agreeing to these Business Terms and concluding a legally binding contract with ZNest. You are not authorized to create, access, or use a Business Account if you do not agree to these Business Terms.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tctext3copy_204707534";
    item['en'] = "PLEASE READ THESE BUSINESS TERMS CAREFULLY AS THEY REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy15_796237548";
    item['en'] = "1.   \tREQUIREMENTS, REPRESENTATIONS AND WARRANTIES";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy64_149581804";
    item['en'] = "A.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy71_1517823848";
    item['en'] = "In order to access or use the Services, you agree that:";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tctext2copy2_1695691182";
    item['en'] = "In the event of any termination of these Business Terms, whether by you or us, these Business Terms in their entirety will continue in full force and effect.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy65_474531120";
    item['en'] = "i.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy72_1741085942";
    item['en'] = "you have the authority to act on behalf of the business or businesses associated with or claimed through your Business Account and bind any such business (including any corresponding business entity) to the Business Terms (such business or businesses, your “Business”);";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy66_748723539";
    item['en'] = "ii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy73_501951164";
    item['en'] = "your access to or use of the Business Site will only be in your capacity as an authorized representative of your Business;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy67_427548862";
    item['en'] = "iii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy74_550613314";
    item['en'] = "you will not use the Consumer Site for business activities, including but not limited to flagging reviews or messaging people who have reviewed your Business;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy68_1243847271";
    item['en'] = "iv.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy75_1665181000";
    item['en'] = "your Business complies with applicable laws and does not offer, advertise, sell, or lease illegal products and/or services;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy69_110807117";
    item['en'] = "v.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy76_151960320";
    item['en'] = "you grant ZNest a non-transferable, non-exclusive, royalty-free limited license to display your public website on the Services, or allow for its display through iframes or other framing technology;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy70_1751772465";
    item['en'] = "vi.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy77_692581847";
    item['en'] = "you agree that we may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we have on record for your business, and that our communications (including phone calls) with you may be monitored and recorded for quality purposes;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy71_1046172035";
    item['en'] = "vii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy78_1418272569";
    item['en'] = "you understand that we may display ratings and/or information based on health and safety inspections (“Ratings”) for your Business, and may place a Consumer Alert regarding those Ratings, on the business page for your Business; and";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy72_1357569945";
    item['en'] = "viii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy79_2130159057";
    item['en'] = "you understand and acknowledge that non-disparagement clauses in certain consumer contracts, such as clauses that seek to restrict or prohibit reviews (including provisions that penalize consumers for posting reviews) about your Business, are prohibited under California law (Cal. Civil Code § 1670.8) and under the federal Consumer Review Fairness Act (15 U.S. Code § 45b) and you agree that you will not include such clauses in your consumer contracts, or otherwise attempt to enforce non-disparagement or ‘gag’ clauses against consumers under any circumstances. You understand that we may publicly notify consumers, including by placing a Consumer Alert on the business page for your Business, if we have a good faith belief that such clauses are used by your Business.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy73_1497989369";
    item['en'] = "B.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy80_931660286";
    item['en'] = "You represent and warrant that you will not, and will not authorize or induce any other party, to:";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy74_1401268592";
    item['en'] = "i.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy81_655032829";
    item['en'] = "offer incentives of any kind, such as discounts, freebies, refunds, gift cards, contest entries, offers, or deals in exchange for the posting of reviews of your Business, or to prevent or remove reviews, and you understand and acknowledge that ZNest, through its Consumer Alerts, may publicly notify consumers about such incentives and other attempts to obtain, prevent, or remove reviews;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy75_220453881";
    item['en'] = "ii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy82_1428147373";
    item['en'] = "solicit or ask for reviews from your customers;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy76_1557970187";
    item['en'] = "iii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy83_1224340048";
    item['en'] = "write reviews or vote on Content (e.g., voting user reviews as useful, funny, or cool) for your Business or your Business’s competitors;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy77_53237349";
    item['en'] = "iv.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy84_2127244152";
    item['en'] = "pay or induce anyone to post, refrain from posting, or remove reviews, or otherwise attempt to circumvent fraud detection systems;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy78_1980204852";
    item['en'] = "v.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy85_1966464136";
    item['en'] = "attempt to generate automated, fraudulent, or otherwise invalid ad impressions, inquiries, conversions, ad clicks, or other actions;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy79_372115295";
    item['en'] = "vi.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy86_701030099";
    item['en'] = "use any automated means or form of scraping or data extraction to access, query or otherwise collect ZNest data, content and/or reviews from the Consumer Site or the Business Site, except as expressly permitted by ZNest;";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy80_1721258320";
    item['en'] = "vii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy87_1082721701";
    item['en'] = "use any ZNest trademark or service mark in any manner without ZNest’s prior written consent; or";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy81_2133641314";
    item['en'] = "viii.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy88_1598577453";
    item['en'] = "misrepresent your identity or affiliation to anyone in connection with ZNest.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbulletnumberingcopy82_368127994";
    item['en'] = "C.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy89_784363663";
    item['en'] = "You understand and acknowledge that ZNest allows consumers to post Content about your Business, including photos, ratings, and reviews. ";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcbullettextcopy90_1175281546";
    item['en'] = "The following Sections 2 and 3 apply if you are a resident of the United States or Canada only:";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy16_751744887";
    item['en'] = "2.   \tDISCLAIMERS AND LIMITATIONS OF LIABILITY";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy17_2035904265";
    item['en'] = "PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE ZNEST ENTITIES TO YOU. FOR CLARITY, THE BELOW APPLIES IN ADDITION TO THE DISCLAIMERS AND LIMITATIONS OF LIABILITY DETAILED IN SECTION 12 OF THE TERMS.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy18_1974454851";
    item['en'] = "The Federal Communications Decency Act (47 U.S. Code § 230) limits the liability of interactive computer services, like ZNest, for their role in publishing third-party Content, including consumer reviews. Additionally, anti-SLAPP laws, such as Cal. Civ. Proc. Code  § 425.16 in California, may require you to pay ZNest’s attorneys’ fees if you attempt to impose such liability on ZNest through legal proceedings";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy19_1302299013";
    item['en'] = "3.  ARBITRATION, DISPUTES, AND CHOICE OF LAW";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy20_859555573";
    item['en'] = "FOR CLARITY, THIS SECTION GOVERNS ANY BUSINESS CLAIM BROUGHT BY YOU OR ZNEST. ANY CLAIM NOT SUBJECT TO THIS SECTION IS INSTEAD GOVERNED BY SECTION 13 OF THE TERMS.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy21_950930906";
    item['en'] = "Except for Excluded Business Claims, any controversy or claim arising out of or relating to: (a) these Terms, or the breach thereof; or (b) your access to or use of your Business Account Business Site; (each such controversy or claim, a “Business Claim”), shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The arbitrator will issue a reasoned award in writing, including all findings of fact and law upon which the award was made. The arbitrator will not have the power to commit errors of law, and the award may be vacated or corrected through judicial review by a court of competent jurisdiction under the California Arbitration Act for any such error. “Excluded Business Claims” means Claims related to intellectual property (like copyrights and trademarks) or violations of Section 7 of the Terms of Service (Representations and Warranties).\nBusiness Claims shall be heard by a single arbitrator. Arbitrations will be held in San Francisco, California, but the parties may choose for themselves whether to appear in person, by phone, or through the submission of documents. The arbitration shall be governed by the laws of the State of California. The prevailing party shall be entitled to an award of reasonable attorneys’ fees.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy22_1493254838";
    item['en'] = "NOTWITHSTANDING THE FOREGOING, FOR ANY BUSINESS CLAIM THAT IS NOT SUBJECT TO ARBITRATION, INCLUDING WITHOUT LIMITATION EXCLUDED BUSINESS CLAIMS, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE STATE AND FEDERAL COURTS LOCATED WITHIN SAN FRANCISCO COUNTY, CALIFORNIA, WHICH IS THE PLACE OF PERFORMANCE OF THESE BUSINESS TERMS.";

    item = {};
    this.items.push(item);
    item['key'] = "tc_tcsmallheadingtextcopy23_1591258957";
    item['en'] = "YOU AND ZNEST AGREE THAT EACH MAY BRING OR PARTICIPATE IN BUSINESS CLAIMS AGAINST THE OTHER ONLY IN THEIR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND ZNEST AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE BUSINESS CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. IF A BUSINESS CLAIM IMPLICATES THIS SECTION, AND THIS SECTION IS FOUND TO BE INVALID, UNENFORCEABLE OR ILLEGAL BY A COURT, SUCH BUSINESS CLAIM MUST BE ADJUDICATED BY A COURT AND NOT BY AN ARBITRATOR.";

    item = {};
    this.items.push(item);
    item['key'] = "login_text2_1911448798";
    item['en'] = "By continuing, you agree to ZNest’s";

    item = {};
    this.items.push(item);
    item['key'] = "login_textcopy_2107109336";
    item['en'] = "Terms and Conditions";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textsubcopy_1634177454";
    item['en'] = "No community listed with that zip code.Add your email address to the wait list.";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_zipcodeexplorecopy_591310265";
    item['en'] = "Email";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_validator_666536732";
    item['en'] = "*Please enter valid email format ";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_searchbuttoncopy_1042368021";
    item['en'] = "Submit";

    item = {};
    this.items.push(item);
    item['key'] = "loginmain_textnozipmatchcopy_1760944820";
    item['en'] = "Thank you.\n\nYou will be notified when communities are added to this zip code.";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_validator_1277438374";
    item['en'] = " ";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_validator_2113390899";
    item['en'] = "*Please fill in the required information";

    item = {};
    this.items.push(item);
    item['key'] = "updateinfo_buttoncopy_1390244516";
    item['en'] = "Back";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_buttonback_740698673";
    item['en'] = "Back to List";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails2_buttonbackcopy_186847694";
    item['en'] = "Back to List";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_buttonbacktop_418200932";
    item['en'] = "Back to List";

    item = {};
    this.items.push(item);
    item['key'] = "communitydetails_buttonbackbottom_1614809219";
    item['en'] = "Back to List";

    item = {};
    this.items.push(item);
    item['key'] = "uploadphotos_text_642222384";
    item['en'] = "File uploaded";

    item = {};
    this.items.push(item);
    item['key'] = "login_buttonaboutus_1855618708";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "login_buttonlearnmore_1647574338";
    item['en'] = "learn more";

    item = {};
    this.items.push(item);
    item['key'] = "login2_buttonlearnmore_1566534553";
    item['en'] = "Learn more";

    item = {};
    this.items.push(item);
    item['key'] = "login2_buttonaboutus_1036196821";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "login2_text_89152951";
    item['en'] = "Login or Sign Up to Continue";

    item = {};
    this.items.push(item);
    item['key'] = "login2_text2_2075923915";
    item['en'] = "By continuing, you agree to ZNest’s";

    item = {};
    this.items.push(item);
    item['key'] = "login2_textcopy_1950816403";
    item['en'] = "Terms and Conditions";

    item = {};
    this.items.push(item);
    item['key'] = "login_textcopy2_1705428796";
    item['en'] = "You will receive a verification email if sign up via email";

    item = {};
    this.items.push(item);
    item['key'] = "login_textcopy3_358295498";
    item['en'] = "Once you click on the verification email, refresh the web browser if this page does not proceed automatically";

    item = {};
    this.items.push(item);
    item['key'] = "login2_buttonlearnmore_1790798294";
    item['en'] = "Learn more";

    item = {};
    this.items.push(item);
    item['key'] = "login2_buttonaboutus_30958814";
    item['en'] = "About Us";

    item = {};
    this.items.push(item);
    item['key'] = "login2_text_1421738059";
    item['en'] = "Login or Sign Up to Continue";

    item = {};
    this.items.push(item);
    item['key'] = "login2_textcopy2_1843080309";
    item['en'] = "You will receive a verification email if sign up via email";

    item = {};
    this.items.push(item);
    item['key'] = "login2_textcopy3_1554227844";
    item['en'] = "Once you click on the verification email, refresh the web browser if this page does not proceed automatically";

    item = {};
    this.items.push(item);
    item['key'] = "login2_text2_1522580744";
    item['en'] = "By continuing, you agree to ZNest’s";

    item = {};
    this.items.push(item);
    item['key'] = "login2_textcopy_1127682195";
    item['en'] = "Terms and Conditions";

    item = {};
    this.items.push(item);
    item['key'] = "home_textsubcopy_248025168";
    item['en'] = "You are already logged in";

    item = {};
    this.items.push(item);
    item['key'] = "home_text_645130118";
    item['en'] = "This site is currently only available in Hawaii. Subscribe to our email and you will be notified when we roll out to other states.";

    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);

    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);

    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);

    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
