import React, { Component,useRef } from 'react';
import './App.css';
// import UploadPhotos from './UploadPhotos';
import Florida from './images/florida.jpeg';
import img_elImageIL from './images/CommunityDetails2Screen_elImageIL_828629221.png';
import img_elImageAL from './images/CommunityDetails2Screen_elImageAL_1199380299.png';
import img_elImageMC from './images/CommunityDetails2Screen_elImageMC_1946152380.png';
import img_elImageSN from './images/CommunityDetails2Screen_elImageSN_102632794.png';
import img_elCommunityCoverImage from './images/CommunityDetails2Screen_elCommunityCoverImage_720907.jpg';
import img_elImageILCopy from './images/CommunityDetails2Screen_elImageIL_828629221.png';
import img_elIndeLivingStudioFeeUserPic from './images/CommunityDetails2Screen_elIndeLivingStudioFeeUserPic_865980.jpg';
import img_elImageALCopy from './images/CommunityDetails2Screen_elImageAL_1199380299.png';
import img_elAssistedLivingStudioFeeUserPic from './images/CommunityDetails2Screen_elAssistedLivingStudioFeeUserPic_621094502.jpg';
import img_elAssistedLiving1bdrmFeeUserPic from './images/CommunityDetails2Screen_elAssistedLivingStudioFeeUserPic_621094502.jpg';
import img_elAssistedLiving2bdrmFeeUserPic from './images/CommunityDetails2Screen_elAssistedLivingStudioFeeUserPic_621094502.jpg';
import img_elAssistedLiving2bdrmSharedFeeUserPic from './images/CommunityDetails2Screen_elAssistedLivingStudioFeeUserPic_621094502.jpg';
import img_elAssistedLiving2ndOccFeeUserPic from './images/CommunityDetails2Screen_elAssistedLivingStudioFeeUserPic_621094502.jpg';
import img_elAssistedLivingCareFeeUserPic from './images/CommunityDetails2Screen_elAssistedLivingStudioFeeUserPic_621094502.jpg';
import img_elImageMCCopy from './images/CommunityDetails2Screen_elImageMC_1946152380.png';
import img_elMemoryCareStudioFeeUserPic from './images/CommunityDetails2Screen_elAssistedLivingStudioFeeUserPic_621094502.jpg';
import img_elMemoryCare1bdrmFeeUserPic from './images/CommunityDetails2Screen_elMemoryCare1bdrmFeeUserPic_1062742123.jpg';
import img_elMemoryCare2bdrmFeeUserPic from './images/CommunityDetails2Screen_elMemoryCare1bdrmFeeUserPic_1062742123.jpg';
import img_elMemoryCare2bdrmSharedFeeUserPic from './images/CommunityDetails2Screen_elMemoryCare1bdrmFeeUserPic_1062742123.jpg';
import img_elMemoryCare2ndOccFeeUserPic from './images/CommunityDetails2Screen_elMemoryCare1bdrmFeeUserPic_1062742123.jpg';
import img_elMemoryCareCareFeeUserPic from './images/CommunityDetails2Screen_elMemoryCare1bdrmFeeUserPic_1062742123.jpg';
import img_elImageSNCopy from './images/CommunityDetails2Screen_elImageSN_102632794.png';
import img_elSkilledNursingPrivateFeeUserPic from './images/CommunityDetails2Screen_elSkilledNursingPrivateFeeUserPic_1718355198.jpg';
import img_elSkilledNursingSemiFeeUserPic from './images/CommunityDetails2Screen_elSkilledNursingPrivateFeeUserPic_1718355198.jpg';
import ScrollingPhotos from './ScrollingPhotos';
import btn_icon_1309316487 from './images/btn_icon_1309316487.png';
import img_elTwittercircle from './images/CommunityDetails2Screen_elTwittercircle.png';
import img_elTwitter from './images/UpdateInfoScreen_elTwitter_1312850244.png';
import img_elInstagramcircle from './images/CommunityDetails2Screen_elInstagramcircle.png';
import img_elIg from './images/UpdateInfoScreen_elIg_98352411.png';
import img_elFacebookcircle from './images/CommunityDetails2Screen_elFacebookcircle.png';
import img_elFb from './images/UpdateInfoScreen_elFb_660566527.png';
import img_elLogo from './images/UpdateInfoScreen_elLogo_1476464072.png';
import img_elLogoznesthorizwhite from './images/CommunityDetails2Screen_elLogoznesthorizwhite.png';
import img_elBeta from './images/CommunityDetails2Screen_elBeta.png';
// UI framework component imports
// import Button from 'muicss/lib/react/button';

// Bootstrap framework component imports

import { Container,Row,Col,Table,Button,ListGroup,Overlay,Tooltip,OverlayTrigger,Popover } from 'react-bootstrap';
import Header from './includes/header';
import { FaSearch,FaCamera } from 'react-icons/fa';
import {Tabs, Tab} from 'react-bootstrap-tabs';

export default class CommunityDetailScreen extends Component {
  // const [show, setShow] = useState(false);
  // target = useRef(null);
  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, ilBeds, indeLiving1bdrmAvg, reviewerProfilePicUrl, reviewerName, reviewDate, indeLivingStudioAvg, indeLiving2bdrmAvg, photoURL, imageFilePath, mcBeds, snBeds, communityCity

  constructor(props) {
    super(props);

    this.state = {
      communityname: (<div> </div>),
      communityname_plainText: " ",
      elButtonAddPhotos_visible: true,
      elCompPhotoUpload_visible: false,
      communityaddress: (<div> </div>),
      communityaddress_plainText: " ",
      communitycity: "Lake Worth, Florida 33447",
      communitycity_plainText: " ",
      communityphone: (<div> </div>),
      communityphone_plainText: " ",
      textbasicinfo: (<div>Basic Information</div>),
      textbasicinfo_plainText: "Basic Information",
      editbutton: (<div>Edit</div>),
      editbutton_plainText: "Edit",
      textlicensetypes: (<div>Type of Licenses<br /><br /><br />Independent Living<br /><br />Assisted Living<br /><br />Memory Care<br /><br />Skilled Nursing</div>),
      textlicensetypes_plainText: "Type of Licenses\n\n\nIndependent Living\n\nAssisted Living\n\nMemory Care\n\nSkilled Nursing",
      textlicensedbeds: (<div>Licensed Beds</div>),
      textlicensedbeds_plainText: "Licensed Beds",
      indelivingbedcount: (<div> </div>),
      indelivingbedcount_plainText: " ",
      assistedlivingbedcount: (<div> </div>),
      assistedlivingbedcount_plainText: " ",
      memorycarebedcount: (<div> </div>),
      memorycarebedcount_plainText: " ",
      skillednursingbedcount: (<div> </div>),
      skillednursingbedcount_plainText: " ",
      otherbedcount: (<div>0</div>),
      otherbedcount_plainText: "0",
      texttotalbed: (<div>Total</div>),
      texttotalbed_plainText: "Total",
      totalbedcount: (<div>9</div>),
      totalbedcount_plainText: "9",
      textpricing: (<div>Pricing</div>),
      textpricing_plainText: "Pricing",
      // textclickinstruction: (<div>Click on the price to report an updated price</div>),
      // textclickinstruction_plainText: "Click on the price to report an updated price",
      textclickinstruction: (<div>All pricing are monthly rates unless otherwise stated. Click on the price to report an updated price</div>),
      textclickinstruction_plainText: "All pricing are monthly rates unless otherwise stated. Click on the price to report an updated price",
      textpricingheading1: (<div>Description:</div>),
      textpricingheading1_plainText: "Description:",
      textpricingheading2: (<div>Median Price:</div>),
      textpricingheading2_plainText: "Median Price:",
      textpricingheading3: (<div>Price reported by:</div>),
      textpricingheading3_plainText: "Price reported by:",
      communityFeeEditable: '',
      textindeliving: (<div>Independent Living</div>),
      textindeliving_plainText: "Independent Living",
      textindelivingcommfee: (<div>Community Fee (One-Time)</div>),
      textindelivingcommfee_plainText: "Community Fee (One-Time)",
      indelivingcommfee: (<div> </div>),
      indelivingcommfee_plainText: " ",
      indelivingcommfeeuser: (<div> </div>),
      indelivingcommfeeuser_plainText: " ",
      indelivingcommfeedate: (<div> </div>),
      indelivingcommfeedate_plainText: " ",
      elIndeLivingCommFeeEditable_visible: false,
      indeLivingCommFeeEditable: '',
      elIndeLivingCommFeeSubmit_visible: false,
      textindelivingstudio: (<div>Studio</div>),
      textindelivingstudio_plainText: "Studio",
      elIndeLivingStudioFee_visible: true,
      indelivingstudiofee: (<div> </div>),
      indelivingstudiofee_plainText: " ",
      elIndeLivingStudioFeeUserPic_visible: true,
      indelivingstudiofeeuser: (<div> </div>),
      indelivingstudiofeeuser_plainText: " ",
      indelivingstudiofeedate: (<div> </div>),
      indelivingstudiofeedate_plainText: " ",
      elIndeLivingStudioFeeEditable_visible: false,
      indeLivingStudioFeeEditable: '',
      elIndeLivingStudioFeeSubmit_visible: false,
      textindeliving1bdrm: (<div>1-Bedroom</div>),
      textindeliving1bdrm_plainText: "1-Bedroom",
      elIndeLiving1bdrmFee_visible: true,
      indeliving1bdrmfee: (<div> </div>),
      indeliving1bdrmfee_plainText: " ",
      elIndeLiving1bdrmUserPic_visible: true,
      indeliving1bdrmuser: (<div> </div>),
      indeliving1bdrmuser_plainText: " ",
      indeliving1bdrmdate: (<div> </div>),
      indeliving1bdrmdate_plainText: " ",
      elIndeLiving1bdrmFeeEditable_visible: false,
      indeLiving1bdrmFeeEditable: '',
      elIndeLiving1bdrmFeeSubmit_visible: false,
      textindeliving2bdrm: (<div>2-Bedroom</div>),
      textindeliving2bdrm_plainText: "2-Bedroom",
      elIndeLiving2bdrmFee_visible: true,
      indeliving2bdrmfee: (<div> </div>),
      indeliving2bdrmfee_plainText: " ",
      elIndeLiving2bdrmUserPic_visible: true,
      indeliving2bdrmuser: (<div> </div>),
      indeliving2bdrmuser_plainText: " ",
      indeliving2bdrmdate: (<div> </div>),
      indeliving2bdrmdate_plainText: " ",
      elIndeLiving2bdrmFeeEditable_visible: false,
      indeLiving2bdrmFeeEditable: '',
      elIndeLiving2bdrmFeeSubmit_visible: false,
      textassistedliving: (<div>Assisted Living</div>),
      textassistedliving_plainText: "Assisted Living",
      textassistedlivingcommfee: (<div>Community Fee (One-Time)</div>),
      textassistedlivingcommfee_plainText: "Community Fee (One-Time)",
      assistedlivingcommfee: (<div> </div>),
      assistedlivingcommfee_plainText: " ",
      assistedlivingcommfeeuser: (<div> </div>),
      assistedlivingcommfeeuser_plainText: " ",
      assistedlivingcommfeedate: (<div> </div>),
      assistedlivingcommfeedate_plainText: " ",
      elAssistedLivingCommFeeEditable_visible: false,
      assistedLivingCommFeeEditable: '',
      elAssistedLivingCommFeeSubmit_visible: false,
      textassistedlivingstudio: (<div>Studio</div>),
      textassistedlivingstudio_plainText: "Studio",
      elAssistedLivingStudioFee_visible: true,
      assistedlivingstudiofee: (<div> </div>),
      assistedlivingstudiofee_plainText: " ",
      elAssistedLivingStudioFeeUserPic_visible: true,
      assistedlivingstudiofeeuser: (<div> </div>),
      assistedlivingstudiofeeuser_plainText: " ",
      assistedlivingstudiofeedate: (<div> </div>),
      assistedlivingstudiofeedate_plainText: " ",
      elAssistedLivingStudioFeeEditable_visible: false,
      assistedLivingStudioFeeEditable: '',
      elAssistedLivingStudioFeeSubmit_visible: false,
      textassistedliving1bdrm: (<div>1-Bedroom</div>),
      textassistedliving1bdrm_plainText: "1-Bedroom",
      elAssistedLiving1bdrmFee_visible: true,
      assistedliving1bdrmfee: (<div> </div>),
      assistedliving1bdrmfee_plainText: " ",
      elAssistedLiving1bdrmFeeUserPic_visible: true,
      assistedliving1bdrmfeeuser: (<div> </div>),
      assistedliving1bdrmfeeuser_plainText: " ",
      assistedliving1bdrmfeedate: (<div> </div>),
      assistedliving1bdrmfeedate_plainText: " ",
      elAssistedLiving1bdrmFeeEditable_visible: false,
      assistedLiving1bdrmFeeEditable: '',
      elAssistedLiving1bdrmFeeSubmit_visible: false,
      textassistedliving2bdrm: (<div>2-Bedroom</div>),
      textassistedliving2bdrm_plainText: "2-Bedroom",
      elAssistedLiving2bdrmFee_visible: true,
      assistedliving2bdrmfee: (<div> </div>),
      assistedliving2bdrmfee_plainText: " ",
      elAssistedLiving2bdrmFeeUserPic_visible: true,
      assistedliving2bdrmfeeuser: (<div> </div>),
      assistedliving2bdrmfeeuser_plainText: " ",
      assistedliving2bdrmfeedate: (<div> </div>),
      assistedliving2bdrmfeedate_plainText: " ",
      elAssistedLiving2bdrmFeeEditable_visible: false,
      assistedLiving2bdrmFeeEditable: '',
      elAssistedLiving2bdrmFeeSubmit_visible: false,
      textassistedliving2bdrmshared: (<div>2-Bedroom Shared</div>),
      textassistedliving2bdrmshared_plainText: "2-Bedroom Shared",
      elAssistedLiving2bdrmSharedFee_visible: true,
      assistedliving2bdrmsharedfee: (<div> </div>),
      assistedliving2bdrmsharedfee_plainText: " ",
      elAssistedLiving2bdrmSharedFeeUserPic_visible: true,
      assistedliving2bdrmsharedfeeuser: (<div> </div>),
      assistedliving2bdrmsharedfeeuser_plainText: " ",
      assistedliving2bdrmsharedfeedate: (<div> </div>),
      assistedliving2bdrmsharedfeedate_plainText: " ",
      elAssistedLiving2bdrmSharedFeeEditable_visible: false,
      assistedLiving2bdrmSharedFeeEditable: '',
      elAssistedLiving2bdrmSharedFeeSubmit_visible: false,
      textassistedliving2ndocc: (<div>2nd Occupant Fee</div>),
      textassistedliving2ndocc_plainText: "2nd Occupant Fee",
      elAssistedLiving2ndOccFee_visible: true,
      assistedliving2ndoccfee: (<div> </div>),
      assistedliving2ndoccfee_plainText: " ",
      elAssistedLiving2ndOccFeeUserPic_visible: true,
      assistedliving2ndoccfeeuser: (<div> </div>),
      assistedliving2ndoccfeeuser_plainText: " ",
      assistedliving2ndoccfeedate: (<div> </div>),
      assistedliving2ndoccfeedate_plainText: " ",
      elAssistedLiving2ndOccFeeEditable_visible: false,
      assistedLiving2ndOccFeeEditable: '',
      elAssistedLiving2ndOccFeeSubmit_visible: false,
      textassistedlivingcarefee: (<div>Care Fee</div>),
      textassistedlivingcarefee_plainText: "Care Fee",
      elAssistedLivingCareFee_visible: true,
      assistedlivingcarefee: (<div> </div>),
      assistedlivingcarefee_plainText: " ",
      elAssistedLivingCareFeeUserPic_visible: true,
      assistedlivingcarefeeuser: (<div> </div>),
      assistedlivingcarefeeuser_plainText: " ",
      assistedlivingcarefeedate: (<div> </div>),
      assistedlivingcarefeedate_plainText: " ",
      elAssistedLivingCareFeeEditable_visible: false,
      assistedLivingCareFeeEditable: '',
      elAssistedLivingCareFeeSubmit_visible: false,
      textmemorycare: (<div>Memory Care</div>),
      textmemorycare_plainText: "Memory Care",
      textmemorycarecommfee: (<div>Community Fee (One-Time)</div>),
      textmemorycarecommfee_plainText: "Community Fee (One-Time)",
      memorycarecommfee: (<div> </div>),
      memorycarecommfee_plainText: " ",
      memorycarecommfeeuser: (<div> </div>),
      memorycarecommfeeuser_plainText: " ",
      memorycarecommfeedate: (<div> </div>),
      memorycarecommfeedate_plainText: " ",
      elMemoryCareCommFeeEditable_visible: false,
      memoryCareCommFeeEditable: '',
      elMemoryCareCommFeeSubmit_visible: false,
      textmemorycarestudio: (<div>Studio</div>),
      textmemorycarestudio_plainText: "Studio",
      elMemoryCareStudioFee_visible: true,
      memorycarestudiofee: (<div> </div>),
      memorycarestudiofee_plainText: " ",
      elMemoryCareStudioFeeUserPic_visible: true,
      memorycarestudiofeeuser: (<div> </div>),
      memorycarestudiofeeuser_plainText: " ",
      memorycarestudiofeedate: (<div> </div>),
      memorycarestudiofeedate_plainText: " ",
      elMemoryCareStudioFeeEditable_visible: false,
      memoryCareStudioFeeEditable: '',
      elMemoryCareStudioFeeSubmit_visible: false,
      textmemorycare1bdrm: (<div>1-Bedroom</div>),
      textmemorycare1bdrm_plainText: "1-Bedroom",
      elMemoryCare1bdrmFee_visible: true,
      memorycare1bdrmfee: (<div> </div>),
      memorycare1bdrmfee_plainText: " ",
      elMemoryCare1bdrmFeeUserPic_visible: true,
      memorycare1bdrmfeeuser: (<div> </div>),
      memorycare1bdrmfeeuser_plainText: " ",
      memorycare1bdrmfeedate: (<div> </div>),
      memorycare1bdrmfeedate_plainText: " ",
      elMemoryCare1bdrmFeeEditable_visible: false,
      memoryCare1bdrmFeeEditable: '',
      elMemoryCare1bdrmFeeSubmit_visible: false,
      textmemorycare2bdrm: (<div>2-Bedroom</div>),
      textmemorycare2bdrm_plainText: "2-Bedroom",
      elMemoryCare2bdrmFee_visible: true,
      memorycare2bdrmfee: (<div> </div>),
      memorycare2bdrmfee_plainText: " ",
      elMemoryCare2bdrmFeeUserPic_visible: true,
      memorycare2bdrmfeeuser: (<div> </div>),
      memorycare2bdrmfeeuser_plainText: " ",
      memorycare2bdrmfeedate: (<div> </div>),
      memorycare2bdrmfeedate_plainText: " ",
      elMemoryCare2bdrmFeeEditable_visible: false,
      memoryCare2bdrmFeeEditable: '',
      elMemoryCare2bdrmFeeSubmit_visible: false,
      textmemorycare2bdrmshared: (<div>2-Bedroom Shared</div>),
      textmemorycare2bdrmshared_plainText: "2-Bedroom Shared",
      elMemoryCare2bdrmSharedFee_visible: true,
      memorycare2bdrmsharedfee: (<div> </div>),
      memorycare2bdrmsharedfee_plainText: " ",
      elMemoryCare2bdrmSharedFeeUserPic_visible: true,
      memorycare2bdrmsharedfeeuser: (<div> </div>),
      memorycare2bdrmsharedfeeuser_plainText: " ",
      memorycare2bdrmsharedfeedate: (<div> </div>),
      memorycare2bdrmsharedfeedate_plainText: " ",
      elMemoryCare2bdrmSharedFeeEditable_visible: false,
      memoryCare2bdrmSharedFeeEditable: '',
      elMemoryCare2bdrmSharedFeeSubmit_visible: false,
      textmemorycare2ndocc: (<div>2nd Occupant Fee</div>),
      textmemorycare2ndocc_plainText: "2nd Occupant Fee",
      memorycare2ndoccfee: (<div> </div>),
      memorycare2ndoccfee_plainText: " ",
      elMemoryCare2ndOccFeeUserPic_visible: true,
      memorycare2ndoccfeeuser: (<div> </div>),
      memorycare2ndoccfeeuser_plainText: " ",
      memorycare2ndoccfeedate: (<div> </div>),
      memorycare2ndoccfeedate_plainText: " ",
      elMemoryCare2ndOccFeeEditable_visible: false,
      memoryCare2ndOccFeeEditable: '',
      elMemoryCare2ndOccFeeSubmit_visible: false,
      textmemorycarecarefee: (<div>Care Fee</div>),
      textmemorycarecarefee_plainText: "Care Fee",
      elMemoryCareCareFee_visible: true,
      memorycarecarefee: (<div> </div>),
      memorycarecarefee_plainText: " ",
      elMemoryCareCareFeeUserPic_visible: true,
      memorycarecarefeeuser: (<div> </div>),
      memorycarecarefeeuser_plainText: " ",
      memorycarecarefeedate: (<div> </div>),
      memorycarecarefeedate_plainText: " ",
      elMemoryCareCareFeeEditable_visible: false,
      memoryCareCareFeeEditable: '',
      elMemoryCareCareFeeSubmit_visible: false,
      textskillednursing: (<div>Skilled Nursing</div>),
      textskillednursing_plainText: "Skilled Nursing",
      textskillednursingprivate: (<div>Private (Daily Rates)</div>),
      textskillednursingprivate_plainText: "Private (Daily Rates)",
      skillednursingprivatefee: (<div> </div>),
      skillednursingprivatefee_plainText: " ",
      elSkilledNursingPrivateFeeUserPic_visible: true,
      skillednursingprivatefeeuser: (<div> </div>),
      skillednursingprivatefeeuser_plainText: " ",
      skillednursingprivatefeedate: (<div> </div>),
      skillednursingprivatefeedate_plainText: " ",
      elSkilledNursingPrivateEditable_visible: false,
      skilledNursingPrivateEditable: '',
      elSkilledNursingPrivateSubmit_visible: false,
      textskillednursingsemi: (<div>Semi-Private (Daily Rates)</div>),
      textskillednursingsemi_plainText: "Semi-Private (Daily Rates)",
      elSkilledNursingSemiFee_visible: true,
      skillednursingsemifee: (<div> </div>),
      skillednursingsemifee_plainText: " ",
      elSkilledNursingSemiFeeUserPic_visible: true,
      skillednursingsemifeeuser: (<div> </div>),
      skillednursingsemifeeuser_plainText: " ",
      skillednursingsemifeedate: (<div> </div>),
      skillednursingsemifeedate_plainText: " ",
      elSkilledNursingSemiEditable_visible: false,
      skilledNursingSemiEditable: '',
      elSkilledNursingSemiSubmit_visible: false,
      textphotos: (<div>Photos</div>),
      textphotos_plainText: "Photos",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButtonBackTop = async (ev) => {
    // Go to screen 'Community'
    this.props.appActions.goToScreen('community', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elButtonAddPhotos = async (ev) => {
    this.setState({elCompPhotoUpload_visible: true});

    this.setState({elButtonAddPhotos_visible: false});

  }


  onClick_elEditButton = async (ev) => {
    // Go to screen 'UpdateInfo'
    this.props.appActions.goToScreen('updateInfo', { ...this.props, transitionId: 'fadeIn' });

  }


  textInputChanged_elCommunityFeeEditable = (event) => {
    this.setState({communityFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_communityFeeEntered", event.target.value);
  }

  onClick_elCommunityFeeSubmit = async (ev) => {
    await this.sendData_elCommunityFeeSubmit_to_prices();

    this.setState({elIndeLivingCommFeeEditable_visible: false});

    this.setState({elIndeLivingCommFeeSubmit_visible: false});

    this.setState({elIndeLivingStudioFeeUserPic_visible: true});

  }


  onClick_elIndeLivingCommFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesIndeLivingCommFee'], this.props.appActions.serviceOptions_pricesIndeLivingCommFee, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'CommunityDetails2'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elIndeLivingCommFeeEditable = (event) => {
    this.setState({indeLivingCommFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_indeLivingCommFeeEntered", event.target.value);
  }

  onClick_elIndeLivingCommFeeSubmit = async (ev) => {
    await this.sendData_elIndeLivingCommFeeSubmit_to_pricesIndeLivingCommFee();

    this.setState({elIndeLivingCommFeeEditable_visible: false});

    this.setState({elIndeLivingCommFeeSubmit_visible: false});

    this.setState({elIndeLivingStudioFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'indeLivingCommFeeEditable'
    this.setState({
      indeLivingCommFeeEditable: "",
    });

  }


  onClick_elIndeLivingStudioFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesIndeLivingStudio'], this.props.appActions.serviceOptions_pricesIndeLivingStudio, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elIndeLivingStudioFeeEditable = (event) => {
    this.setState({indeLivingStudioFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_indeLivingStudioEntered", event.target.value);
  }

  onClick_elIndeLivingStudioFeeSubmit = async (ev) => {
    await this.sendData_elIndeLivingStudioFeeSubmit_to_pricesIndeLivingStudio();

    this.setState({elIndeLivingStudioFeeEditable_visible: false});

    this.setState({elIndeLivingStudioFeeSubmit_visible: false});

    this.setState({elIndeLiving1bdrmUserPic_visible: true});

    // Perform action 'Clear text' on element 'indeLivingStudioFeeEditable'
    this.setState({
      indeLivingStudioFeeEditable: "",
    });

  }


  onClick_elIndeLiving1bdrmFee = async (ev) => {
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesIndeLiving1bdrm'], this.props.appActions.serviceOptions_pricesIndeLiving1bdrm, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elIndeLiving1bdrmFeeEditable = (event) => {
    this.setState({indeLiving1bdrmFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_indeLiving1bdrmEntered", event.target.value);
  }

  onClick_elIndeLiving1bdrmFeeSubmit = async (ev) => {
    await this.sendData_elIndeLiving1bdrmFeeSubmit_to_pricesIndeLiving1bdrm();

    this.setState({elIndeLiving1bdrmFeeEditable_visible: false});

    this.setState({elIndeLiving1bdrmFeeSubmit_visible: false});

    this.setState({elIndeLiving2bdrmUserPic_visible: true});

    // Perform action 'Clear text' on element 'indeLiving1bdrmFeeEditable'
    this.setState({
      indeLiving1bdrmFeeEditable: "",
    });

  }


  onClick_elIndeLiving2bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesIndeLiving2bdrm'], this.props.appActions.serviceOptions_pricesIndeLiving2bdrm, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elIndeLiving2bdrmFeeEditable = (event) => {
    this.setState({indeLiving2bdrmFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_indeLiving2bdrmEntered", event.target.value);
  }

  onClick_elIndeLiving2bdrmFeeSubmit = async (ev) => {
    await this.sendData_elIndeLiving2bdrmFeeSubmit_to_pricesIndeLiving2bdrm();

    this.setState({elIndeLiving2bdrmFeeEditable_visible: false});

    this.setState({elIndeLiving2bdrmFeeSubmit_visible: false});


    // Perform action 'Clear text' on element 'indeLiving2bdrmFeeEditable'
    this.setState({
      indeLiving2bdrmFeeEditable: "",
    });

  }


  onClick_elAssistedLivingCommFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLivingCommFee'], this.props.appActions.serviceOptions_pricesAssistedLivingCommFee, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elAssistedLivingCommFeeEditable = (event) => {
    this.setState({assistedLivingCommFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_assistedLivingCommFeeEntered", event.target.value);
  }

  onClick_elAssistedLivingCommFeeSubmit = async (ev) => {
    await this.sendData_elAssistedLivingCommFeeSubmit_to_pricesAssistedLivingCommFee();

    this.setState({elAssistedLivingCommFeeEditable_visible: false});

    this.setState({elAssistedLivingCommFeeSubmit_visible: false});

    this.setState({elAssistedLivingStudioFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'assistedLivingCommFeeEditable'
    this.setState({
      assistedLivingCommFeeEditable: "",
    });

  }


  onClick_elAssistedLivingStudioFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLivingStudio'], this.props.appActions.serviceOptions_pricesAssistedLivingStudio, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elAssistedLivingStudioFeeEditable = (event) => {
    this.setState({assistedLivingStudioFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_assistedLivingStudioEntered", event.target.value);
  }

  onClick_elAssistedLivingStudioFeeSubmit = async (ev) => {
    await this.sendData_elAssistedLivingStudioFeeSubmit_to_pricesAssistedLivingStudio();

    this.setState({elAssistedLivingStudioFeeEditable_visible: false});

    this.setState({elAssistedLivingStudioFeeSubmit_visible: false});

    this.setState({elAssistedLiving1bdrmFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'assistedLivingStudioFeeEditable'
    this.setState({
      assistedLivingStudioFeeEditable: "",
    });

  }


  onClick_elAssistedLiving1bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving1bdrm'], this.props.appActions.serviceOptions_pricesAssistedLiving1bdrm, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elAssistedLiving1bdrmFeeEditable = (event) => {
    this.setState({assistedLiving1bdrmFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_assistedLiving1bdrmEntered", event.target.value);
  }

  onClick_elAssistedLiving1bdrmFeeSubmit = async (ev) => {
    await this.sendData_elAssistedLiving1bdrmFeeSubmit_to_pricesAssistedLiving1bdrm();

    this.setState({elAssistedLiving1bdrmFeeEditable_visible: false});

    this.setState({elAssistedLiving1bdrmFeeSubmit_visible: false});

    this.setState({elAssistedLiving2bdrmFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'assistedLiving1bdrmFeeEditable'
    this.setState({
      assistedLiving1bdrmFeeEditable: "",
    });

  }


  onClick_elAssistedLiving2bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving2bdrm'], this.props.appActions.serviceOptions_pricesAssistedLiving2bdrm, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elAssistedLiving2bdrmFeeEditable = (event) => {
    this.setState({assistedLiving2bdrmFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_assistedLiving2bdrmEntered", event.target.value);
  }

  onClick_elAssistedLiving2bdrmFeeSubmit = async (ev) => {
    await this.sendData_elAssistedLiving2bdrmFeeSubmit_to_pricesAssistedLiving2bdrm();

    this.setState({elAssistedLiving2bdrmFeeEditable_visible: false});

    this.setState({elAssistedLiving2bdrmFeeSubmit_visible: false});

    this.setState({elAssistedLiving2bdrmSharedFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'assistedLiving2bdrmFeeEditable'
    this.setState({
      assistedLiving2bdrmFeeEditable: "",
    });

  }


  onClick_elAssistedLiving2bdrmSharedFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving2bdrmShared'], this.props.appActions.serviceOptions_pricesAssistedLiving2bdrmShared, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elAssistedLiving2bdrmSharedFeeEditable = (event) => {
    this.setState({assistedLiving2bdrmSharedFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_assistedLiving2bdrmSharedEntered", event.target.value);
  }

  onClick_elAssistedLiving2bdrmSharedFeeSubmit = async (ev) => {
    await this.sendData_elAssistedLiving2bdrmSharedFeeSubmit_to_pricesAssistedLiving2bdrmShared();

    this.setState({elAssistedLiving2bdrmSharedFeeEditable_visible: false});

    this.setState({elAssistedLiving2bdrmSharedFeeSubmit_visible: false});

    this.setState({elAssistedLiving2ndOccFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'assistedLiving2bdrmSharedFeeEditable'
    this.setState({
      assistedLiving2bdrmSharedFeeEditable: "",
    });

  }


  onClick_elAssistedLiving2ndOccFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving2ndOcc'], this.props.appActions.serviceOptions_pricesAssistedLiving2ndOcc, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elAssistedLiving2ndOccFeeEditable = (event) => {
    this.setState({assistedLiving2ndOccFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_assistedLiving2ndOccEntered", event.target.value);
  }

  onClick_elAssistedLiving2ndOccFeeSubmit = async (ev) => {
    await this.sendData_elAssistedLiving2ndOccFeeSubmit_to_pricesAssistedLiving2ndOcc();

    this.setState({elAssistedLiving2ndOccFeeEditable_visible: false});

    this.setState({elAssistedLiving2ndOccFeeSubmit_visible: false});

    this.setState({elAssistedLivingCareFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'assistedLiving2ndOccFeeEditable'
    this.setState({
      assistedLiving2ndOccFeeEditable: "",
    });

  }


  onClick_elAssistedLivingCareFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLivingCareFees'], this.props.appActions.serviceOptions_pricesAssistedLivingCareFees, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elAssistedLivingCareFeeEditable = (event) => {
    this.setState({assistedLivingCareFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_assistedLivingCareFeesEntered", event.target.value);
  }

  onClick_elAssistedLivingCareFeeSubmit = async (ev) => {
    await this.sendData_elAssistedLivingCareFeeSubmit_to_pricesAssistedLivingCareFees();

    this.setState({elAssistedLivingCareFeeEditable_visible: false});

    this.setState({elAssistedLivingCareFeeSubmit_visible: false});

    // Perform action 'Clear text' on element 'assistedLivingCareFeeEditable'
    this.setState({
      assistedLivingCareFeeEditable: "",
    });

  }


  onClick_elMemoryCareCommFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCareCommFee'], this.props.appActions.serviceOptions_pricesMemoryCareCommFee, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elMemoryCareCommFeeEditable = (event) => {
    this.setState({memoryCareCommFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_memoryCareCommFeeEntered", event.target.value);
  }

  onClick_elMemoryCareCommFeeSubmit = async (ev) => {
    await this.sendData_elMemoryCareCommFeeSubmit_to_pricesMemoryCareCommFee();

    this.setState({elMemoryCareCommFeeEditable_visible: false});

    this.setState({elMemoryCareCommFeeSubmit_visible: false});

    this.setState({elMemoryCareStudioFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'memoryCareCommFeeEditable'
    this.setState({
      memoryCareCommFeeEditable: "",
    });

  }

  onClick_elMemoryCareStudioFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCareStudio'], this.props.appActions.serviceOptions_pricesMemoryCareStudio, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { transitionId: 'fadeIn' });
  }


  textInputChanged_elMemoryCareStudioFeeEditable = (event) => {
    this.setState({memoryCareStudioFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_memoryCareStudioEntered", event.target.value);
  }

  onClick_elMemoryCareStudioFeeSubmit = async (ev) => {
    await this.sendData_elMemoryCareStudioFeeSubmit_to_pricesMemoryCareStudio();

    this.setState({elMemoryCareStudioFeeEditable_visible: false});

    this.setState({elMemoryCareStudioFeeSubmit_visible: false});

    this.setState({elMemoryCare1bdrmFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'memoryCareStudioFeeEditable'
    this.setState({
      memoryCareStudioFeeEditable: "",
    });

  }


  onClick_elMemoryCare1bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCare1bdrm'], this.props.appActions.serviceOptions_pricesMemoryCare1bdrm, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elMemoryCare1bdrmFeeEditable = (event) => {
    this.setState({memoryCare1bdrmFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_memoryCare1bdrmEntered", event.target.value);
  }

  onClick_elMemoryCare1bdrmFeeSubmit = async (ev) => {
    await this.sendData_elMemoryCare1bdrmFeeSubmit_to_pricesMemoryCare1bdrm();

    this.setState({elMemoryCare1bdrmFeeEditable_visible: false});

    this.setState({elMemoryCare1bdrmFeeSubmit_visible: false});

    this.setState({elMemoryCare2bdrmFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'memoryCare1bdrmFeeEditable'
    this.setState({
      memoryCare1bdrmFeeEditable: "",
    });

  }

  onClick_elMemoryCare2bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCare2bdrm'], this.props.appActions.serviceOptions_pricesMemoryCare2bdrm, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elMemoryCare2bdrmFeeEditable = (event) => {
    this.setState({memoryCare2bdrmFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_memoryCare2bdrmEntered", event.target.value);
  }

  onClick_elMemoryCare2bdrmFeeSubmit = async (ev) => {
    this.setState({elMemoryCare2bdrmFeeEditable_visible: false});

    this.setState({elMemoryCare2bdrmFeeSubmit_visible: false});

    this.setState({elMemoryCare2bdrmSharedFeeUserPic_visible: true});

    await this.sendData_elMemoryCare2bdrmFeeSubmit_to_pricesMemoryCare2bdrm();

    // Perform action 'Clear text' on element 'memoryCare2bdrmFeeEditable'
    this.setState({
      memoryCare2bdrmFeeEditable: "",
    });

  }

  onClick_elMemoryCare2bdrmSharedFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving2bdrmShared'], this.props.appActions.serviceOptions_pricesAssistedLiving2bdrmShared, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elMemoryCare2bdrmSharedFeeEditable = (event) => {
    this.setState({memoryCare2bdrmSharedFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_memoryCare2bdrmSharedEntered", event.target.value);
  }

  onClick_elMemoryCare2bdrmSharedFeeSubmit = async (ev) => {
    await this.sendData_elMemoryCare2bdrmSharedFeeSubmit_to_pricesMemoryCare2bdrmShared();

    this.setState({elMemoryCare2bdrmSharedFeeEditable_visible: false});

    this.setState({elMemoryCare2bdrmSharedFeeSubmit_visible: false});

    this.setState({elMemoryCare2ndOccFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'memoryCare2bdrmSharedFeeEditable'
    this.setState({
      memoryCare2bdrmSharedFeeEditable: "",
    });

  }


  onClick_elMemoryCare2ndOccFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCare2ndOcc'], this.props.appActions.serviceOptions_pricesMemoryCare2ndOcc, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elMemoryCare2ndOccFeeEditable = (event) => {
    this.setState({memoryCare2ndOccFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_memoryCare2ndOccEntered", event.target.value);
  }

  onClick_elMemoryCare2ndOccFeeSubmit = async (ev) => {
    await this.sendData_elMemoryCare2ndOccFeeSubmit_to_pricesMemoryCare2ndOcc();

    this.setState({elMemoryCare2ndOccFeeEditable_visible: false});

    this.setState({elMemoryCare2ndOccFeeSubmit_visible: false});

    this.setState({elMemoryCareCareFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'memoryCare2ndOccFeeEditable'
    this.setState({
      memoryCare2ndOccFeeEditable: "",
    });

  }

  onClick_elMemoryCareCareFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCareCareFees'], this.props.appActions.serviceOptions_pricesMemoryCareCareFees, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }


  textInputChanged_elMemoryCareCareFeeEditable = (event) => {
    this.setState({memoryCareCareFeeEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_memoryCareCareFeesEntered", event.target.value);
  }

  onClick_elMemoryCareCareFeeSubmit = async (ev) => {
    await this.sendData_elMemoryCareCareFeeSubmit_to_pricesMemoryCareCareFees();

    this.setState({elMemoryCareCareFeeEditable_visible: false});

    this.setState({elMemoryCareCareFeeSubmit_visible: false});

    this.setState({elSkilledNursingPrivateFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'memoryCareCareFeeEditable'
    this.setState({
      memoryCareCareFeeEditable: "",
    });

  }

  onClick_elSkilledNursingPrivateFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesSkilledNursePrivate'], this.props.appActions.serviceOptions_pricesSkilledNursePrivate, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }

  textInputChanged_elSkilledNursingPrivateEditable = (event) => {
    this.setState({skilledNursingPrivateEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_skilledNursePrivateEntered", event.target.value);
  }

  onClick_elSkilledNursingPrivateSubmit = async (ev) => {
    await this.sendData_elSkilledNursingPrivateSubmit_to_pricesSkilledNursePrivate();

    this.setState({elSkilledNursingPrivateEditable_visible: false});

    this.setState({elSkilledNursingPrivateSubmit_visible: false});

    this.setState({elSkilledNursingSemiFeeUserPic_visible: true});

    // Perform action 'Clear text' on element 'skilledNursingPrivateEditable'
    this.setState({
      skilledNursingPrivateEditable: "",
    });

  }

  onClick_elSkilledNursingSemiFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesSkilledNurseSemi'], this.props.appActions.serviceOptions_pricesSkilledNurseSemi, false);

    this.props.appActions.updateDataSlot("ds_loginType", 1);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });
  }

  textInputChanged_elSkilledNursingSemiEditable = (event) => {
    this.setState({skilledNursingSemiEditable: event.target.value});

    this.props.appActions.updateDataSlot("ds_skilledNurseSemiEntered", event.target.value);
  }

  onClick_elSkilledNursingSemiSubmit = async (ev) => {
    await this.sendData_elSkilledNursingSemiSubmit_to_pricesSkilledNurseSemi();

    this.setState({elSkilledNursingSemiEditable_visible: false});

    this.setState({elSkilledNursingSemiSubmit_visible: false});

    // Perform action 'Clear text' on element 'skilledNursingSemiEditable'
    this.setState({
      skilledNursingSemiEditable: "",
    });

  }


  onClick_elButtonBackBottom = async (ev) => {
    // Go to screen 'Community'
    this.props.appActions.goToScreen('community', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elIconButton = async (ev) => {
    this.toggle_n();

  }


  onClick_elLogoznesthorizwhite = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { ...this.props, transitionId: 'fadeIn' });

  }


  sendData_elIndeLiving2bdrmFeeSubmit_to_pricesIndeLiving2bdrm = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesIndeLiving2bdrm');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      indeLiving2bdrmEntered: this.state.indeLiving2bdrmFeeEditable,
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving2bdrm');
      var qty = dsArr.items[0].indeLiving2bdrmQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.indeLiving2bdrmQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;  }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesIndeLiving2bdrm', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesIndeLiving2bdrm', row);
    }
  }


  sendData_elMemoryCare2ndOccFeeSubmit_to_pricesMemoryCare2ndOcc = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesMemoryCare2ndOcc');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      memoryCare2ndOccEntered: this.state.memoryCare2ndOccFeeEditable,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2ndOcc');
      var qty = dsArr.items[0].memoryCare2ndOccQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.memoryCare2ndOccQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesMemoryCare2ndOcc', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesMemoryCare2ndOcc', row);
    }
  }


  sendData_elSkilledNursingPrivateSubmit_to_pricesSkilledNursePrivate = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesSkilledNursePrivate');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      skilledNursePrivateQty: this.state.skilledNursingPrivateEditable,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      skilledNursePrivateEntered: this.state.skilledNursingPrivateEditable,
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesSkilledNursePrivate');
      var qty = dsArr.items[0].skilledNursePrivateQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.skilledNursePrivateQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesSkilledNursePrivate', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesSkilledNursePrivate', row);
    }
  }


  sendData_elAssistedLivingCareFeeSubmit_to_pricesAssistedLivingCareFees = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesAssistedLivingCareFees');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      assistedLivingCareFeesQty: this.state.assistedLivingCareFeeEditable,
      assistedLivingCareFeesEntered: this.state.assistedLivingCareFeeEditable,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCareFees');
      var qty = dsArr.items[0].assistedLivingCareFeesQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.assistedLivingCareFeesQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesAssistedLivingCareFees', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesAssistedLivingCareFees', row);
    }
  }


  sendData_elSkilledNursingSemiSubmit_to_pricesSkilledNurseSemi = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesSkilledNurseSemi');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
      skilledNurseSemiEntered: this.state.skilledNursingSemiEditable,
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesSkilledNurseSemi');
      var qty = dsArr.items[0].skilledNurseSemiQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.skilledNurseSemiQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesSkilledNurseSemi', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesSkilledNurseSemi', row);
    }
  }


  sendData_elMemoryCare2bdrmSharedFeeSubmit_to_pricesMemoryCare2bdrmShared = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesMemoryCare2bdrmShared');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      memoryCare2bdrmSharedEntered: this.state.memoryCare2bdrmSharedFeeEditable,
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrmShared');
      var qty = dsArr.items[0].memoryCare2bdrmSharedQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.memoryCare2bdrmSharedQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesMemoryCare2bdrmShared', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesMemoryCare2bdrmShared', row);
    }
  }


  sendData_elAssistedLiving2bdrmSharedFeeSubmit_to_pricesAssistedLiving2bdrmShared = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrmShared');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      assistedLiving2bdrmSharedEntered: this.state.assistedLiving2bdrmSharedFeeEditable,
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrmShared');
      var qty = dsArr.items[0].assistedLiving2bdrmSharedQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.assistedLiving2bdrmSharedQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesAssistedLiving2bdrmShared', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesAssistedLiving2bdrmShared', row);
    }
  }


  sendData_elMemoryCare1bdrmFeeSubmit_to_pricesMemoryCare1bdrm = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesMemoryCare1bdrm');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      memoryCare1bdrmEntered: this.state.memoryCare1bdrmFeeEditable,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare1bdrm');
      var qty = dsArr.items[0].memoryCare1bdrmQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.memoryCare1bdrmQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesMemoryCare1bdrm', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesMemoryCare1bdrm', row);
    }
  }


  sendData_elAssistedLivingStudioFeeSubmit_to_pricesAssistedLivingStudio = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesAssistedLivingStudio');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      assistedLivingStudioEntered: this.state.assistedLivingStudioFeeEditable,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingStudio');
      var qty = dsArr.items[0].assistedLivingStudioQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.assistedLivingStudioQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesAssistedLivingStudio', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesAssistedLivingStudio', row);
    }
  }


  sendData_elAssistedLiving1bdrmFeeSubmit_to_pricesAssistedLiving1bdrm = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesAssistedLiving1bdrm');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
      assistedLiving1bdrmEntered: this.state.assistedLiving1bdrmFeeEditable,
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving1bdrm');
      var qty = dsArr.items[0].assistedLiving1bdrmQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.assistedLiving1bdrmQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesAssistedLiving1bdrm', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesAssistedLiving1bdrm', row);
    }
  }


  sendData_elMemoryCare2bdrmFeeSubmit_to_pricesMemoryCare2bdrm = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesMemoryCare2bdrm');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      memoryCare2bdrmEntered: this.state.memoryCare2bdrmFeeEditable,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrm');
      var qty = dsArr.items[0].memoryCare2bdrmQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.memoryCare2bdrmQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;  }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesMemoryCare2bdrm', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesMemoryCare2bdrm', row);
    }
  }


  sendData_elIndeLivingCommFeeSubmit_to_pricesIndeLivingCommFee = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesIndeLivingCommFee');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      indeLivingCommFeeEntered: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_indeLivingCommFeeEntered'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingCommFee');
      var qty = dsArr.items[0].indeLivingCommFeeQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.indeLivingCommFeeQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesIndeLivingCommFee', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesIndeLivingCommFee', row);
    }
  }


  sendData_elMemoryCareCommFeeSubmit_to_pricesMemoryCareCommFee = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesMemoryCareCommFee');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      memoryCareCommFeeEntered: this.state.memoryCareCommFeeEditable,
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCommFee');
      var qty = dsArr.items[0].memoryCareCommFeeQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.memoryCareCommFeeQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesMemoryCareCommFee', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesMemoryCareCommFee', row);
    }
  }


  sendData_elAssistedLiving2bdrmFeeSubmit_to_pricesAssistedLiving2bdrm = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrm');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      assistedLiving2bdrmEntered: this.state.assistedLiving2bdrmFeeEditable,
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrm');
      var qty = dsArr.items[0].assistedLiving2bdrmQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.assistedLiving2bdrmQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesAssistedLiving2bdrm', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesAssistedLiving2bdrm', row);
    }
  }


  sendData_elIndeLiving1bdrmFeeSubmit_to_pricesIndeLiving1bdrm = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesIndeLiving1bdrm');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      indeLiving1bdrmEntered: this.state.indeLiving1bdrmFeeEditable,
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving1bdrm');
      var qty = dsArr.items[0].indeLiving1bdrmQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.indeLiving1bdrmQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesIndeLiving1bdrm', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesIndeLiving1bdrm', row);
    }
  }


  sendData_elCommunityFeeSubmit_to_prices = () => {

  }


  sendData_elAssistedLivingCommFeeSubmit_to_pricesAssistedLivingCommFee = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesAssistedLivingCommFee');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      assistedLivingCommFeeEntered: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_assistedLivingCommFeeEntered'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCommFee');
      var qty = dsArr.items[0].assistedLivingCommFeeQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.assistedLivingCommFeeQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesAssistedLivingCommFee', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesAssistedLivingCommFee', row);
    }
  }


  sendData_elAssistedLiving2ndOccFeeSubmit_to_pricesAssistedLiving2ndOcc = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesAssistedLiving2ndOcc');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      assistedLiving2ndOccEntered: this.state.assistedLiving2ndOccFeeEditable,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2ndOcc');
      var qty = dsArr.items[0].assistedLiving2ndOccQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.assistedLiving2ndOccQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesAssistedLiving2ndOcc', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesAssistedLiving2ndOcc', row);
    }
  }


  sendData_elIndeLivingStudioFeeSubmit_to_pricesIndeLivingStudio = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesIndeLivingStudio');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
      indeLivingStudioEntered: this.state.indeLivingStudioFeeEditable,
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingStudio');
      var qty = dsArr.items[0].indeLivingStudioQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.indeLivingStudioQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;  }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesIndeLivingStudio', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesIndeLivingStudio', row);
    }
  }


  sendData_elMemoryCareCareFeeSubmit_to_pricesMemoryCareCareFees = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesMemoryCareCareFees');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      memoryCareCareFeesEntered: this.state.memoryCareCareFeeEditable,
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCareFees');
      var qty = dsArr.items[0].memoryCareCareFeesQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.memoryCareCareFeesQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesMemoryCareCareFees', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesMemoryCareCareFees', row);
    }
  }


  sendData_elMemoryCareStudioFeeSubmit_to_pricesMemoryCareStudio = () => {
    const dataSheet = this.props.appActions.getDataSheet('pricesMemoryCareStudio');

    let row = this.props.dataSheetRow || {
      "dataSheetRow": this.props.dataSheetRow,
      "ilBeds": this.props.ilBeds,
      "indeLiving1bdrmAvg": this.props.indeLiving1bdrmAvg,
      "reviewerProfilePicUrl": this.props.reviewerProfilePicUrl,
      "reviewerName": this.props.reviewerName,
      "reviewDate": this.props.reviewDate,
      "indeLivingStudioAvg": this.props.indeLivingStudioAvg,
      "indeLiving2bdrmAvg": this.props.indeLiving2bdrmAvg,
      "photoURL": this.props.photoURL,
      "imageFilePath": this.props.imageFilePath,
      "mcBeds": this.props.mcBeds,
      "snBeds": this.props.snBeds,
      "communityCity": this.props.communityCity,
    };
    row = { ...row,
      reviewerName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : ''),
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      memoryCareStudioEntered: this.state.memoryCareStudioFeeEditable,
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
    };

    const transformData = (input) => {
        let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareStudio');
      var qty = dsArr.items[0].memoryCareStudioQty;
      var arrayLength = dsArr.items.length;

      qty++;

      input.memoryCareStudioQty = qty;

      input.reviewDate = Date.now();

      // This was needed due to an artifact
      input.communityFeeAvg = 0;

      input.dataSheetRow = '';
      input.ilBeds = '';
      input.imageFilePath = '';
      input.indeLiving1bdrmAvg = '';
      input.indeLiving2bdrmAvg = '';
      input.indeLivingStudioAvg = '';
      input.mcBeds = '';
      input.photoURL = '';
      input.snBeds = '';
      input.communityCity = '';
      input.reviewerProfilePicUrl = (this.props.reviewerProfilePicUrl || '');

      return input;
    }
    row = transformData(row);

    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('pricesMemoryCareStudio', row);
    } else {
      return this.props.appActions.addToDataSheet('pricesMemoryCareStudio', row);
    }
  }


  render() {
    var loginTypeValue = this.props.appActions.dataSlots['ds_loginTypeValue'];
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const popoverBottom = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving1bdrmFeeEditable} value={this.state.assistedLiving1bdrmFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elAssistedLiving1bdrmFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverIndeLivingCommFee = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elIndeLivingCommFeeEditable} value={this.state.indeLivingCommFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elIndeLivingCommFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverIndeLivingStudio = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elIndeLivingStudioFeeEditable} value={this.state.indeLivingStudioFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elIndeLivingStudioFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverIndeLiving1bdrm = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elIndeLiving1bdrmFeeEditable} value={this.state.indeLiving1bdrmFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elIndeLiving1bdrmFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverIndeLiving2bdrm = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elIndeLiving2bdrmFeeEditable} value={this.state.indeLiving2bdrmFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elIndeLiving2bdrmFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverAssistedLivingCommFee = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elAssistedLivingCommFeeEditable} value={this.state.assistedLivingCommFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elAssistedLivingCommFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverAssistedLivingStudio = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elAssistedLivingStudioFeeEditable} value={this.state.assistedLivingStudioFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elAssistedLivingStudioFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverAssistedLiving1bdrm = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving1bdrmFeeEditable} value={this.state.assistedLiving1bdrmFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elAssistedLiving1bdrmFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverAssistedLiving2bdrm = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving2bdrmFeeEditable} value={this.state.assistedLiving2bdrmFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elAssistedLiving2bdrmFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverAssistedLiving2bdrmShared = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving2bdrmSharedFeeEditable} value={this.state.assistedLiving2bdrmSharedFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elAssistedLiving2bdrmSharedFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverAssistedLiving2ndOcc = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving2ndOccFeeEditable} value={this.state.assistedLiving2ndOccFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elAssistedLiving2ndOccFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverAssistedLivingCare = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elAssistedLivingCareFeeEditable} value={this.state.assistedLivingCareFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elAssistedLivingCareFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverMemoryCareCommFee = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elMemoryCareCommFeeEditable} value={this.state.memoryCareCommFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elMemoryCareCommFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverMemoryCareStudio = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elMemoryCareStudioFeeEditable} value={this.state.memoryCareStudioFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elMemoryCareStudioFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverMemoryCare1bdrm = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elMemoryCare1bdrmFeeEditable} value={this.state.memoryCare1bdrmFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elMemoryCare1bdrmFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverMemoryCare2bdrm = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elMemoryCare2bdrmFeeEditable} value={this.state.memoryCare2bdrmFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elMemoryCare2bdrmFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverMemoryCare2bdrmShared = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elMemoryCare2bdrmSharedFeeEditable} value={this.state.memoryCare2bdrmSharedFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elMemoryCare2bdrmSharedFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverMemoryCare2ndOcc = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elMemoryCare2ndOccFeeEditable} value={this.state.memoryCare2ndOccFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elMemoryCare2ndOccFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverMemoryCareCare = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elMemoryCareCareFeeEditable} value={this.state.memoryCareCareFeeEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elMemoryCareCareFeeSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverSkilledNursingPrivate = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elSkilledNursingPrivateEditable} value={this.state.SkilledNursingPrivateEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elSkilledNursingPrivateSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    const popoverSkilledNursingSemi = (
      <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover">
        <div className="flowRow mt-2 d-flex">
        <div className="elZipCodeExplore">
            <input className="baseFont style_popup" type="text" placeholder="" onChange={this.textInputChanged_elSkilledNursingSemiEditable} value={this.state.SkilledNursingSemiEditable}  />
          </div>
          <div className="elSearchButton pb-5">
            <button className="style_button_popup"  onClick={this.onClick_elSkilledNursingSemiSubmit} >
            Submit
            </button>
          </div>
         </div>
      </Popover>
    );
    // eslint-disable-next-line
    const dataSheet_communities = this.props.dataSheets['communities'];
    // eslint-disable-next-line
    const dataSheet_photos = this.props.dataSheets['photos'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };

    const style_elButtonBackTop = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_communityName = (input) => {

      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      let dsArr = this.props.appActions.getDataSheet('selected_community');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var communityName = dsArr.items[0].communityName;
      }

      input = communityName;

      return input;

    }

    const value_communityName = transformPropValue_communityName((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityName'] : ''));

    const style_elCommunityName = {
      fontSize: 25.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elButtonAddPhotos = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: '#315283',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elButtonAddPhotos = this.state.elButtonAddPhotos_visible ? (
      <div className="elButtonAddPhotos">
        <button className="actionFont" style={style_elButtonAddPhotos} onClick={this.onClick_elButtonAddPhotos} >
          Add Photos
        </button>
      </div>

     ) : null;
    const elCompPhotoUpload = this.state.elCompPhotoUpload_visible ? (
      <div className="hasNestedComps elCompPhotoUpload">
        <div>
        </div>
      </div>

     ) : null;
    let transformPropValue_imageIL = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var ilFlag = dsArr.items[0].ilFlag;
        var alFlag = dsArr.items[0].alFlag;
        var mcFlag = dsArr.items[0].mcFlag;
        var snFlag = dsArr.items[0].snFlag;
      }

      if(ilFlag){
        // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-IL.png';
        input = img_elImageIL;
      }
      else if(alFlag){
        // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-AL.png';
        input = img_elImageAL;
      }
      else if(snFlag){
        // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-SN.png';
        input = img_elImageSN;
      }
      else
        input = '';

      return input;
    }

    const value_imageIL = transformPropValue_imageIL(this.props.dataSheetRow ? this.props.dataSheetRow.photoURL : '');


    let transformPropValue_imageAL = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var ilFlag = dsArr.items[0].ilFlag;
        var alFlag = dsArr.items[0].alFlag;
        var snFlag = dsArr.items[0].snFlag;

        // console.log("ilFlag = ", dsArr.items[0].ilFlag);
        // console.log("alFlag = ", dsArr.items[0].alFlag);
        // console.log("mcFlag = ", dsArr.items[0].mcFlag);
        // console.log("snFlag = ", dsArr.items[0].snFlag);
      }

      if(ilFlag && alFlag){
        // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-AL.png';
        input = img_elImageAL;
      }
      else if( (!ilFlag && alFlag && snFlag) || (ilFlag && !alFlag && snFlag)){
        // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-SN.png';
        input = img_elImageSN;
      }
      else
        input = '';

      return input;
    }

    const value_imageAL = transformPropValue_imageAL((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageAL = {
      height: 'auto',
     };
    let transformPropValue_imageMC = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var ilFlag = dsArr.items[0].ilFlag;
        var alFlag = dsArr.items[0].alFlag;
        var mcFlag = dsArr.items[0].mcFlag;
        var snFlag = dsArr.items[0].snFlag;
      }

      if(ilFlag && alFlag && mcFlag){
        // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-MC.png';
        input = img_elImageMC;
      }
      if(ilFlag && alFlag && snFlag){
        // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-SN.png';
        input = img_elImageSN;
      }
      else
        input = '';

      return input;
    }

    const value_imageMC = transformPropValue_imageMC((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageMC = {
      height: 'auto',
     };
    let transformPropValue_imageSN = (input) => {
      // There is no community with all 4 types.
      // Hence we would never have all 4 logos being displayed
      // at the same time

      input = '';

      return input;
    }

    const value_imageSN = transformPropValue_imageSN((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageSN = {
      height: 'auto',
     };

    let transformPropValue_communityAddress = (input) => {

       let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

       let dsArr = this.props.appActions.getDataSheet('selected_community');
       var itemsLength = dsArr.items.length;

       if(itemsLength > 0){
         var communityAddress = dsArr.items[0].communityAddress;
       }

       input = communityAddress;

       return input;
    }

    const value_communityAddress = transformPropValue_communityAddress((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityAddress'] : ''));

    const style_elCommunityAddress = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_communityCity = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getCity);

        function getCity(item, index){
          if(dsArr.items[index].document_key == selectedCommunityId){
            // input = dsArr.items[index].communityCity + ', ' + dsArr.items[index].communityState;
            input = dsArr.items[index].communityCity + ', ' + dsArr.items[index].communityState + ' ' + dsArr.items[index].communityZip;
          }
         }
      }

      return input;
    }

    const value_communityCity = transformPropValue_communityCity(this.props.communitycity);

    const style_elCommunityCity = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_communityPhone = (input) => {

      let dsArr = this.props.appActions.getDataSheet('selected_community');
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        dsArr.items.forEach(getPhone);

        function getPhone(item, index){
          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].communityPhone;
          }
         }
      }

      return input;
    }

    const value_communityPhone = transformPropValue_communityPhone((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityPhone'] : ''));

    const style_elCommunityPhone = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    let transformPropValue_communityWWW = (input) => {

     let dsArr = this.props.appActions.getDataSheet('selected_community');
     let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

     var itemsLength = dsArr.items.length;

     if(itemsLength > 0) {
       dsArr.items.forEach(getWWW);

       function getWWW(item, index){
         if(dsArr.items[index].document_key == selectedCommunityId){
           input = dsArr.items[index].communityWWW;
         }
       }
     }

       return input;
     }

     const value_communityWWW = transformPropValue_communityWWW((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityWWW'] : ''));

     const style_elCommunityWWW = {
       color: 'rgba(0, 0, 0, 0.8500)',
       textAlign: 'left',
      };


    const style_elTextBasicInfo = {
      fontSize: 23.8,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_editButton = (input) => {
      input = "Edit";

      return input;
    }

    const value_editButton = transformPropValue_editButton(this.props.indeLiving1bdrmAvg);

    const style_elEditButton = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextLicenseTypes = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextLicensedBeds = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = formatAvg(dsArr.items[index].ilBeds);
          }
         }
      }

      return input;
    }

    const value_indeLivingBedCount = transformPropValue_indeLivingBedCount(this.props.ilBeds);

    const style_elIndeLivingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_assistedLivingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community');
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = formatAvg(dsArr.items[index].alBeds);
          }
         }
      }

      return input;
    }

    const value_assistedLivingBedCount = transformPropValue_assistedLivingBedCount(this.props.ilBeds);

    const style_elAssistedLivingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_memoryCareBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community');
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = formatAvg(dsArr.items[index].mcBeds);
          }
         }
      }

      return input;
    }

    const value_memoryCareBedCount = transformPropValue_memoryCareBedCount(this.props.mcBeds);

    const style_elMemoryCareBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_skilledNursingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community');
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = formatAvg(dsArr.items[index].snBeds);
          }
         }
      }

      return input;
    }

    const value_skilledNursingBedCount = transformPropValue_skilledNursingBedCount(this.props.snBeds);

    const style_elSkilledNursingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elOtherBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elOtherBedCount_outer = {
      display: 'none',
     };

    const style_elLine1 = {
      borderTop: '1px solid black',
     };
    const style_elTextTotalBed = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_totalBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('selected_community');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var ilBeds = dsArr.items[0].ilBeds;
        var alBeds = dsArr.items[0].alBeds;
        var snBeds = dsArr.items[0].snBeds;
      }

      input = formatAvg(ilBeds + alBeds + snBeds);

      return input;
    }

    const value_totalBedCount = transformPropValue_totalBedCount((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_numberOfCommunities'] : ''));

    const style_elTotalBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };

    const value_communityCoverImage = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : '');

    const value_communityCoverImage_clean = value_communityCoverImage.slice(0.6);

    const style_elCommunityCoverImage = {
      height: 'auto',
     };
    const style_elTextPricing = {
      fontSize: 23.8,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextClickInstruction = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextPricingHeading1 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextPricingHeading2 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextPricingHeading3 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elCommunityFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elCommunityFeeEditable_outer = {
      display: 'none',
     };

    const style_elCommunityFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCommunityFeeSubmit_outer = {
      display: 'none',
     };
    let transformPropValue_imageILCopy = (input) => {
      // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-IL.png';
      input = img_elImageILCopy;

      return input;
    }

    const value_imageILCopy = transformPropValue_imageILCopy(this.props.dataSheetRow ? this.props.dataSheetRow.photoURL : '');

    const style_elImageILCopy = {
      height: 'auto',
     };
    const style_elTextIndeLiving = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextIndeLivingCommFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingCommFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingCommFee');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].indeLivingCommFeeQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].indeLivingCommFeeAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].indeLivingCommFeeAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].indeLivingCommFeeQty > 0) {
          var avg = dsArr.items[0].indeLivingCommFeeAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_indeLivingCommFee = transformPropValue_indeLivingCommFee(this.props.indeLiving1bdrmAvg);

    const style_elIndeLivingCommFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_indeLivingCommFeeUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_indeLivingCommFeeUserPic = transformPropValue_indeLivingCommFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elIndeLivingCommFeeUserPic = {
      height: 'auto',
     };
    let transformPropValue_indeLivingCommFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_indeLivingCommFeeUser = transformPropValue_indeLivingCommFeeUser(this.props.reviewerName);

    const style_elIndeLivingCommFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingCommFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("1bdrm DB Date = ", date);
        input = date;
      }

      //console.log("1bdrm Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        var year = a.getFullYear();
        //console.log("year = ", year);
        var month = a.getMonth();
        //console.log("month = ", month);
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_indeLivingCommFeeDate = transformPropValue_indeLivingCommFeeDate(this.props.reviewDate);

    const style_elIndeLivingCommFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elIndeLivingCommFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elIndeLivingCommFeeEditable = this.state.elIndeLivingCommFeeEditable_visible ? (
      <div className="elIndeLivingCommFeeEditable">
        <input className="baseFont" style={style_elIndeLivingCommFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elIndeLivingCommFeeEditable} value={this.state.indeLivingCommFeeEditable}  />
      </div>

     ) : null;

    const style_elIndeLivingCommFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIndeLivingCommFeeSubmit = this.state.elIndeLivingCommFeeSubmit_visible ? (
      <div className="elIndeLivingCommFeeSubmit">
        <Button className="actionFont" style={style_elIndeLivingCommFeeSubmit}  color="accent" onClick={this.onClick_elIndeLivingCommFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextIndeLivingStudio = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingStudioFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingStudio');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].indeLivingStudioQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].indeLivingStudioAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].indeLivingStudioAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].indeLivingStudioQty > 0) {
          var avg = dsArr.items[0].indeLivingStudioAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_indeLivingStudioFee = transformPropValue_indeLivingStudioFee(this.props.indeLivingStudioAvg);

    const style_elIndeLivingStudioFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIndeLivingStudioFee = this.state.elIndeLivingStudioFee_visible ? (
      <div className="elIndeLivingStudioFee">
        <div className="baseFont" style={style_elIndeLivingStudioFee} onClick={this.onClick_elIndeLivingStudioFee} >
          <div>{value_indeLivingStudioFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_indeLivingStudioFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_indeLivingStudioFeeUserPic = transformPropValue_indeLivingStudioFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elIndeLivingStudioFeeUserPic = {
      height: 'auto',
     };
    const elIndeLivingStudioFeeUserPic = this.state.elIndeLivingStudioFeeUserPic_visible ? (
      <div className="elIndeLivingStudioFeeUserPic">
        <img style={style_elIndeLivingStudioFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_indeLivingStudioFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_indeLivingStudioFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_indeLivingStudioFeeUser = transformPropValue_indeLivingStudioFeeUser(this.props.reviewerName);

    const style_elIndeLivingStudioFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingStudioFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_indeLivingStudioFeeDate = transformPropValue_indeLivingStudioFeeDate(this.props.reviewDate);

    const style_elIndeLivingStudioFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elIndeLivingStudioFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elIndeLivingStudioFeeEditable = this.state.elIndeLivingStudioFeeEditable_visible ? (
      <div className="elIndeLivingStudioFeeEditable">
        <input className="baseFont" style={style_elIndeLivingStudioFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elIndeLivingStudioFeeEditable} value={this.state.indeLivingStudioFeeEditable}  />
      </div>

     ) : null;

    const style_elIndeLivingStudioFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIndeLivingStudioFeeSubmit = this.state.elIndeLivingStudioFeeSubmit_visible ? (
      <div className="elIndeLivingStudioFeeSubmit">
        <Button className="actionFont" style={style_elIndeLivingStudioFeeSubmit}  color="accent" onClick={this.onClick_elIndeLivingStudioFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextIndeLiving1bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    // let transformPropValue_indeLiving1bdrmFee = (input) => {
    //   input = '$4000';
    //   return input;
    // }

    let transformPropValue_indeLiving1bdrmFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving1bdrm');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].indeLiving1bdrmQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].indeLiving1bdrmAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].indeLiving1bdrmAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].indeLiving1bdrmQty > 0) {
          var avg = dsArr.items[0].indeLiving1bdrmAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_indeLiving1bdrmFee = transformPropValue_indeLiving1bdrmFee(this.props.indeLiving1bdrmAvg);

    const style_elIndeLiving1bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIndeLiving1bdrmFee = this.state.elIndeLiving1bdrmFee_visible ? (
      <div className="elIndeLiving1bdrmFee">
        <div className="baseFont" style={style_elIndeLiving1bdrmFee} onClick={this.onClick_elIndeLiving1bdrmFee} >
          <div>{value_indeLiving1bdrmFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_indeLiving1bdrmUserPic = (input) => {



      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_indeLiving1bdrmUserPic = transformPropValue_indeLiving1bdrmUserPic(this.props.reviewerProfilePicUrl);

    const style_elIndeLiving1bdrmUserPic = {
      height: 'auto',
     };
    const elIndeLiving1bdrmUserPic = this.state.elIndeLiving1bdrmUserPic_visible ? (
      <div className="elIndeLiving1bdrmUserPic">
        <img style={style_elIndeLiving1bdrmUserPic} src={this.props.appActions.createImageUrlFromProp(value_indeLiving1bdrmUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_indeLiving1bdrmUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_indeLiving1bdrmUser = transformPropValue_indeLiving1bdrmUser(this.props.reviewerName);

    const style_elIndeLiving1bdrmUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLiving1bdrmDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("1bdrm DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("1bdrm Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_indeLiving1bdrmDate = transformPropValue_indeLiving1bdrmDate(this.props.reviewDate);

    const style_elIndeLiving1bdrmDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elIndeLiving1bdrmFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elIndeLiving1bdrmFeeEditable = this.state.elIndeLiving1bdrmFeeEditable_visible ? (
      <div className="elIndeLiving1bdrmFeeEditable">
        <input className="baseFont" style={style_elIndeLiving1bdrmFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elIndeLiving1bdrmFeeEditable} value={this.state.indeLiving1bdrmFeeEditable}  />
      </div>

     ) : null;

    const style_elIndeLiving1bdrmFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIndeLiving1bdrmFeeSubmit = this.state.elIndeLiving1bdrmFeeSubmit_visible ? (
      <div className="elIndeLiving1bdrmFeeSubmit">
        <Button className="actionFont" style={style_elIndeLiving1bdrmFeeSubmit}  color="accent" onClick={this.onClick_elIndeLiving1bdrmFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextIndeLiving2bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLiving2bdrmFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving2bdrm');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].indeLiving2bdrmQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].indeLiving2bdrmAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].indeLiving2bdrmAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].indeLiving2bdrmQty > 0) {
          var avg = dsArr.items[0].indeLiving2bdrmAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_indeLiving2bdrmFee = transformPropValue_indeLiving2bdrmFee(this.props.indeLiving2bdrmAvg);

    const style_elIndeLiving2bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIndeLiving2bdrmFee = this.state.elIndeLiving2bdrmFee_visible ? (
      <div className="elIndeLiving2bdrmFee">
        <div className="baseFont" style={style_elIndeLiving2bdrmFee} onClick={this.onClick_elIndeLiving2bdrmFee} >
          <div>{value_indeLiving2bdrmFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_indeLiving2bdrmUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_indeLiving2bdrmUserPic = transformPropValue_indeLiving2bdrmUserPic(this.props.reviewerProfilePicUrl);

    const style_elIndeLiving2bdrmUserPic = {
      height: 'auto',
     };
    const elIndeLiving2bdrmUserPic = this.state.elIndeLiving2bdrmUserPic_visible ? (
      <div className="elIndeLiving2bdrmUserPic">
        <img style={style_elIndeLiving2bdrmUserPic} src={this.props.appActions.createImageUrlFromProp(value_indeLiving2bdrmUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_indeLiving2bdrmUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_indeLiving2bdrmUser = transformPropValue_indeLiving2bdrmUser(this.props.reviewerName);

    const style_elIndeLiving2bdrmUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLiving2bdrmDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        // var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        // console.log("month = ", a.getMonth());

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;

      }
      else
      {
        // input = "Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_indeLiving2bdrmDate = transformPropValue_indeLiving2bdrmDate(this.props.reviewDate);

    const style_elIndeLiving2bdrmDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elIndeLiving2bdrmFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elIndeLiving2bdrmFeeEditable = this.state.elIndeLiving2bdrmFeeEditable_visible ? (
      <div className="elIndeLiving2bdrmFeeEditable">
        <input className="baseFont" style={style_elIndeLiving2bdrmFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elIndeLiving2bdrmFeeEditable} value={this.state.indeLiving2bdrmFeeEditable}  />
      </div>

     ) : null;

    const style_elIndeLiving2bdrmFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIndeLiving2bdrmFeeSubmit = this.state.elIndeLiving2bdrmFeeSubmit_visible ? (
      <div className="elIndeLiving2bdrmFeeSubmit">
        <Button className="actionFont" style={style_elIndeLiving2bdrmFeeSubmit}  color="accent" onClick={this.onClick_elIndeLiving2bdrmFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    let transformPropValue_imageALCopy = (input) => {
      // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-AL.png';
      input = img_elImageALCopy;

      return input;
    }

    const value_imageALCopy = transformPropValue_imageALCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageALCopy = {
      height: 'auto',
     };
    const style_elTextAssistedLiving = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextAssistedLivingCommFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingCommFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCommFee');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].assistedLivingCommFeeQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].assistedLivingCommFeeAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].assistedLivingCommFeeAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].assistedLivingCommFeeQty > 0) {
          var avg = dsArr.items[0].assistedLivingCommFeeAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_assistedLivingCommFee = transformPropValue_assistedLivingCommFee(this.props.indeLiving1bdrmAvg);

    const style_elAssistedLivingCommFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_assistedLivingCommFeeUserPic = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLivingCommFeeUserPic = transformPropValue_assistedLivingCommFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLivingCommFeeUserPic = {
      height: 'auto',
     };
    let transformPropValue_assistedLivingCommFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLivingCommFeeUser = transformPropValue_assistedLivingCommFeeUser(this.props.reviewerName);

    const style_elAssistedLivingCommFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingCommFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("1bdrm DB Date = ", date);
        input = date;
      }

      //console.log("1bdrm Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        var year = a.getFullYear();
        //console.log("year = ", year);
        var month = a.getMonth();
        //console.log("month = ", month);
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_assistedLivingCommFeeDate = transformPropValue_assistedLivingCommFeeDate(this.props.reviewDate);

    const style_elAssistedLivingCommFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elAssistedLivingCommFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elAssistedLivingCommFeeEditable = this.state.elAssistedLivingCommFeeEditable_visible ? (
      <div className="elAssistedLivingCommFeeEditable">
        <input className="baseFont" style={style_elAssistedLivingCommFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elAssistedLivingCommFeeEditable} value={this.state.assistedLivingCommFeeEditable}  />
      </div>

     ) : null;

    const style_elAssistedLivingCommFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLivingCommFeeSubmit = this.state.elAssistedLivingCommFeeSubmit_visible ? (
      <div className="elAssistedLivingCommFeeSubmit">
        <Button className="actionFont" style={style_elAssistedLivingCommFeeSubmit}  color="accent" onClick={this.onClick_elAssistedLivingCommFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextAssistedLivingStudio = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingStudioFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingStudio');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].assistedLivingStudioQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].assistedLivingStudioAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].assistedLivingStudioAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].assistedLivingStudioQty > 0) {
          var avg = dsArr.items[0].assistedLivingStudioAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_assistedLivingStudioFee = transformPropValue_assistedLivingStudioFee(this.props.indeLivingStudioAvg);

    const style_elAssistedLivingStudioFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLivingStudioFee = this.state.elAssistedLivingStudioFee_visible ? (
      <div className="elAssistedLivingStudioFee">
        <div className="baseFont" style={style_elAssistedLivingStudioFee} onClick={this.onClick_elAssistedLivingStudioFee} >
          <div>{value_assistedLivingStudioFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_assistedLivingStudioFeeUserPic = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLivingStudioFeeUserPic = transformPropValue_assistedLivingStudioFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLivingStudioFeeUserPic = {
      height: 'auto',
     };
    const elAssistedLivingStudioFeeUserPic = this.state.elAssistedLivingStudioFeeUserPic_visible ? (
      <div className="elAssistedLivingStudioFeeUserPic">
        <img style={style_elAssistedLivingStudioFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLivingStudioFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_assistedLivingStudioFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLivingStudioFeeUser = transformPropValue_assistedLivingStudioFeeUser(this.props.reviewerName);

    const style_elAssistedLivingStudioFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingStudioFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_assistedLivingStudioFeeDate = transformPropValue_assistedLivingStudioFeeDate(this.props.reviewDate);

    const style_elAssistedLivingStudioFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elAssistedLivingStudioFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elAssistedLivingStudioFeeEditable = this.state.elAssistedLivingStudioFeeEditable_visible ? (
      <div className="elAssistedLivingStudioFeeEditable">
        <input className="baseFont" style={style_elAssistedLivingStudioFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elAssistedLivingStudioFeeEditable} value={this.state.assistedLivingStudioFeeEditable}  />
      </div>

     ) : null;

    const style_elAssistedLivingStudioFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLivingStudioFeeSubmit = this.state.elAssistedLivingStudioFeeSubmit_visible ? (
      <div className="elAssistedLivingStudioFeeSubmit">
        <Button className="actionFont" style={style_elAssistedLivingStudioFeeSubmit}  color="accent" onClick={this.onClick_elAssistedLivingStudioFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextAssistedLiving1bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    // let transformPropValue_assistedLiving1bdrmFee = (input) => {
    //   input = '$5000';
    //   return input;
    // }

    let transformPropValue_assistedLiving1bdrmFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving1bdrm');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].assistedLiving1bdrmQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].assistedLiving1bdrmAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].assistedLiving1bdrmAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].assistedLiving1bdrmQty > 0) {
          var avg = dsArr.items[0].assistedLiving1bdrmAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_assistedLiving1bdrmFee = transformPropValue_assistedLiving1bdrmFee(this.props.assistedLiving1bdrmAvg);

    const style_elAssistedLiving1bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLiving1bdrmFee = this.state.elAssistedLiving1bdrmFee_visible ? (
      <div className="elAssistedLiving1bdrmFee">
        <div className="baseFont" style={style_elAssistedLiving1bdrmFee} onClick={this.onClick_elAssistedLiving1bdrmFee} >
         <div>{value_assistedLiving1bdrmFee}</div>
      {/*     <div><p><u>{value_assistedLiving1bdrmFee}</u></p></div> */}
        </div>
      </div>

     ) : null;
    let transformPropValue_assistedLiving1bdrmFeeUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLiving1bdrmFeeUserPic = transformPropValue_assistedLiving1bdrmFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLiving1bdrmFeeUserPic = {
      height: 'auto',
     };
    const elAssistedLiving1bdrmFeeUserPic = this.state.elAssistedLiving1bdrmFeeUserPic_visible ? (
      <div className="elAssistedLiving1bdrmFeeUserPic">
        <img style={style_elAssistedLiving1bdrmFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLiving1bdrmFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_assistedLiving1bdrmFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLiving1bdrmFeeUser = transformPropValue_assistedLiving1bdrmFeeUser(this.props.reviewerName);

    const style_elAssistedLiving1bdrmFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving1bdrmFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_assistedLiving1bdrmFeeDate = transformPropValue_assistedLiving1bdrmFeeDate(this.props.reviewDate);

    const style_elAssistedLiving1bdrmFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elAssistedLiving1bdrmFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elAssistedLiving1bdrmFeeEditable = this.state.elAssistedLiving1bdrmFeeEditable_visible ? (
      <div className="elAssistedLiving1bdrmFeeEditable">
        <input className="baseFont" style={style_elAssistedLiving1bdrmFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving1bdrmFeeEditable} value={this.state.assistedLiving1bdrmFeeEditable}  />
      </div>

     ) : null;

    const style_elAssistedLiving1bdrmFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLiving1bdrmFeeSubmit = this.state.elAssistedLiving1bdrmFeeSubmit_visible ? (
      <div className="elAssistedLiving1bdrmFeeSubmit">
        <Button className="actionFont" style={style_elAssistedLiving1bdrmFeeSubmit}  color="accent" onClick={this.onClick_elAssistedLiving1bdrmFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextAssistedLiving2bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2bdrmFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrm');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].assistedLiving2bdrmQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].assistedLiving2bdrmAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].assistedLiving2bdrmAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].assistedLiving2bdrmQty > 0) {
          var avg = dsArr.items[0].assistedLiving2bdrmAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_assistedLiving2bdrmFee = transformPropValue_assistedLiving2bdrmFee(this.props.indeLiving1bdrmAvg);

    const style_elAssistedLiving2bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2bdrmFee = this.state.elAssistedLiving2bdrmFee_visible ? (
      <div className="elAssistedLiving2bdrmFee">
        <div className="baseFont" style={style_elAssistedLiving2bdrmFee} onClick={this.onClick_elAssistedLiving2bdrmFee} >
          <div>{value_assistedLiving2bdrmFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_assistedLiving2bdrmFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLiving2bdrmFeeUserPic = transformPropValue_assistedLiving2bdrmFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLiving2bdrmFeeUserPic = {
      height: 'auto',
     };
    const elAssistedLiving2bdrmFeeUserPic = this.state.elAssistedLiving2bdrmFeeUserPic_visible ? (
      <div className="elAssistedLiving2bdrmFeeUserPic">
        <img style={style_elAssistedLiving2bdrmFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2bdrmFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_assistedLiving2bdrmFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLiving2bdrmFeeUser = transformPropValue_assistedLiving2bdrmFeeUser(this.props.reviewerName);

    const style_elAssistedLiving2bdrmFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2bdrmFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_assistedLiving2bdrmFeeDate = transformPropValue_assistedLiving2bdrmFeeDate(this.props.reviewDate);

    const style_elAssistedLiving2bdrmFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elAssistedLiving2bdrmFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2bdrmFeeEditable = this.state.elAssistedLiving2bdrmFeeEditable_visible ? (
      <div className="elAssistedLiving2bdrmFeeEditable">
        <input className="baseFont" style={style_elAssistedLiving2bdrmFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving2bdrmFeeEditable} value={this.state.assistedLiving2bdrmFeeEditable}  />
      </div>

     ) : null;

    const style_elAssistedLiving2bdrmFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2bdrmFeeSubmit = this.state.elAssistedLiving2bdrmFeeSubmit_visible ? (
      <div className="elAssistedLiving2bdrmFeeSubmit">
        <Button className="actionFont" style={style_elAssistedLiving2bdrmFeeSubmit}  color="accent" onClick={this.onClick_elAssistedLiving2bdrmFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextAssistedLiving2bdrmShared = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2bdrmSharedFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrmShared');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].assistedLiving2bdrmSharedQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].assistedLiving2bdrmSharedAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].assistedLiving2bdrmSharedAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].assistedLiving2bdrmSharedQty > 0) {
          var avg = dsArr.items[0].assistedLiving2bdrmSharedAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_assistedLiving2bdrmSharedFee = transformPropValue_assistedLiving2bdrmSharedFee(this.props.indeLiving1bdrmAvg);

    const style_elAssistedLiving2bdrmSharedFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2bdrmSharedFee = this.state.elAssistedLiving2bdrmSharedFee_visible ? (
      <div className="elAssistedLiving2bdrmSharedFee">
        <div className="baseFont" style={style_elAssistedLiving2bdrmSharedFee} onClick={this.onClick_elAssistedLiving2bdrmSharedFee} >
          <div>{value_assistedLiving2bdrmSharedFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_assistedLiving2bdrmSharedFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLiving2bdrmSharedFeeUserPic = transformPropValue_assistedLiving2bdrmSharedFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLiving2bdrmSharedFeeUserPic = {
      height: 'auto',
     };
    const elAssistedLiving2bdrmSharedFeeUserPic = this.state.elAssistedLiving2bdrmSharedFeeUserPic_visible ? (
      <div className="elAssistedLiving2bdrmSharedFeeUserPic">
        <img style={style_elAssistedLiving2bdrmSharedFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2bdrmSharedFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_assistedLiving2bdrmSharedFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLiving2bdrmSharedFeeUser = transformPropValue_assistedLiving2bdrmSharedFeeUser(this.props.reviewerName);

    const style_elAssistedLiving2bdrmSharedFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2bdrmSharedFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_assistedLiving2bdrmSharedFeeDate = transformPropValue_assistedLiving2bdrmSharedFeeDate(this.props.reviewDate);

    const style_elAssistedLiving2bdrmSharedFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elAssistedLiving2bdrmSharedFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2bdrmSharedFeeEditable = this.state.elAssistedLiving2bdrmSharedFeeEditable_visible ? (
      <div className="elAssistedLiving2bdrmSharedFeeEditable">
        <input className="baseFont" style={style_elAssistedLiving2bdrmSharedFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving2bdrmSharedFeeEditable} value={this.state.assistedLiving2bdrmSharedFeeEditable}  />
      </div>

     ) : null;

    const style_elAssistedLiving2bdrmSharedFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2bdrmSharedFeeSubmit = this.state.elAssistedLiving2bdrmSharedFeeSubmit_visible ? (
      <div className="elAssistedLiving2bdrmSharedFeeSubmit">
        <Button className="actionFont" style={style_elAssistedLiving2bdrmSharedFeeSubmit}  color="accent" onClick={this.onClick_elAssistedLiving2bdrmSharedFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextAssistedLiving2ndOcc = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2ndOccFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2ndOcc');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].assistedLiving2ndOccQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].assistedLiving2ndOccAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].assistedLiving2ndOccAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].assistedLiving2ndOccQty > 0) {
          var avg = dsArr.items[0].assistedLiving2ndOccAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_assistedLiving2ndOccFee = transformPropValue_assistedLiving2ndOccFee(this.props.indeLiving1bdrmAvg);

    const style_elAssistedLiving2ndOccFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2ndOccFee = this.state.elAssistedLiving2ndOccFee_visible ? (
      <div className="elAssistedLiving2ndOccFee">
        <div className="baseFont" style={style_elAssistedLiving2ndOccFee} onClick={this.onClick_elAssistedLiving2ndOccFee} >
          <div>{value_assistedLiving2ndOccFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_assistedLiving2ndOccFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLiving2ndOccFeeUserPic = transformPropValue_assistedLiving2ndOccFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLiving2ndOccFeeUserPic = {
      height: 'auto',
     };
    const elAssistedLiving2ndOccFeeUserPic = this.state.elAssistedLiving2ndOccFeeUserPic_visible ? (
      <div className="elAssistedLiving2ndOccFeeUserPic">
        <img style={style_elAssistedLiving2ndOccFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2ndOccFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_assistedLiving2ndOccFeeUser = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLiving2ndOccFeeUser = transformPropValue_assistedLiving2ndOccFeeUser(this.props.reviewerName);

    const style_elAssistedLiving2ndOccFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2ndOccFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_assistedLiving2ndOccFeeDate = transformPropValue_assistedLiving2ndOccFeeDate(this.props.reviewDate);

    const style_elAssistedLiving2ndOccFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elAssistedLiving2ndOccFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2ndOccFeeEditable = this.state.elAssistedLiving2ndOccFeeEditable_visible ? (
      <div className="elAssistedLiving2ndOccFeeEditable">
        <input className="baseFont" style={style_elAssistedLiving2ndOccFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elAssistedLiving2ndOccFeeEditable} value={this.state.assistedLiving2ndOccFeeEditable}  />
      </div>

     ) : null;

    const style_elAssistedLiving2ndOccFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLiving2ndOccFeeSubmit = this.state.elAssistedLiving2ndOccFeeSubmit_visible ? (
      <div className="elAssistedLiving2ndOccFeeSubmit">
        <Button className="actionFont" style={style_elAssistedLiving2ndOccFeeSubmit}  color="accent" onClick={this.onClick_elAssistedLiving2ndOccFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextAssistedLivingCareFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingCareFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCareFees');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].assistedLivingCareFeesQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].assistedLivingCareFeesAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].assistedLivingCareFeesAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].assistedLivingCareFeesQty > 0) {
          var avg = dsArr.items[0].assistedLivingCareFeesAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_assistedLivingCareFee = transformPropValue_assistedLivingCareFee(this.props.indeLiving1bdrmAvg);

    const style_elAssistedLivingCareFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLivingCareFee = this.state.elAssistedLivingCareFee_visible ? (
      <div className="elAssistedLivingCareFee">
        <div className="baseFont" style={style_elAssistedLivingCareFee} onClick={this.onClick_elAssistedLivingCareFee} >
          <div>{value_assistedLivingCareFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_assistedLivingCareFeeUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLivingCareFeeUserPic = transformPropValue_assistedLivingCareFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLivingCareFeeUserPic = {
      height: 'auto',
     };
    const elAssistedLivingCareFeeUserPic = this.state.elAssistedLivingCareFeeUserPic_visible ? (
      <div className="elAssistedLivingCareFeeUserPic">
        <img style={style_elAssistedLivingCareFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLivingCareFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_assistedLivingCareFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLivingCareFeeUser = transformPropValue_assistedLivingCareFeeUser(this.props.reviewerName);

    const style_elAssistedLivingCareFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingCareFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_assistedLivingCareFeeDate = transformPropValue_assistedLivingCareFeeDate(this.props.reviewDate);

    const style_elAssistedLivingCareFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elAssistedLivingCareFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elAssistedLivingCareFeeEditable = this.state.elAssistedLivingCareFeeEditable_visible ? (
      <div className="elAssistedLivingCareFeeEditable">
        <input className="baseFont" style={style_elAssistedLivingCareFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elAssistedLivingCareFeeEditable} value={this.state.assistedLivingCareFeeEditable}  />
      </div>

     ) : null;

    const style_elAssistedLivingCareFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAssistedLivingCareFeeSubmit = this.state.elAssistedLivingCareFeeSubmit_visible ? (
      <div className="elAssistedLivingCareFeeSubmit">
        <Button className="actionFont" style={style_elAssistedLivingCareFeeSubmit}  color="accent" onClick={this.onClick_elAssistedLivingCareFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    let transformPropValue_imageMCCopy = (input) => {
      // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-MC.png';
      input = img_elImageMCCopy;

      return input;
    }

    const value_imageMCCopy = transformPropValue_imageMCCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageMCCopy = {
      height: 'auto',
     };
    const style_elTextMemoryCare = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextMemoryCareCommFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareCommFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCommFee');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].memoryCareCommFeeQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].memoryCareCommFeeAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].memoryCareCommFeeAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].memoryCareCommFeeQty > 0) {
          var avg = dsArr.items[0].memoryCareCommFeeAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_memoryCareCommFee = transformPropValue_memoryCareCommFee(this.props.indeLiving1bdrmAvg);

    const style_elMemoryCareCommFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCareCommFeeUserPic = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCareCommFeeUserPic = transformPropValue_memoryCareCommFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCareCommFeeUserPic = {
      height: 'auto',
     };
    let transformPropValue_memoryCareCommFeeUser = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCareCommFeeUser = transformPropValue_memoryCareCommFeeUser(this.props.reviewerName);

    const style_elMemoryCareCommFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareCommFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("1bdrm DB Date = ", date);
        input = date;
      }

      //console.log("1bdrm Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        var year = a.getFullYear();
        //console.log("year = ", year);
        var month = a.getMonth();
        //console.log("month = ", month);
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_memoryCareCommFeeDate = transformPropValue_memoryCareCommFeeDate(this.props.reviewDate);

    const style_elMemoryCareCommFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elMemoryCareCommFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elMemoryCareCommFeeEditable = this.state.elMemoryCareCommFeeEditable_visible ? (
      <div className="elMemoryCareCommFeeEditable">
        <input className="baseFont" style={style_elMemoryCareCommFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elMemoryCareCommFeeEditable} value={this.state.memoryCareCommFeeEditable}  />
      </div>

     ) : null;

    const style_elMemoryCareCommFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCareCommFeeSubmit = this.state.elMemoryCareCommFeeSubmit_visible ? (
      <div className="elMemoryCareCommFeeSubmit">
        <Button className="actionFont" style={style_elMemoryCareCommFeeSubmit}  color="accent" onClick={this.onClick_elMemoryCareCommFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextMemoryCareStudio = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareStudioFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareStudio');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].memoryCareStudioQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].memoryCareStudioAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].memoryCareStudioAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].memoryCareStudioQty > 0) {
          var avg = dsArr.items[0].memoryCareStudioAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_memoryCareStudioFee = transformPropValue_memoryCareStudioFee(this.props.indeLiving1bdrmAvg);

    const style_elMemoryCareStudioFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCareStudioFee = this.state.elMemoryCareStudioFee_visible ? (
      <div className="elMemoryCareStudioFee">
        <div className="baseFont" style={style_elMemoryCareStudioFee} onClick={this.onClick_elMemoryCareStudioFee} >
          <div>{value_memoryCareStudioFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_memoryCareStudioFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCareStudioFeeUserPic = transformPropValue_memoryCareStudioFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCareStudioFeeUserPic = {
      height: 'auto',
     };
    const elMemoryCareStudioFeeUserPic = this.state.elMemoryCareStudioFeeUserPic_visible ? (
      <div className="elMemoryCareStudioFeeUserPic">
        <img style={style_elMemoryCareStudioFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCareStudioFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_memoryCareStudioFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCareStudioFeeUser = transformPropValue_memoryCareStudioFeeUser(this.props.reviewerName);

    const style_elMemoryCareStudioFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareStudioFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_memoryCareStudioFeeDate = transformPropValue_memoryCareStudioFeeDate(this.props.reviewDate);

    const style_elMemoryCareStudioFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elMemoryCareStudioFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elMemoryCareStudioFeeEditable = this.state.elMemoryCareStudioFeeEditable_visible ? (
      <div className="elMemoryCareStudioFeeEditable">
        <input className="baseFont" style={style_elMemoryCareStudioFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elMemoryCareStudioFeeEditable} value={this.state.memoryCareStudioFeeEditable}  />
      </div>

     ) : null;

    const style_elMemoryCareStudioFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCareStudioFeeSubmit = this.state.elMemoryCareStudioFeeSubmit_visible ? (
      <div className="elMemoryCareStudioFeeSubmit">
        <Button className="actionFont" style={style_elMemoryCareStudioFeeSubmit}  color="accent" onClick={this.onClick_elMemoryCareStudioFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextMemoryCare1bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare1bdrmFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare1bdrm');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].memoryCare1bdrmQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].memoryCare1bdrmAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].memoryCare1bdrmAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].memoryCare1bdrmQty > 0) {
          var avg = dsArr.items[0].memoryCare1bdrmAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_memoryCare1bdrmFee = transformPropValue_memoryCare1bdrmFee(this.props.indeLiving1bdrmAvg);

    const style_elMemoryCare1bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCare1bdrmFee = this.state.elMemoryCare1bdrmFee_visible ? (
      <div className="elMemoryCare1bdrmFee">
        <div className="baseFont" style={style_elMemoryCare1bdrmFee} onClick={this.onClick_elMemoryCare1bdrmFee} >
          <div>{value_memoryCare1bdrmFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_memoryCare1bdrmFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCare1bdrmFeeUserPic = transformPropValue_memoryCare1bdrmFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCare1bdrmFeeUserPic = {
      height: 'auto',
     };
    const elMemoryCare1bdrmFeeUserPic = this.state.elMemoryCare1bdrmFeeUserPic_visible ? (
      <div className="elMemoryCare1bdrmFeeUserPic">
        <img style={style_elMemoryCare1bdrmFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCare1bdrmFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_memoryCare1bdrmFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCare1bdrmFeeUser = transformPropValue_memoryCare1bdrmFeeUser(this.props.reviewerName);

    const style_elMemoryCare1bdrmFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare1bdrmFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_memoryCare1bdrmFeeDate = transformPropValue_memoryCare1bdrmFeeDate(this.props.reviewDate);

    const style_elMemoryCare1bdrmFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elMemoryCare1bdrmFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elMemoryCare1bdrmFeeEditable = this.state.elMemoryCare1bdrmFeeEditable_visible ? (
      <div className="elMemoryCare1bdrmFeeEditable">
        <input className="baseFont" style={style_elMemoryCare1bdrmFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elMemoryCare1bdrmFeeEditable} value={this.state.memoryCare1bdrmFeeEditable}  />
      </div>

     ) : null;

    const style_elMemoryCare1bdrmFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCare1bdrmFeeSubmit = this.state.elMemoryCare1bdrmFeeSubmit_visible ? (
      <div className="elMemoryCare1bdrmFeeSubmit">
        <Button className="actionFont" style={style_elMemoryCare1bdrmFeeSubmit}  color="accent" onClick={this.onClick_elMemoryCare1bdrmFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextMemoryCare2bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2bdrmFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrm');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].memoryCare2bdrmQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].memoryCare2bdrmAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].memoryCare2bdrmAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].memoryCare2bdrmQty > 0) {
          var avg = dsArr.items[0].memoryCare2bdrmAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_memoryCare2bdrmFee = transformPropValue_memoryCare2bdrmFee(this.props.indeLiving1bdrmAvg);

    const style_elMemoryCare2bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCare2bdrmFee = this.state.elMemoryCare2bdrmFee_visible ? (
      <div className="elMemoryCare2bdrmFee">
        <div className="baseFont" style={style_elMemoryCare2bdrmFee} onClick={this.onClick_elMemoryCare2bdrmFee} >
          <div>{value_memoryCare2bdrmFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_memoryCare2bdrmFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCare2bdrmFeeUserPic = transformPropValue_memoryCare2bdrmFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCare2bdrmFeeUserPic = {
      height: 'auto',
     };
    const elMemoryCare2bdrmFeeUserPic = this.state.elMemoryCare2bdrmFeeUserPic_visible ? (
      <div className="elMemoryCare2bdrmFeeUserPic">
        <img style={style_elMemoryCare2bdrmFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCare2bdrmFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_memoryCare2bdrmFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCare2bdrmFeeUser = transformPropValue_memoryCare2bdrmFeeUser(this.props.reviewerName);

    const style_elMemoryCare2bdrmFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2bdrmFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_memoryCare2bdrmFeeDate = transformPropValue_memoryCare2bdrmFeeDate(this.props.reviewDate);

    const style_elMemoryCare2bdrmFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elMemoryCare2bdrmFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elMemoryCare2bdrmFeeEditable = this.state.elMemoryCare2bdrmFeeEditable_visible ? (
      <div className="elMemoryCare2bdrmFeeEditable">
        <input className="baseFont" style={style_elMemoryCare2bdrmFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elMemoryCare2bdrmFeeEditable} value={this.state.memoryCare2bdrmFeeEditable}  />
      </div>

     ) : null;

    const style_elMemoryCare2bdrmFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCare2bdrmFeeSubmit = this.state.elMemoryCare2bdrmFeeSubmit_visible ? (
      <div className="elMemoryCare2bdrmFeeSubmit">
        <Button className="actionFont" style={style_elMemoryCare2bdrmFeeSubmit}  color="accent" onClick={this.onClick_elMemoryCare2bdrmFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextMemoryCare2bdrmShared = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2bdrmSharedFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrmShared');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].memoryCare2bdrmSharedQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].memoryCare2bdrmSharedAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].memoryCare2bdrmSharedAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].memoryCare2bdrmSharedQty > 0) {
          var avg = dsArr.items[0].memoryCare2bdrmSharedAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_memoryCare2bdrmSharedFee = transformPropValue_memoryCare2bdrmSharedFee(this.props.indeLiving1bdrmAvg);

    const style_elMemoryCare2bdrmSharedFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCare2bdrmSharedFee = this.state.elMemoryCare2bdrmSharedFee_visible ? (
      <div className="elMemoryCare2bdrmSharedFee">
        <div className="baseFont" style={style_elMemoryCare2bdrmSharedFee} onClick={this.onClick_elMemoryCare2bdrmSharedFee} >
          <div>{value_memoryCare2bdrmSharedFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_memoryCare2bdrmSharedFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCare2bdrmSharedFeeUserPic = transformPropValue_memoryCare2bdrmSharedFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCare2bdrmSharedFeeUserPic = {
      height: 'auto',
     };
    const elMemoryCare2bdrmSharedFeeUserPic = this.state.elMemoryCare2bdrmSharedFeeUserPic_visible ? (
      <div className="elMemoryCare2bdrmSharedFeeUserPic">
        <img style={style_elMemoryCare2bdrmSharedFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCare2bdrmSharedFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_memoryCare2bdrmSharedFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCare2bdrmSharedFeeUser = transformPropValue_memoryCare2bdrmSharedFeeUser(this.props.reviewerName);

    const style_elMemoryCare2bdrmSharedFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2bdrmSharedFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_memoryCare2bdrmSharedFeeDate = transformPropValue_memoryCare2bdrmSharedFeeDate(this.props.reviewDate);

    const style_elMemoryCare2bdrmSharedFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elMemoryCare2bdrmSharedFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elMemoryCare2bdrmSharedFeeEditable = this.state.elMemoryCare2bdrmSharedFeeEditable_visible ? (
      <div className="elMemoryCare2bdrmSharedFeeEditable">
        <input className="baseFont" style={style_elMemoryCare2bdrmSharedFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elMemoryCare2bdrmSharedFeeEditable} value={this.state.memoryCare2bdrmSharedFeeEditable}  />
      </div>

     ) : null;

    const style_elMemoryCare2bdrmSharedFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCare2bdrmSharedFeeSubmit = this.state.elMemoryCare2bdrmSharedFeeSubmit_visible ? (
      <div className="elMemoryCare2bdrmSharedFeeSubmit">
        <Button className="actionFont" style={style_elMemoryCare2bdrmSharedFeeSubmit}  color="accent" onClick={this.onClick_elMemoryCare2bdrmSharedFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextMemoryCare2ndOcc = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2ndOccFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2ndOcc');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].memoryCare2ndOccQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].memoryCare2ndOccAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].memoryCare2ndOccAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].memoryCare2ndOccQty > 0) {
          var avg = dsArr.items[0].memoryCare2ndOccAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_memoryCare2ndOccFee = transformPropValue_memoryCare2ndOccFee(this.props.indeLiving1bdrmAvg);

    const style_elMemoryCare2ndOccFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCare2ndOccFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCare2ndOccFeeUserPic = transformPropValue_memoryCare2ndOccFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCare2ndOccFeeUserPic = {
      height: 'auto',
     };
    const elMemoryCare2ndOccFeeUserPic = this.state.elMemoryCare2ndOccFeeUserPic_visible ? (
      <div className="elMemoryCare2ndOccFeeUserPic">
        <img style={style_elMemoryCare2ndOccFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCare2ndOccFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_memoryCare2ndOccFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCare2ndOccFeeUser = transformPropValue_memoryCare2ndOccFeeUser(this.props.reviewerName);

    const style_elMemoryCare2ndOccFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2ndOccFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_memoryCare2ndOccFeeDate = transformPropValue_memoryCare2ndOccFeeDate(this.props.reviewDate);

    const style_elMemoryCare2ndOccFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elMemoryCare2ndOccFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elMemoryCare2ndOccFeeEditable = this.state.elMemoryCare2ndOccFeeEditable_visible ? (
      <div className="elMemoryCare2ndOccFeeEditable">
        <input className="baseFont" style={style_elMemoryCare2ndOccFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elMemoryCare2ndOccFeeEditable} value={this.state.memoryCare2ndOccFeeEditable}  />
      </div>

     ) : null;

    const style_elMemoryCare2ndOccFeeSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCare2ndOccFeeSubmit = this.state.elMemoryCare2ndOccFeeSubmit_visible ? (
      <div className="elMemoryCare2ndOccFeeSubmit">
        <Button className="actionFont" style={style_elMemoryCare2ndOccFeeSubmit}  color="accent" onClick={this.onClick_elMemoryCare2ndOccFeeSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextMemoryCareCareFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareCareFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCareFees');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].memoryCareCareFeesQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].memoryCareCareFeesAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].memoryCareCareFeesAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].memoryCareCareFeesQty > 0) {
          var avg = dsArr.items[0].memoryCareCareFeesAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_memoryCareCareFee = transformPropValue_memoryCareCareFee(this.props.indeLiving1bdrmAvg);

    const style_elMemoryCareCareFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elMemoryCareCareFee = this.state.elMemoryCareCareFee_visible ? (
      <div className="elMemoryCareCareFee">
        <div className="baseFont" style={style_elMemoryCareCareFee} onClick={this.onClick_elMemoryCareCareFee} >
          <div>{value_memoryCareCareFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_memoryCareCareFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCareCareFeeUserPic = transformPropValue_memoryCareCareFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCareCareFeeUserPic = {
      height: 'auto',
     };
    const elMemoryCareCareFeeUserPic = this.state.elMemoryCareCareFeeUserPic_visible ? (
      <div className="elMemoryCareCareFeeUserPic">
        <img style={style_elMemoryCareCareFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCareCareFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_memoryCareCareFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCareCareFeeUser = transformPropValue_memoryCareCareFeeUser(this.props.reviewerName);

    const style_elMemoryCareCareFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareCareFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_memoryCareCareFeeDate = transformPropValue_memoryCareCareFeeDate(this.props.reviewDate);

    const style_elMemoryCareCareFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elMemoryCareCareFeeEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    // const elMemoryCareCareFeeEditable = this.state.elMemoryCareCareFeeEditable_visible ? (
    //   <div className="elMemoryCareCareFeeEditable">
    //     <input className="baseFont" style={style_elMemoryCareCareFeeEditable} type="text" placeholder="" onChange={this.textInputChanged_elMemoryCareCareFeeEditable} value={this.state.memoryCareCareFeeEditable}  />
    //   </div>

    //  ) : null;

    // const style_elMemoryCareCareFeeSubmit = {
    //   display: 'block',
    //   color: 'white',
    //   textAlign: 'center',
    //   cursor: 'pointer',
    //   pointerEvents: 'auto',
    //  };
    // const elMemoryCareCareFeeSubmit = this.state.elMemoryCareCareFeeSubmit_visible ? (
    //   <div className="elMemoryCareCareFeeSubmit">
    //     <Button className="actionFont" style={style_elMemoryCareCareFeeSubmit}  color="accent" onClick={this.onClick_elMemoryCareCareFeeSubmit} >
    //       Submit
    //     </Button>
    //   </div>

    //  ) : null;
    let transformPropValue_imageSNCopy = (input) => {
      // input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-SN.png';
      input = img_elImageSNCopy;

      return input;
    }

    const value_imageSNCopy = transformPropValue_imageSNCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    // const style_elImageSNCopy = {
    //   height: 'auto',
    //  };
    // const style_elTextSkilledNursing = {
    //   fontSize: 15.0,
    //   color: 'rgba(0, 0, 0, 0.8500)',
    //   textAlign: 'left',
    //  };
    // const style_elTextSkilledNursingPrivate = {
    //   fontSize: 15.0,
    //   color: 'rgba(0, 0, 0, 0.8500)',
    //   textAlign: 'left',
    //  };
    let transformPropValue_skilledNursingPrivateFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNursePrivate');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].skilledNursePrivateQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].skilledNursePrivateAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].skilledNursePrivateAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].skilledNursePrivateQty > 0) {
          var avg = dsArr.items[0].skilledNursePrivateAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_skilledNursingPrivateFee = transformPropValue_skilledNursingPrivateFee(this.props.indeLiving1bdrmAvg);

    const style_elSkilledNursingPrivateFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_skilledNursingPrivateFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNursePrivate');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_skilledNursingPrivateFeeUserPic = transformPropValue_skilledNursingPrivateFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elSkilledNursingPrivateFeeUserPic = {
      height: 'auto',
     };
    const elSkilledNursingPrivateFeeUserPic = this.state.elSkilledNursingPrivateFeeUserPic_visible ? (
      <div className="elSkilledNursingPrivateFeeUserPic">
        <img style={style_elSkilledNursingPrivateFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_skilledNursingPrivateFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_skilledNursingPrivateFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNursePrivate');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_skilledNursingPrivateFeeUser = transformPropValue_skilledNursingPrivateFeeUser(this.props.reviewerName);

    const style_elSkilledNursingPrivateFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_skilledNursingPrivateFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNursePrivate');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
      var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_skilledNursingPrivateFeeDate = transformPropValue_skilledNursingPrivateFeeDate(this.props.reviewDate);

    const style_elSkilledNursingPrivateFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elSkilledNursingPrivateEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elSkilledNursingPrivateEditable = this.state.elSkilledNursingPrivateEditable_visible ? (
      <div className="elSkilledNursingPrivateEditable">
        <input className="baseFont" style={style_elSkilledNursingPrivateEditable} type="text" placeholder="" onChange={this.textInputChanged_elSkilledNursingPrivateEditable} value={this.state.skilledNursingPrivateEditable}  />
      </div>

     ) : null;

    const style_elSkilledNursingPrivateSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elSkilledNursingPrivateSubmit = this.state.elSkilledNursingPrivateSubmit_visible ? (
      <div className="elSkilledNursingPrivateSubmit">
        <Button className="actionFont" style={style_elSkilledNursingPrivateSubmit}  color="accent" onClick={this.onClick_elSkilledNursingPrivateSubmit} >
          Submit
        </Button>
      </div>

     ) : null;
    const style_elTextSkilledNursingSemi = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_skilledNursingSemiFee = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNurseSemi');
      var itemsLength = dsArr.items.length;

      if(itemsLength > 0){
        var qty = dsArr.items[0].skilledNurseSemiQty;

        if (qty == 0) // DB only contains a single default entry
          input = "Submit";
        // The following is needed to take care of pre-scraped DB entry
        else if(dsArr.items[0].skilledNurseSemiAvg == "")
                input = 'Updating';
        // The following takes care of non-pre-scraped DB entry
        else if(dsArr.items[0].skilledNurseSemiAvg == null)
          input = 'Updating';
        else if(dsArr.items[0].skilledNurseSemiQty > 0) {
          var avg = dsArr.items[0].skilledNurseSemiAvg;
          input = '$' + formatAvg(avg);
        }
      }
      else
      {
        //input="Unknown date";
        input = "Submit";
      }

      return input;
    }

    const value_skilledNursingSemiFee = transformPropValue_skilledNursingSemiFee(this.props.indeLiving1bdrmAvg);

    const style_elSkilledNursingSemiFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elSkilledNursingSemiFee = this.state.elSkilledNursingSemiFee_visible ? (
      <div className="elSkilledNursingSemiFee">
        <div className="baseFont" style={style_elSkilledNursingSemiFee} onClick={this.onClick_elSkilledNursingSemiFee} >
          <div>{value_skilledNursingSemiFee}</div>
        </div>
      </div>

     ) : null;
    let transformPropValue_skilledNursingSemiFeeUserPic = (input) => {


      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNurseSemi');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_skilledNursingSemiFeeUserPic = transformPropValue_skilledNursingSemiFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elSkilledNursingSemiFeeUserPic = {
      height: 'auto',
     };
    const elSkilledNursingSemiFeeUserPic = this.state.elSkilledNursingSemiFeeUserPic_visible ? (
      <div className="elSkilledNursingSemiFeeUserPic">
        <img style={style_elSkilledNursingSemiFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_skilledNursingSemiFeeUserPic)} alt=""  />
      </div>

     ) : null;
    let transformPropValue_skilledNursingSemiFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNurseSemi');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_skilledNursingSemiFeeUser = transformPropValue_skilledNursingSemiFeeUser(this.props.reviewerName);

    const style_elSkilledNursingSemiFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_skilledNursingSemiFeeDate = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNurseSemi');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var date = dsArr.items[0].reviewDate;
        //console.log("New DB Date = ", date);
        input = date;
      }

      input = date;

      //console.log("Raw date = ", input)

      if(input != null && input != '') {
        var a = new Date(input);
        var year = a.getFullYear();
        var month = a.getMonth();
        var date = a.getDate();

        var time = month + '/' + date + '/' + year;
        input = 'on ' + time;
      }
      else
      {
        // input="Unknown date";
        input = "";
      }

      //console.log("Processed date = ", input)

      return input;
    }

    const value_skilledNursingSemiFeeDate = transformPropValue_skilledNursingSemiFeeDate(this.props.reviewDate);

    const style_elSkilledNursingSemiFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elSkilledNursingSemiEditable = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    

    const style_elSkilledNursingSemiSubmit = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
  

    const style_elButtonBackBottom = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    

    // Source items and any special components used for list/grid element 'photoList'.
    let items_photoList = [];
    let listComps_photoList = {};
    items_photoList = items_photoList.concat(this.props.appActions.getDataSheet('photos').items);
    this._elPhotoList_items = [];

    

    function formatAvg(amt){
      if(amt >= 1000){

        var afterComma = amt.toString().slice(-3);
        var beforeComma = amt.toString().slice(0, -3);

        amt = beforeComma + ',' + afterComma;
      }

      return amt;
    }

    return (
      <div className="AppScreen CommunityDetails2Screen mb-5" style={baseStyle}>
        <Header loginType={loginTypeValue}/>

       <div className="layoutFlow" style={layoutFlowStyle}>

      {/*   <Container className="pt-5"> */}
         {/* Search Section */}
         <Row>
         <Col lg={3}></Col>
         <Col lg={6}>
        <div className="flowRow flowRow_HomeScreen_elZipAutoComplete_758871 d-flex">
        <div className="elZipCodeExplore">
          {/*  <input className="baseFont style_ZipCodeExplore" type="text" placeholder="Search by City,Zip, Community name" onChange={this.textInputChanged_elZipCodeExplore} value={this.state.zipCodeExplore}  /> */}
          </div>
          <div className="elSearchButton pb-5">
          {/*  <button className="style_elSearchButton"  onClick={this.onClick_elSearchButton} >
            <FaSearch />
            </button> */}
          </div>
         </div>
         </Col>
         </Row>
        {/* </Container> */}

         <div className="col-md-10 mx-auto">

         <Container>

         <div className="elButtonBackTop mb-5">
            <Button className="actionFont" style={style_elButtonBackTop} onClick={this.onClick_elButtonBackTop} >
              Back to List
            </Button>
          </div>


          <Row>
          <Col lg={6} md={12} xs={12}>
            <h1 className="title">{value_communityName}</h1>
              <div className="d-flex">
            {/* Basic Image Start */}

             <div className="elImageIL">
               <img  src={this.props.appActions.createImageUrlFromProp(value_imageIL)} alt=""  width="30"/>
             </div>

             <div className="elImageIL">
               <img  src={this.props.appActions.createImageUrlFromProp(value_imageAL)} alt=""  width="30"/>
             </div>

             <div className="elImageIL">
               <img  src={this.props.appActions.createImageUrlFromProp(value_imageMC)} alt=""  width="30"/>
             </div>

             <div className="elImageIL">
               <img  src={this.props.appActions.createImageUrlFromProp(value_imageSN)} alt=""  width="30"/>
             </div>

             </div>

            {/* Information Start */}

             <div className="elCommunityAddress">
            <div className="baseFont" style={style_elCommunityAddress}>
              <div>{value_communityAddress}</div>
            </div>
          </div>
          <div className="elCommunityCity">
            <div className="baseFont" style={style_elCommunityCity}>
              <div>{value_communityCity}</div>
            </div>
          </div>
          <div className="elCommunityPhone">
            <div className="baseFont" style={style_elCommunityPhone}>
              <div>{value_communityPhone}</div>
            </div>
          </div>
          <div className="elCommunityWWW">
            <div className="baseFont" style={style_elCommunityWWW}>
              <a href={value_communityWWW} target="_blank">{value_communityWWW.slice(8,-1)}</a>
            </div>
          </div>
            {/* Basic Information */}
            <div className="row pb-3 pt-3">
         <Col lg={8} md={12} xs={12}>
          <div className="elTextBasicInfo">
              <h1 className="display-3">{this.state.textbasicinfo}</h1>
          </div>
         </Col>
         <Col lg={4} md={12} xs={12}>
          <div className="elEditButton">
            <div className="baseFont pt-2" onClick={this.onClick_elEditButton} >
              <div className="text-decoration-underline pt-3">{value_editButton}</div>
            </div>
          </div>
          </Col>
          </div>

         {/* Calculation Tables */}
          <Table className='table borderless' responsive>
          <thead>
            <tr>
            <th></th>
            <th></th>
           </tr>
         </thead>
        <tbody>
        <tr>
           <td></td>
           <td className="text-right"><b>Licensed Beds</b> </td>
          </tr>
          <tr>
           <td>Independent Living</td>
          {/*<td><b>{value_indeLivingBedCount}</b></td>*/}
           <td className="text-right"><b>{value_indeLivingBedCount}</b></td>
          </tr>
          <tr>
           <td>Assisted Living / Memory Care</td>
           <td className="text-right"><b>{value_assistedLivingBedCount}</b></td>
          </tr>
          <tr>
           <td>Skilled Nursing</td>
           <td className="text-right"><b>{value_skilledNursingBedCount}</b></td>
          </tr>
          <tr className="border-top border-bottom">
           <td>Total</td>
           <td className="text-right"><b>{value_totalBedCount}</b></td>
           <br></br>
          </tr>
        </tbody>
        </Table>
        </Col>
        <Col lg={6} md={12} xs={12}>
        {/*<img  src={Florida} alt=""  className='img-fluid'/>*/}
        <img  src={this.props.appActions.createImageUrlFromProp(value_communityCoverImage)} alt=""  className='img-fluid'/>

        </Col>
        <Col lg={6} md={12} xs={12}>

         {/* Calculation Tables */}
       
        </Col>
        <Col lg={8} md={12} xs={12}>
        <div className="pt-5 pb-5">
          <div className="elTextPricing">
            <div className="systemFontRegular" style={style_elTextPricing}>
              <div>{this.state.textpricing}</div>
              <button className="upload">
             <FaCamera />  Upload Pricing Sheets
             </button>
            </div>
          </div>

          <div className="elTextClickInstruction">
            <div className="font-SFUITextRegular" style={style_elTextClickInstruction}>
              <div className="text-primary">{this.state.textclickinstruction}</div>
            </div>
          </div>
        </div>
          {/* Table Started */}
     
        <div className="d-flex table-space mt-3">


          <div className="coloumns pt-1">
            <h4 className="text-heights"><b>Description</b></h4>
          </div>
           <div className="coloumns pt-1">
            <h4 className="text-heights"><b>Median Price</b></h4>
           </div>
           <div className="coloumns pt-1 table-hide">
            <h4 className="text-heights"><b>Price Reported by:</b></h4>
           </div>
           <div className="coloumns pt-1 table-hide">
            {/*<h4 className="text-heights">-</h4>*/}
          </div>
       </div>

       {/* Individual Living */}

       <div className="d-flex mt-5">
        <div className="elImageILCopy blue">
          <span className='span-light'>IL</span>
        </div>

          <div className="elTextIndeLiving pl-1">
            <div className="systemFontBold" style={style_elTextIndeLiving}>
              <div>{this.state.textindeliving}</div>
            </div>
           </div>
        </div>


        <div className="d-flex table-space mt-3">


          <div className="coloumns pt-1">
            <h5 className="text-height"><b>Community Fee (One-Time)</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_indeLivingCommFeeUser} {value_indeLivingCommFeeDate}</span></p></h5>
            <h5 className="text-height"><b>Studio</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_indeLivingStudioFeeUser} {value_indeLivingStudioFeeDate}</span></p></h5>
            <h5 className="text-height"><b>1-Bedroom</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_indeLiving1bdrmUser} {value_indeLiving1bdrmDate}</span></p></h5>
            <h5 className="text-height"><b>2-Bedroom</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_indeLiving2bdrmUser} {value_indeLiving2bdrmDate}</span></p></h5>
          </div>
           <div className="coloumns pt-1">
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elIndeLiving1bdrmFee} >{value_indeLivingCommFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elIndeLiving2bdrmFee} >{value_indeLivingStudioFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elIndeLiving1bdrmFee} >{value_indeLiving1bdrmFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elIndeLiving2bdrmFee} >{value_indeLiving2bdrmFee}</h5>
           </div>
           <div className="coloumns pt-1 table-hide">
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_indeLivingCommFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_indeLivingCommFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_indeLivingStudioFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_indeLivingStudioFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_indeLiving1bdrmUserPic)} alt=""  width="30" className="image-hide"/> {value_indeLiving1bdrmUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_indeLiving2bdrmUserPic)} alt=""  width="30" className="image-hide"/> {value_indeLiving2bdrmUser}</h5>

           </div>
           <div className="coloumns pt-1 table-hide">
            <h5 className="text-height"> {value_indeLivingCommFeeDate}</h5>
            <h5 className="text-height"> {value_indeLivingStudioFeeDate}</h5>
            <h5 className="text-height"> {value_indeLiving1bdrmDate}</h5>
            <h5 className="text-height"> {value_indeLiving2bdrmDate}</h5>
          </div>
       </div>

 {/* Assistant Living Living */}

        <div className="d-flex mt-5">
        <div className="elImageILCopy yellow">
          <span className='span-light'>AL</span>
        </div>

            <div className="elTextIndeLiving pl-1">
              <div className="systemFontBold" style={style_elTextIndeLiving}>
                <div>Assisted Living</div>
              </div>
             </div>
          </div>

       

        <div className="d-flex table-space mt-3">

        <div className="coloumns pt-1">
          <h5 className="text-height"><b>Community Fee (One-Time)</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_assistedLivingCommFeeUser} {value_assistedLivingCommFeeDate}</span></p></h5>
          <h5 className="text-height"><b>Studio</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_assistedLivingStudioFeeUser} {value_assistedLivingStudioFeeDate}</span></p></h5>
          <h5 className="text-height"><b>1-Bedroom</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_assistedLiving1bdrmFeeUser} {value_assistedLiving1bdrmFeeDate}</span></p></h5>
          <h5 className="text-height"><b>2-Bedroom</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_assistedLiving2bdrmFeeUser} {value_assistedLiving2bdrmFeeDate}</span></p></h5>
          <h5 className="text-height"><b>2-Bedroom Shared</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_assistedLiving2bdrmSharedFeeUser} {value_assistedLiving2bdrmSharedFeeDate}</span></p></h5>
          <h5 className="text-height"><b>2-Occupant fees</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_assistedLiving2ndOccFeeUser} {value_assistedLiving2ndOccFeeDate}</span></p></h5>
          <h5 className="text-height"><b>Care fees</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_assistedLivingCareFeeUser} {value_assistedLivingCareFeeDate}</span></p></h5>
        </div>
        <div className="coloumns pt-1">
          <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elAssistedLivingCommFee} >{value_assistedLivingCommFee}</h5>
          <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elAssistedLivingStudioFee} >{value_assistedLivingStudioFee}</h5>
          <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elAssistedLiving1bdrmFee} >{value_assistedLiving1bdrmFee}</h5>
          <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elAssistedLiving2bdrmFee} >{value_assistedLiving2bdrmFee}</h5>
          <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elAssistedLiving2bdrmSharedFee} >{value_assistedLiving2bdrmSharedFee}</h5>
          <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elAssistedLiving2ndOccFee} >{value_assistedLiving2ndOccFee}</h5>
          <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elAssistedLivingCareFee} >{value_assistedLivingCareFee}</h5>
        </div>
         <div className="coloumns pt-1 table-hide">
          <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLivingCommFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_assistedLivingCommFeeUser}</h5>
          <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLivingStudioFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_assistedLivingStudioFeeUser}</h5>
          <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLiving1bdrmFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_assistedLiving1bdrmFeeUser}</h5>
          <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2bdrmFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_assistedLiving2bdrmFeeUser}</h5>
          <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2bdrmSharedFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_assistedLiving2bdrmSharedFeeUser}</h5>
          <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2ndOccFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_assistedLiving2ndOccFeeUser}</h5>
          <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLivingCareFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_assistedLivingCareFeeUser}</h5>
         </div>
         <div className="coloumns pt-1 table-hide">
          <h5 className="text-height"> {value_assistedLivingCommFeeDate}</h5>
          <h5 className="text-height"> {value_assistedLivingStudioFeeDate}</h5>
          <h5 className="text-height"> {value_assistedLiving1bdrmFeeDate}</h5>
          <h5 className="text-height"> {value_assistedLiving2bdrmFeeDate}</h5>
          <h5 className="text-height"> {value_assistedLiving2bdrmSharedFeeDate}</h5>
          <h5 className="text-height"> {value_assistedLiving2ndOccFeeDate}</h5>
          <h5 className="text-height"> {value_assistedLivingCareFeeDate}</h5>
        </div>
        </div>

        {/* Memory Living Living */}

     <div className="d-flex mt-5">
     <div className="elImageILCopy orange">
          <span className='span-light'>MC</span>
        </div>

          <div className="elTextIndeLiving pl-1">
            <div className="systemFontBold" style={style_elTextIndeLiving}>
              <div>Memory Care</div>
            </div>
           </div>
        </div>

       

        <div className="d-flex table-space mt-3">

          <div className="coloumns pt-1">
            <h5 className="text-height"><b>Community Fee (One-Time)</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_memoryCareCommFeeUser} {value_memoryCareCommFeeDate}</span></p></h5>
            <h5 className="text-height"><b>Studio</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_memoryCareStudioFeeUser} {value_memoryCareStudioFeeDate}</span></p></h5>
            <h5 className="text-height"><b>1-Bedroom</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_memoryCare1bdrmFeeUser} {value_memoryCare1bdrmFeeDate}</span></p></h5>
            <h5 className="text-height"><b>2-Bedroom</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_memoryCare2bdrmFeeUser} {value_memoryCare2bdrmFeeDate}</span></p></h5>
            <h5 className="text-height"><b>2-Bedroom Shared</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_memoryCare2bdrmSharedFeeUser} {value_memoryCare2bdrmSharedFeeDate}</span></p></h5>
            <h5 className="text-height"><b>2-Occupant fees</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_memoryCare2ndOccFeeUser} {value_memoryCare2ndOccFeeDate}</span></p></h5>
            <h5 className="text-height"><b>Care fees</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_memoryCareCareFeeUser} {value_memoryCareCareFeeDate}</span></p></h5>
          </div>
          <div className="coloumns pt-1">
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elMemoryCareCommFee} >{value_memoryCareCommFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elMemoryCareStudioFee} >{value_memoryCareStudioFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elMemoryCare1bdrmFee} >{value_memoryCare1bdrmFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elMemoryCare2bdrmFee} >{value_memoryCare2bdrmFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elMemoryCare2bdrmSharedFee} >{value_memoryCare2bdrmSharedFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elMemoryCare2ndOccFee} >{value_memoryCare2ndOccFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elMemoryCareCareFee} >{value_memoryCareCareFee}</h5>
          </div>
          <div className="coloumns pt-1 table-hide">
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLivingCommFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_memoryCareCommFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLivingStudioFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_memoryCareStudioFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLiving1bdrmFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_memoryCare1bdrmFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2bdrmFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_memoryCare2bdrmFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2bdrmSharedFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_memoryCare2bdrmSharedFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2ndOccFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_memoryCare2ndOccFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_assistedLivingCareFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_memoryCareCareFeeUser}</h5>
          </div>
          <div className="coloumns pt-1 table-hide">
            <h5 className="text-height"> {value_memoryCareCommFeeDate}</h5>
            <h5 className="text-height"> {value_memoryCareStudioFeeDate}</h5>
            <h5 className="text-height"> {value_memoryCare1bdrmFeeDate}</h5>
            <h5 className="text-height"> {value_memoryCare2bdrmFeeDate}</h5>
            <h5 className="text-height"> {value_memoryCare2bdrmSharedFeeDate}</h5>
            <h5 className="text-height"> {value_memoryCare2ndOccFeeDate}</h5>
            <h5 className="text-height"> {value_memoryCareCareFeeDate}</h5>
          </div>
        </div>

        {/* Skilled Nurshing Living */}

       <div className="d-flex mt-5">
       <div className="elImageILCopy red">
          <span className='span-light'>SN</span>
        </div>


          <div className="elTextIndeLiving pl-1">
            <div className="systemFontBold" style={style_elTextIndeLiving}>
              <div>Skilled Nursing</div>
            </div>
           </div>
        </div>

       

        <div className="d-flex table-space mt-3">
          <div className="coloumns pt-1">
            <h5 className="text-height"><b>Private (Daily Rates)</b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_skilledNursingPrivateFeeUser} {value_memoryCareCommFeeDate}</span></p></h5>
            <h5 className="text-height"><b>Semi-Private (Daily Rates)   </b> <br/> <p className="span-table"><b>Price Reported by</b> <br /><span className="span-username">{value_skilledNursingSemiFeeUser} {value_skilledNursingSemiFeeDate}</span></p></h5>
          </div>
          <div className="coloumns pt-1">
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elSkilledNursingPrivateFee} >{value_skilledNursingPrivateFee}</h5>
            <h5 className="text-height text-primary text-decoration-underline" onClick={this.onClick_elSkilledNursingSemiFee} >{value_skilledNursingSemiFee}</h5>
          </div>
          <div className="coloumns pt-1 table-hide">
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_skilledNursingPrivateFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_skilledNursingPrivateFeeUser}</h5>
            <h5 className="text-height"><img  src={this.props.appActions.createImageUrlFromProp(value_skilledNursingSemiFeeUserPic)} alt=""  width="30" className="image-hide"/> {value_skilledNursingSemiFeeUser}</h5>
         </div>
         <div className="coloumns pt-1 table-hide">
            <h5 className="text-height"> {value_skilledNursingPrivateFeeDate}</h5>
            <h5 className="text-height"> {value_skilledNursingSemiFeeDate}</h5>
         </div>
        </div>

        </Col>

      </Row>
      </Container>
      </div>

        <div className="col-md-10 mx-auto">
        <Container>

          <div className="elButtonBackBottom mt-5 mb-5">
            <Button className="actionFont" style={style_elButtonBackBottom} onClick={this.onClick_elButtonBackBottom} >
              Back to List
            </Button>
          </div>

          <div className="elTextPhotos">
            <div className="systemFontRegular">
              <h1>Photos & Videos</h1>
            </div>


          <Row>


             
          </Row>


          <div className="hasNestedComps elPhotoComp">
            <div>
              <ScrollingPhotos {...dataSheet_communities.items[0]} ref={(el)=> this._elPhotoComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>

        <div className="col-md-10 mx-auto mt-5 mb-5">
          <Col lg={12} md={12} xs={12} className="beta-alert text-center">
            <p>Coming Soon: Amenities List, Discussion Boards, and Virtual Tours</p>
          </Col>
        </div>

          <div className="mb-5 mt-5">
            <div />

            <div className="spacer">
              </div>

            </div>

            </Container>
            </div>

        </div>

      </div>

    )
  }

}
