import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
// eslint-disable-next-line
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
// eslint-disable-next-line
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import LoginScreen from './LoginScreen.js';
import UpdateInfoScreen from './UpdateInfoScreen.js';
import CommunityDetailScreen from './CommunityDetailScreen.js';
import CommunityDetailsScreen from './CommunityDetailsScreen.js';
import TCScreen from './TCScreen.js';
import HowItWorksScreen from './HowItWorksScreen';
import AboutUsScreen from './AboutUsScreen.js';
import ContributorScreen from './ContributorScreen';
import CommunityScreen from './CommunityScreen.js';
import LoginHomeScreen from './LoginHomeScreen.js';
import HomeScreen from './HomeScreen.js';
import LoginpasswordScreen from './LoginpasswordScreen.js';
import WriteAReviewScreen from './WriteAReviewScreen.js';
import LoginMain2Screen from './LoginMain2Screen.js';
import Login2HomeScreen from './Login2HomeScreen.js';
import DataSheet_localizationSheet from './DataSheet_localizationSheet.js';
import DataSheet_pickerNumberOfRows from './DataSheet_pickerNumberOfRows.js';
import DataSheet_communities from './DataSheet_communities.js';
import DataSheet_reviews from './DataSheet_reviews.js';
import DataSheet_multiZip2 from './DataSheet_multiZip2.js';
import DataSheet_prices from './DataSheet_prices.js';
import DataSheet_pricesIndeLiving1bdrm from './DataSheet_pricesIndeLiving1bdrm.js';
import DataSheet_pricesIndeLiving2bdrm from './DataSheet_pricesIndeLiving2bdrm.js';
import DataSheet_photos from './DataSheet_photos.js';
import DataSheet_pricesIndeLivingStudio from './DataSheet_pricesIndeLivingStudio.js';
import DataSheet_pricesIndeLivingCommFee from './DataSheet_pricesIndeLivingCommFee.js';
import DataSheet_pricesAssistedLivingStudio from './DataSheet_pricesAssistedLivingStudio.js';
import DataSheet_pricesAssistedLiving1bdrm from './DataSheet_pricesAssistedLiving1bdrm.js';
import DataSheet_pricesAssistedLivingCommFee from './DataSheet_pricesAssistedLivingCommFee.js';
import DataSheet_pricesAssistedLiving2bdrm from './DataSheet_pricesAssistedLiving2bdrm.js';
import DataSheet_pricesAssistedLiving2bdrmShared from './DataSheet_pricesAssistedLiving2bdrmShared.js';
import DataSheet_pricesMemoryCareCommFee from './DataSheet_pricesMemoryCareCommFee.js';
import DataSheet_pricesMemoryCareStudio from './DataSheet_pricesMemoryCareStudio.js';
import DataSheet_pricesMemoryCare1bdrm from './DataSheet_pricesMemoryCare1bdrm.js';
import DataSheet_pricesMemoryCare2bdrm from './DataSheet_pricesMemoryCare2bdrm.js';
import DataSheet_pricesMemoryCare2bdrmShared from './DataSheet_pricesMemoryCare2bdrmShared.js';
import DataSheet_pricesAssistedLiving2ndOcc from './DataSheet_pricesAssistedLiving2ndOcc.js';
import DataSheet_pricesAssistedLivingCareFees from './DataSheet_pricesAssistedLivingCareFees.js';
import DataSheet_pricesMemoryCare2ndOcc from './DataSheet_pricesMemoryCare2ndOcc.js';
import DataSheet_pricesMemoryCareCareFees from './DataSheet_pricesMemoryCareCareFees.js';
import DataSheet_pricesSkilledNursePrivate from './DataSheet_pricesSkilledNursePrivate.js';
import DataSheet_pricesSkilledNurseSemi from './DataSheet_pricesSkilledNurseSemi.js';
import DataSheet_city from './DataSheet_city.js';
import DataSheet_updateInfoDB from './DataSheet_updateInfoDB.js';
import DataSheet_selected_community from './DataSheet_selected_community.js';
import DataSheet_waitList from './DataSheet_waitList.js';
import firebase from 'firebase';
import firestore from 'firebase/firestore';
import HowItWorks from './HowItWorksScreen';


class App extends Component {
  constructor(props) {
    super(props);

    this.dataSheets = {};
    this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
    this.dataSheets['pickerNumberOfRows'] = new DataSheet_pickerNumberOfRows('pickerNumberOfRows', this.dataSheetDidUpdate);
    this.dataSheets['communities'] = new DataSheet_communities('communities', this.dataSheetDidUpdate);
    this.dataSheets['reviews'] = new DataSheet_reviews('reviews', this.dataSheetDidUpdate);
    this.dataSheets['multiZip2'] = new DataSheet_multiZip2('multiZip2', this.dataSheetDidUpdate);
    this.dataSheets['prices'] = new DataSheet_prices('prices', this.dataSheetDidUpdate);
    this.dataSheets['pricesIndeLiving1bdrm'] = new DataSheet_pricesIndeLiving1bdrm('pricesIndeLiving1bdrm', this.dataSheetDidUpdate);
    this.dataSheets['pricesIndeLiving2bdrm'] = new DataSheet_pricesIndeLiving2bdrm('pricesIndeLiving2bdrm', this.dataSheetDidUpdate);
    this.dataSheets['photos'] = new DataSheet_photos('photos', this.dataSheetDidUpdate);
    this.dataSheets['pricesIndeLivingStudio'] = new DataSheet_pricesIndeLivingStudio('pricesIndeLivingStudio', this.dataSheetDidUpdate);
    this.dataSheets['pricesIndeLivingCommFee'] = new DataSheet_pricesIndeLivingCommFee('pricesIndeLivingCommFee', this.dataSheetDidUpdate);
    this.dataSheets['pricesAssistedLivingStudio'] = new DataSheet_pricesAssistedLivingStudio('pricesAssistedLivingStudio', this.dataSheetDidUpdate);
    this.dataSheets['pricesAssistedLiving1bdrm'] = new DataSheet_pricesAssistedLiving1bdrm('pricesAssistedLiving1bdrm', this.dataSheetDidUpdate);
    this.dataSheets['pricesAssistedLivingCommFee'] = new DataSheet_pricesAssistedLivingCommFee('pricesAssistedLivingCommFee', this.dataSheetDidUpdate);
    this.dataSheets['pricesAssistedLiving2bdrm'] = new DataSheet_pricesAssistedLiving2bdrm('pricesAssistedLiving2bdrm', this.dataSheetDidUpdate);
    this.dataSheets['pricesAssistedLiving2bdrmShared'] = new DataSheet_pricesAssistedLiving2bdrmShared('pricesAssistedLiving2bdrmShared', this.dataSheetDidUpdate);
    this.dataSheets['pricesMemoryCareCommFee'] = new DataSheet_pricesMemoryCareCommFee('pricesMemoryCareCommFee', this.dataSheetDidUpdate);
    this.dataSheets['pricesMemoryCareStudio'] = new DataSheet_pricesMemoryCareStudio('pricesMemoryCareStudio', this.dataSheetDidUpdate);
    this.dataSheets['pricesMemoryCare1bdrm'] = new DataSheet_pricesMemoryCare1bdrm('pricesMemoryCare1bdrm', this.dataSheetDidUpdate);
    this.dataSheets['pricesMemoryCare2bdrm'] = new DataSheet_pricesMemoryCare2bdrm('pricesMemoryCare2bdrm', this.dataSheetDidUpdate);
    this.dataSheets['pricesMemoryCare2bdrmShared'] = new DataSheet_pricesMemoryCare2bdrmShared('pricesMemoryCare2bdrmShared', this.dataSheetDidUpdate);
    this.dataSheets['pricesAssistedLiving2ndOcc'] = new DataSheet_pricesAssistedLiving2ndOcc('pricesAssistedLiving2ndOcc', this.dataSheetDidUpdate);
    this.dataSheets['pricesAssistedLivingCareFees'] = new DataSheet_pricesAssistedLivingCareFees('pricesAssistedLivingCareFees', this.dataSheetDidUpdate);
    this.dataSheets['pricesMemoryCare2ndOcc'] = new DataSheet_pricesMemoryCare2ndOcc('pricesMemoryCare2ndOcc', this.dataSheetDidUpdate);
    this.dataSheets['pricesMemoryCareCareFees'] = new DataSheet_pricesMemoryCareCareFees('pricesMemoryCareCareFees', this.dataSheetDidUpdate);
    this.dataSheets['pricesSkilledNursePrivate'] = new DataSheet_pricesSkilledNursePrivate('pricesSkilledNursePrivate', this.dataSheetDidUpdate);
    this.dataSheets['pricesSkilledNurseSemi'] = new DataSheet_pricesSkilledNurseSemi('pricesSkilledNurseSemi', this.dataSheetDidUpdate);
    this.dataSheets['city'] = new DataSheet_city('city', this.dataSheetDidUpdate);
    this.dataSheets['updateInfoDB'] = new DataSheet_updateInfoDB('updateInfoDB', this.dataSheetDidUpdate);
    this.dataSheets['selected_community'] = new DataSheet_selected_community('selected_community', this.dataSheetDidUpdate);
    this.dataSheets['waitList'] = new DataSheet_waitList('waitList', this.dataSheetDidUpdate);
    this.dataSheetLoaded = {};

    this.dataSlots = {};
    this.dataSlots['ds_activeLang'] = "en";
    this.dataSlots['ds_SlotUserID'] = "wQAgECn9AAZH7BkIgmq6uZq5xwp1";
    this.dataSlots['ds_SlotUserName'] = "";
    this.dataSlots['ds_SlotUserEmail'] = "";
    this.dataSlots['ds_SlotReviewRating'] = "0";
    this.dataSlots['ds_numberOfCommunities'] = "9";
    // this.dataSlots['ds_selectedCommunityId'] = "Dcf7AKv3fHyYt8bVwQBV";
    this.dataSlots['ds_selectedCommunityId'] = localStorage.getItem('ds_selectedCommunityId') || "";
    this.dataSlots['ds_selectedCommunityName'] = "";
    this.dataSlots['ds_selectedCommunityWWW'] = "";
    this.dataSlots['ds_enteredZipCode'] = "96822";
    this.dataSlots['ds_selectedCommunityPhone'] = "";
    this.dataSlots['ds_communityFeeEntered'] = "";
    this.dataSlots['ds_selectedCommunityAddress'] = "";
    this.dataSlots['ds_selectedCommunityCoverImage'] = "";
    this.dataSlots['ds_indeLivingStudioEntered'] = "";
    this.dataSlots['ds_indeLiving2bdrmEntered'] = "";
    this.dataSlots['ds_indeLiving1bdrmEntered'] = "";
    this.dataSlots['ds_indeLiving2ndOccuFeeEntered'] = "";
    this.dataSlots['ds_indeLivingCareFeeEntered'] = "";
    this.dataSlots['ds_communityListIndex'] = "0";
    this.dataSlots['ds_uploadedImagePath'] = "";
    this.dataSlots['ds_uploadedImageFilename'] = "";
    this.dataSlots['ds_indeLivingCommFeeEntered'] = "";
    this.dataSlots['ds_assistedLivingCommFeeEntered'] = "";
    this.dataSlots['ds_assistedLivingStudioEntered'] = "";
    this.dataSlots['ds_assistedLiving1bdrmEntered'] = "";
    this.dataSlots['ds_assistedLiving2bdrmEntered'] = "";
    this.dataSlots['ds_assistedLiving2bdrmSharedEntered'] = "";
    this.dataSlots['ds_assistedLiving2ndOccEntered'] = "";
    this.dataSlots['ds_assistedLivingCareFeesEntered'] = "";
    this.dataSlots['ds_memoryCareCommFeeEntered'] = "";
    this.dataSlots['ds_memoryCareStudioEntered'] = "";
    this.dataSlots['ds_memoryCare1bdrmEntered'] = "";
    this.dataSlots['ds_memoryCare2bdrmEntered'] = "";
    this.dataSlots['ds_memoryCare2bdrmSharedEntered'] = "";
    this.dataSlots['ds_memoryCare2ndOccEntered'] = "";
    this.dataSlots['ds_skilledNursePrivateEntered'] = "";
    this.dataSlots['ds_skilledNurseSemiEntered'] = "";
    this.dataSlots['ds_memoryCareCareFeesEntered'] = "";
    this.dataSlots['ds_MixedCaseAlias'] = "";
    this.dataSlots['ds_ilFlag'] = "false";
    this.dataSlots['ds_loginTransition'] = "";
    this.dataSlots['ds_alFlag'] = "";
    this.dataSlots['ds_mcFlag'] = "";
    this.dataSlots['ds_snFlag'] = "";
    this.dataSlots['ds_ilBeds'] = "";
    this.dataSlots['ds_alBeds'] = "";
    this.dataSlots['ds_mcBeds'] = "";
    this.dataSlots['ds_snBeds'] = "";
    this.dataSlots['ds_selectedCommunityCity'] = "";
    this.dataSlots['ds_selectedCommunityState'] = "";
    this.dataSlots['ds_selectedCommunityZip'] = "";

    this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);


    // Initialize web service plugin 'firebase-wikiliving'
    firebase.initializeApp({
        apiKey: "AIzaSyCCWawWcZc8jAPpLpgg7lncz6KJlOiifJk",
        authDomain: "wikiliving.firebaseapp.com",
        databaseURL: "https://wikiliving.firebaseio.com",
        projectId: "wikiliving",
        storageBucket: "wikiliving.appspot.com",
        messagingSenderId: "1045851420701",
        appId: "1:1045851420701:web:cee8375a0907e5256e2ef4",
        measurementId: "G-728QSRCZEW"
    });
    firebase.firestore().settings({});

    this.serviceOptions_communities = {
      dataSlots: this.dataSlots,
      servicePath: "communities",
      query: "where(\"neighboringZip.$slot('ds_enteredZipCode')\", \">\", 0).orderBy(\"neighboringZip.$slot('ds_enteredZipCode')\").limit($slot('ds_numberOfCommunities'))",
    };
    this.dataSheets['communities'].appActions = this;
    this.dataSheets['communities'].firebase = firebase;

    this.serviceOptions_reviews = {
      dataSlots: this.dataSlots,
      servicePath: "reviews",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"reviewDate\",\"desc\")",
    };
    this.dataSheets['reviews'].appActions = this;
    this.dataSheets['reviews'].firebase = firebase;

    this.serviceOptions_multiZip2 = {
      dataSlots: this.dataSlots,
      servicePath: "",
      query: "",
    };
    this.dataSheets['multiZip2'].appActions = this;
    this.dataSheets['multiZip2'].dataSlots = this.dataSlots;

    this.serviceOptions_prices = {
      dataSlots: this.dataSlots,
      servicePath: "prices",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"communityFeeQty\",\"desc\").limit(1)",
    };
    this.dataSheets['prices'].appActions = this;
    this.dataSheets['prices'].firebase = firebase;

    this.serviceOptions_pricesIndeLiving1bdrm = {
      dataSlots: this.dataSlots,
      servicePath: "pricesIndeLiving1bdrm",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"indeLiving1bdrmQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesIndeLiving1bdrm'].appActions = this;
    this.dataSheets['pricesIndeLiving1bdrm'].firebase = firebase;

    this.serviceOptions_pricesIndeLiving2bdrm = {
      dataSlots: this.dataSlots,
      servicePath: "pricesIndeLiving2bdrm",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"indeLiving2bdrmQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesIndeLiving2bdrm'].appActions = this;
    this.dataSheets['pricesIndeLiving2bdrm'].firebase = firebase;

    this.serviceOptions_photos = {
      dataSlots: this.dataSlots,
      servicePath: "photos",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"timestamp\",\"desc\")",
    };
    this.dataSheets['photos'].appActions = this;
    this.dataSheets['photos'].firebase = firebase;

    this.serviceOptions_pricesIndeLivingStudio = {
      dataSlots: this.dataSlots,
      servicePath: "pricesIndeLivingStudio",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"indeLivingStudioQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesIndeLivingStudio'].appActions = this;
    this.dataSheets['pricesIndeLivingStudio'].firebase = firebase;

    this.serviceOptions_pricesIndeLivingCommFee = {
      dataSlots: this.dataSlots,
      servicePath: "pricesIndeLivingCommFee",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"indeLivingCommFeeQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesIndeLivingCommFee'].appActions = this;
    this.dataSheets['pricesIndeLivingCommFee'].firebase = firebase;

    this.serviceOptions_pricesAssistedLivingStudio = {
      dataSlots: this.dataSlots,
      servicePath: "pricesAssistedLivingStudio",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"assistedLivingStudioQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesAssistedLivingStudio'].appActions = this;
    this.dataSheets['pricesAssistedLivingStudio'].firebase = firebase;

    this.serviceOptions_pricesAssistedLiving1bdrm = {
      dataSlots: this.dataSlots,
      servicePath: "pricesAssistedLiving1bdrm",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"assistedLiving1bdrmQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesAssistedLiving1bdrm'].appActions = this;
    this.dataSheets['pricesAssistedLiving1bdrm'].firebase = firebase;

    this.serviceOptions_pricesAssistedLivingCommFee = {
      dataSlots: this.dataSlots,
      servicePath: "pricesAssistedLivingCommFee",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"assistedLivingCommFeeQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesAssistedLivingCommFee'].appActions = this;
    this.dataSheets['pricesAssistedLivingCommFee'].firebase = firebase;

    this.serviceOptions_pricesAssistedLiving2bdrm = {
      dataSlots: this.dataSlots,
      servicePath: "pricesAssistedLiving2bdrm",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"assistedLiving2bdrmQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesAssistedLiving2bdrm'].appActions = this;
    this.dataSheets['pricesAssistedLiving2bdrm'].firebase = firebase;

    this.serviceOptions_pricesAssistedLiving2bdrmShared = {
      dataSlots: this.dataSlots,
      servicePath: "pricesAssistedLiving2bdrmShared",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"assistedLiving2bdrmSharedQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesAssistedLiving2bdrmShared'].appActions = this;
    this.dataSheets['pricesAssistedLiving2bdrmShared'].firebase = firebase;

    this.serviceOptions_pricesMemoryCareCommFee = {
      dataSlots: this.dataSlots,
      servicePath: "pricesMemoryCareCommFee",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"memoryCareCommFeeQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesMemoryCareCommFee'].appActions = this;
    this.dataSheets['pricesMemoryCareCommFee'].firebase = firebase;

    this.serviceOptions_pricesMemoryCareStudio = {
      dataSlots: this.dataSlots,
      servicePath: "pricesMemoryCareStudio",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"memoryCareStudioQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesMemoryCareStudio'].appActions = this;
    this.dataSheets['pricesMemoryCareStudio'].firebase = firebase;

    this.serviceOptions_pricesMemoryCare1bdrm = {
      dataSlots: this.dataSlots,
      servicePath: "pricesMemoryCare1bdrm",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"memoryCare1bdrmQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesMemoryCare1bdrm'].appActions = this;
    this.dataSheets['pricesMemoryCare1bdrm'].firebase = firebase;

    this.serviceOptions_pricesMemoryCare2bdrm = {
      dataSlots: this.dataSlots,
      servicePath: "pricesMemoryCare2bdrm",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"memoryCare2bdrmQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesMemoryCare2bdrm'].appActions = this;
    this.dataSheets['pricesMemoryCare2bdrm'].firebase = firebase;

    this.serviceOptions_pricesMemoryCare2bdrmShared = {
      dataSlots: this.dataSlots,
      servicePath: "pricesMemoryCare2bdrmShared",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"memoryCare2bdrmSharedQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesMemoryCare2bdrmShared'].appActions = this;
    this.dataSheets['pricesMemoryCare2bdrmShared'].firebase = firebase;

    this.serviceOptions_pricesAssistedLiving2ndOcc = {
      dataSlots: this.dataSlots,
      servicePath: "pricesAssistedLiving2ndOcc",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"assistedLiving2ndOccQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesAssistedLiving2ndOcc'].appActions = this;
    this.dataSheets['pricesAssistedLiving2ndOcc'].firebase = firebase;

    this.serviceOptions_pricesAssistedLivingCareFees = {
      dataSlots: this.dataSlots,
      servicePath: "pricesAssistedLivingCareFees",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"assistedLivingCareFeesQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesAssistedLivingCareFees'].appActions = this;
    this.dataSheets['pricesAssistedLivingCareFees'].firebase = firebase;

    this.serviceOptions_pricesMemoryCare2ndOcc = {
      dataSlots: this.dataSlots,
      servicePath: "pricesMemoryCare2ndOcc",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"memoryCare2ndOccQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesMemoryCare2ndOcc'].appActions = this;
    this.dataSheets['pricesMemoryCare2ndOcc'].firebase = firebase;

    this.serviceOptions_pricesMemoryCareCareFees = {
      dataSlots: this.dataSlots,
      servicePath: "pricesMemoryCareCareFees",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"memoryCareCareFeesQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesMemoryCareCareFees'].appActions = this;
    this.dataSheets['pricesMemoryCareCareFees'].firebase = firebase;

    this.serviceOptions_pricesSkilledNursePrivate = {
      dataSlots: this.dataSlots,
      servicePath: "pricesSkilledNursePrivate",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"skilledNursePrivateQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesSkilledNursePrivate'].appActions = this;
    this.dataSheets['pricesSkilledNursePrivate'].firebase = firebase;

    this.serviceOptions_pricesSkilledNurseSemi = {
      dataSlots: this.dataSlots,
      servicePath: "pricesSkilledNurseSemi",
      query: "where(\"communityID\", \"==\", \"$slot('ds_selectedCommunityId')\").orderBy(\"skilledNurseSemiQty\",\"desc\").limit(1)",
    };
    this.dataSheets['pricesSkilledNurseSemi'].appActions = this;
    this.dataSheets['pricesSkilledNurseSemi'].firebase = firebase;

    this.serviceOptions_city = {
      dataSlots: this.dataSlots,
      servicePath: "",
      query: "key=YGIEAUPT7S0W79FFIU8F",
    };
    this.dataSheets['city'].appActions = this;
    this.dataSheets['city'].dataSlots = this.dataSlots;

    this.serviceOptions_updateInfoDB = {
      dataSlots: this.dataSlots,
      servicePath: "updateInfo",
      query: "orderBy(\"timestamp\",\"desc\").limit(1)",
    };
    this.dataSheets['updateInfoDB'].appActions = this;
    this.dataSheets['updateInfoDB'].firebase = firebase;

    this.serviceOptions_selected_community = {
      dataSlots: this.dataSlots,
      servicePath: "communities",
      query: "where(\"key\", \"==\", \"$slot('ds_selectedCommunityId')\")",
    };
    this.dataSheets['selected_community'].appActions = this;
    this.dataSheets['selected_community'].firebase = firebase;

    this.serviceOptions_waitList = {
      dataSlots: this.dataSlots,
      servicePath: "waitList",
      query: "orderBy(\"timestamp\",\"desc\").limit(1)",
    };
    this.dataSheets['waitList'].appActions = this;
    this.dataSheets['waitList'].firebase = firebase;


    this.state = {
      screenTransitionForward: true,
    }

  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = 'narrow-phone';
    else if (w < 768) formatId = 'wide-phone';
    else if (w < 1024) formatId = 'narrow-tablet';
    else formatId = 'wide-tablet';
    if (formatId !== this.state.screenFormatId) {
      this.setState({screenFormatId: formatId});
    }
  }

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener('resize', this.windowDidResize);

    this.serviceOptions_communities.servicePath = this.dataSheets['communities'].expandSlotTemplateString("communities", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['communities'], this.serviceOptions_communities, true);

    this.serviceOptions_prices.servicePath = this.dataSheets['prices'].expandSlotTemplateString("prices", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['prices'], this.serviceOptions_prices, true);

    this.serviceOptions_pricesIndeLiving1bdrm.servicePath = this.dataSheets['pricesIndeLiving1bdrm'].expandSlotTemplateString("pricesIndeLiving1bdrm", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLiving1bdrm'], this.serviceOptions_pricesIndeLiving1bdrm, true);

    this.serviceOptions_pricesIndeLiving2bdrm.servicePath = this.dataSheets['pricesIndeLiving2bdrm'].expandSlotTemplateString("pricesIndeLiving2bdrm", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLiving2bdrm'], this.serviceOptions_pricesIndeLiving2bdrm, true);

    this.serviceOptions_photos.servicePath = this.dataSheets['photos'].expandSlotTemplateString("photos", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['photos'], this.serviceOptions_photos, true);

    this.serviceOptions_pricesIndeLivingStudio.servicePath = this.dataSheets['pricesIndeLivingStudio'].expandSlotTemplateString("pricesIndeLivingStudio", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLivingStudio'], this.serviceOptions_pricesIndeLivingStudio, true);

    this.serviceOptions_pricesIndeLivingCommFee.servicePath = this.dataSheets['pricesIndeLivingCommFee'].expandSlotTemplateString("pricesIndeLivingCommFee", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLivingCommFee'], this.serviceOptions_pricesIndeLivingCommFee, true);

    this.serviceOptions_pricesAssistedLivingStudio.servicePath = this.dataSheets['pricesAssistedLivingStudio'].expandSlotTemplateString("pricesAssistedLivingStudio", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingStudio'], this.serviceOptions_pricesAssistedLivingStudio, true);

    this.serviceOptions_pricesAssistedLiving1bdrm.servicePath = this.dataSheets['pricesAssistedLiving1bdrm'].expandSlotTemplateString("pricesAssistedLiving1bdrm", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving1bdrm'], this.serviceOptions_pricesAssistedLiving1bdrm, true);

    this.serviceOptions_pricesAssistedLivingCommFee.servicePath = this.dataSheets['pricesAssistedLivingCommFee'].expandSlotTemplateString("pricesAssistedLivingCommFee", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingCommFee'], this.serviceOptions_pricesAssistedLivingCommFee, true);

    this.serviceOptions_pricesAssistedLiving2bdrm.servicePath = this.dataSheets['pricesAssistedLiving2bdrm'].expandSlotTemplateString("pricesAssistedLiving2bdrm", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2bdrm'], this.serviceOptions_pricesAssistedLiving2bdrm, true);

    this.serviceOptions_pricesAssistedLiving2bdrmShared.servicePath = this.dataSheets['pricesAssistedLiving2bdrmShared'].expandSlotTemplateString("pricesAssistedLiving2bdrmShared", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2bdrmShared'], this.serviceOptions_pricesAssistedLiving2bdrmShared, true);

    this.serviceOptions_pricesMemoryCareCommFee.servicePath = this.dataSheets['pricesMemoryCareCommFee'].expandSlotTemplateString("pricesMemoryCareCommFee", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareCommFee'], this.serviceOptions_pricesMemoryCareCommFee, true);

    this.serviceOptions_pricesMemoryCareStudio.servicePath = this.dataSheets['pricesMemoryCareStudio'].expandSlotTemplateString("pricesMemoryCareStudio", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareStudio'], this.serviceOptions_pricesMemoryCareStudio, true);

    this.serviceOptions_pricesMemoryCare1bdrm.servicePath = this.dataSheets['pricesMemoryCare1bdrm'].expandSlotTemplateString("pricesMemoryCare1bdrm", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare1bdrm'], this.serviceOptions_pricesMemoryCare1bdrm, true);

    this.serviceOptions_pricesMemoryCare2bdrm.servicePath = this.dataSheets['pricesMemoryCare2bdrm'].expandSlotTemplateString("pricesMemoryCare2bdrm", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2bdrm'], this.serviceOptions_pricesMemoryCare2bdrm, true);

    this.serviceOptions_pricesMemoryCare2bdrmShared.servicePath = this.dataSheets['pricesMemoryCare2bdrmShared'].expandSlotTemplateString("pricesMemoryCare2bdrmShared", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2bdrmShared'], this.serviceOptions_pricesMemoryCare2bdrmShared, true);

    this.serviceOptions_pricesAssistedLiving2ndOcc.servicePath = this.dataSheets['pricesAssistedLiving2ndOcc'].expandSlotTemplateString("pricesAssistedLiving2ndOcc", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2ndOcc'], this.serviceOptions_pricesAssistedLiving2ndOcc, true);

    this.serviceOptions_pricesAssistedLivingCareFees.servicePath = this.dataSheets['pricesAssistedLivingCareFees'].expandSlotTemplateString("pricesAssistedLivingCareFees", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingCareFees'], this.serviceOptions_pricesAssistedLivingCareFees, true);

    this.serviceOptions_pricesMemoryCare2ndOcc.servicePath = this.dataSheets['pricesMemoryCare2ndOcc'].expandSlotTemplateString("pricesMemoryCare2ndOcc", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2ndOcc'], this.serviceOptions_pricesMemoryCare2ndOcc, true);

    this.serviceOptions_pricesMemoryCareCareFees.servicePath = this.dataSheets['pricesMemoryCareCareFees'].expandSlotTemplateString("pricesMemoryCareCareFees", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareCareFees'], this.serviceOptions_pricesMemoryCareCareFees, true);

    this.serviceOptions_pricesSkilledNursePrivate.servicePath = this.dataSheets['pricesSkilledNursePrivate'].expandSlotTemplateString("pricesSkilledNursePrivate", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesSkilledNursePrivate'], this.serviceOptions_pricesSkilledNursePrivate, true);

    this.serviceOptions_pricesSkilledNurseSemi.servicePath = this.dataSheets['pricesSkilledNurseSemi'].expandSlotTemplateString("pricesSkilledNurseSemi", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['pricesSkilledNurseSemi'], this.serviceOptions_pricesSkilledNurseSemi, true);

    this.serviceOptions_updateInfoDB.servicePath = this.dataSheets['updateInfoDB'].expandSlotTemplateString("updateInfo", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['updateInfoDB'], this.serviceOptions_updateInfoDB, true);

    this.serviceOptions_selected_community.servicePath = this.dataSheets['selected_community'].expandSlotTemplateString("communities", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['selected_community'], this.serviceOptions_selected_community, true);

    this.serviceOptions_waitList.servicePath = this.dataSheets['waitList'].expandSlotTemplateString("waitList", this.dataSlots);
    this.loadData_firebasewikiliving(this.dataSheets['waitList'], this.serviceOptions_waitList, true);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  goToScreen = (screenId, props) => {
    // This method is the default implementation and could be customized by a navigation plugin.
    this.props.history.push('/'+screenId, {...props, appActions: null, locStrings: null, dataSheets: null});
    window.scrollTo(0, 0);
  }

  goBack = () => {
    // This method is the default implementation and could be customized by a navigation plugin.
    this.props.history.goBack();
  }

  getDataSheet = (sheetId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    return this.dataSheets[sheetId];
  }

  addToDataSheet = (sheetId, newRow, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && newRow) {
      let promise = sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateInDataSheet = (sheetId, row, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  removeFromDataSheet = (sheetId, row) => {
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateDataSlot = (slotId, value, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    if (value === this.dataSlots[slotId])
      return;

    this.dataSlots[slotId] = value;

    if (slotId === 'ds_activeLang') {
      this.locStrings.setLanguage(value);
    }
    if (this.serviceOptions_communities.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['communities'].expandSlotTemplateString(this.serviceOptions_communities.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['communities'], this.serviceOptions_communities, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['communities'].expandSlotTemplateString("communities", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_communities.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['communities'], this.serviceOptions_communities, true);
      }
    }
    if (this.serviceOptions_reviews.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['reviews'].expandSlotTemplateString(this.serviceOptions_reviews.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['reviews'], this.serviceOptions_reviews, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['reviews'].expandSlotTemplateString("reviews", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_reviews.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['reviews'], this.serviceOptions_reviews, true);
      }
    }
    if (this.serviceOptions_prices.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['prices'].expandSlotTemplateString(this.serviceOptions_prices.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['prices'], this.serviceOptions_prices, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['prices'].expandSlotTemplateString("prices", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_prices.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['prices'], this.serviceOptions_prices, true);
      }
    }
    if (this.serviceOptions_pricesIndeLiving1bdrm.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesIndeLiving1bdrm'].expandSlotTemplateString(this.serviceOptions_pricesIndeLiving1bdrm.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLiving1bdrm'], this.serviceOptions_pricesIndeLiving1bdrm, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesIndeLiving1bdrm'].expandSlotTemplateString("pricesIndeLiving1bdrm", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesIndeLiving1bdrm.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLiving1bdrm'], this.serviceOptions_pricesIndeLiving1bdrm, true);
      }
    }
    if (this.serviceOptions_pricesIndeLiving2bdrm.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesIndeLiving2bdrm'].expandSlotTemplateString(this.serviceOptions_pricesIndeLiving2bdrm.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLiving2bdrm'], this.serviceOptions_pricesIndeLiving2bdrm, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesIndeLiving2bdrm'].expandSlotTemplateString("pricesIndeLiving2bdrm", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesIndeLiving2bdrm.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLiving2bdrm'], this.serviceOptions_pricesIndeLiving2bdrm, true);
      }
    }
    if (this.serviceOptions_photos.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['photos'].expandSlotTemplateString(this.serviceOptions_photos.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['photos'], this.serviceOptions_photos, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['photos'].expandSlotTemplateString("photos", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_photos.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['photos'], this.serviceOptions_photos, true);
      }
    }
    if (this.serviceOptions_pricesIndeLivingStudio.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesIndeLivingStudio'].expandSlotTemplateString(this.serviceOptions_pricesIndeLivingStudio.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLivingStudio'], this.serviceOptions_pricesIndeLivingStudio, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesIndeLivingStudio'].expandSlotTemplateString("pricesIndeLivingStudio", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesIndeLivingStudio.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLivingStudio'], this.serviceOptions_pricesIndeLivingStudio, true);
      }
    }
    if (this.serviceOptions_pricesIndeLivingCommFee.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesIndeLivingCommFee'].expandSlotTemplateString(this.serviceOptions_pricesIndeLivingCommFee.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLivingCommFee'], this.serviceOptions_pricesIndeLivingCommFee, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesIndeLivingCommFee'].expandSlotTemplateString("pricesIndeLivingCommFee", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesIndeLivingCommFee.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesIndeLivingCommFee'], this.serviceOptions_pricesIndeLivingCommFee, true);
      }
    }
    if (this.serviceOptions_pricesAssistedLivingStudio.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesAssistedLivingStudio'].expandSlotTemplateString(this.serviceOptions_pricesAssistedLivingStudio.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingStudio'], this.serviceOptions_pricesAssistedLivingStudio, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesAssistedLivingStudio'].expandSlotTemplateString("pricesAssistedLivingStudio", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesAssistedLivingStudio.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingStudio'], this.serviceOptions_pricesAssistedLivingStudio, true);
      }
    }
    if (this.serviceOptions_pricesAssistedLiving1bdrm.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesAssistedLiving1bdrm'].expandSlotTemplateString(this.serviceOptions_pricesAssistedLiving1bdrm.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving1bdrm'], this.serviceOptions_pricesAssistedLiving1bdrm, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesAssistedLiving1bdrm'].expandSlotTemplateString("pricesAssistedLiving1bdrm", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesAssistedLiving1bdrm.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving1bdrm'], this.serviceOptions_pricesAssistedLiving1bdrm, true);
      }
    }
    if (this.serviceOptions_pricesAssistedLivingCommFee.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesAssistedLivingCommFee'].expandSlotTemplateString(this.serviceOptions_pricesAssistedLivingCommFee.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingCommFee'], this.serviceOptions_pricesAssistedLivingCommFee, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesAssistedLivingCommFee'].expandSlotTemplateString("pricesAssistedLivingCommFee", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesAssistedLivingCommFee.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingCommFee'], this.serviceOptions_pricesAssistedLivingCommFee, true);
      }
    }
    if (this.serviceOptions_pricesAssistedLiving2bdrm.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesAssistedLiving2bdrm'].expandSlotTemplateString(this.serviceOptions_pricesAssistedLiving2bdrm.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2bdrm'], this.serviceOptions_pricesAssistedLiving2bdrm, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesAssistedLiving2bdrm'].expandSlotTemplateString("pricesAssistedLiving2bdrm", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesAssistedLiving2bdrm.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2bdrm'], this.serviceOptions_pricesAssistedLiving2bdrm, true);
      }
    }
    if (this.serviceOptions_pricesAssistedLiving2bdrmShared.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesAssistedLiving2bdrmShared'].expandSlotTemplateString(this.serviceOptions_pricesAssistedLiving2bdrmShared.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2bdrmShared'], this.serviceOptions_pricesAssistedLiving2bdrmShared, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesAssistedLiving2bdrmShared'].expandSlotTemplateString("pricesAssistedLiving2bdrmShared", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesAssistedLiving2bdrmShared.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2bdrmShared'], this.serviceOptions_pricesAssistedLiving2bdrmShared, true);
      }
    }
    if (this.serviceOptions_pricesMemoryCareCommFee.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesMemoryCareCommFee'].expandSlotTemplateString(this.serviceOptions_pricesMemoryCareCommFee.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareCommFee'], this.serviceOptions_pricesMemoryCareCommFee, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesMemoryCareCommFee'].expandSlotTemplateString("pricesMemoryCareCommFee", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesMemoryCareCommFee.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareCommFee'], this.serviceOptions_pricesMemoryCareCommFee, true);
      }
    }
    if (this.serviceOptions_pricesMemoryCareStudio.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesMemoryCareStudio'].expandSlotTemplateString(this.serviceOptions_pricesMemoryCareStudio.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareStudio'], this.serviceOptions_pricesMemoryCareStudio, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesMemoryCareStudio'].expandSlotTemplateString("pricesMemoryCareStudio", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesMemoryCareStudio.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareStudio'], this.serviceOptions_pricesMemoryCareStudio, true);
      }
    }
    if (this.serviceOptions_pricesMemoryCare1bdrm.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesMemoryCare1bdrm'].expandSlotTemplateString(this.serviceOptions_pricesMemoryCare1bdrm.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare1bdrm'], this.serviceOptions_pricesMemoryCare1bdrm, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesMemoryCare1bdrm'].expandSlotTemplateString("pricesMemoryCare1bdrm", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesMemoryCare1bdrm.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare1bdrm'], this.serviceOptions_pricesMemoryCare1bdrm, true);
      }
    }
    if (this.serviceOptions_pricesMemoryCare2bdrm.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesMemoryCare2bdrm'].expandSlotTemplateString(this.serviceOptions_pricesMemoryCare2bdrm.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2bdrm'], this.serviceOptions_pricesMemoryCare2bdrm, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesMemoryCare2bdrm'].expandSlotTemplateString("pricesMemoryCare2bdrm", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesMemoryCare2bdrm.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2bdrm'], this.serviceOptions_pricesMemoryCare2bdrm, true);
      }
    }
    if (this.serviceOptions_pricesMemoryCare2bdrmShared.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesMemoryCare2bdrmShared'].expandSlotTemplateString(this.serviceOptions_pricesMemoryCare2bdrmShared.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2bdrmShared'], this.serviceOptions_pricesMemoryCare2bdrmShared, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesMemoryCare2bdrmShared'].expandSlotTemplateString("pricesMemoryCare2bdrmShared", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesMemoryCare2bdrmShared.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2bdrmShared'], this.serviceOptions_pricesMemoryCare2bdrmShared, true);
      }
    }
    if (this.serviceOptions_pricesAssistedLiving2ndOcc.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesAssistedLiving2ndOcc'].expandSlotTemplateString(this.serviceOptions_pricesAssistedLiving2ndOcc.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2ndOcc'], this.serviceOptions_pricesAssistedLiving2ndOcc, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesAssistedLiving2ndOcc'].expandSlotTemplateString("pricesAssistedLiving2ndOcc", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesAssistedLiving2ndOcc.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLiving2ndOcc'], this.serviceOptions_pricesAssistedLiving2ndOcc, true);
      }
    }
    if (this.serviceOptions_pricesAssistedLivingCareFees.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesAssistedLivingCareFees'].expandSlotTemplateString(this.serviceOptions_pricesAssistedLivingCareFees.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingCareFees'], this.serviceOptions_pricesAssistedLivingCareFees, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesAssistedLivingCareFees'].expandSlotTemplateString("pricesAssistedLivingCareFees", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesAssistedLivingCareFees.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesAssistedLivingCareFees'], this.serviceOptions_pricesAssistedLivingCareFees, true);
      }
    }
    if (this.serviceOptions_pricesMemoryCare2ndOcc.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesMemoryCare2ndOcc'].expandSlotTemplateString(this.serviceOptions_pricesMemoryCare2ndOcc.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2ndOcc'], this.serviceOptions_pricesMemoryCare2ndOcc, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesMemoryCare2ndOcc'].expandSlotTemplateString("pricesMemoryCare2ndOcc", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesMemoryCare2ndOcc.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCare2ndOcc'], this.serviceOptions_pricesMemoryCare2ndOcc, true);
      }
    }
    if (this.serviceOptions_pricesMemoryCareCareFees.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesMemoryCareCareFees'].expandSlotTemplateString(this.serviceOptions_pricesMemoryCareCareFees.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareCareFees'], this.serviceOptions_pricesMemoryCareCareFees, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesMemoryCareCareFees'].expandSlotTemplateString("pricesMemoryCareCareFees", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesMemoryCareCareFees.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesMemoryCareCareFees'], this.serviceOptions_pricesMemoryCareCareFees, true);
      }
    }
    if (this.serviceOptions_pricesSkilledNursePrivate.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesSkilledNursePrivate'].expandSlotTemplateString(this.serviceOptions_pricesSkilledNursePrivate.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesSkilledNursePrivate'], this.serviceOptions_pricesSkilledNursePrivate, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesSkilledNursePrivate'].expandSlotTemplateString("pricesSkilledNursePrivate", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesSkilledNursePrivate.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesSkilledNursePrivate'], this.serviceOptions_pricesSkilledNursePrivate, true);
      }
    }
    if (this.serviceOptions_pricesSkilledNurseSemi.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['pricesSkilledNurseSemi'].expandSlotTemplateString(this.serviceOptions_pricesSkilledNurseSemi.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['pricesSkilledNurseSemi'], this.serviceOptions_pricesSkilledNurseSemi, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['pricesSkilledNurseSemi'].expandSlotTemplateString("pricesSkilledNurseSemi", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_pricesSkilledNurseSemi.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['pricesSkilledNurseSemi'], this.serviceOptions_pricesSkilledNurseSemi, true);
      }
    }
    if (this.serviceOptions_city.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['city'].expandSlotTemplateString(this.serviceOptions_city.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_zipCodeLookup(this.dataSheets['city'], this.serviceOptions_city, true);
      }
    }

    if (slotId === 'ds_enteredZipCode') {
      this.serviceOptions_city.servicePath = value;
      this.loadData_zipCodeLookup(this.dataSheets['city'], this.serviceOptions_city, true);
    }
    if (this.serviceOptions_updateInfoDB.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['updateInfoDB'].expandSlotTemplateString(this.serviceOptions_updateInfoDB.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['updateInfoDB'], this.serviceOptions_updateInfoDB, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['updateInfoDB'].expandSlotTemplateString("updateInfo", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_updateInfoDB.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['updateInfoDB'], this.serviceOptions_updateInfoDB, true);
      }
    }
    if (this.serviceOptions_selected_community.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['selected_community'].expandSlotTemplateString(this.serviceOptions_selected_community.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['selected_community'], this.serviceOptions_selected_community, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['selected_community'].expandSlotTemplateString("communities", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_selected_community.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['selected_community'], this.serviceOptions_selected_community, true);
      }
    }
    if (this.serviceOptions_waitList.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['waitList'].expandSlotTemplateString(this.serviceOptions_waitList.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_firebasewikiliving(this.dataSheets['waitList'], this.serviceOptions_waitList, true);
      }
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['waitList'].expandSlotTemplateString("waitList", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_waitList.servicePath = servicePath;
        this.loadData_firebasewikiliving(this.dataSheets['waitList'], this.serviceOptions_waitList, true);
      }
    }
    this.setState({});
  }

  dataSheetDidUpdate = (dataSheet) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.setState({});
  }

  updateLocalizationFromDataSheet = (dataSheet) => {
    const stringsObj = dataSheet.getStringsByLanguage();
    if (stringsObj && Object.keys(stringsObj).length > 0) {
      this.locStrings = new LocalizedStrings(stringsObj);
    } else {
      this.locStrings = new LocalizedStrings({en: {}});
    }
    this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
  }

  loadData_firebasewikiliving = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Firebase (Cloud Firestore)'.
    this.setState({loading: true});

    // clear any placeholder data before load
    if (firstLoad) {
      dataSheet.items = [];
    }

    const fetchComplete = (err) => {
      if (err) {
        // Add error handling
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }

    const db = firebase.firestore();
    let isCollectionGroup = options.servicePath.startsWith("group:");
    let collection;
    if (isCollectionGroup) {
      collection = db.collectionGroup(options.servicePath.substring(6));
    } else {
      let path = options.servicePath.trim();
      if (path.startsWith("/")) path = path.substring(1);
      if (path.endsWith("/")) path = path.substring(0, path.length-1);
      if ((path.split("/").length-1)%2==0) {
        collection = db.collection(path);
      } else {
        collection = db.doc(path);
      }
    }
    const query = dataSheet.expandSlotTemplateString(options.query, this.dataSlots);
    let queryObj;

    if (query.length < 1) {
      queryObj = collection;
    } else {
      //console.log("loading firebase data for '%s' with query: %s", options.servicePath, query);
      try {
        queryObj = eval(`(function(c){ return c.${query}; })(collection)`);
      } catch (e) {
        //console.log("** error creating firebase query object from '%s': ", query, e)
        return;
      }
    }

    queryObj.onSnapshot(
      (querySnapshot) => {
        let jsonArr = [];

        if (querySnapshot.docs) {
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), document_key: doc.id, document_path: doc.ref.path };
            jsonArr.push(data);
          });
        } else if (querySnapshot.data) {
          const doc = querySnapshot;
          const data = { ...doc.data(), document_key: doc.id, document_path: doc.ref.path };
          jsonArr.push(data);
        }

        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);
      },
      (err) => {
        fetchComplete(err, options);
      });


     /*
    dbLoadingPromise.get().then((querySnapshot) => {
        let jsonArr = [];

        querySnapshot.forEach((doc) => {
          const data = { ...doc.data(), key: doc.id };
          jsonArr.push(data);
        });

        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);
      },
      (err) => {
        fetchComplete(err, options);
      });
      */

  }
  loadData_multiZip = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Generic JSON'.
    this.setState({loading: true});

    // clear any placeholder data before load
    if (firstLoad) {
    	dataSheet.items = [];
    }

    const fetchComplete = (err) => {
      if (err) {
        // Add error handling
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }

    const url = dataSheet.urlFromOptions(options);  // this method was written by the web service plugin

    const fetchOpts = {
      method: 'GET',
      headers: {},
    };

    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        dataSheet.loadFromJson(json);
        fetchComplete(null, options);
      })
      .catch((exc) => {
        fetchComplete(exc, options);
      });
  }
  loadData_zipCodeLookup = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Generic JSON'.
    this.setState({loading: true});

    // clear any placeholder data before load
    if (firstLoad) {
    	dataSheet.items = [];
    }

    const fetchComplete = (err) => {
      if (err) {
        // Add error handling
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }

    const url = dataSheet.urlFromOptions(options);  // this method was written by the web service plugin

    const fetchOpts = {
      method: 'GET',
      headers: {},
    };

    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        dataSheet.loadFromJson(json);
        fetchComplete(null, options);
      })
      .catch((exc) => {
        fetchComplete(exc, options);
      });
  }

  createImageUrlFromProp = (prop) => {
    if (prop instanceof Object) {
      if (prop.type != null && prop.type === 'image' && prop.path != null) {
        return "(null)"+prop.path;
      }
    }
    return prop;
  }

  render() {
    let makeElementForScreen = (screenId, baseProps, atTop, forward) => {
      let screenProps = {
        ...baseProps,
        atTopOfScreenStack: atTop,
        transitionForward: forward,
        appActions: this,
        dataSheets: this.dataSheets,
        locStrings: this.locStrings,
        deviceInfo: {
          screenFormatId: this.state.screenFormatId
        },
        'ds_activeLang': this.dataSlots['ds_activeLang'],
        'ds_SlotUserID': this.dataSlots['ds_SlotUserID'],
        'ds_SlotUserName': this.dataSlots['ds_SlotUserName'],
        'ds_SlotUserEmail': this.dataSlots['ds_SlotUserEmail'],
        'ds_SlotUserPhoto': this.dataSlots['ds_SlotUserPhoto'],
        'ds_SlotReviewRating': this.dataSlots['ds_SlotReviewRating'],
        'ds_numberOfCommunities': this.dataSlots['ds_numberOfCommunities'],
        'ds_selectedCommunityId': this.dataSlots['ds_selectedCommunityId'],
        'ds_selectedCommunityName': this.dataSlots['ds_selectedCommunityName'],
        'ds_selectedCommunityWWW': this.dataSlots['ds_selectedCommunityWWW'],
        'ds_enteredZipCode': this.dataSlots['ds_enteredZipCode'],
        'ds_selectedCommunityPhone': this.dataSlots['ds_selectedCommunityPhone'],
        'ds_communityFeeEntered': this.dataSlots['ds_communityFeeEntered'],
        'ds_selectedCommunityAddress': this.dataSlots['ds_selectedCommunityAddress'],
        'ds_selectedCommunityCoverImage': this.dataSlots['ds_selectedCommunityCoverImage'],
        'ds_indeLivingStudioEntered': this.dataSlots['ds_indeLivingStudioEntered'],
        'ds_indeLiving2bdrmEntered': this.dataSlots['ds_indeLiving2bdrmEntered'],
        'ds_indeLiving1bdrmEntered': this.dataSlots['ds_indeLiving1bdrmEntered'],
        'ds_indeLiving2ndOccuFeeEntered': this.dataSlots['ds_indeLiving2ndOccuFeeEntered'],
        'ds_indeLivingCareFeeEntered': this.dataSlots['ds_indeLivingCareFeeEntered'],
        'ds_communityListIndex': this.dataSlots['ds_communityListIndex'],
        'ds_uploadedImagePath': this.dataSlots['ds_uploadedImagePath'],
        'ds_uploadedImageFilename': this.dataSlots['ds_uploadedImageFilename'],
        'ds_indeLivingCommFeeEntered': this.dataSlots['ds_indeLivingCommFeeEntered'],
        'ds_assistedLivingCommFeeEntered': this.dataSlots['ds_assistedLivingCommFeeEntered'],
        'ds_assistedLivingStudioEntered': this.dataSlots['ds_assistedLivingStudioEntered'],
        'ds_assistedLiving1bdrmEntered': this.dataSlots['ds_assistedLiving1bdrmEntered'],
        'ds_assistedLiving2bdrmEntered': this.dataSlots['ds_assistedLiving2bdrmEntered'],
        'ds_assistedLiving2bdrmSharedEntered': this.dataSlots['ds_assistedLiving2bdrmSharedEntered'],
        'ds_assistedLiving2ndOccEntered': this.dataSlots['ds_assistedLiving2ndOccEntered'],
        'ds_assistedLivingCareFeesEntered': this.dataSlots['ds_assistedLivingCareFeesEntered'],
        'ds_memoryCareCommFeeEntered': this.dataSlots['ds_memoryCareCommFeeEntered'],
        'ds_memoryCareStudioEntered': this.dataSlots['ds_memoryCareStudioEntered'],
        'ds_memoryCare1bdrmEntered': this.dataSlots['ds_memoryCare1bdrmEntered'],
        'ds_memoryCare2bdrmEntered': this.dataSlots['ds_memoryCare2bdrmEntered'],
        'ds_memoryCare2bdrmSharedEntered': this.dataSlots['ds_memoryCare2bdrmSharedEntered'],
        'ds_memoryCare2ndOccEntered': this.dataSlots['ds_memoryCare2ndOccEntered'],
        'ds_skilledNursePrivateEntered': this.dataSlots['ds_skilledNursePrivateEntered'],
        'ds_skilledNurseSemiEntered': this.dataSlots['ds_skilledNurseSemiEntered'],
        'ds_memoryCareCareFeesEntered': this.dataSlots['ds_memoryCareCareFeesEntered'],
        'ds_MixedCaseAlias': this.dataSlots['ds_MixedCaseAlias'],
        'ds_ilFlag': this.dataSlots['ds_ilFlag'],
        'ds_loginTransition': this.dataSlots['ds_loginTransition'],
        'ds_alFlag': this.dataSlots['ds_alFlag'],
        'ds_mcFlag': this.dataSlots['ds_mcFlag'],
        'ds_snFlag': this.dataSlots['ds_snFlag'],
        'ds_ilBeds': this.dataSlots['ds_ilBeds'],
        'ds_alBeds': this.dataSlots['ds_alBeds'],
        'ds_mcBeds': this.dataSlots['ds_mcBeds'],
        'ds_snBeds': this.dataSlots['ds_snBeds'],
        'ds_selectedCommunityCity': this.dataSlots['ds_selectedCommunityCity'],
        'ds_selectedCommunityState': this.dataSlots['ds_selectedCommunityState'],
        'ds_selectedCommunityZip': this.dataSlots['ds_selectedCommunityZip'],
      };
      let transformSourceDataRowValue_LoginScreen = (input) => {
        input = "2W0K9cPlT0R2TemziypP";
        return input;
      }
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_LoginScreen = this.dataSheets['communities'].items[parseInt((transformSourceDataRowValue_LoginScreen(this.dataSlots.ds_selectedCommunityId) !== undefined) ? transformSourceDataRowValue_LoginScreen(this.dataSlots.ds_selectedCommunityId) : 0, 10)];
      const screenData_LoginScreen = {
        ...dataSheetRow_LoginScreen,
        dataSheetRow: dataSheetRow_LoginScreen,
      }
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_UpdateInfoScreen = this.dataSheets['selected_community'].items[1];
      const screenData_UpdateInfoScreen = {
        ...dataSheetRow_UpdateInfoScreen,
        dataSheetRow: dataSheetRow_UpdateInfoScreen,
      }
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_LoginHomeScreen = this.dataSheets['communities'].items[0];
      const screenData_LoginHomeScreen = {
        ...dataSheetRow_LoginHomeScreen,
        dataSheetRow: dataSheetRow_LoginHomeScreen,
      }
      switch (screenId) {
        default:
          return null;
        case 'login':
          return (<LoginScreen {...screenProps} {...screenData_LoginScreen} />)
        case 'updateInfo':
          return (<UpdateInfoScreen {...screenProps} {...screenData_UpdateInfoScreen} />)
        case 'communityDetail':
          return (<CommunityDetailScreen {...screenProps} />)
        case 'communityDetails':
          return (<CommunityDetailsScreen {...screenProps} />)
        case 'how-it-works':
            return (<HowItWorksScreen {...screenProps} />)
        case 'tC':
          return (<TCScreen {...screenProps} />)
        case 'about-us':
          return (<AboutUsScreen {...screenProps} />)
        case 'contributor':
          return (<ContributorScreen {...screenProps} />)
        case 'community':
          return (<CommunityScreen {...screenProps} />)
        case 'loginHome':
          return (<LoginHomeScreen {...screenProps} {...screenData_LoginHomeScreen} />)
        case 'home':
          return (<HomeScreen {...screenProps} />)
        case 'loginpassword':
          return (<LoginpasswordScreen {...screenProps} />)
        case 'writeAReview':
          return (<WriteAReviewScreen {...screenProps} />)
        case 'loginMain2':
          return (<LoginMain2Screen {...screenProps} />)
        case 'login2Home':
          return (<Login2HomeScreen {...screenProps} />)
      }
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/" render={(props) => makeElementForScreen('loginpassword', props.location.state, true, true)} exact />
          <Route path="/login" render={(props) => {
            return makeElementForScreen('login', props.location.state, true, true);
          }} />
          <Route path="/updateInfo" render={(props) => {
            return (firebase.auth().currentUser != null) ? makeElementForScreen('updateInfo', props.location.state, true, true) : <Redirect to="/login" />;
          }} />
          <Route path="/communityDetail" render={(props) => {
            return makeElementForScreen('communityDetail', props.location.state, true, true);
          }} />
          <Route path="/communityDetails" render={(props) => {
            return makeElementForScreen('communityDetails', props.location.state, true, true);
          }} />
          <Route path="/tC" render={(props) => {
            return makeElementForScreen('tC', props.location.state, true, true);
          }} />
          <Route path="/about-us" render={(props) => {
            return makeElementForScreen('about-us', props.location.state, true, true);
          }} />
          <Route path="/how-it-works" render={(props) => {
            return makeElementForScreen('how-it-works', props.location.state, true, true);
          }} />
          <Route path="/contributor" render={(props) => {
            return makeElementForScreen('contributor', props.location.state, true, true);
          }} />
          <Route path="/community" render={(props) => {
            return makeElementForScreen('community', props.location.state, true, true);
          }} />
          <Route path="/loginHome" render={(props) => {
            return makeElementForScreen('loginHome', props.location.state, true, true);
          }} />
          <Route path="/home" render={(props) => {
            return makeElementForScreen('home', props.location.state, true, true);
          }} />
          <Route path="/loginpassword" render={(props) => {
            return makeElementForScreen('loginpassword', props.location.state, true, true);
          }} />
          <Route path="/writeAReview" render={(props) => {
            return makeElementForScreen('writeAReview', props.location.state, true, true);
          }} />
          <Route path="/loginMain2" render={(props) => {
            return makeElementForScreen('loginMain2', props.location.state, true, true);
          }} />
          <Route path="/login2Home" render={(props) => {
            return makeElementForScreen('login2Home', props.location.state, true, true);
          }} />
        </Switch>
      </div>
    );
  }
}
export default withRouter(App)
