import React, { Component } from 'react';
import './App.css';
// import UploadPhotos from './UploadPhotos';
import img_elImageIL from './images/CommunityDetails2Screen_elImageIL_828629221.png';
import img_elImageAL from './images/CommunityDetails2Screen_elImageAL_1199380299.png';
import img_elImageMC from './images/CommunityDetails2Screen_elImageMC_1946152380.png';
import img_elImageSN from './images/CommunityDetails2Screen_elImageSN_102632794.png';
import img_elImageILCopy from './images/CommunityDetails2Screen_elImageIL_828629221.png';
import img_elIndeLivingStudioUserPic from './images/CommunityDetails2Screen_elIndeLivingStudioFeeUserPic_865980.jpg';
import img_elImageALCopy from './images/CommunityDetails2Screen_elImageAL_1199380299.png';
import img_elAssistedLivingStudioUserPic from './images/CommunityDetailsScreen_elAssistedLivingStudioUserPic_915103.jpg';
import img_elImageMCCopy from './images/CommunityDetails2Screen_elImageMC_1946152380.png';
import img_elMemoryCareStudioUserPic from './images/CommunityDetails2Screen_elAssistedLivingStudioFeeUserPic_621094502.jpg';
import img_elImageSNCopy from './images/CommunityDetails2Screen_elImageSN_102632794.png';
import ScrollingPhotos from './ScrollingPhotos';
import btn_icon_342806 from './images/btn_icon_342806.png';
import btn_icon_120268699 from './images/btn_icon_120268699.png';
import img_elTwittercircle from './images/CommunityDetailsScreen_elTwittercircle.png';
import img_elTwitter from './images/UpdateInfoScreen_elTwitter_1312850244.png';
import img_elInstagramcircle from './images/CommunityDetailsScreen_elInstagramcircle.png';
import img_elIg from './images/UpdateInfoScreen_elIg_98352411.png';
import img_elFacebookcircle from './images/CommunityDetailsScreen_elFacebookcircle.png';
import img_elFb from './images/UpdateInfoScreen_elFb_660566527.png';
import img_elLogo from './images/UpdateInfoScreen_elLogo_1476464072.png';
import img_elLogoznesthorizwhite from './images/CommunityDetailsScreen_elLogoznesthorizwhite.png';
import img_elBeta from './images/CommunityDetailsScreen_elBeta.png';
import Drawer1 from './Drawer1';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class CommunityDetailsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, communityName, reviewerName, reviewDate, communityPhone, indeLivingStudioAvg, reviewerProfilePicUrl, indeLiving1bdrmAvg, indeLiving2bdrmAvg, photoURL, ilBeds, imageFilePath, alBeds, mcBeds, snBeds, dataSheetRow, communityCity, communityID

  constructor(props) {
    super(props);

    this.state = {
      communityname: (<div> </div>),
      communityname_plainText: " ",
      communityaddress: (<div> </div>),
      communityaddress_plainText: " ",
      communitycity: (<div> </div>),
      communitycity_plainText: " ",
      communityphone: (<div> </div>),
      communityphone_plainText: " ",
      textbasicinfo: (<div>Basic Information</div>),
      textbasicinfo_plainText: "Basic Information",
      editbutton: (<div>Edit</div>),
      editbutton_plainText: "Edit",
      textlicensetypes: (<div>Type of Licenses<br /><br /><br />Independent Living<br /><br />Assisted Living<br /><br />Memory Care<br /><br />Skilled Nursing</div>),
      textlicensetypes_plainText: "Type of Licenses\n\n\nIndependent Living\n\nAssisted Living\n\nMemory Care\n\nSkilled Nursing",
      textlicensedbeds: (<div>Licensed Beds</div>),
      textlicensedbeds_plainText: "Licensed Beds",
      indelivingbedcount: (<div> </div>),
      indelivingbedcount_plainText: " ",
      assistedlivingbedcount: (<div> </div>),
      assistedlivingbedcount_plainText: " ",
      memorycarebedcount: (<div> </div>),
      memorycarebedcount_plainText: " ",
      skillednursingbedcount: (<div> </div>),
      skillednursingbedcount_plainText: " ",
      otherbedcount: (<div>0</div>),
      otherbedcount_plainText: "0",
      texttotalbed: (<div>Total</div>),
      texttotalbed_plainText: "Total",
      totalbedcount: (<div>9</div>),
      totalbedcount_plainText: "9",
      textpricing: (<div>Pricing</div>),
      textpricing_plainText: "Pricing",
      textclickinstruction: (<div>Click on the price to report an updated price</div>),
      textclickinstruction_plainText: "Click on the price to report an updated price",
      textpricingheading1: (<div>Description:</div>),
      textpricingheading1_plainText: "Description:",
      textpricingheading2: (<div>Median Price:</div>),
      textpricingheading2_plainText: "Median Price:",
      textpricingheading3: (<div>Price reported by:</div>),
      textpricingheading3_plainText: "Price reported by:",
      textindeliving: (<div>Independent Living</div>),
      textindeliving_plainText: "Independent Living",
      textindelivingcommfee: (<div>Community Fee</div>),
      textindelivingcommfee_plainText: "Community Fee",
      indelivingcommfee: (<div> </div>),
      indelivingcommfee_plainText: " ",
      indelivingcommfeeuser: (<div> </div>),
      indelivingcommfeeuser_plainText: " ",
      indelivingcommfeedate: (<div> </div>),
      indelivingcommfeedate_plainText: " ",
      textindelivingstudio: (<div>Studio</div>),
      textindelivingstudio_plainText: "Studio",
      indelivingstudiofee: (<div> </div>),
      indelivingstudiofee_plainText: " ",
      indelivingstudiouser: (<div> </div>),
      indelivingstudiouser_plainText: " ",
      indelivingstudiodate: (<div> </div>),
      indelivingstudiodate_plainText: " ",
      textindeliving1bdrm: (<div>1-Bedroom</div>),
      textindeliving1bdrm_plainText: "1-Bedroom",
      indeliving1bdrmfee: (<div> </div>),
      indeliving1bdrmfee_plainText: " ",
      indeliving1bdrmuser: (<div> </div>),
      indeliving1bdrmuser_plainText: " ",
      indeliving1bdrmdate: (<div> </div>),
      indeliving1bdrmdate_plainText: " ",
      textindeliving2bdrm: (<div>2-Bedroom</div>),
      textindeliving2bdrm_plainText: "2-Bedroom",
      indeliving2bdrmfee: (<div> </div>),
      indeliving2bdrmfee_plainText: " ",
      indeliving2bdrmuser: (<div> </div>),
      indeliving2bdrmuser_plainText: " ",
      indeliving2bdrmdate: (<div> </div>),
      indeliving2bdrmdate_plainText: " ",
      textassistedliving: (<div>Assisted Living</div>),
      textassistedliving_plainText: "Assisted Living",
      textassistedlivingcommfee: (<div>Community Fee</div>),
      textassistedlivingcommfee_plainText: "Community Fee",
      assistedlivingcommfee: (<div> </div>),
      assistedlivingcommfee_plainText: " ",
      assistedlivingcommfeeuser: (<div> </div>),
      assistedlivingcommfeeuser_plainText: " ",
      assistedlivingcommfeedate: (<div> </div>),
      assistedlivingcommfeedate_plainText: " ",
      textassistedlivingstudio: (<div>Studio</div>),
      textassistedlivingstudio_plainText: "Studio",
      assistedlivingstudiofee: (<div> </div>),
      assistedlivingstudiofee_plainText: " ",
      assistedlivingstudiouser: (<div> </div>),
      assistedlivingstudiouser_plainText: " ",
      assistedlivingstudiodate: (<div> </div>),
      assistedlivingstudiodate_plainText: " ",
      textassistedliving1bdrm: (<div>1-Bedroom</div>),
      textassistedliving1bdrm_plainText: "1-Bedroom",
      assistedliving1bdrmfee: (<div> </div>),
      assistedliving1bdrmfee_plainText: " ",
      assistedliving1bdrmuser: (<div> </div>),
      assistedliving1bdrmuser_plainText: " ",
      assistedliving1bdrmdate: (<div> </div>),
      assistedliving1bdrmdate_plainText: " ",
      textassistedliving2bdrm: (<div>2-Bedroom</div>),
      textassistedliving2bdrm_plainText: "2-Bedroom",
      assistedliving2bdrmfee: (<div> </div>),
      assistedliving2bdrmfee_plainText: " ",
      assistedliving2bdrmuser: (<div> </div>),
      assistedliving2bdrmuser_plainText: " ",
      assistedliving2bdrmdate: (<div> </div>),
      assistedliving2bdrmdate_plainText: " ",
      textassistedliving2bdrmshared: (<div>2-Bedroom Shared</div>),
      textassistedliving2bdrmshared_plainText: "2-Bedroom Shared",
      assistedliving2bdrmsharedfee: (<div> </div>),
      assistedliving2bdrmsharedfee_plainText: " ",
      assistedliving2bdrmshareduser: (<div> </div>),
      assistedliving2bdrmshareduser_plainText: " ",
      assistedliving2bdrmshareddate: (<div> </div>),
      assistedliving2bdrmshareddate_plainText: " ",
      textassistedliving2ndocc: (<div>2nd Occupant Fee</div>),
      textassistedliving2ndocc_plainText: "2nd Occupant Fee",
      assistedliving2ndoccfee: (<div> </div>),
      assistedliving2ndoccfee_plainText: " ",
      assistedliving2ndoccuser: (<div> </div>),
      assistedliving2ndoccuser_plainText: " ",
      assistedliving2ndoccdate: (<div> </div>),
      assistedliving2ndoccdate_plainText: " ",
      textassistedlivingcarefee: (<div>Care Fee</div>),
      textassistedlivingcarefee_plainText: "Care Fee",
      assistedlivingcarefee: (<div> </div>),
      assistedlivingcarefee_plainText: " ",
      assistedlivingcarefeeuser: (<div> </div>),
      assistedlivingcarefeeuser_plainText: " ",
      assistedlivingcarefeedate: (<div> </div>),
      assistedlivingcarefeedate_plainText: " ",
      textmemorycare: (<div>Memory Care</div>),
      textmemorycare_plainText: "Memory Care",
      textmemorycarecommfee: (<div>Community Fee</div>),
      textmemorycarecommfee_plainText: "Community Fee",
      memorycarecommfee: (<div> </div>),
      memorycarecommfee_plainText: " ",
      memorycarecommfeeuser: (<div> </div>),
      memorycarecommfeeuser_plainText: " ",
      memorycarecommfeedate: (<div> </div>),
      memorycarecommfeedate_plainText: " ",
      textmemorycarestudio: (<div>Studio</div>),
      textmemorycarestudio_plainText: "Studio",
      memorycarestudiofee: (<div> </div>),
      memorycarestudiofee_plainText: " ",
      memorycarestudiouser: (<div> </div>),
      memorycarestudiouser_plainText: " ",
      memorycarestudiodate: (<div> </div>),
      memorycarestudiodate_plainText: " ",
      textmemorycare1bdrm: (<div>1-Bedroom</div>),
      textmemorycare1bdrm_plainText: "1-Bedroom",
      memorycare1bdrmfee: (<div> </div>),
      memorycare1bdrmfee_plainText: " ",
      memorycare1bdrmuser: (<div> </div>),
      memorycare1bdrmuser_plainText: " ",
      memorycare1bdrmdate: (<div> </div>),
      memorycare1bdrmdate_plainText: " ",
      textmemorycare2bdrm: (<div>2-Bedroom</div>),
      textmemorycare2bdrm_plainText: "2-Bedroom",
      memorycare2bdrmfee: (<div> </div>),
      memorycare2bdrmfee_plainText: " ",
      memorycare2bdrmuser: (<div> </div>),
      memorycare2bdrmuser_plainText: " ",
      memorycare2bdrmdate: (<div> </div>),
      memorycare2bdrmdate_plainText: " ",
      textmemorycare2bdrmshared: (<div>2-Bedroom Shared</div>),
      textmemorycare2bdrmshared_plainText: "2-Bedroom Shared",
      memorycare2bdrmsharedfee: (<div> </div>),
      memorycare2bdrmsharedfee_plainText: " ",
      memorycare2bdrmshareduser: (<div> </div>),
      memorycare2bdrmshareduser_plainText: " ",
      memorycare2bdrmshareddate: (<div> </div>),
      memorycare2bdrmshareddate_plainText: " ",
      textmemorycare2ndocc: (<div>2nd Occupant Fee</div>),
      textmemorycare2ndocc_plainText: "2nd Occupant Fee",
      memorycare2ndoccfee: (<div> </div>),
      memorycare2ndoccfee_plainText: " ",
      memorycare2ndoccuser: (<div> </div>),
      memorycare2ndoccuser_plainText: " ",
      memorycare2ndoccdate: (<div> </div>),
      memorycare2ndoccdate_plainText: " ",
      textmemorycarecarefee: (<div>Care Fee</div>),
      textmemorycarecarefee_plainText: "Care Fee",
      memorycarecarefee: (<div> </div>),
      memorycarecarefee_plainText: " ",
      memorycarecarefeeuser: (<div> </div>),
      memorycarecarefeeuser_plainText: " ",
      memorycarecarefeedate: (<div> </div>),
      memorycarecarefeedate_plainText: " ",
      textskillednursing: (<div>Skilled Nursing</div>),
      textskillednursing_plainText: "Skilled Nursing",
      textskillednursingprivate: (<div>Private</div>),
      textskillednursingprivate_plainText: "Private",
      skillednursingprivatefee: (<div> </div>),
      skillednursingprivatefee_plainText: " ",
      skillednursingprivateuser: (<div> </div>),
      skillednursingprivateuser_plainText: " ",
      skillednursingprivatedate: (<div> </div>),
      skillednursingprivatedate_plainText: " ",
      textskillednursingsemi: (<div>Semi-Private</div>),
      textskillednursingsemi_plainText: "Semi-Private",
      skillednursingsemifee: (<div> </div>),
      skillednursingsemifee_plainText: " ",
      skillednursingsemiuser: (<div> </div>),
      skillednursingsemiuser_plainText: " ",
      skillednursingsemidate: (<div> </div>),
      skillednursingsemidate_plainText: " ",
      field: this.transformPropValue_field(this.props.communityID) || '',
      textreviewsheader: (<div>Reviews for </div>),
      textreviewsheader_plainText: "Reviews for ",
      textphotos: (<div>Photos</div>),
      textphotos_plainText: "Photos",
      drawer1_isOpen: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButtonBackTop = async (ev) => {
    // Go to screen 'Community'
    this.props.appActions.goToScreen('community', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elButtonAddPhotos = async (ev) => {
    let newVal = "CommunityDetails2";
    this.props.appActions.updateDataSlot('ds_loginTransition', newVal);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elEditButton = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['communities'], this.props.appActions.serviceOptions_communities, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elIndeLivingCommFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesIndeLivingCommFee'], this.props.appActions.serviceOptions_pricesIndeLivingCommFee, false);

    // Go to screen 'CommunityDetails2'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elIndeLivingStudioFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesIndeLivingStudio'], this.props.appActions.serviceOptions_pricesIndeLivingStudio, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elIndeLiving1bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesIndeLiving1bdrm'], this.props.appActions.serviceOptions_pricesIndeLiving1bdrm, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elIndeLiving2bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesIndeLiving2bdrm'], this.props.appActions.serviceOptions_pricesIndeLiving2bdrm, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elAssistedLivingCommFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLivingCommFee'], this.props.appActions.serviceOptions_pricesAssistedLivingCommFee, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elAssistedLivingStudioFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLivingStudio'], this.props.appActions.serviceOptions_pricesAssistedLivingStudio, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elAssistedLiving1bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving1bdrm'], this.props.appActions.serviceOptions_pricesAssistedLiving1bdrm, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elAssistedLiving2bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving2bdrm'], this.props.appActions.serviceOptions_pricesAssistedLiving2bdrm, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elAssistedLiving2bdrmSharedFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving2bdrmShared'], this.props.appActions.serviceOptions_pricesAssistedLiving2bdrmShared, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elAssistedLiving2ndOccFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving2ndOcc'], this.props.appActions.serviceOptions_pricesAssistedLiving2ndOcc, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elAssistedLivingCareFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLivingCareFees'], this.props.appActions.serviceOptions_pricesAssistedLivingCareFees, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elMemoryCareCommFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCareCommFee'], this.props.appActions.serviceOptions_pricesMemoryCareCommFee, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elMemoryCareStudioFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCareStudio'], this.props.appActions.serviceOptions_pricesMemoryCareStudio, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { transitionId: 'fadeIn' });

  }


  onClick_elMemoryCare1bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCare1bdrm'], this.props.appActions.serviceOptions_pricesMemoryCare1bdrm, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elMemoryCare2bdrmFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCare2bdrm'], this.props.appActions.serviceOptions_pricesMemoryCare2bdrm, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elMemoryCare2bdrmSharedFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesAssistedLiving2bdrmShared'], this.props.appActions.serviceOptions_pricesAssistedLiving2bdrmShared, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elMemoryCare2ndOccFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCare2ndOcc'], this.props.appActions.serviceOptions_pricesMemoryCare2ndOcc, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elMemoryCareCareFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesMemoryCareCareFees'], this.props.appActions.serviceOptions_pricesMemoryCareCareFees, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elSkilledNursingPrivateFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesSkilledNursePrivate'], this.props.appActions.serviceOptions_pricesSkilledNursePrivate, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elSkilledNursingSemiFee = async (ev) => {
    // Refresh data sheet
    await this.props.appActions.loadData_firebasewikiliving(this.props.appActions.dataSheets['pricesSkilledNurseSemi'], this.props.appActions.serviceOptions_pricesSkilledNurseSemi, false);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elButtonBackBottom = async (ev) => {
    // Go to screen 'Community'
    this.props.appActions.goToScreen('community', { ...this.props, transitionId: 'fadeIn' });

  }


  textInputChanged_elField = (event) => {
    this.setState({field: event.target.value});
  }
  transformPropValue_field = (input) => {
    let ilFlag = this.props.dataSheetRow.ilFlag;
    let alFlag = this.props.dataSheetRow.alFlag;
    let mcFlag = this.props.dataSheetRow.mcFlag;
    let snFlag = this.props.dataSheetRow.snFlag;

    let ilBeds = this.props.dataSheetRow.ilBeds;
    let alBeds = this.props.dataSheetRow.alBeds;
    let mcBeds = this.props.dataSheetRow.mcBeds;
    let snBeds = this.props.dataSheetRow.snBeds;

    this.props.appActions.updateDataSlot("ds_ilFlag", ilFlag);
    this.props.appActions.updateDataSlot("ds_alFlag", alFlag);
    this.props.appActions.updateDataSlot("ds_mcFlag", mcFlag);
    this.props.appActions.updateDataSlot("ds_snFlag", snFlag);

    this.props.appActions.updateDataSlot('ds_ilBeds', ilBeds);
    this.props.appActions.updateDataSlot('ds_alBeds', alBeds);
    this.props.appActions.updateDataSlot('ds_mcBeds', mcBeds);
    this.props.appActions.updateDataSlot('ds_snBeds', snBeds);
  }


  onClick_elTextReviewsHeader = async (ev) => {
    window.location = '';

  }


  onClick_elFab = async (ev) => {
    let newVal = this.props.communityName;
    this.props.appActions.updateDataSlot('ds_selectedCommunityName', newVal);

    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { transitionId: 'fadeIn' });

  }


  onClick_elIconButton = async (ev) => {
    this.toggle_drawer1();

  }


  onClick_elLogoznesthorizwhite = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { ...this.props, transitionId: 'fadeIn' });

  }


  toggle_drawer1 = () => {
    this.setState({drawer1_isOpen: !this.state.drawer1_isOpen});
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    // eslint-disable-next-line
    const dataSheet_communities = this.props.dataSheets['communities'];
    // eslint-disable-next-line
    const dataSheet_photos = this.props.dataSheets['photos'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };

    const style_elButtonBackTop = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_communityName = (input) => {
      let commName = this.props.appActions.dataSlots['ds_selectedCommunityName'];

      this.props.appActions.updateDataSlot('ds_selectedCommunityName', commName);

      return input;
    }

    const value_communityName = transformPropValue_communityName((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityName'] : ''));

    const style_elCommunityName = {
      fontSize: 25.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elButtonAddPhotos = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: '#315283',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCompPhotoUpload_outer = {
      display: 'none',
     };
    let transformPropValue_imageIL = (input) => {
      let ilFlag = this.props.dataSheetRow.ilFlag;
      let alFlag = this.props.dataSheetRow.alFlag;
      let mcFlag = this.props.dataSheetRow.mcFlag;
      let snFlag = this.props.dataSheetRow.snFlag;

      if(ilFlag){
        input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-IL.png';
      }
      else if(alFlag){
        input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-AL.png';
      }
      else if(snFlag){
        input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-SN.png';
      }
      else
        input = '';

      return input;
    }

    const value_imageIL = transformPropValue_imageIL(this.props.dataSheetRow ? this.props.dataSheetRow.photoURL : '');

    const style_elImageIL = {
      height: 'auto',
     };
    let transformPropValue_imageAL = (input) => {
      let ilFlag = this.props.dataSheetRow.ilFlag;
      let alFlag = this.props.dataSheetRow.alFlag;
      let mcFlag = this.props.dataSheetRow.mcFlag;
      let snFlag = this.props.dataSheetRow.snFlag;
     
      console.log("ilflag",ilFlag);
      if(ilFlag && alFlag){
        input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-AL.png';
      }
      else if( (!ilFlag && alFlag && snFlag) || (ilFlag && !alFlag && snFlag)){
        input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-SN.png';
      }
      else
        input = '';

      return input;
    }

    const value_imageAL = transformPropValue_imageAL((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageAL = {
      height: 'auto',
     };
    let transformPropValue_imageMC = (input) => {
      let ilFlag = this.props.dataSheetRow.ilFlag;
      let alFlag = this.props.dataSheetRow.alFlag;
      let mcFlag = this.props.dataSheetRow.mcFlag;
      let snFlag = this.props.dataSheetRow.snFlag;

      if(ilFlag && alFlag && mcFlag){
        input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-MC.png';
      }
      else
        input = '';

      return input;
    }

    const value_imageMC = transformPropValue_imageMC((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageMC = {
      height: 'auto',
     };
    let transformPropValue_imageSN = (input) => {
      // There is no community with all 4 types.
      // Hence we would never have all 4 logos being displayed
      // at the same time

      input = '';

      return input;
    }

    const value_imageSN = transformPropValue_imageSN((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageSN = {
      height: 'auto',
     };

    const value_communityAddress = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityAddress'] : '');

    const style_elCommunityAddress = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_communityCity = (input) => {
      input = this.props.dataSheetRow.communityCity + ', ' + this.props.dataSheetRow.communityState;

      return input;
    }

    const value_communityCity = transformPropValue_communityCity(this.props.communityCity);

    const style_elCommunityCity = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_communityPhone = (input) => {

      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getPhone);

        function getPhone(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].communityPhone;
          }
         }
      }

      return input;
    }

    const value_communityPhone = transformPropValue_communityPhone(this.props.communityPhone);

    const style_elCommunityPhone = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextBasicInfo = {
      fontSize: 23.8,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_editButton = (input) => {

      input = "Edit";

      return input;
    }

    const value_editButton = transformPropValue_editButton(this.props.indeLiving1bdrmAvg);

    const style_elEditButton = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextLicenseTypes = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextLicensedBeds = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].ilBeds;
          }
         }
      }

      return input;
    }

    const value_indeLivingBedCount = transformPropValue_indeLivingBedCount(this.props.ilBeds);

    const style_elIndeLivingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_assistedLivingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].alBeds;
          }
         }
      }

      return input;
    }

    const value_assistedLivingBedCount = transformPropValue_assistedLivingBedCount(this.props.alBeds);

    const style_elAssistedLivingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_memoryCareBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities'); // this works
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].mcBeds;
          }
         }
      }

      return input;
    }

    const value_memoryCareBedCount = transformPropValue_memoryCareBedCount(this.props.mcBeds);

    const style_elMemoryCareBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_skilledNursingBedCount = (input) => {
      let dsArr = this.props.appActions.getDataSheet('communities');
      let selectedCommunityId = this.props.appActions.dataSlots['ds_selectedCommunityId'];

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {

        dsArr.items.forEach(getBeds);

        function getBeds(item, index){

          if(dsArr.items[index].document_key == selectedCommunityId){
            input = dsArr.items[index].snBeds;
          }
         }
      }

      return input;
    }

    const value_skilledNursingBedCount = transformPropValue_skilledNursingBedCount(this.props.snBeds);

    const style_elSkilledNursingBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elOtherBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    const style_elOtherBedCount_outer = {
      display: 'none',
     };

    const style_elLine1 = {
      borderTop: '1px solid black',
     };
    const style_elTextTotalBed = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_totalBedCount = (input) => {
      var ilBeds = this.props.dataSheetRow.ilBeds;
      var alBeds = this.props.dataSheetRow.alBeds;
      var snBeds = this.props.dataSheetRow.snBeds;

      input = ilBeds + alBeds + snBeds;

      return input;
    }

    const value_totalBedCount = transformPropValue_totalBedCount((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_numberOfCommunities'] : ''));

    const style_elTotalBedCount = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };

    const value_communityCoverImage = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : '');

    const style_elCommunityCoverImage = {
      height: 'auto',
     };
    const style_elTextPricing = {
      fontSize: 23.8,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextClickInstruction = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextPricingHeading1 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextPricingHeading2 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextPricingHeading3 = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_imageILCopy = (input) => {
      input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-IL.png';

      return input;
    }

    const value_imageILCopy = transformPropValue_imageILCopy(this.props.dataSheetRow ? this.props.dataSheetRow.photoURL : '');

    const style_elImageILCopy = {
      height: 'auto',
     };
    const style_elTextIndeLiving = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextIndeLivingCommFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingCommFee = (input) => {
      input = '$1000';
      return input;
    }

    const value_indeLivingCommFee = transformPropValue_indeLivingCommFee(this.props.indeLivingStudioAvg);

    const style_elIndeLivingCommFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_indeLivingCommFeeUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_indeLivingCommFeeUserPic = transformPropValue_indeLivingCommFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elIndeLivingCommFeeUserPic = {
      height: 'auto',
     };
    let transformPropValue_indeLivingCommFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_indeLivingCommFeeUser = transformPropValue_indeLivingCommFeeUser(this.props.reviewerName);

    const style_elIndeLivingCommFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingCommFeeDate = (input) => {
      input = 'Oct 1, 2021 10:23';
      return input;
    }

    const value_indeLivingCommFeeDate = transformPropValue_indeLivingCommFeeDate(this.props.reviewDate);

    const style_elIndeLivingCommFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextIndeLivingStudio = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingStudioFee = (input) => {
      input = '$3000';
      return input;
    }

    const value_indeLivingStudioFee = transformPropValue_indeLivingStudioFee(this.props.indeLivingStudioAvg);

    const style_elIndeLivingStudioFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_indeLivingStudioUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_indeLivingStudioUserPic = transformPropValue_indeLivingStudioUserPic(this.props.reviewerProfilePicUrl);

    const style_elIndeLivingStudioUserPic = {
      height: 'auto',
     };
    let transformPropValue_indeLivingStudioUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_indeLivingStudioUser = transformPropValue_indeLivingStudioUser(this.props.reviewerName);

    const style_elIndeLivingStudioUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLivingStudioDate = (input) => {
      input = 'Oct 2, 2021 14:42';
      return input;
    }

    const value_indeLivingStudioDate = transformPropValue_indeLivingStudioDate(this.props.reviewDate);

    const style_elIndeLivingStudioDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextIndeLiving1bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLiving1bdrmFee = (input) => {
      input = '$4000';
      return input;
    }

    const value_indeLiving1bdrmFee = transformPropValue_indeLiving1bdrmFee(this.props.indeLiving1bdrmAvg);

    const style_elIndeLiving1bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_indeLiving1bdrmUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_indeLiving1bdrmUserPic = transformPropValue_indeLiving1bdrmUserPic(this.props.reviewerProfilePicUrl);

    const style_elIndeLiving1bdrmUserPic = {
      height: 'auto',
     };
    let transformPropValue_indeLiving1bdrmUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        if(name != null && name != ''){
        	input = name;
        }
        else {
        }
      }

      return input;
    }

    const value_indeLiving1bdrmUser = transformPropValue_indeLiving1bdrmUser(this.props.reviewerName);

    const style_elIndeLiving1bdrmUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLiving1bdrmDate = (input) => {
      input = 'Oct 3, 2021 12:16';
      return input;
    }

    const value_indeLiving1bdrmDate = transformPropValue_indeLiving1bdrmDate(this.props.reviewDate);

    const style_elIndeLiving1bdrmDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextIndeLiving2bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLiving2bdrmFee = (input) => {
      input = '$6000';
      return input;
    }

    const value_indeLiving2bdrmFee = transformPropValue_indeLiving2bdrmFee(this.props.indeLiving2bdrmAvg);

    const style_elIndeLiving2bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_indeLiving2bdrmUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_indeLiving2bdrmUserPic = transformPropValue_indeLiving2bdrmUserPic(this.props.reviewerProfilePicUrl);

    const style_elIndeLiving2bdrmUserPic = {
      height: 'auto',
     };
    let transformPropValue_indeLiving2bdrmUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesIndeLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_indeLiving2bdrmUser = transformPropValue_indeLiving2bdrmUser(this.props.reviewerName);

    const style_elIndeLiving2bdrmUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_indeLiving2bdrmDate = (input) => {
      input = 'Oct 4, 2021 14:54';
      return input;
    }

    const value_indeLiving2bdrmDate = transformPropValue_indeLiving2bdrmDate(this.props.reviewDate);

    const style_elIndeLiving2bdrmDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_imageALCopy = (input) => {
      input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-AL.png';

      return input;
    }

    const value_imageALCopy = transformPropValue_imageALCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageALCopy = {
      height: 'auto',
     };
    const style_elTextAssistedLiving = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextAssistedLivingCommFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingCommFee = (input) => {
      input = '$1600';
      return input;
    }

    const value_assistedLivingCommFee = transformPropValue_assistedLivingCommFee(this.props.indeLivingStudioAvg);

    const style_elAssistedLivingCommFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_assistedLivingCommFeeUserPic = (input) => {
      input = '$1600';
      return input;
    }

    const value_assistedLivingCommFeeUserPic = transformPropValue_assistedLivingCommFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLivingCommFeeUserPic = {
      height: 'auto',
     };
    let transformPropValue_assistedLivingCommFeeUser = (input) => {
      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLivingCommFeeUser = transformPropValue_assistedLivingCommFeeUser(this.props.reviewerName);

    const style_elAssistedLivingCommFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingCommFeeDate = (input) => {
      input = 'Sep 4, 2021 8:40';
      return input;
    }

    const value_assistedLivingCommFeeDate = transformPropValue_assistedLivingCommFeeDate(this.props.reviewDate);

    const style_elAssistedLivingCommFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextAssistedLivingStudio = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingStudioFee = (input) => {
      input = '$4000';
      return input;
    }

    const value_assistedLivingStudioFee = transformPropValue_assistedLivingStudioFee(this.props.indeLivingStudioAvg);

    const style_elAssistedLivingStudioFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_assistedLivingStudioUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLivingStudioUserPic = transformPropValue_assistedLivingStudioUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLivingStudioUserPic = {
      height: 'auto',
     };
    let transformPropValue_assistedLivingStudioUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLivingStudioUser = transformPropValue_assistedLivingStudioUser(this.props.reviewerName);

    const style_elAssistedLivingStudioUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingStudioDate = (input) => {
      input = 'Oct 7, 2021 18:25';
      return input;
    }

    const value_assistedLivingStudioDate = transformPropValue_assistedLivingStudioDate(this.props.reviewDate);

    const style_elAssistedLivingStudioDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextAssistedLiving1bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving1bdrmFee = (input) => {
      input = '$5000';
      return input;
    }

    const value_assistedLiving1bdrmFee = transformPropValue_assistedLiving1bdrmFee(this.props.indeLiving1bdrmAvg);

    const style_elAssistedLiving1bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_assistedLiving1bdrmUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLiving1bdrmUserPic = transformPropValue_assistedLiving1bdrmUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLiving1bdrmUserPic = {
      height: 'auto',
     };
    let transformPropValue_assistedLiving1bdrmUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        if(name != null && name != ''){
        	input = name;
        }
        else {
        }
      }

      return input;
    }

    const value_assistedLiving1bdrmUser = transformPropValue_assistedLiving1bdrmUser(this.props.reviewerName);

    const style_elAssistedLiving1bdrmUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving1bdrmDate = (input) => {
      input = 'Oct 2, 2021 22:12';
      return input;
    }

    const value_assistedLiving1bdrmDate = transformPropValue_assistedLiving1bdrmDate(this.props.reviewDate);

    const style_elAssistedLiving1bdrmDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextAssistedLiving2bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2bdrmFee = (input) => {
      input = '$6000';
      return input;
    }

    const value_assistedLiving2bdrmFee = transformPropValue_assistedLiving2bdrmFee(this.props.indeLiving2bdrmAvg);

    const style_elAssistedLiving2bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_assistedLiving2bdrmUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLiving2bdrmUserPic = transformPropValue_assistedLiving2bdrmUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLiving2bdrmUserPic = {
      height: 'auto',
     };
    let transformPropValue_assistedLiving2bdrmUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLiving2bdrmUser = transformPropValue_assistedLiving2bdrmUser(this.props.reviewerName);

    const style_elAssistedLiving2bdrmUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2bdrmDate = (input) => {
      input = 'Oct 12, 2021 3:21';
      return input;
    }

    const value_assistedLiving2bdrmDate = transformPropValue_assistedLiving2bdrmDate(this.props.reviewDate);

    const style_elAssistedLiving2bdrmDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextAssistedLiving2bdrmShared = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2bdrmSharedFee = (input) => {
      input = '$3500';
      return input;
    }

    const value_assistedLiving2bdrmSharedFee = transformPropValue_assistedLiving2bdrmSharedFee(this.props.indeLiving2bdrmAvg);

    const style_elAssistedLiving2bdrmSharedFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_assistedLiving2bdrmSharedUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLiving2bdrmSharedUserPic = transformPropValue_assistedLiving2bdrmSharedUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLiving2bdrmSharedUserPic = {
      height: 'auto',
     };
    let transformPropValue_assistedLiving2bdrmSharedUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLiving2bdrmSharedUser = transformPropValue_assistedLiving2bdrmSharedUser(this.props.reviewerName);

    const style_elAssistedLiving2bdrmSharedUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2bdrmSharedDate = (input) => {
      input = 'Aug 2, 2021 14:51';
      return input;
    }

    const value_assistedLiving2bdrmSharedDate = transformPropValue_assistedLiving2bdrmSharedDate(this.props.reviewDate);

    const style_elAssistedLiving2bdrmSharedDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextAssistedLiving2ndOcc = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2ndOccFee = (input) => {
      input = '$2000';
      return input;
    }

    const value_assistedLiving2ndOccFee = transformPropValue_assistedLiving2ndOccFee(this.props.indeLiving2bdrmAvg);

    const style_elAssistedLiving2ndOccFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_assistedLiving2ndOccUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLiving2ndOccUserPic = transformPropValue_assistedLiving2ndOccUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLiving2ndOccUserPic = {
      height: 'auto',
     };
    let transformPropValue_assistedLiving2ndOccUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLiving2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLiving2ndOccUser = transformPropValue_assistedLiving2ndOccUser(this.props.reviewerName);

    const style_elAssistedLiving2ndOccUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLiving2ndOccDate = (input) => {
      input = 'Oct 2, 2021 11:35';
      return input;
    }

    const value_assistedLiving2ndOccDate = transformPropValue_assistedLiving2ndOccDate(this.props.reviewDate);

    const style_elAssistedLiving2ndOccDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextAssistedLivingCareFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingCareFee = (input) => {
      input = '$1500';
      return input;
    }

    const value_assistedLivingCareFee = transformPropValue_assistedLivingCareFee(this.props.indeLivingStudioAvg);

    const style_elAssistedLivingCareFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_assistedLivingCareFeeUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_assistedLivingCareFeeUserPic = transformPropValue_assistedLivingCareFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elAssistedLivingCareFeeUserPic = {
      height: 'auto',
     };
    let transformPropValue_assistedLivingCareFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesAssistedLivingCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_assistedLivingCareFeeUser = transformPropValue_assistedLivingCareFeeUser(this.props.reviewerName);

    const style_elAssistedLivingCareFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_assistedLivingCareFeeDate = (input) => {
      input = 'Oct 11, 2021 17:33';
      return input;
    }

    const value_assistedLivingCareFeeDate = transformPropValue_assistedLivingCareFeeDate(this.props.reviewDate);

    const style_elAssistedLivingCareFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_imageMCCopy = (input) => {
      input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-MC.png';

      return input;
    }

    const value_imageMCCopy = transformPropValue_imageMCCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageMCCopy = {
      height: 'auto',
     };
    const style_elTextMemoryCare = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextMemoryCareCommFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareCommFee = (input) => {
      input = '$2000';
      return input;
    }

    const value_memoryCareCommFee = transformPropValue_memoryCareCommFee(this.props.indeLivingStudioAvg);

    const style_elMemoryCareCommFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCareCommFeeUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCareCommFeeUserPic = transformPropValue_memoryCareCommFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCareCommFeeUserPic = {
      height: 'auto',
     };
    let transformPropValue_memoryCareCommFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCommFee');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCareCommFeeUser = transformPropValue_memoryCareCommFeeUser(this.props.reviewerName);

    const style_elMemoryCareCommFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareCommFeeDate = (input) => {
      input = 'Oct 12, 2021 11:53';
      return input;
    }

    const value_memoryCareCommFeeDate = transformPropValue_memoryCareCommFeeDate(this.props.reviewDate);

    const style_elMemoryCareCommFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextMemoryCareStudio = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareStudioFee = (input) => {
      input = '$5000';
      return input;
    }

    const value_memoryCareStudioFee = transformPropValue_memoryCareStudioFee(this.props.indeLivingStudioAvg);

    const style_elMemoryCareStudioFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCareStudioUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        //console.log("New DB profilePic = ", pic);
        input = pic;
      }

      return input;
    }

    const value_memoryCareStudioUserPic = transformPropValue_memoryCareStudioUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCareStudioUserPic = {
      height: 'auto',
     };
    let transformPropValue_memoryCareStudioUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareStudio');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        //console.log("New DB reviewerName = ", name);
        input = name;
      }

      return input;
    }

    const value_memoryCareStudioUser = transformPropValue_memoryCareStudioUser(this.props.reviewerName);

    const style_elMemoryCareStudioUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareStudioDate = (input) => {
      input = 'Oct 2, 2021 5:08';
      return input;
    }

    const value_memoryCareStudioDate = transformPropValue_memoryCareStudioDate(this.props.reviewDate);

    const style_elMemoryCareStudioDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextMemoryCare1bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare1bdrmFee = (input) => {
      input = '$6000';
      return input;
    }

    const value_memoryCare1bdrmFee = transformPropValue_memoryCare1bdrmFee(this.props.indeLiving1bdrmAvg);

    const style_elMemoryCare1bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCare1bdrmUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCare1bdrmUserPic = transformPropValue_memoryCare1bdrmUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCare1bdrmUserPic = {
      height: 'auto',
     };
    let transformPropValue_memoryCare1bdrmUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare1bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        if(name != null && name != ''){
        	input = name;
        }
        else {
        }
      }

      return input;
    }

    const value_memoryCare1bdrmUser = transformPropValue_memoryCare1bdrmUser(this.props.reviewerName);

    const style_elMemoryCare1bdrmUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare1bdrmDate = (input) => {
      input = 'Oct 14, 2021 12:35';
      return input;
    }

    const value_memoryCare1bdrmDate = transformPropValue_memoryCare1bdrmDate(this.props.reviewDate);

    const style_elMemoryCare1bdrmDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextMemoryCare2bdrm = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2bdrmFee = (input) => {
      input = '$8000';
      return input;
    }

    const value_memoryCare2bdrmFee = transformPropValue_memoryCare2bdrmFee(this.props.indeLiving2bdrmAvg);

    const style_elMemoryCare2bdrmFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCare2bdrmUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCare2bdrmUserPic = transformPropValue_memoryCare2bdrmUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCare2bdrmUserPic = {
      height: 'auto',
     };
    let transformPropValue_memoryCare2bdrmUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrm');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCare2bdrmUser = transformPropValue_memoryCare2bdrmUser(this.props.reviewerName);

    const style_elMemoryCare2bdrmUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2bdrmDate = (input) => {
      input = 'Oct 3, 2021 9:50';
      return input;
    }

    const value_memoryCare2bdrmDate = transformPropValue_memoryCare2bdrmDate(this.props.reviewDate);

    const style_elMemoryCare2bdrmDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextMemoryCare2bdrmShared = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2bdrmSharedFee = (input) => {
      input = '$3000';
      return input;
    }

    const value_memoryCare2bdrmSharedFee = transformPropValue_memoryCare2bdrmSharedFee(this.props.indeLiving2bdrmAvg);

    const style_elMemoryCare2bdrmSharedFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCare2bdrmSharedUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCare2bdrmSharedUserPic = transformPropValue_memoryCare2bdrmSharedUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCare2bdrmSharedUserPic = {
      height: 'auto',
     };
    let transformPropValue_memoryCare2bdrmSharedUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2bdrmShared');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCare2bdrmSharedUser = transformPropValue_memoryCare2bdrmSharedUser(this.props.reviewerName);

    const style_elMemoryCare2bdrmSharedUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2bdrmSharedDate = (input) => {
      input = 'Oct 6, 2021 16:53';
      return input;
    }

    const value_memoryCare2bdrmSharedDate = transformPropValue_memoryCare2bdrmSharedDate(this.props.reviewDate);

    const style_elMemoryCare2bdrmSharedDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextMemoryCare2ndOcc = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2ndOccFee = (input) => {
      input = '$1000';
      return input;
    }

    const value_memoryCare2ndOccFee = transformPropValue_memoryCare2ndOccFee(this.props.indeLiving2bdrmAvg);

    const style_elMemoryCare2ndOccFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCare2ndOccUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCare2ndOccUserPic = transformPropValue_memoryCare2ndOccUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCare2ndOccUserPic = {
      height: 'auto',
     };
    let transformPropValue_memoryCare2ndOccUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCare2ndOcc');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCare2ndOccUser = transformPropValue_memoryCare2ndOccUser(this.props.reviewerName);

    const style_elMemoryCare2ndOccUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCare2ndOccDate = (input) => {
      input = 'Oct 13, 2021 12:45';
      return input;
    }

    const value_memoryCare2ndOccDate = transformPropValue_memoryCare2ndOccDate(this.props.reviewDate);

    const style_elMemoryCare2ndOccDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextMemoryCareCareFee = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareCareFee = (input) => {
      input = '$3000';
      return input;
    }

    const value_memoryCareCareFee = transformPropValue_memoryCareCareFee(this.props.indeLivingStudioAvg);

    const style_elMemoryCareCareFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_memoryCareCareFeeUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_memoryCareCareFeeUserPic = transformPropValue_memoryCareCareFeeUserPic(this.props.reviewerProfilePicUrl);

    const style_elMemoryCareCareFeeUserPic = {
      height: 'auto',
     };
    let transformPropValue_memoryCareCareFeeUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesMemoryCareCareFees');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_memoryCareCareFeeUser = transformPropValue_memoryCareCareFeeUser(this.props.reviewerName);

    const style_elMemoryCareCareFeeUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_memoryCareCareFeeDate = (input) => {
      input = 'Oct 7, 2021 10:43';
      return input;
    }

    const value_memoryCareCareFeeDate = transformPropValue_memoryCareCareFeeDate(this.props.reviewDate);

    const style_elMemoryCareCareFeeDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_imageSNCopy = (input) => {
      input = 'https://tomdev.s3-us-west-2.amazonaws.com/icon-care-SN.png';

      return input;
    }

    const value_imageSNCopy = transformPropValue_imageSNCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityCoverImage'] : ''));

    const style_elImageSNCopy = {
      height: 'auto',
     };
    const style_elTextSkilledNursing = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextSkilledNursingPrivate = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_skilledNursingPrivateFee = (input) => {
      input = '$8000';
      return input;
    }

    const value_skilledNursingPrivateFee = transformPropValue_skilledNursingPrivateFee(this.props.indeLiving1bdrmAvg);

    const style_elSkilledNursingPrivateFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_skilledNursingPrivateUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNursePrivate');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_skilledNursingPrivateUserPic = transformPropValue_skilledNursingPrivateUserPic(this.props.reviewerProfilePicUrl);

    const style_elSkilledNursingPrivateUserPic = {
      height: 'auto',
     };
    let transformPropValue_skilledNursingPrivateUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNursePrivate');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        if(name != null && name != ''){
        	input = name;
        }
        else {
        }
      }

      return input;
    }

    const value_skilledNursingPrivateUser = transformPropValue_skilledNursingPrivateUser(this.props.reviewerName);

    const style_elSkilledNursingPrivateUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_skilledNursingPrivateDate = (input) => {

      input = 'Oct 1, 2021 6:34';
      return input;
    }

    const value_skilledNursingPrivateDate = transformPropValue_skilledNursingPrivateDate(this.props.reviewDate);

    const style_elSkilledNursingPrivateDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextSkilledNursingSemi = {
      fontSize: 15.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_skilledNursingSemiFee = (input) => {
      input = '$7000';
      return input;
    }

    const value_skilledNursingSemiFee = transformPropValue_skilledNursingSemiFee(this.props.indeLiving2bdrmAvg);

    const style_elSkilledNursingSemiFee = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_skilledNursingSemiUserPic = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNurseSemi');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var pic = dsArr.items[0].reviewerProfilePicUrl;
        input = pic;
      }

      return input;
    }

    const value_skilledNursingSemiUserPic = transformPropValue_skilledNursingSemiUserPic(this.props.reviewerProfilePicUrl);

    const style_elSkilledNursingSemiUserPic = {
      height: 'auto',
     };
    let transformPropValue_skilledNursingSemiUser = (input) => {

      let dsArr = this.props.appActions.getDataSheet('pricesSkilledNurseSemi');

      var itemsLength = dsArr.items.length;

      if(itemsLength > 0) {
        var name = dsArr.items[0].reviewerName;
        input = name;
      }

      return input;
    }

    const value_skilledNursingSemiUser = transformPropValue_skilledNursingSemiUser(this.props.reviewerName);

    const style_elSkilledNursingSemiUser = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_skilledNursingSemiDate = (input) => {
      input = 'Oct 1, 2021 8:13';
      return input;
    }

    const value_skilledNursingSemiDate = transformPropValue_skilledNursingSemiDate(this.props.reviewDate);

    const style_elSkilledNursingSemiDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    const style_elButtonBackBottom = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elLine = {
      borderTop: '1px solid black',
     };
    const style_elLine_outer = {
      display: 'none',
     };

    const value_field = this.state.field;

    const style_elField = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elField_outer = {
      display: 'none',
     };
    let transformPropValue_textReviewsHeader = (input) => {
      return "Reviews for "+input;
    }

    const value_textReviewsHeader = transformPropValue_textReviewsHeader(this.props.communityName);

    const style_elTextReviewsHeader = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextReviewsHeader_outer = {
      display: 'none',
     };
    const style_elTextPhotos = {
      fontSize: 23.8,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };

    // Source items and any special components used for list/grid element 'photoList'.
    let items_photoList = [];
    let listComps_photoList = {};
    items_photoList = items_photoList.concat(this.props.appActions.getDataSheet('photos').items);
    this._elPhotoList_items = [];

    const style_elPhotoList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elPhotoComp_outer = {
      display: 'none',
     };

    const style_elBkgdOutlineOverall = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#39527f',
     };

    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };

    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_120268699+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '76.387%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elTwittercircle = {
      backgroundImage: 'url('+img_elTwittercircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elTwitter = {
      height: 'auto',
      display: 'none',
     };

    const style_elInstagramcircle = {
      backgroundImage: 'url('+img_elInstagramcircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elIg = {
      height: 'auto',
      display: 'none',
     };

    const style_elFacebookcircle = {
      backgroundImage: 'url('+img_elFacebookcircle+')',
      backgroundSize: '100% 100%',
     };
    const style_elFb = {
      height: 'auto',
      display: 'none',
     };

    const style_elButtonLearnMore = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };

    const style_elButtonAboutUs = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10.4,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
     };
    const style_elLogo = {
      height: 'auto',
      display: 'none',
     };

    const style_elLogoznesthorizwhite = {
      backgroundImage: 'url('+img_elLogoznesthorizwhite+')',
      backgroundSize: '100% 100%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elBeta = {
      backgroundImage: 'url('+img_elBeta+')',
      backgroundSize: '100% 100%',
     };
    const style_drawer1 = {
      pointerEvents: (this.state.drawer1_isOpen) ? 'auto' : 'none'
    }

    return (
      <div className="AppScreen CommunityDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elButtonBackTop">
            <Button className="actionFont" style={style_elButtonBackTop} onClick={this.onClick_elButtonBackTop} >
              Back to List
            </Button>
          </div>

          <div className="elCommunityName">
            <div className="font-avenirNextRegular" style={style_elCommunityName}>
              <div>{value_communityName}</div>
            </div>
          </div>

          <div className="elButtonAddPhotos">
            <button className="actionFont" style={style_elButtonAddPhotos} onClick={this.onClick_elButtonAddPhotos} >
              Add Photos
            </button>
          </div>

          <div className="hasNestedComps elCompPhotoUpload" style={style_elCompPhotoUpload_outer}>
            <div>
            </div>
          </div>

          <div className="elImageIL">
            <img style={style_elImageIL} src={this.props.appActions.createImageUrlFromProp(value_imageIL)} alt=""  />
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elImageAL_938037118">
          <div className="elImageAL">
            <img style={style_elImageAL} src={this.props.appActions.createImageUrlFromProp(value_imageAL)} alt=""  />
          </div>

          <div className="elImageMC">
            <img style={style_elImageMC} src={this.props.appActions.createImageUrlFromProp(value_imageMC)} alt=""  />
          </div>

          <div className="elImageSN">
            <img style={style_elImageSN} src={this.props.appActions.createImageUrlFromProp(value_imageSN)} alt=""  />
          </div>

          </div>
          <div className="elCommunityAddress">
            <div className="baseFont" style={style_elCommunityAddress}>
              <div>{value_communityAddress}</div>
            </div>
          </div>

          <div className="elCommunityCity">
            <div className="baseFont" style={style_elCommunityCity}>
              <div>{value_communityCity}</div>
            </div>
          </div>

          <div className="elCommunityPhone">
            <div className="baseFont" style={style_elCommunityPhone}>
              <div>{value_communityPhone}</div>
            </div>
          </div>

          <div className="elTextBasicInfo">
            <div className="systemFontRegular" style={style_elTextBasicInfo}>
              <div>{this.state.textbasicinfo}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elEditButton_1373363033">
          <div className="elEditButton">
            <div className="baseFont" style={style_elEditButton} onClick={this.onClick_elEditButton} >
              <div>{value_editButton}</div>
            </div>
          </div>

          </div>
          <div className="elTextLicenseTypes">
            <div className="baseFont" style={style_elTextLicenseTypes}>
              <div><div dangerouslySetInnerHTML={{__html: this.state.textlicensetypes_plainText.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>

          <div className="elTextLicensedBeds">
            <div className="baseFont" style={style_elTextLicensedBeds}>
              <div>{this.state.textlicensedbeds}</div>
            </div>
          </div>

          <div className="elIndeLivingBedCount">
            <div className="baseFont" style={style_elIndeLivingBedCount}>
              <div>{value_indeLivingBedCount}</div>
            </div>
          </div>

          <div className="elAssistedLivingBedCount">
            <div className="baseFont" style={style_elAssistedLivingBedCount}>
              <div>{value_assistedLivingBedCount}</div>
            </div>
          </div>

          <div className="elMemoryCareBedCount">
            <div className="baseFont" style={style_elMemoryCareBedCount}>
              <div>{value_memoryCareBedCount}</div>
            </div>
          </div>

          <div className="elSkilledNursingBedCount">
            <div className="baseFont" style={style_elSkilledNursingBedCount}>
              <div>{value_skilledNursingBedCount}</div>
            </div>
          </div>

          <div className="elOtherBedCount" style={style_elOtherBedCount_outer}>
            <div className="baseFont" style={style_elOtherBedCount}>
              <div>{this.state.otherbedcount}</div>
            </div>
          </div>

          <div className="elLine1">
            <div style={style_elLine1} />
          </div>

          <div className="elTextTotalBed">
            <div className="baseFont" style={style_elTextTotalBed}>
              <div>{this.state.texttotalbed}</div>
            </div>
          </div>

          <div className="elTotalBedCount">
            <div className="baseFont" style={style_elTotalBedCount}>
              <div>{value_totalBedCount}</div>
            </div>
          </div>

          <div className="elCommunityCoverImage">
            <img style={style_elCommunityCoverImage} src={this.props.appActions.createImageUrlFromProp(value_communityCoverImage)} alt=""  />
          </div>

          <div className="elTextPricing">
            <div className="systemFontRegular" style={style_elTextPricing}>
              <div>{this.state.textpricing}</div>
            </div>
          </div>

          <div className="elTextClickInstruction">
            <div className="baseFont" style={style_elTextClickInstruction}>
              <div>{this.state.textclickinstruction}</div>
            </div>
          </div>

          <div className="elTextPricingHeading1">
            <div className="systemFontBold" style={style_elTextPricingHeading1}>
              <div>{this.state.textpricingheading1}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elTextPricingHeading2_942212">
          <div className="elTextPricingHeading2">
            <div className="systemFontBold" style={style_elTextPricingHeading2}>
              <div>{this.state.textpricingheading2}</div>
            </div>
          </div>

          <div className="elTextPricingHeading3">
            <div className="systemFontBold" style={style_elTextPricingHeading3}>
              <div>{this.state.textpricingheading3}</div>
            </div>
          </div>

          <div className="elImageILCopy">
            <img style={style_elImageILCopy} src={this.props.appActions.createImageUrlFromProp(value_imageILCopy)} alt=""  />
          </div>

          </div>
          <div className="elTextIndeLiving">
            <div className="systemFontBold" style={style_elTextIndeLiving}>
              <div>{this.state.textindeliving}</div>
            </div>
          </div>

          <div className="elTextIndeLivingCommFee">
            <div className="systemFontRegular" style={style_elTextIndeLivingCommFee}>
              <div>{this.state.textindelivingcommfee}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elIndeLivingCommFee_680417">
          <div className="elIndeLivingCommFee">
            <div className="systemFontRegular" style={style_elIndeLivingCommFee} onClick={this.onClick_elIndeLivingCommFee} >
              <div>{value_indeLivingCommFee}</div>
            </div>
          </div>

          <div className="elIndeLivingCommFeeUserPic">
            <img style={style_elIndeLivingCommFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_indeLivingCommFeeUserPic)} alt=""  />
          </div>

          <div className="elIndeLivingCommFeeUser">
            <div className="baseFont" style={style_elIndeLivingCommFeeUser}>
              <div>{value_indeLivingCommFeeUser}</div>
            </div>
          </div>

          <div className="elIndeLivingCommFeeDate">
            <div className="baseFont" style={style_elIndeLivingCommFeeDate}>
              <div>{value_indeLivingCommFeeDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextIndeLivingStudio">
            <div className="systemFontRegular" style={style_elTextIndeLivingStudio}>
              <div>{this.state.textindelivingstudio}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elIndeLivingStudioFee_957778">
          <div className="elIndeLivingStudioFee">
            <div className="baseFont" style={style_elIndeLivingStudioFee} onClick={this.onClick_elIndeLivingStudioFee} >
              <div>{value_indeLivingStudioFee}</div>
            </div>
          </div>

          <div className="elIndeLivingStudioUserPic">
            <img style={style_elIndeLivingStudioUserPic} src={this.props.appActions.createImageUrlFromProp(value_indeLivingStudioUserPic)} alt=""  />
          </div>

          <div className="elIndeLivingStudioUser">
            <div className="baseFont" style={style_elIndeLivingStudioUser}>
              <div>{value_indeLivingStudioUser}</div>
            </div>
          </div>

          <div className="elIndeLivingStudioDate">
            <div className="baseFont" style={style_elIndeLivingStudioDate}>
              <div>{value_indeLivingStudioDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextIndeLiving1bdrm">
            <div className="systemFontRegular" style={style_elTextIndeLiving1bdrm}>
              <div>{this.state.textindeliving1bdrm}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elIndeLiving1bdrmFee_309532">
          <div className="elIndeLiving1bdrmFee">
            <div className="baseFont" style={style_elIndeLiving1bdrmFee} onClick={this.onClick_elIndeLiving1bdrmFee} >
              <div>{value_indeLiving1bdrmFee}</div>
            </div>
          </div>

          <div className="elIndeLiving1bdrmUserPic">
            <img style={style_elIndeLiving1bdrmUserPic} src={this.props.appActions.createImageUrlFromProp(value_indeLiving1bdrmUserPic)} alt=""  />
          </div>

          <div className="elIndeLiving1bdrmUser">
            <div className="baseFont" style={style_elIndeLiving1bdrmUser}>
              <div>{value_indeLiving1bdrmUser}</div>
            </div>
          </div>

          <div className="elIndeLiving1bdrmDate">
            <div className="baseFont" style={style_elIndeLiving1bdrmDate}>
              <div>{value_indeLiving1bdrmDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextIndeLiving2bdrm">
            <div className="systemFontRegular" style={style_elTextIndeLiving2bdrm}>
              <div>{this.state.textindeliving2bdrm}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elIndeLiving2bdrmFee_975704">
          <div className="elIndeLiving2bdrmFee">
            <div className="baseFont" style={style_elIndeLiving2bdrmFee} onClick={this.onClick_elIndeLiving2bdrmFee} >
              <div>{value_indeLiving2bdrmFee}</div>
            </div>
          </div>

          <div className="elIndeLiving2bdrmUserPic">
            <img style={style_elIndeLiving2bdrmUserPic} src={this.props.appActions.createImageUrlFromProp(value_indeLiving2bdrmUserPic)} alt=""  />
          </div>

          <div className="elIndeLiving2bdrmUser">
            <div className="baseFont" style={style_elIndeLiving2bdrmUser}>
              <div>{value_indeLiving2bdrmUser}</div>
            </div>
          </div>

          <div className="elIndeLiving2bdrmDate">
            <div className="baseFont" style={style_elIndeLiving2bdrmDate}>
              <div>{value_indeLiving2bdrmDate}</div>
            </div>
          </div>

          <div className="elImageALCopy">
            <img style={style_elImageALCopy} src={this.props.appActions.createImageUrlFromProp(value_imageALCopy)} alt=""  />
          </div>

          </div>
          <div className="elTextAssistedLiving">
            <div className="systemFontBold" style={style_elTextAssistedLiving}>
              <div>{this.state.textassistedliving}</div>
            </div>
          </div>

          <div className="elTextAssistedLivingCommFee">
            <div className="systemFontRegular" style={style_elTextAssistedLivingCommFee}>
              <div>{this.state.textassistedlivingcommfee}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elAssistedLivingCommFee_299187">
          <div className="elAssistedLivingCommFee">
            <div className="baseFont" style={style_elAssistedLivingCommFee} onClick={this.onClick_elAssistedLivingCommFee} >
              <div>{value_assistedLivingCommFee}</div>
            </div>
          </div>

          <div className="elAssistedLivingCommFeeUserPic">
            <img style={style_elAssistedLivingCommFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLivingCommFeeUserPic)} alt=""  />
          </div>

          <div className="elAssistedLivingCommFeeUser">
            <div className="baseFont" style={style_elAssistedLivingCommFeeUser}>
              <div>{value_assistedLivingCommFeeUser}</div>
            </div>
          </div>

          <div className="elAssistedLivingCommFeeDate">
            <div className="baseFont" style={style_elAssistedLivingCommFeeDate}>
              <div>{value_assistedLivingCommFeeDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextAssistedLivingStudio">
            <div className="systemFontRegular" style={style_elTextAssistedLivingStudio}>
              <div>{this.state.textassistedlivingstudio}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elAssistedLivingStudioFee_286123">
          <div className="elAssistedLivingStudioFee">
            <div className="baseFont" style={style_elAssistedLivingStudioFee} onClick={this.onClick_elAssistedLivingStudioFee} >
              <div>{value_assistedLivingStudioFee}</div>
            </div>
          </div>

          <div className="elAssistedLivingStudioUserPic">
            <img style={style_elAssistedLivingStudioUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLivingStudioUserPic)} alt=""  />
          </div>

          <div className="elAssistedLivingStudioUser">
            <div className="baseFont" style={style_elAssistedLivingStudioUser}>
              <div>{value_assistedLivingStudioUser}</div>
            </div>
          </div>

          <div className="elAssistedLivingStudioDate">
            <div className="baseFont" style={style_elAssistedLivingStudioDate}>
              <div>{value_assistedLivingStudioDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextAssistedLiving1bdrm">
            <div className="systemFontRegular" style={style_elTextAssistedLiving1bdrm}>
              <div>{this.state.textassistedliving1bdrm}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elAssistedLiving1bdrmFee_915030">
          <div className="elAssistedLiving1bdrmFee">
            <div className="baseFont" style={style_elAssistedLiving1bdrmFee} onClick={this.onClick_elAssistedLiving1bdrmFee} >
              <div>{value_assistedLiving1bdrmFee}</div>
            </div>
          </div>

          <div className="elAssistedLiving1bdrmUserPic">
            <img style={style_elAssistedLiving1bdrmUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLiving1bdrmUserPic)} alt=""  />
          </div>

          <div className="elAssistedLiving1bdrmUser">
            <div className="baseFont" style={style_elAssistedLiving1bdrmUser}>
              <div>{value_assistedLiving1bdrmUser}</div>
            </div>
          </div>

          <div className="elAssistedLiving1bdrmDate">
            <div className="baseFont" style={style_elAssistedLiving1bdrmDate}>
              <div>{value_assistedLiving1bdrmDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextAssistedLiving2bdrm">
            <div className="systemFontRegular" style={style_elTextAssistedLiving2bdrm}>
              <div>{this.state.textassistedliving2bdrm}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmFee_372917">
          <div className="elAssistedLiving2bdrmFee">
            <div className="baseFont" style={style_elAssistedLiving2bdrmFee} onClick={this.onClick_elAssistedLiving2bdrmFee} >
              <div>{value_assistedLiving2bdrmFee}</div>
            </div>
          </div>

          <div className="elAssistedLiving2bdrmUserPic">
            <img style={style_elAssistedLiving2bdrmUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2bdrmUserPic)} alt=""  />
          </div>

          <div className="elAssistedLiving2bdrmUser">
            <div className="baseFont" style={style_elAssistedLiving2bdrmUser}>
              <div>{value_assistedLiving2bdrmUser}</div>
            </div>
          </div>

          <div className="elAssistedLiving2bdrmDate">
            <div className="baseFont" style={style_elAssistedLiving2bdrmDate}>
              <div>{value_assistedLiving2bdrmDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextAssistedLiving2bdrmShared">
            <div className="systemFontRegular" style={style_elTextAssistedLiving2bdrmShared}>
              <div>{this.state.textassistedliving2bdrmshared}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmSharedFee_325042">
          <div className="elAssistedLiving2bdrmSharedFee">
            <div className="baseFont" style={style_elAssistedLiving2bdrmSharedFee} onClick={this.onClick_elAssistedLiving2bdrmSharedFee} >
              <div>{value_assistedLiving2bdrmSharedFee}</div>
            </div>
          </div>

          <div className="elAssistedLiving2bdrmSharedUserPic">
            <img style={style_elAssistedLiving2bdrmSharedUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2bdrmSharedUserPic)} alt=""  />
          </div>

          <div className="elAssistedLiving2bdrmSharedUser">
            <div className="baseFont" style={style_elAssistedLiving2bdrmSharedUser}>
              <div>{value_assistedLiving2bdrmSharedUser}</div>
            </div>
          </div>

          <div className="elAssistedLiving2bdrmSharedDate">
            <div className="baseFont" style={style_elAssistedLiving2bdrmSharedDate}>
              <div>{value_assistedLiving2bdrmSharedDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextAssistedLiving2ndOcc">
            <div className="systemFontRegular" style={style_elTextAssistedLiving2ndOcc}>
              <div>{this.state.textassistedliving2ndocc}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elAssistedLiving2ndOccFee_838170">
          <div className="elAssistedLiving2ndOccFee">
            <div className="baseFont" style={style_elAssistedLiving2ndOccFee} onClick={this.onClick_elAssistedLiving2ndOccFee} >
              <div>{value_assistedLiving2ndOccFee}</div>
            </div>
          </div>

          <div className="elAssistedLiving2ndOccUserPic">
            <img style={style_elAssistedLiving2ndOccUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLiving2ndOccUserPic)} alt=""  />
          </div>

          <div className="elAssistedLiving2ndOccUser">
            <div className="baseFont" style={style_elAssistedLiving2ndOccUser}>
              <div>{value_assistedLiving2ndOccUser}</div>
            </div>
          </div>

          <div className="elAssistedLiving2ndOccDate">
            <div className="baseFont" style={style_elAssistedLiving2ndOccDate}>
              <div>{value_assistedLiving2ndOccDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextAssistedLivingCareFee">
            <div className="systemFontRegular" style={style_elTextAssistedLivingCareFee}>
              <div>{this.state.textassistedlivingcarefee}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elAssistedLivingCareFee_65281">
          <div className="elAssistedLivingCareFee">
            <div className="baseFont" style={style_elAssistedLivingCareFee} onClick={this.onClick_elAssistedLivingCareFee} >
              <div>{value_assistedLivingCareFee}</div>
            </div>
          </div>

          <div className="elAssistedLivingCareFeeUserPic">
            <img style={style_elAssistedLivingCareFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_assistedLivingCareFeeUserPic)} alt=""  />
          </div>

          <div className="elAssistedLivingCareFeeUser">
            <div className="baseFont" style={style_elAssistedLivingCareFeeUser}>
              <div>{value_assistedLivingCareFeeUser}</div>
            </div>
          </div>

          <div className="elAssistedLivingCareFeeDate">
            <div className="baseFont" style={style_elAssistedLivingCareFeeDate}>
              <div>{value_assistedLivingCareFeeDate}</div>
            </div>
          </div>

          <div className="elImageMCCopy">
            <img style={style_elImageMCCopy} src={this.props.appActions.createImageUrlFromProp(value_imageMCCopy)} alt=""  />
          </div>

          </div>
          <div className="elTextMemoryCare">
            <div className="systemFontBold" style={style_elTextMemoryCare}>
              <div>{this.state.textmemorycare}</div>
            </div>
          </div>

          <div className="elTextMemoryCareCommFee">
            <div className="systemFontRegular" style={style_elTextMemoryCareCommFee}>
              <div>{this.state.textmemorycarecommfee}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elMemoryCareCommFee_512119">
          <div className="elMemoryCareCommFee">
            <div className="baseFont" style={style_elMemoryCareCommFee} onClick={this.onClick_elMemoryCareCommFee} >
              <div>{value_memoryCareCommFee}</div>
            </div>
          </div>

          <div className="elMemoryCareCommFeeUserPic">
            <img style={style_elMemoryCareCommFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCareCommFeeUserPic)} alt=""  />
          </div>

          <div className="elMemoryCareCommFeeUser">
            <div className="baseFont" style={style_elMemoryCareCommFeeUser}>
              <div>{value_memoryCareCommFeeUser}</div>
            </div>
          </div>

          <div className="elMemoryCareCommFeeDate">
            <div className="baseFont" style={style_elMemoryCareCommFeeDate}>
              <div>{value_memoryCareCommFeeDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextMemoryCareStudio">
            <div className="systemFontRegular" style={style_elTextMemoryCareStudio}>
              <div>{this.state.textmemorycarestudio}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elMemoryCareStudioFee_1011000">
          <div className="elMemoryCareStudioFee">
            <div className="baseFont" style={style_elMemoryCareStudioFee} onClick={this.onClick_elMemoryCareStudioFee} >
              <div>{value_memoryCareStudioFee}</div>
            </div>
          </div>

          <div className="elMemoryCareStudioUserPic">
            <img style={style_elMemoryCareStudioUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCareStudioUserPic)} alt=""  />
          </div>

          <div className="elMemoryCareStudioUser">
            <div className="baseFont" style={style_elMemoryCareStudioUser}>
              <div>{value_memoryCareStudioUser}</div>
            </div>
          </div>

          <div className="elMemoryCareStudioDate">
            <div className="baseFont" style={style_elMemoryCareStudioDate}>
              <div>{value_memoryCareStudioDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextMemoryCare1bdrm">
            <div className="systemFontRegular" style={style_elTextMemoryCare1bdrm}>
              <div>{this.state.textmemorycare1bdrm}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elMemoryCare1bdrmFee_312056">
          <div className="elMemoryCare1bdrmFee">
            <div className="baseFont" style={style_elMemoryCare1bdrmFee} onClick={this.onClick_elMemoryCare1bdrmFee} >
              <div>{value_memoryCare1bdrmFee}</div>
            </div>
          </div>

          <div className="elMemoryCare1bdrmUserPic">
            <img style={style_elMemoryCare1bdrmUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCare1bdrmUserPic)} alt=""  />
          </div>

          <div className="elMemoryCare1bdrmUser">
            <div className="baseFont" style={style_elMemoryCare1bdrmUser}>
              <div>{value_memoryCare1bdrmUser}</div>
            </div>
          </div>

          <div className="elMemoryCare1bdrmDate">
            <div className="baseFont" style={style_elMemoryCare1bdrmDate}>
              <div>{value_memoryCare1bdrmDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextMemoryCare2bdrm">
            <div className="systemFontRegular" style={style_elTextMemoryCare2bdrm}>
              <div>{this.state.textmemorycare2bdrm}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elMemoryCare2bdrmFee_422387">
          <div className="elMemoryCare2bdrmFee">
            <div className="baseFont" style={style_elMemoryCare2bdrmFee} onClick={this.onClick_elMemoryCare2bdrmFee} >
              <div>{value_memoryCare2bdrmFee}</div>
            </div>
          </div>

          <div className="elMemoryCare2bdrmUserPic">
            <img style={style_elMemoryCare2bdrmUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCare2bdrmUserPic)} alt=""  />
          </div>

          <div className="elMemoryCare2bdrmUser">
            <div className="baseFont" style={style_elMemoryCare2bdrmUser}>
              <div>{value_memoryCare2bdrmUser}</div>
            </div>
          </div>

          <div className="elMemoryCare2bdrmDate">
            <div className="baseFont" style={style_elMemoryCare2bdrmDate}>
              <div>{value_memoryCare2bdrmDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextMemoryCare2bdrmShared">
            <div className="systemFontRegular" style={style_elTextMemoryCare2bdrmShared}>
              <div>{this.state.textmemorycare2bdrmshared}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elMemoryCare2bdrmSharedFee_756506">
          <div className="elMemoryCare2bdrmSharedFee">
            <div className="baseFont" style={style_elMemoryCare2bdrmSharedFee} onClick={this.onClick_elMemoryCare2bdrmSharedFee} >
              <div>{value_memoryCare2bdrmSharedFee}</div>
            </div>
          </div>

          <div className="elMemoryCare2bdrmSharedUserPic">
            <img style={style_elMemoryCare2bdrmSharedUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCare2bdrmSharedUserPic)} alt=""  />
          </div>

          <div className="elMemoryCare2bdrmSharedUser">
            <div className="baseFont" style={style_elMemoryCare2bdrmSharedUser}>
              <div>{value_memoryCare2bdrmSharedUser}</div>
            </div>
          </div>

          <div className="elMemoryCare2bdrmSharedDate">
            <div className="baseFont" style={style_elMemoryCare2bdrmSharedDate}>
              <div>{value_memoryCare2bdrmSharedDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextMemoryCare2ndOcc">
            <div className="systemFontRegular" style={style_elTextMemoryCare2ndOcc}>
              <div>{this.state.textmemorycare2ndocc}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elMemoryCare2ndOccFee_551430">
          <div className="elMemoryCare2ndOccFee">
            <div className="baseFont" style={style_elMemoryCare2ndOccFee} onClick={this.onClick_elMemoryCare2ndOccFee} >
              <div>{value_memoryCare2ndOccFee}</div>
            </div>
          </div>

          <div className="elMemoryCare2ndOccUserPic">
            <img style={style_elMemoryCare2ndOccUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCare2ndOccUserPic)} alt=""  />
          </div>

          <div className="elMemoryCare2ndOccUser">
            <div className="baseFont" style={style_elMemoryCare2ndOccUser}>
              <div>{value_memoryCare2ndOccUser}</div>
            </div>
          </div>

          <div className="elMemoryCare2ndOccDate">
            <div className="baseFont" style={style_elMemoryCare2ndOccDate}>
              <div>{value_memoryCare2ndOccDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextMemoryCareCareFee">
            <div className="systemFontRegular" style={style_elTextMemoryCareCareFee}>
              <div>{this.state.textmemorycarecarefee}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elMemoryCareCareFee_611838">
          <div className="elMemoryCareCareFee">
            <div className="baseFont" style={style_elMemoryCareCareFee} onClick={this.onClick_elMemoryCareCareFee} >
              <div>{value_memoryCareCareFee}</div>
            </div>
          </div>

          <div className="elMemoryCareCareFeeUserPic">
            <img style={style_elMemoryCareCareFeeUserPic} src={this.props.appActions.createImageUrlFromProp(value_memoryCareCareFeeUserPic)} alt=""  />
          </div>

          <div className="elMemoryCareCareFeeUser">
            <div className="baseFont" style={style_elMemoryCareCareFeeUser}>
              <div>{value_memoryCareCareFeeUser}</div>
            </div>
          </div>

          <div className="elMemoryCareCareFeeDate">
            <div className="baseFont" style={style_elMemoryCareCareFeeDate}>
              <div>{value_memoryCareCareFeeDate}</div>
            </div>
          </div>

          <div className="elImageSNCopy">
            <img style={style_elImageSNCopy} src={this.props.appActions.createImageUrlFromProp(value_imageSNCopy)} alt=""  />
          </div>

          </div>
          <div className="elTextSkilledNursing">
            <div className="systemFontBold" style={style_elTextSkilledNursing}>
              <div>{this.state.textskillednursing}</div>
            </div>
          </div>

          <div className="elTextSkilledNursingPrivate">
            <div className="systemFontRegular" style={style_elTextSkilledNursingPrivate}>
              <div>{this.state.textskillednursingprivate}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elSkilledNursingPrivateFee_25414791">
          <div className="elSkilledNursingPrivateFee">
            <div className="baseFont" style={style_elSkilledNursingPrivateFee} onClick={this.onClick_elSkilledNursingPrivateFee} >
              <div>{value_skilledNursingPrivateFee}</div>
            </div>
          </div>

          <div className="elSkilledNursingPrivateUserPic">
            <img style={style_elSkilledNursingPrivateUserPic} src={this.props.appActions.createImageUrlFromProp(value_skilledNursingPrivateUserPic)} alt=""  />
          </div>

          <div className="elSkilledNursingPrivateUser">
            <div className="baseFont" style={style_elSkilledNursingPrivateUser}>
              <div>{value_skilledNursingPrivateUser}</div>
            </div>
          </div>

          <div className="elSkilledNursingPrivateDate">
            <div className="baseFont" style={style_elSkilledNursingPrivateDate}>
              <div>{value_skilledNursingPrivateDate}</div>
            </div>
          </div>

          </div>
          <div className="elTextSkilledNursingSemi">
            <div className="systemFontRegular" style={style_elTextSkilledNursingSemi}>
              <div>{this.state.textskillednursingsemi}</div>
            </div>
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elSkilledNursingSemiFee_1146281762">
          <div className="elSkilledNursingSemiFee">
            <div className="baseFont" style={style_elSkilledNursingSemiFee} onClick={this.onClick_elSkilledNursingSemiFee} >
              <div>{value_skilledNursingSemiFee}</div>
            </div>
          </div>

          <div className="elSkilledNursingSemiUserPic">
            <img style={style_elSkilledNursingSemiUserPic} src={this.props.appActions.createImageUrlFromProp(value_skilledNursingSemiUserPic)} alt=""  />
          </div>

          <div className="elSkilledNursingSemiUser">
            <div className="baseFont" style={style_elSkilledNursingSemiUser}>
              <div>{value_skilledNursingSemiUser}</div>
            </div>
          </div>

          <div className="elSkilledNursingSemiDate">
            <div className="baseFont" style={style_elSkilledNursingSemiDate}>
              <div>{value_skilledNursingSemiDate}</div>
            </div>
          </div>

          </div>
          <div className="elButtonBackBottom">
            <Button className="actionFont" style={style_elButtonBackBottom} onClick={this.onClick_elButtonBackBottom} >
              Back to List
            </Button>
          </div>

          <div className="elLine" style={style_elLine_outer}>
            <div style={style_elLine} />
          </div>

          <div className="flowRow flowRow_CommunityDetailsScreen_elField_1859237862">
          <div className="elField" style={style_elField_outer}>
            <input className="baseFont" style={style_elField} type="text" placeholder="" onChange={this.textInputChanged_elField} value={value_field !== undefined ? value_field : ''}  />
          </div>

          </div>
          <div className="elTextReviewsHeader" style={style_elTextReviewsHeader_outer}>
            <div className="headlineFont" style={style_elTextReviewsHeader} onClick={this.onClick_elTextReviewsHeader} >
              <div>{value_textReviewsHeader}</div>
            </div>
          </div>

          <div className="elTextPhotos">
            <div className="systemFontRegular" style={style_elTextPhotos}>
              <div>{this.state.textphotos}</div>
            </div>
          </div>

          <div className="hasNestedComps elPhotoList">
            <div style={style_elPhotoList}>
              {items_photoList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_photoList[row._componentId]
                    : <ScrollingPhotos appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'photos'} dataSheetRow={row} {...{ 'photoURL': row['photoURL'], 'imageFilePath': row['imageFilePath'], }} ref={(el) => {if (el) this._elPhotoList_items.push(el)}} />;
                return (<div key={row.key}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elPhotoList_endMarker = el} />
            </div>
          </div>

          <div className="hasNestedComps elPhotoComp" style={style_elPhotoComp_outer}>
            <div>
              <ScrollingPhotos {...dataSheet_communities.items[0]} ref={(el)=> this._elPhotoComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>

          <div className="elSpacer">
            <div />
          </div>
        </div>

        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elBkgdOutlineOverall" style={style_elBkgdOutlineOverall} />
            <Button className="actionFont elFab" style={style_elFab}  variant="fab" color="accent" onClick={this.onClick_elFab} >
              <img src={btn_icon_342806} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
            <div className="elTwittercircle" style={style_elTwittercircle} />
            <img className="elTwitter" style={style_elTwitter} src={img_elTwitter} alt=""  />
            <div className="elInstagramcircle" style={style_elInstagramcircle} />
            <img className="elIg" style={style_elIg} src={img_elIg} alt=""  />
            <div className="elFacebookcircle" style={style_elFacebookcircle} />
            <img className="elFb" style={style_elFb} src={img_elFb} alt=""  />
            <button className="font-SFUITextRegular  elButtonLearnMore" style={style_elButtonLearnMore}>
              learn more
            </button>
            <button className="font-SFUITextRegular  elButtonAboutUs" style={style_elButtonAboutUs}>
              About Us
            </button>
            <img className="elLogo" style={style_elLogo} src={img_elLogo} alt=""  />
            <div className="elLogoznesthorizwhite" style={style_elLogoznesthorizwhite} onClick={this.onClick_elLogoznesthorizwhite}  />
            <div className="elBeta" style={style_elBeta} />
          </div>
        </div>
        <div className="drawer drawer1" style={style_drawer1} onClick={this.toggle_drawer1}>
          <div className={"drawerContent" + (this.state.drawer1_isOpen ? " drawerOpen": " drawerClosedLeft")} onClick={(e) => {e.stopPropagation();}}>
            <Drawer1 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_drawer1} />
          </div>
        </div>
      </div>
    )
  }

}
