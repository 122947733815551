import React, { Component } from 'react';
import './App.css';
import Rating from 'react-rating';
import StarOn from './StarOn';
import StarOff from './StarOff';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class WriteAReviewScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, ds_SlotUserName, ds_SlotUserEmail, ds_SlotUserPhoto, ds_SlotReviewRating

  constructor(props) {
    super(props);

    this.state = {
      title: (<div>New review for </div>),
      title_plainText: "New review for ",
      reviewerName: this.props.ds_SlotUserName || '',
      reviewerEmail: this.props.ds_SlotUserEmail || '',
      reviewTitle: '',
      reviewText: '',
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reviews'];
      let serviceOptions = this.props.appActions.serviceOptions_reviews;
      if ( !this.props.appActions.dataSheetLoaded['reviews']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("reviews", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebasewikiliving(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reviews'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elReviewerName = (event) => {
    this.setState({reviewerName: event.target.value});
  }

  textInputChanged_elReviewerEmail = (event) => {
    this.setState({reviewerEmail: event.target.value});
  }

  textInputChanged_elReviewTitle = (event) => {
    this.setState({reviewTitle: event.target.value});
  }

  valueChanged_elRating = (rating) => {
    this.setState({rating: rating});

    this.props.appActions.updateDataSlot("ds_SlotReviewRating", rating);
  }

  textAreaChanged_elReviewText = (event) => {
    this.setState({reviewText: event.target.value});
  }

  onClick_elSendButton = async (ev) => {
    await this.sendData_elSendButton_to_reviews();

    // Go to screen 'CommunityDetails'
    this.props.appActions.goToScreen('communityDetails', { ...this.props, transitionId: 'fadeIn' });

  }


  onClick_elButton = async (ev) => {
    // Go to screen 'CommunityDetails'
    this.props.appActions.goToScreen('communityDetails', { transitionId: 'fadeIn' });

  }


  sendData_elSendButton_to_reviews = () => {
    const dataSheet = this.props.appActions.getDataSheet('reviews');

    let row = this.props.dataSheetRow || {
    };
    row = { ...row,
      userID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserID'] : ''),
      reviewSubject: this.state.reviewTitle,
      reviewerName: this.state.reviewerName,
      reviewText: this.state.reviewText,
      reviewRating: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotReviewRating'] : ''),
      reviewerProfilePicUrl: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : ''),
      communityID: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityId'] : ''),
    };

    const transformData = (input) => {
      //input.reviewerName = "Jane TestPerson";
      //input.reviewSubject = "Test review";
      //input.reviewRating=2;
      input.reviewDate=Date.now();
      return input;
    }
    row = transformData(row);

    return this.props.appActions.addToDataSheet('reviews', row);
  }


  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_title = (input) => {

      input="New review for "+input;
      return input;
    }

    const value_title = transformPropValue_title((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedCommunityName'] : ''));

    const style_elTitle = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.ds_SlotUserPhoto)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };

    const value_reviewerName = this.state.reviewerName;

    const style_elReviewerName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };

    const value_reviewerEmail = this.state.reviewerEmail;

    const style_elReviewerEmail = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };

    const style_elReviewTitle = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    let transformPropValue_rating_value = (input) => {

      return input;
    }
    const style_elRating = {
      pointerEvents: 'auto',
     };

    const style_elReviewText = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
     };

    const style_elSendButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    const style_elButton = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };

    return (
      <div className="AppScreen WriteAReviewScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div>{value_title}</div>
            </div>
          </div>

          <div className="elImage">
            <div style={style_elImage} />
          </div>

          <div className="elReviewerName">
            <input className="baseFont" style={style_elReviewerName} type="text" placeholder="Your name" onChange={this.textInputChanged_elReviewerName} value={value_reviewerName !== undefined ? value_reviewerName : ''}  />
          </div>

          <div className="elReviewerEmail">
            <input className="baseFont" style={style_elReviewerEmail} type="text" placeholder="Your email" onChange={this.textInputChanged_elReviewerEmail} value={value_reviewerEmail !== undefined ? value_reviewerEmail : ''}  />
          </div>

          <div className="elReviewTitle">
            <input className="baseFont" style={style_elReviewTitle} type="text" placeholder="Title..." onChange={this.textInputChanged_elReviewTitle} value={this.state.reviewTitle}  />
          </div>

          <div className="elRating">
            <div style={style_elRating}>
              <Rating readonly={false} start={0} stop={5} initialRating={parseInt(transformPropValue_rating_value(this.props.ds_SlotReviewRating))} onChange={this.valueChanged_elRating} fullSymbol={<StarOn locStrings={this.props.locStrings} />} emptySymbol={<StarOff locStrings={this.props.locStrings} />} />
            </div>
          </div>

          <div className="elReviewText">
            <textarea className="baseFont" style={style_elReviewText}  placeholder="Review..." onChange={this.textAreaChanged_elReviewText} value={this.state.reviewText}  />
          </div>

          <div className="elSendButton">
            <Button className="actionFont" style={style_elSendButton}  color="accent" onClick={this.onClick_elSendButton} >
              Post review
            </Button>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Write a Review</div></Appbar>

        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elButton" style={style_elButton} onClick={this.onClick_elButton} >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }

}
