import React, { Component } from 'react';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

export default class FirebaseLogin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false
    };

    var IsNewUser = false;
    var ProviderId = "";

    this.autosignInEnabled = true;

    this.firebaseUIConfig = {
      signInFlow: 'popup',
      signInSuccessUrl: 'http://localhost:3003/communityDetails2',
      signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          var credential = authResult.credential;
          var isNewUser = authResult.additionalUserInfo.isNewUser;
          var providerId = authResult.additionalUserInfo.providerId;
          var operationType = authResult.operationType;

          IsNewUser = authResult.additionalUserInfo.isNewUser;
          ProviderId = authResult.additionalUserInfo.providerId;

          // console.log("creditial = ", authResult.credential);

          return true;
        },
        signInFailure: (error) => {
          // console.error("** Firebase sign-in failed: ", error);
          this.loginFailed(error);
          return false;
        },
      }
    };
  }

  sendVerifyEmail() {
    var user = firebase.auth().currentUser;

	user.sendEmailVerification().then(function() {
    	// console.log("******Email sent");
  		// Email sent.
	}).catch(function(error) {
    	// console.log("******Error happened", error);
 		// An error happened.

	});


  }

  saveCurrentUserDataInApp() {

    var user = firebase.auth().currentUser;
    var userName = user.displayName;
    var email = user.email;
    var userPhotoUrl = user.photoURL;
    var userId = user.uid;
    var userPhoneNumber=user.phoneNumber;

    ((v) => { this.props.appActions.updateDataSlot("ds_SlotUserName", v) })(userName);
    ((v) => { this.props.appActions.updateDataSlot("ds_SlotUserEmail", v) })(email || '');
    ((v) => { this.props.appActions.updateDataSlot("ds_SlotUserPhoto", v) })(userPhotoUrl);
    ((v) => { this.props.appActions.updateDataSlot("ds_SlotUserID", v) })(userId);

  }


  loginSuccess() {


    sessionStorage.setItem('loginStatus_login2Home', 'active');
    this.props.appActions.goToScreen('home');


  }

  loginFailed(err) {
    // This is typically never called by Firebase UI because the sign-in flow is handled as a popup.

  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {
          if (user != null) {
          	// console.log("isSignedIn How About Here = ", this.state.isSignedIn);
          	// console.log("user.Sb.emailVerified = ", user.Sb.emailVerified);
            // console.log("user.providerData[0].providerId = ", user.providerData[0].providerId);
          	if ( (user.providerData[0].providerId == "password") && (user.Sb.emailVerified == false) ) {
        			// Send verification email. No login
        			this.sendVerifyEmail();
              // console.log("Sent verification email")
          	}
            else if (user.Sb.emailVerified) {
          		// Verification email clicked on. Login success
          		this.saveCurrentUserDataInApp();
              	// this.loginSuccess();
            	this.state.isSignedIn = true;
        	  }

          }
        }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if ( !this.state.isSignedIn) {
      return (
        <StyledFirebaseAuth uiCallback={ui => {if (!this.autosignInEnabled) ui.disableAutoSignIn();}} uiConfig={this.firebaseUIConfig} firebaseAuth={firebase.auth()}/>
      )
    } else {
      return null;
    }
  }
}
