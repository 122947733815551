import React, { Component } from 'react';
import './App.css';
import img_elBackgroundimage from './images/LoginMain2Screen_elBackgroundimage_63109274.jpg';
import FirebaseLogin from './FirebaseLogin';
import img_elImageIL from './images/CommunityDetails2Screen_elImageIL_828629221.png';
import img_elImageAL from './images/CommunityDetails2Screen_elImageAL_1199380299.png';
import img_elImageMC from './images/CommunityDetails2Screen_elImageMC_1946152380.png';
import img_elImageSN from './images/CommunityDetails2Screen_elImageSN_102632794.png';
import img_elWikiLivinglogo from './images/LoginMain2Screen_elWikiLivinglogo.png';

export default class LoginMain2Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, cityAliasName

  constructor(props) {
    super(props);
    
    this.state = {
      zipCodeExplore: '',
      zipautocomplete: (<div>AutoComplete</div>),
      zipautocomplete_plainText: "AutoComplete",
      textweasacomm: (<div>We, as a community, must help each other find options for the elderly!</div>),
      textweasacomm_plainText: "We, as a community, must help each other find options for the elderly!",
      textbelowweasacomm: (<div>WikiLiving is a platform that provides the most accurate information about care or housing for our loved ones, ranging from healthy, active seniors to those that need daily care. WikiLiving is a free service to all users and providers of care. Other websites showcase only options that pay for sponsorship. Users can update pricing as soon as it changes, so that we, as a community of caring people, can help each other.</div>),
      textbelowweasacomm_plainText: "WikiLiving is a platform that provides the most accurate information about care or housing for our loved ones, ranging from healthy, active seniors to those that need daily care. WikiLiving is a free service to all users and providers of care. Other websites showcase only options that pay for sponsorship. Users can update pricing as soon as it changes, so that we, as a community of caring people, can help each other.",
      texttypesofcare: (<div>Types of Care</div>),
      texttypesofcare_plainText: "Types of Care",
      textindeliving: (<div>Independent Living</div>),
      textindeliving_plainText: "Independent Living",
      textassistliving: (<div>Assisted Living</div>),
      textassistliving_plainText: "Assisted Living",
      subtextindeliving: (<div>For active seniors with basic services like meals, activities, laundry, and housekeeping.</div>),
      subtextindeliving_plainText: "For active seniors with basic services like meals, activities, laundry, and housekeeping.",
      subtextassistliving: (<div>For seniors who need help with activities of daily living like dressing, bathing, and grooming</div>),
      subtextassistliving_plainText: "For seniors who need help with activities of daily living like dressing, bathing, and grooming",
      textmemorycare: (<div>Memory Care</div>),
      textmemorycare_plainText: "Memory Care",
      textskillnursing: (<div>Skilled Nursing</div>),
      textskillnursing_plainText: "Skilled Nursing",
      subtextmemorycare: (<div>For seniors with memory care needs like Alzhimer’s and other forms of dementia.</div>),
      subtextmemorycare_plainText: "For seniors with memory care needs like Alzhimer’s and other forms of dementia.",
      subtextskillnursing: (<div>For seniors who need supervision from trained medical staff.</div>),
      subtextskillnursing_plainText: "For seniors who need supervision from trained medical staff.",
      continueWoLogin: '',
      textcontinuewologin: (<div>or continue without login</div>),
      textcontinuewologin_plainText: "or continue without login",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elZipCodeExplore = (event) => {
    this.setState({zipCodeExplore: event.target.value});
  }
  
  onClick_elZipAutoComplete = async (ev) => {
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  onClick_elSearchButton = async (ev) => {
    let newVal = this.state.zipCodeExplore;
    this.props.appActions.updateDataSlot('(null)', newVal);
  
  
    newVal = "0";
    this.props.appActions.updateDataSlot('(null)', newVal);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  textInputChanged_elContinueWoLogin = (event) => {
    this.setState({continueWoLogin: event.target.value});
  }
  
  onClick_elTextContinueWOlogin = async (ev) => {
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      display: 'none',
      backgroundColor: '#f6f6f6',
     };
    const style_elBackgroundimage = {
      height: 'auto',
     };
    
    const style_elN50Shade = {
      background: 'rgba(0, 0, 0, 1.000)',
      opacity: 0.50,
     };
    const style_elFirebaseLogin = {
      pointerEvents: 'auto',
     };
    
    const style_elZipCodeExplore = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const value_zipAutoComplete = this.props.CityAliasName;
    
    const style_elZipAutoComplete = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elSearchButton = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 14.1,
      color: '#feffff',
      textAlign: 'center',
      backgroundColor: '#34a397',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elWhiteBackground = {
      background: 'rgba(254, 255, 255, 1.000)',
     };
    const style_elTextWeAsAComm = {
      fontSize: 24.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elTextBelowWeAsAComm = {
      fontSize: 18.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elGreyBackground = {
      background: 'rgba(220, 219, 221, 1.000)',
     };
    const style_elTextTypesOfCare = {
      fontSize: 24.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elImageIL = {
      backgroundImage: 'url('+img_elImageIL+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elTextIndeLiving = {
      fontSize: 24.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elImageAL = {
      backgroundImage: 'url('+img_elImageAL+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elTextAssistLiving = {
      fontSize: 24.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elSubTextIndeLiving = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elSubTextAssistLiving = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elImageMC = {
      backgroundImage: 'url('+img_elImageMC+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elTextMemoryCare = {
      fontSize: 24.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextSkillNursing = {
      fontSize: 24.7,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elImageSN = {
      backgroundImage: 'url('+img_elImageSN+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elSubTextMemoryCare = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elSubTextSkillNursing = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elContinueWoLogin = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elContinueWoLogin_outer = {
      display: 'none',
     };
    const style_elTextContinueWOlogin = {
      color: '#3368ff',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextContinueWOlogin_outer = {
      display: 'none',
     };
    const style_elCard_outer = {
      display: 'none',
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const style_elWikiLivinglogo = {
      backgroundImage: 'url('+img_elWikiLivinglogo+')',
      backgroundSize: '100% 100%',
     };
    
    return (
      <div className="AppScreen LoginMain2Screen" style={baseStyle}>
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elBackgroundimage">
            <img style={style_elBackgroundimage} src={img_elBackgroundimage} alt=""  />
          </div>
          
          <div className="flowRow flowRow_LoginMain2Screen_elN50Shade_259113480">
          <div className="elN50Shade">
            <div style={style_elN50Shade} />
          </div>
          
          </div>
          <div className="elFirebaseLogin">
            <div style={style_elFirebaseLogin}>
              <FirebaseLogin ref={(el)=> this._elFirebaseLogin = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elZipCodeExplore">
            <input className="baseFont" style={style_elZipCodeExplore} type="number" placeholder="Zip Code" onChange={this.textInputChanged_elZipCodeExplore} value={this.state.zipCodeExplore}  />
          </div>
          
          <div className="flowRow flowRow_LoginMain2Screen_elZipAutoComplete_482917279">
          <div className="elZipAutoComplete">
            <div className="baseFont" style={style_elZipAutoComplete} onClick={this.onClick_elZipAutoComplete} >
              <div>{value_zipAutoComplete}</div>
            </div>
          </div>
          
          </div>
          <div className="elSearchButton">
            <button className="systemFontRegular" style={style_elSearchButton} onClick={this.onClick_elSearchButton} >
              Search
            </button>
          </div>
          
          <div className="elWhiteBackground">
            <div style={style_elWhiteBackground} />
          </div>
          
          <div className="elTextWeAsAComm">
            <div className="systemFontBold" style={style_elTextWeAsAComm}>
              <div>{this.state.textweasacomm}</div>
            </div>
          </div>
          
          <div className="elTextBelowWeAsAComm">
            <div className="systemFontRegular" style={style_elTextBelowWeAsAComm}>
              <div>{this.state.textbelowweasacomm}</div>
            </div>
          </div>
          
          <div className="elGreyBackground">
            <div style={style_elGreyBackground} />
          </div>
          
          <div className="elTextTypesOfCare">
            <div className="systemFontBold" style={style_elTextTypesOfCare}>
              <div>{this.state.texttypesofcare}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_LoginMain2Screen_elImageIL_1200291698">
          <div className="elImageIL">
            <div style={style_elImageIL} />
          </div>
          
          </div>
          <div className="elTextIndeLiving">
            <div className="systemFontBold" style={style_elTextIndeLiving}>
              <div>{this.state.textindeliving}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_LoginMain2Screen_elImageAL_622965024">
          <div className="elImageAL">
            <div style={style_elImageAL} />
          </div>
          
          </div>
          <div className="elTextAssistLiving">
            <div className="systemFontBold" style={style_elTextAssistLiving}>
              <div>{this.state.textassistliving}</div>
            </div>
          </div>
          
          <div className="elSubTextIndeLiving">
            <div className="baseFont" style={style_elSubTextIndeLiving}>
              <div>{this.state.subtextindeliving}</div>
            </div>
          </div>
          
          <div className="elSubTextAssistLiving">
            <div className="baseFont" style={style_elSubTextAssistLiving}>
              <div>{this.state.subtextassistliving}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_LoginMain2Screen_elImageMC_1766942203">
          <div className="elImageMC">
            <div style={style_elImageMC} />
          </div>
          
          </div>
          <div className="elTextMemoryCare">
            <div className="systemFontBold" style={style_elTextMemoryCare}>
              <div>{this.state.textmemorycare}</div>
            </div>
          </div>
          
          <div className="elTextSkillNursing">
            <div className="systemFontBold" style={style_elTextSkillNursing}>
              <div>{this.state.textskillnursing}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_LoginMain2Screen_elImageSN_849261442">
          <div className="elImageSN">
            <div style={style_elImageSN} />
          </div>
          
          </div>
          <div className="elSubTextMemoryCare">
            <div className="baseFont" style={style_elSubTextMemoryCare}>
              <div>{this.state.subtextmemorycare}</div>
            </div>
          </div>
          
          <div className="elSubTextSkillNursing">
            <div className="baseFont" style={style_elSubTextSkillNursing}>
              <div>{this.state.subtextskillnursing}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_LoginMain2Screen_elContinueWoLogin_376818140">
          <div className="elContinueWoLogin" style={style_elContinueWoLogin_outer}>
            <input className="baseFont" style={style_elContinueWoLogin} type="text" placeholder="" onChange={this.textInputChanged_elContinueWoLogin} value={this.state.continueWoLogin}  />
          </div>
          
          <div className="elTextContinueWOlogin" style={style_elTextContinueWOlogin_outer}>
            <div className="baseFont" style={style_elTextContinueWOlogin} onClick={this.onClick_elTextContinueWOlogin} >
              <div>{this.state.textcontinuewologin}</div>
            </div>
          </div>
          
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" />
          </div>
          
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elWikiLivinglogo" style={style_elWikiLivinglogo} />
          </div>
        </div>
      </div>
    )
  }
  
}
